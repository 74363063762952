import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  shipmentType: null, // Can be 'dhl' or 'ups'
  shipmentMethod: {
    method: null, // Can be 'dropoff' or 'pickup'
    date: '',
    area: '',
    price: '',
  },
  senderDetails: {
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    postalAddress: '',
  },
  receiverDetails: {
    name: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    state: '',
    city: '',
    postalAddress: '',
  },
  itemDescription: {
    category: '',
    value: '',
    currency: 'USD',
    description: '',
    items: [],
    quantity: '',
    weight: '',
    length: '',
    width: '',
    height: ''
  },
  deliveryOptions: {
    option: null,
    price: ""
  },
  orderSummary: {},
  payment: {},
};

const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    setShipmentType(state, action) {
      state.shipmentType = action.payload;
    },
    setShipmentMethod(state, action) {
      state.shipmentMethod = { ...state.shipmentMethod, ...action.payload };
    },
    setSenderDetails(state, action) {
      state.senderDetails = { ...state.senderDetails, ...action.payload };
    },
    setReceiverDetails(state, action) {
      state.receiverDetails = { ...state.receiverDetails, ...action.payload };
    },
    setItemDescription(state, action) {
      state.itemDescription = { ...state.itemDescription, ...action.payload };
    },
    setDeliveryOptions(state, action) {
      state.deliveryOptions = { ...state.deliveryOptions, ...action.payload }
    },
    setOrderSummary(state, action) {
      state.orderSummary = action.payload;
    },
    setPayment(state, action) {
      state.payment = action.payload;
    },
    resetShipment(state) {
      return initialState;
    },
  },
});

export const {
  setShipmentType,
  setShipmentMethod,
  setSenderDetails,
  setReceiverDetails,
  setItemDescription,
  setDeliveryOptions,
  setOrderSummary,
  setPayment,
  resetShipment,
} = shipmentSlice.actions;

export default shipmentSlice.reducer;
