import React from "react";
import TanTable from "../../../Utils/TanTable";

const DhlZone = ({ dhlZones, isLoading }) => {
	const columns = [
		{
			header: "Serial Number",
			accessorKey: "sn",
			cell: ({ row }) => row.index + 1,
		},
		{ header: "Zone Numbers", accessorKey: "zone" },
		{ header: "Countries", accessorKey: "country_name" },
		// { header: "Action", accessorKey: "action" }
	];

	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the countries belonging to different Zones
			</h1>
			<TanTable columnData={columns} data={dhlZones} loadingState={isLoading} />
		</div>
	);
};

export default DhlZone;
