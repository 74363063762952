import React from "react";

import { AiOutlineEdit } from "react-icons/ai";

import BackButton from "../../Button/back";
import Btn from "../../Button/btn";

const ShipmentSummary = ({
  inputItems,
  setActiveSlide,
  isLoading,
  createShipment,
  categoryList,
}) => {
  const prevSlide = () => {
    setActiveSlide(1);
  };

  return (
    <div>
      <BackButton action={() => setActiveSlide(1)} />
      <h2 className='text-xl font-medium my-4'>Shipment Summary</h2>
      <div className='bg-white w-full rounded-md shadow-md p-8'>
        <h2 className='text-center my-6'>Review shipment information</h2>
        <div className='overflow-x-scroll'>
          <div className='min-w-[500px]'>
            <div className='grid grid-cols-3 gap-2 font-medium text-xs md:text-sm'>
              <h2>Address:</h2>
              <h2 className='text-gray-600'>
                {inputItems?.state}, {inputItems?.country},{" "}
                {inputItems.delivery_address}, zip code: {inputItems.zip_code},
              </h2>
              <button
                className='flex items-center w-fit ml-auto p-2 rounded-md bg-pry text-black gap-2'
                onClick={prevSlide}
              >
                <h2 className='text-xs hidden md:block'>Edit</h2>
                <i>
                  <AiOutlineEdit />
                </i>
              </button>
            </div>
            <div className='grid grid-cols-3 gap-2 font-medium text-xs md:text-sm mt-4'>
              <h2>Ship From:</h2>
              <h2 className='text-gray-600'>{inputItems.ship_from},</h2>
              <button
                className='flex items-center w-fit ml-auto p-2 rounded-md bg-pry text-black gap-2'
                onClick={prevSlide}
              >
                <h2 className='text-xs hidden md:block'>Edit</h2>
                <i>
                  <AiOutlineEdit />
                </i>
              </button>
            </div>
            <div className='grid grid-cols-3 gap-2 font-medium text-xs md:text-sm mt-4'>
              <h2>Delivery Phone no:</h2>
              <h2 className='text-gray-600'>{inputItems.delivery_number}</h2>
              <button
                className='flex items-center w-fit ml-auto p-2 rounded-md bg-pry text-black gap-2'
                onClick={prevSlide}
              >
                <h2 className='text-xs hidden md:block'>Edit</h2>
                <i>
                  <AiOutlineEdit />
                </i>
              </button>
            </div>
            <div className='grid grid-cols-3 gap-2 font-medium text-xs md:text-sm mt-4'>
              <h2>Store Name:</h2>
              <h2 className='text-gray-600'>{inputItems.store_name}</h2>
              <button
                className='flex items-center w-fit ml-auto p-2 rounded-md bg-pry text-black gap-2'
                onClick={prevSlide}
              >
                <h2 className='text-xs hidden md:block'>Edit</h2>
                <i>
                  <AiOutlineEdit />
                </i>
              </button>
            </div>
            <div className='my-8 border-[1px] rounded-md'>
              <div className='border-b p-2 grid grid-cols-3 items-center justify-between gap-2 font-medium text-xs md:text-sm bg-gray-100 rounded-md'>
                <h2>Category</h2>
                <h2>Qty</h2>
                <h2>Tracking ID</h2>
              </div>
              {categoryList.map(({ category, quantity, tracking_id }, ind) => (
                <div
                  key={ind}
                  className='border-b p-2 grid grid-cols-3 items-center justify-between gap-2 font-medium text-xs md:text-sm'
                >
                  <h2 className='text-gray-600'>{category}</h2>
                  <h2 className='text-gray-600'>{quantity}</h2>
                  <div className='flex justify-between gap-2 items-center'>
                    <h2 className='text-gray-600'>{tracking_id}</h2>
                    <button
                      className='flex items-center w-fit ml-auto p-2 rounded-md bg-pry text-black gap-2'
                      onClick={prevSlide}
                    >
                      <h2 className='text-xs hidden md:block'>Edit</h2>
                      <i>
                        <AiOutlineEdit />
                      </i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Btn
          text={"Continue"}
          className='w-full bg-pry my-6'
          onClick={createShipment}
          loadingState={isLoading}
        />
      </div>
    </div>
  );
};

export default ShipmentSummary;
