import React, { useEffect, useState } from "react";

import { Spinner } from "react-activity";

import axios from "axios";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config, imageUrl } from "../../../Utils/constants";
import img from "../../../Assets/images/userEmoji.png";
import Export from "../../../Utils/Admin/export";
import Filters from "../../../Utils/Admin/filters";
import Search from "../../../Utils/Admin/search";
import User from "../../../Components/Admin/User/user";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);

  const userHeader = [
    "Photo",
    "Name",
    "Phone Number",
    "Email",
    "Referral Code",
    "Address",
    "Status",
  ];

  const columns = [
    { header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
    {
      header: "Photo",
      accessorKey: "zone",
      cell: ({ row }) => {
        const profile_photo = row.original.profile_photo;

        const profileImage = profile_photo
          ? `${imageUrl}${profile_photo}`
          : img;
        return (
          <img
            src={profileImage}
            className="w-10 h-10 rounded-full object-cover"
            alt="user"
          />
        );
      },
    },
    { header: "Full name", accessorKey: "fullname" },
    {
      header: "User Type",
      accessorKey: "user_type",
      cell: ({ row }) => {
        const userType = row.original.user_type;
        // console.log("User type from row:", userType); 
        return (
          userType?.charAt(0).toUpperCase() + userType?.slice(1).toLowerCase()
        );
      },
    },
    // { header: "User Type", accessorKey: "user_type" },
    // { header: "CAC Document", accessorKey: "cac" },
    {
      header: "CAC Document",
      accessorKey: "cac",
      cell: ({ row }) => {
        const cacDocument = row.original.cac; // Adjust according to your API response key
        if (cacDocument) {
          return (
            <button
              onClick={() =>
                window.open(`${imageUrl}/${cacDocument}`, "_blank")
              }
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Download CAC
            </button>
          );
        }
        return <span className="text-gray-500">No Document</span>;
      },
    },
    { header: "Phone Number", accessorKey: "phone_number" },
    { header: "Email", accessorKey: "email" },
    { header: "Referral code", accessorKey: "referral_code" },
    { header: "Address", accessorKey: "address" },
    {
      header: "Status",
      accessorKey: "state",
      cell: ({ row }) => <User item={row.original} />,
    },
  ];

  const { adminToken } = useSelector((state) => state.admin);

  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    const url = `${baseUrl}admin/users`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      // console.log("User data from API:", data.data);
      setIsLoading(false);
      setUsers(data.data);
    } catch (error) {
      // console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);


  return (
    <div>
      <div className="flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md">
        <h2 className="font-bold">
          Total Users{" "}
          <span className="text-gray-500 font-normal">({users?.length})</span>
        </h2>
        <div className="hidden xl:block">
          <Search />
        </div>
        <div className="hidden md:block">
          <Filters />
        </div>
        <Export
          data={users}
          filename="users"
          title="All Users"
          headers={userHeader}
        />
      </div>

      <TanTable data={users} columnData={columns} loadingState={isLoading} />
    </div>
  );
};

export default Users;
