// src/UsdtDeposit.js
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { FaCopy } from "react-icons/fa";
import BtnGrid from "../../Button/BtnGrid";
import { useNavigate } from "react-router-dom";

const DepositUsdtBarcode = () => {
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [walletAddress, setWalletAddress] = useState(
    "1QYTYUYUI380987486FY55YG6UHUUJ8"
  );

  const handleNetworkChange = (event) => {
    setSelectedNetwork(event.target.value);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(walletAddress);
    alert("Wallet address copied!");
  };

  const handleDone = () => {
    navigate("/dashboard/wallet-Tabs");
  };
  const navigate = useNavigate();

  return (
    <div className="w-full p-4">
      <h1 className="semi-bold text-center mb-4">USDT Deposit</h1>
      <div className="mb-8 flex flex-col items-center">
        <QRCode value={walletAddress} size={160} />
        <div className="text-center mt-4">Scan Code to Deposit USDT</div>
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="wallet"
        >
          Wallet
        </label>
        <div className="flex">
          <input
            type="text"
            id="wallet"
            value={walletAddress}
            readOnly
            className="block w-full bg-gray-100 border border-gray-300 rounded-l py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          />
          <button
            onClick={handleCopy}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-3 px-4 rounded-r"
          >
            <FaCopy />
          </button>
        </div>
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="network"
        >
          Network
        </label>
        <select
          id="network"
          value={selectedNetwork}
          onChange={handleNetworkChange}
          className="block w-full bg-gray-100 border border-gray-300 rounded py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
          <option value="" disabled>
            Select Network
          </option>
          <option value="Tron">Tron (TRC-20) arrival time (2 min)</option>
          <option value="Ethereum">Solana arrival time (2 min)</option>
          <option value="Binance">
            BNB Smart Chain (BEP-20) arrival time (3 min)
          </option>
          <option value="Ethereum">
            Ethereum (ERC-20) arrival time (4 min)
          </option>
        </select>
      </div>
      <div className="text-sm text-red-600">
        <p>Send only USDT using the network you have selected</p>
        <p>
          {" "}
          Sending coins or tokens other than USDT to this address may result in
          the loss of funds.
        </p>
      </div>
      <div className="flex items-center justify-center pt-8">
        <BtnGrid
          className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24"
          onClick={handleDone}
        >
          Done
        </BtnGrid>
      </div>
    </div>
  );
};

export default DepositUsdtBarcode;
