import box from '../../../../Assets/images/Cardboard box.png'

const VolumetricInfoBox = () => {
    return (
        <div className="p-5 bg-[#49917066] rounded-2xl">
            <p className="text-[#282828]">
                <span className="text-[#008E56] font-semibold">Volumteric</span> {" "}
                weight may apply if  your package is shipped in a box or has visible dimensions (i.e square or rectangularly shaped).
            </p>
            <div className='flex sm:flex-row flex-col justify-between gap-5'>
                <p className='text-[#282828] mt-5'>
                    Volumetric weight formula: L x B x H/5000
                </p>
                <div className='flex flex-col justify-center items-center'>
                    <img src={box} alt='box item' className='h-[140px] w-[210px]' />
                    <p className='border-b-[1px] border-b-[#000] px-2.5 text-center w-fit'>
                        Length x Breadth x Height
                    </p>
                </div>
            </div>
        </div>
    )
}

export default VolumetricInfoBox
