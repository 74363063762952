import { createSlice  } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  token: null,
  card: false,
  verification: null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserProfile: (state, { payload }) => {
      state.user = payload;
      state.verification = payload?.verification;
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
    setDollarCardAvailable: (state, { payload }) => {
      state.card = payload;
    },

    setVerification: (state, { payload }) => {
      state.verification = payload;
      if (state.user) {
        state.user.verification = payload;
      }
    }
  }
});
export const {
  setUserProfile,
  setToken,
  setDollarCardAvailable,
  setVerification
} = authSlice.actions;
export default authSlice.reducer;
