import React from "react";

export const convertCurrencyToNaira = (amount) => {
	return amount.toLocaleString('en-NG', { style: "currency", currency: "NGN" })
}

export const FormatCurrency = (amount) => {
	let priceFormat = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "NGN",
		currencyDisplay: "code",
	});

	// Replace "NGN" with the Naira symbol
	return priceFormat.format(amount).replace("NGN", "₦");
};

const Currency = ({ amount, className }) => {
	let priceFormat = Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "NGN",
		currencyDisplay: "code",
	});

	// Replace "NGN" with the Naira symbol
	return (
		<h2 className={className}>
			{priceFormat.format(amount).replace("NGN", "₦")}
		</h2>
	);
};

export default Currency;
