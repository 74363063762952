import React, { useEffect, useState } from "react";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { IoCloseOutline } from "react-icons/io5";
import {
  AiFillCreditCard,
  AiFillCalculator,
  AiFillDollarCircle,
} from "react-icons/ai";
import { IoIosPeople } from "react-icons/io";
import { HiUsers } from "react-icons/hi";
import { FaTruck, FaWallet, FaWarehouse } from "react-icons/fa";
import { SiSlideshare, SiConvertio } from "react-icons/si";
import { CiLogout } from "react-icons/ci";
import { MdOutlineSecurity } from "react-icons/md";
import { IoPricetagOutline } from "react-icons/io5";

import logo from "../../../Assets/images/logo.png";

const Sidebar = ({ closeSidebar }) => {
  const sidebarLinks = [
    { text: "Dashboard", link: "" },
    { text: "Total Users", link: "total-users" },
    { text: "Virtual Dollar Card", link: "virtual-dollar-card" },
    { text: "Shipment", link: "manage-shipment" },
    { text: "Rates", link: "dollar-rate" },
    { text: "Insurance Rate", link: "insurance" },
    { text: "Referrals", link: "referrals" },
    { text: "Tracking", link: "tracking" },
    { text: "Orders & Payment", link: "manageorder-and-payment" },
    { text: "Delivery Calculator Import", link: "delivery-calculator" },
    { text: "Delivery Calculator Cargo", link: "delivery-calculator-cargo" },
    { text: "Cargo Price", link: "cargo-price" },
    { text: "Wallet", link: "manage-wallet" },
    { text: "Fulfillment", link: "fulfillment" },
    { text: "KYC", link: "know-your-customer" },
    { text: "Pricing", link: "pricing" },
  ];

  const location = useLocation();
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const path = location.pathname;
    console.log("Current path:", path);
  
    if (path === "/secretroute/admin") {
      setActiveSlide(0);
    } else if (path.includes("/admin/total-users")) {
      setActiveSlide(1);
    } else if (path.includes("/admin/virtual-dollar-card")) {
      setActiveSlide(2);
    } else if (path.includes("/admin/manage-shipment")) {
      setActiveSlide(3);
    } else if (path.includes("/admin/dollar-rate")) {
      setActiveSlide(4);
    } else if (path.includes("/admin/insurance")) {
      setActiveSlide(5);
    } else if (path.includes("/admin/referrals")) {
      setActiveSlide(6);
    } else if (path.includes("/admin/tracking")) {
      setActiveSlide(7);
    } else if (path.includes("/admin/manageorder-and-payment")) {
      setActiveSlide(8);
    } else if (path.includes("/admin/delivery-calculator-cargo")) {
      setActiveSlide(9);
    } else if (path.includes("/admin/delivery-calculator")) {
      setActiveSlide(10);
    } else if (path.includes("/admin/manage-wallet")) {
      setActiveSlide(11);
    } else if (path.includes("/admin/fulfillment")) {
      setActiveSlide(12);
    } else if (path.includes("/admin/know-your-customer")) {
      setActiveSlide(13);
    } else if (path.includes("/admin/pricing")) {
      setActiveSlide(14);
    } else if (path.includes("/admin/cargo-price")) {
      setActiveSlide(15);
    } else {
      setActiveSlide(""); // Default case
    }
  }, [location]);

  const navigateTo = useNavigate();
  const logout = () => {
    navigateTo("/secretroute/login");
  };

  return (
    <div className="shadow-md h-screen p-6 md:p-8 overflowHide">
      <div className="flex justify-between items-center">
        <img src={logo} alt="logo" className="w-20" />
        <IoCloseOutline
          className="text-3xl text-sec2 md:hidden"
          onClick={closeSidebar}
        />
      </div>

      <div className="flex flex-col gap-1 my-10">
        {sidebarLinks.map(({ link, text }, ind) => {
          return (
            <Link
              to={link}
              key={ind}
              className={`${
                ind === activeSlide ? "bg-pry2 text-sec2" : "text-gray-600"
              } flex font-medium text-lg items-center gap-2 p-4 rounded-md md:hover:bg-gray-100`}
              onClick={() => {
                closeSidebar();
              }}
            >
              <i className="text-xl">
                {ind === 0 ? (
                  <IoIosPeople />
                ) : ind === 1 ? (
                  <HiUsers />
                ) : ind === 2 ? (
                  <AiFillCreditCard />
                ) : ind === 3 ? (
                  <FaTruck />
                ) : ind === 4 ? (
                  <AiFillCreditCard />
                ) : ind === 5 ? (
                  <AiFillDollarCircle />
                ) : ind === 6 ? (
                  <AiFillDollarCircle />
                ) : ind === 7 ? (
                  <SiSlideshare />
                ) : ind === 8 ? (
                  <SiConvertio />
                ) : ind === 9 ? (
                  <AiFillCalculator />
                ) : ind === 10 ? (
                  <AiFillCalculator />
                ) : ind === 11 ? (
                  <FaWallet />
                ) : ind === 12 ? (
                  <FaWarehouse />
                ) : ind === 13 ? (
                  <IoPricetagOutline />
                ) : (
                  <MdOutlineSecurity />
                )}
              </i>

              <h2 className="text-sm">{text}</h2>
            </Link>
          );
        })}
        <hr className="bg-gray-100 h-[1px] my-10" />
        <button
          className="flex items-center gap-4 font-medium"
          onClick={logout}
        >
          <i className="text-xl text-red-600">
            <CiLogout />
          </i>
          <h2>Logout</h2>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
