import React, { useState } from "react";
import { FiCopy } from "react-icons/fi";
import BtnGrid from "../../../Button/BtnGrid";
import BackButton from "../../../Button/back";
import OTP from "../../../../Utils/OTP";
import CustomModal from "./CustomModal";
import SuccessModal from "../../../../Components/Modals/success";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

const Withdraw = () => {
  const navigate = useNavigate();
  const [bank, setBank] = useState("Sterling bank");
  const [accountNumber] = useState("6532189076");
  const [accountName] = useState("Adewale Adeniyi O.");
  const [amount, setAmount] = useState("₦10000");
  const [balance] = useState("NGN 25000");
  const [narration, setNarration] = useState("Money for flexing");
  const [saveBeneficiary, setSaveBeneficiary] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(accountNumber);
    alert("Account number copied to clipboard");
  };

  const handleContinueClick = () => {
    setShowOtpModal(true);
  };

  const handleOtpSubmit = () => {
    // Handle OTP submission
    setShowOtpModal(false);
    setShowSuccessModal(true);
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
     navigate("/dashboard/wallet-Tabs");
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="w-full p-4">
      <IoArrowBackOutline onClick={goBackHandler} />
      <div className="text-center mb-8">
        <h1 className="text-2xl font-semibold">Withdraw</h1>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="w-full mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Select Bank
          </label>
          <div className="mt-1 relative">
            <select
              value={bank}
              onChange={(e) => setBank(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded-md"
            >
              <option value="Sterling bank">Sterling bank</option>
              <option value="Other bank">Other bank</option>
              {/* Add more options as needed */}
            </select>
          </div>
        </div>
        <div className="w-full mb-6 relative">
          <label className="block text-sm font-medium text-gray-700">
            Beneficiary Account Number
          </label>
          <div className="mt-1 flex">
            <input
              type="text"
              value={accountNumber}
              readOnly
              className="block w-full p-2 border border-gray-300 rounded-md"
            />
            <button
              type="button"
              onClick={copyToClipboard}
              className="ml-2 p-2 border border-gray-300 rounded-md text-gray-500 hover:text-gray-700"
            >
              <FiCopy size={20} />
            </button>
          </div>
          <div className="text-sm text-gray-500 mt-1">{accountName}</div>
        </div>
        <div className="w-full mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Amount
          </label>
          <div className="mt-1">
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter amount"
            />
          </div>
          <div className="text-sm text-gray-500 mt-1">Balance: {balance}</div>
        </div>
        <div className="w-full mb-6">
          <label className="block text-sm font-medium text-gray-700">
            Narration
          </label>
          <div className="mt-1">
            <input
              type="text"
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter narration"
            />
          </div>
        </div>
        <div className="w-full mb-6 flex items-center">
          <input
            type="checkbox"
            checked={saveBeneficiary}
            onChange={(e) => setSaveBeneficiary(e.target.checked)}
            className="h-4 w-4 text-green-600 border-gray-300 rounded"
          />
          <label className="ml-2 block text-sm text-gray-900">
            Save beneficiary for future Transaction
          </label>
        </div>
        <div className="w-full flex justify-center">
          <BtnGrid
            type="button"
            onClick={handleContinueClick}
            className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24"
          >
            Continue
          </BtnGrid>
        </div>
      </form>

      {/* OTP Modal */}
      <CustomModal isOpen={showOtpModal} onClose={() => setShowOtpModal(false)}>
        <div className="p-3 max-w-sm mx-auto">
          <h2 className="text-center text-lg font-semibold mb-3">
            Enter Transaction Pin
          </h2>
          <div className="flex justify-center mb-5">
            <OTP length={4} onChange={handleOtpChange} value={otp} />
          </div>
          <div className="flex justify-around">
            <BtnGrid
              onClick={() => setShowOtpModal(false)}
              className="bg-white border border-gray-300 text-gray-700 px-5 py-2 rounded-md"
            >
              Cancel
            </BtnGrid>
            <BtnGrid
              onClick={handleOtpSubmit}
              className="bg-[#008E56] text-white px-5 py-2 rounded-md"
            >
              Submit
            </BtnGrid>
          </div>
        </div>
      </CustomModal>

      {/* Success Modal */}
      {showSuccessModal && (
        <SuccessModal
          visibilityState={showSuccessModal}
          setShowSuccessModal={handleModalClose}
        >
          <h2 className="text-center text-lg font-semibold">Success!</h2>
          <p className="text-center mt-2">
            You have successfully withdrawn ₦10000. Kindly check your wallet
            balance for confirmation.
          </p>
        </SuccessModal>
      )}
    </div>
  );
};

export default Withdraw;
