const SwiftDeliveryIcon = ({ styles, color }) => {
    return (
        <svg className={`${styles}`} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_2_23261)">
            <path d="M24.6667 24.667L31.3334 31.3337M10.6667 10.0003V20.667M18.6667 10.0003V20.667M11.3334 10.0003C11.3334 9.11627 11.6846 8.26842 12.3097 7.6433C12.9348 7.01818 13.7827 6.66699 14.6667 6.66699C15.5508 6.66699 16.3986 7.01818 17.0238 7.6433C17.6489 8.26842 18.0001 9.11627 18.0001 10.0003M14.6667 28.667C6.93475 28.667 0.666748 22.399 0.666748 14.667C0.666748 6.93499 6.93475 0.666992 14.6667 0.666992C22.3987 0.666992 28.6667 6.93499 28.6667 14.667C28.6667 22.399 22.3987 28.667 14.6667 28.667ZM22.6667 20.667H6.66675V20.3337L6.83741 19.307C7.27594 16.6763 7.27594 13.9911 6.83741 11.3603L6.66675 10.3337V10.0003H22.6667V10.3337L22.4961 11.3603C22.0576 13.9911 22.0576 16.6762 22.4961 19.307L22.6667 20.3337V20.667Z" stroke={color || "#282828"}/>
            </g>
            <defs>
            <clipPath id="clip0_2_23261">
            <rect width="32" height="32" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    )
}

export default SwiftDeliveryIcon