import React, { useState, useEffect } from "react";

import * as XLSX from "xlsx";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl } from "../../../Utils/constants";
import { FormatCurrency } from "../../../Utils/currency";

const Upsworld = () => {
  const [uploadedPrices, setUploadedPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);

  // Safe currency formatter with fallback
  const SafeFormatCurrency = (value) => {
    if (value === undefined || value === null || value === '') {
      return '-';
    }
    
    const numValue = Number(value);
    return isNaN(numValue) ? '-' : FormatCurrency(numValue);
  };

  const columns = [
    { 
      header: "S/N", 
      accessorKey: "sn", 
      cell: ({ row }) => row.index + 1 
    },
    { 
      header: "KG", 
      accessorKey: "kg",
      cell: ({ row }) => row.original.kg || '-'
    },
    { 
      header: "Zone 1", 
      accessorKey: "zone_1", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_1) 
    },
    { 
      header: "Zone 2", 
      accessorKey: "zone_2", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_2) 
    },
    { 
      header: "Zone 3", 
      accessorKey: "zone_3", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_3) 
    },
    { 
      header: "Zone 4", 
      accessorKey: "zone_4", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_4) 
    },
    { 
      header: "Zone 5", 
      accessorKey: "zone_5", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_5) 
    },
    { 
      header: "Zone 6", 
      accessorKey: "zone_6", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_6) 
    },
    { 
      header: "Zone 7", 
      accessorKey: "zone_7", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_7) 
    },
    { 
      header: "Zone 8", 
      accessorKey: "zone_8", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_8) 
    },
    { 
      header: "Zone 9", 
      accessorKey: "zone_9", 
      cell: ({ row }) => SafeFormatCurrency(row.original.zone_9) 
    },
  ];

  // Delete pricing
  const handleDeletePricing = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`${baseUrl}admin/delete-ups-price`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      setUploadedPrices([]);
      const successMessage = response.data.message || "Pricing deleted successfully!";
      toast.success(successMessage);
    } catch (error) {
      // console.error("Error deleting pricing:", error);
      const errorMessage = error.response?.data?.message || "Failed to delete pricing. Please try again.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch DHL Pricing Data
  const fetchDhlPricing = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}admin/get-ups-pricing`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      
      const formattedData = response.data.data.map(item => ({
        kg: String(item.kg || ''),
        zone_1: String(item.zone_1 || ''),
        zone_2: String(item.zone_2 || ''),
        zone_3: String(item.zone_3 || ''),
        zone_4: String(item.zone_4 || ''),
        zone_5: String(item.zone_5 || ''),
        zone_6: String(item.zone_6 || ''),
        zone_7: String(item.zone_7 || ''),
        zone_8: String(item.zone_8 || ''),
        zone_9: String(item.zone_9 || ''),
      }));
      
      setUploadedPrices(formattedData);
    } catch (error) {
      console.error("Error fetching DHL pricing data:", error);
      toast.error("Failed to fetch pricing data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setIsLoading(true);
  
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const workbook = XLSX.read(event.target.result, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
  
            const formattedData = data.map(row => ({
              kg: String(row.KG || ''),
              zone_1: String(row["Zone 1"] || ''),
              zone_2: String(row["Zone 2"] || ''),
              zone_3: String(row["Zone 3"] || ''),
              zone_4: String(row["Zone 4"] || ''),
              zone_5: String(row["Zone 5"] || ''),
              zone_6: String(row["Zone 6"] || ''),
              zone_7: String(row["Zone 7"] || ''),
              zone_8: String(row["Zone 8"] || ''),
              zone_9: String(row["Zone 9"] || ''),
            }));
  
            const formData = new FormData();
            formData.append("ups_price", file);
            formData.append("prices", JSON.stringify(formattedData));
  
            const response = await axios.post(`${baseUrl}admin/upload-ups-price`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${adminToken}`,
              },
            });
  
            // After successful upload, immediately fetch the latest data
            await fetchDhlPricing();
            
            const successMessage = response.data?.message || "Price list uploaded successfully!";
            toast.success(successMessage);
          } catch (error) {
            console.error("Error parsing or uploading Excel file:", error);
  
            const backendMessage = error.response?.data?.message || "Failed to upload file.";
            toast.error(backendMessage);
          } finally {
            setIsLoading(false);
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        console.error("File reading error:", error);
        toast.error("Error reading file.");
        setIsLoading(false);
      }
    }
  };

  // Initial data fetch when component mounts
  useEffect(() => {
    if (adminToken) {
      fetchDhlPricing();
    }
  }, [adminToken]);

  return (
    <div className="w-full">
      <ToastContainer position="top-right" />
      <div className="mb-4 flex justify-between items-center">
        <label
          htmlFor="excel-upload"
          className="block w-full p-4 border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-100 mr-4"
        >
          Upload Excel File Ng To The World Price
          <input
            type="file"
            id="excel-upload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className="hidden"
          />
        </label>
        {uploadedPrices.length > 0 && (
          <button
            onClick={handleDeletePricing}
            disabled={isLoading}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:opacity-50"
          >
            {isLoading ? "Deleting..." : "Delete Pricing"}
          </button>
        )}
      </div>

      <h1 className="text-center mb-4">
        {uploadedPrices.length > 0
          ? "Uploaded Price List"
          : "Below are the price list belonging to different countries based on their zones"}
      </h1>

      <TanTable 
        columnData={columns} 
        data={uploadedPrices} 
        loadingState={isLoading} 
      />
    </div>
  );
};

export default Upsworld;