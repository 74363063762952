import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Admin from "../Pages/Admin";
import Dashboard from "../Pages/Dashboard";
import KycPage from "../Pages/KycPage.js";
import LandingPage from "../Pages/LandingPage";
import AdminLogin from "../Pages/Admin/AdminLogin";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import Login from "../Pages/Auth/Login";
import Signup from "../Pages/Auth/Signup";
import VerifyOTP from "../Pages/Auth/VerifyOTP";
import Address from "../Pages/Dashboard/Address";
import AddressFormPage from "../Pages/Dashboard/AddressFormPage";
// import Calculator from "../Pages/Dashboard/Calculator";
import CardDetailsPage from "../Pages/Dashboard/CardDetailsPage";
import CardDetailsPinPage from "../Pages/Dashboard/CardDetailsPinPage.jsx";
import CardScreenPage from "../Pages/Dashboard/CardScreenPage";
import CargoPage from "../Pages/Dashboard/CargoPage.jsx";
import ClientAddressPage from "../Pages/Dashboard/ClientAddressPage";
import ConTransactionPage from "../Pages/Dashboard/ConTransactionPage";
import DhlPricePage from "../Pages/Dashboard/DhlPricePage";
import FedexPricePage from "../Pages/Dashboard/FedexPricePage";
import FulfilmentPage from "../Pages/Dashboard/FulfilmentPage";
import FundCardPage from "./../Pages/Dashboard/FundCardPage";
import DashboardHome from "../Pages/Dashboard/Home";
import ManageCardPage from "../Pages/Dashboard/ManageCardPage";
import NgnWithdrawPage from "./../Pages/Dashboard/NgnWithdrawPage";
import Notification from "../Pages/Dashboard/Notification";
import OrderInfo from "../Pages/Dashboard/OrderInfo";
import Profile from "../Pages/Dashboard/Profile";
import Referral from "../Pages/Dashboard/Referral";
import RegisterGoodsFormPage from "../Pages/Dashboard/RegisterGoodsFormPage";
import SentGoodsFormPage from "../Pages/Dashboard/SentGoodsFormPage";
import UpsPricePage from "../Pages/Dashboard/UpsPricePage";
import UsdtWithdrawPage from "./../Pages/Dashboard/UsdtWithdrawPage";
import Virtual from "../Pages/Dashboard/Virtual";
import Wallet from "../Pages/Dashboard/Wallet";
import WalletBankWithPage from "./../Pages/Dashboard/WalletBankWithPage";
import WalletTabPage from "../Pages/Dashboard/WalletTabPage";
import WithUsdtPage from "../Pages/Dashboard/WithUsdtPage";
import ShipmentInformation from "../Pages/Dashboard/shipmentInformation";
import About from "../Pages/LandingPage/About";
import Contact from "../Pages/LandingPage/Contact";
import DollarCard from "../Pages/LandingPage/DollarCard";
import ExchangeRate from "../Pages/LandingPage/ExchangeRate";
import Home from "../Pages/LandingPage/Home";
import HowItWorks from "../Pages/LandingPage/HowItWorks";
import HowToShip from "../Pages/LandingPage/HowToShip";
import Partner from "../Pages/LandingPage/Partner";
import Pricing from "../Pages/LandingPage/Pricing";
import Privacy from "../Pages/LandingPage/Privacy";
import ProhibitedPage from "./../Pages/LandingPage/ProhibitedPage";
import ShippingGuidelines from "../Pages/LandingPage/ShippingGuidelines";
import Track from "../Pages/LandingPage/Track";
import WhyChoose from "../Pages/LandingPage/WhyChoose";
import Single from "../Components/Dashboard/OrderInfo/Single";
import ConfirmPin from "../Components/Dashboard/VirtualCard/confirmPin.js";
import CashDeposit from "../Components/Dashboard/Wallet/CashDeposit";
import DepositUsdtBarcode from "../Components/Dashboard/Wallet/DepositUsdtBarcode";
import UsdtDeposit from "../Components/Dashboard/Wallet/UsdtDeposit";
import UsdtWalletTopUp from "../Components/Dashboard/Wallet/UsdtwalletTopup";
import ConfirmPayment from "../Components/Dashboard/Wallet/confirmPayment";
import CargoOrderDetails from "../Pages/Admin/AdminDashboard/CargoOrderDetails";
import AdminDashboard from "../Pages/Admin/AdminDashboard/Dashboard";
import DeliveryCalculator from "../Pages/Admin/AdminDashboard/DeliveryCalculator";
import DollarRate from "../Pages/Admin/AdminDashboard/DollarRate";
import ExpressOrderDetails from "../Pages/Admin/AdminDashboard/ExpressOrderDetails";
import Fulfilment from "../Pages/Admin/AdminDashboard/Fulfilment";
import ImportDetails from "../Pages/Admin/AdminDashboard/ImportDetails";
import Insurance from "../Pages/Admin/AdminDashboard/Insurance.jsx";
import KnowYourCus from "../Pages/Admin/AdminDashboard/KnowYourCus";
import ManageAdminWallet from "../Pages/Admin/AdminDashboard/ManageAdminWallet";
import ManageFulfillDetails from "../Pages/Admin/AdminDashboard/ManageFulfillDetails";
import ManageOrders from "../Pages/Admin/AdminDashboard/ManageOrders";
import ManageShipment from "../Pages/Admin/AdminDashboard/ManageShipment";
import Notifications from "../Pages/Admin/AdminDashboard/Notifications";
import Referrals from "../Pages/Admin/AdminDashboard/Referrals";
import Tracking from "../Pages/Admin/AdminDashboard/Tracking";
import Users from "../Pages/Admin/AdminDashboard/Users";
import VirtualDollarCard from "../Pages/Admin/AdminDashboard/VirtualDollarCard";
import AdminPricing from "../Pages/Admin/AdminDashboard/pricing";
import ConfirmShipmentPayment from "../Components/Dashboard/ExpressShipping/NewImplementation/ConfirmShipmentPayment.jsx";
import ShipmentPage from "../Components/Dashboard/ExpressShipping/NewImplementation/ShipmentPage";
import ConfirmCargoPayment from "../Components/Dashboard/OrderInfo/ConfirmCargoPayment"
import DeliveryCalculatorCargo from "../Pages/Admin/AdminDashboard/DeliveryCalculatorCargo"
import CalculatorTab from "../Pages/Dashboard/CalculatorTab";
import FulfillmentPage from "../Pages/LandingPage/FulfillmentPage.jsx";
import CargoPrice from "../Pages/Admin/AdminDashboard/CargoPrice.jsx";
const AppRoutes = () => {
	return (
		<Router>
			<Routes>
				<Route path='paymentresponse' element={<ConfirmPayment />} />
				<Route path='confirm-shipment-payment' element={<ConfirmShipmentPayment />} />
				<Route path='confirm-cargo-payment' element={<ConfirmCargoPayment />} />
				<Route path='/' element={<LandingPage />}>
					<Route index element={<Home />} />
					<Route path='track/:trackID/' element={<Track />} />
					<Route path='/about' element={<About />} />
					<Route path='/dollar-card' element={<DollarCard />} />
					<Route path='/contact' element={<Contact />} />
					<Route path='/pricing' element={<Pricing />} />
					<Route path='/exchange-rate' element={<ExchangeRate />} />
					<Route path='/partner-with-us' element={<Partner />} />
					<Route path='/shipping-guidelines' element={<ShippingGuidelines />} />
					<Route path='/why-choose-eShopAfrica' element={<WhyChoose />} />
					<Route path='/how-eShop-Africa-works' element={<HowItWorks />} />
					<Route path='/how-to-ship' element={<HowToShip />} />
					<Route path='/privacy-policy' element={<Privacy />} />
					<Route path='/fulfillment-services' element={<FulfillmentPage />} />
					<Route path='manage-prohibited-items' element={<ProhibitedPage />} />
				</Route>

				<Route path='/signup' element={<Signup />} />
				<Route path='/login' element={<Login />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
				<Route path='/verify-signup' element={<VerifyOTP />} />

				{/* USER DASHBOARD */}
				<Route path='/dashboard' element={<Dashboard />}>
					<Route index element={<DashboardHome />} />
					<Route path='virtual-card' element={<Virtual />} />
					<Route path='pin' element={<ConfirmPin />} />
					<Route path='card-details-pin' element={<CardDetailsPinPage />} />
					<Route path='card' element={<CardScreenPage />} />
					<Route path='fund-card' element={<FundCardPage />} />
					<Route path='card-details' element={<CardDetailsPage />} />
					<Route path='manage-card' element={<ManageCardPage />} />
					<Route path='referral' element={<Referral />} />
					 <Route path='cargo' element={<CargoPage />} />
					<Route path='profile' element={<Profile />} />
					<Route path='wallet' element={<Wallet />} />
					<Route path='wallet-Tabs' element={<WalletTabPage />} />
					<Route path='usdt-wallet-topup' element={<UsdtWalletTopUp />} />
					<Route path='usdt-deposit' element={<UsdtDeposit />} />
					<Route path='barcode' element={<DepositUsdtBarcode />} />
					<Route path='cash-deposit' element={<CashDeposit />} />
					<Route path='ngn-withdraw' element={<NgnWithdrawPage />} />
					<Route path='usdt-withdraw' element={<UsdtWithdrawPage />} />
					<Route path='wallet-bank-withdraw' element={<WalletBankWithPage />} />
					<Route path='with-usdt' element={<WithUsdtPage />} />
					<Route path='confirm-transaction' element={<ConTransactionPage />} />
					<Route path='address' element={<Address />} />
					<Route path='client-address' element={<ClientAddressPage />} />
					<Route path='add-address' element={<AddressFormPage />} />
					<Route path='order-info' element={<OrderInfo />} />
					<Route path='order-info/:id' element={<ShipmentInformation />} />
					<Route path='singlecargo/:id' element={<Single />} />
					{/* <Route path='delivery-calculator' element={<Calculator />} /> */}
					<Route path='calculator' element={<CalculatorTab />} />
					<Route path='notifications' element={<Notification />} />
					<Route path='dhlprice' element={<DhlPricePage />} />
					<Route path='upsprice' element={<UpsPricePage />} />
					<Route path='fedexprice' element={<FedexPricePage />} />
					<Route path='fulfilment' element={<FulfilmentPage />} />
					<Route path='register-goods' element={<RegisterGoodsFormPage />} />
					<Route path='sentout-goods' element={<SentGoodsFormPage />} />
					<Route path='kyc' element={<KycPage />} />
					{/* Route for new express shipment implementation */}
					<Route path='express-shipment' element={<ShipmentPage />} />
				</Route>

				{/* ADMIN DASHBOARD`` */}
				<Route path='/secretroute/login' element={<AdminLogin />} />
				<Route path='/secretroute/admin' element={<Admin />}>
					<Route index element={<AdminDashboard />} />
					<Route path='total-users' element={<Users />} />
					<Route path='delivery-calculator' element={<DeliveryCalculator />} />
					<Route path='delivery-calculator-cargo' element={<DeliveryCalculatorCargo />} />
					<Route path='cargo-price' element={<CargoPrice />} />
					<Route path='dollar-rate' element={<DollarRate />} />
					{/* <Route path='orders-and-payment' element={<OrdersAndPayment />} /> */}
					<Route path='manageorder-and-payment' element={<ManageOrders/>} />
					<Route path='insurance' element={<Insurance />} />
					<Route path='pricing' element={<AdminPricing />} />
					<Route path='referrals' element={<Referrals />} />
					<Route path='manage-shipment' element={<ManageShipment />} />
					<Route path='tracking' element={<Tracking />} />
					<Route path='virtual-dollar-card' element={<VirtualDollarCard />} />
					<Route path='notifications' element={<Notifications />} />
					<Route path='manage-wallet' element={<ManageAdminWallet />} />
					<Route path='fulfillment' element={<Fulfilment />} />
					<Route path='fulfillmentdetails/:id' element={<ManageFulfillDetails />} />
					<Route path='expressorderdetails/:id' element={<ExpressOrderDetails />} />
					<Route path='cargoorderdetails/:id' element={<CargoOrderDetails/>} />
					<Route path='know-your-customer' element={<KnowYourCus />} />
					<Route path='import-details/:id' element={<ImportDetails />} />
				</Route>


				<Route path='*' element={<h2>404</h2>} />
			</Routes>
		</Router>
	);
};

export default AppRoutes;
