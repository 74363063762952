import BookADelivery from "./BookADelivery"
import ShipmentInfoHeader from "./ShipmentInfoHeader"

const ChooseToShip = (
        { 
            service,
            choseToShip,
            setChoseToShip
        }
    ) => {
    return (
        <section className="flex flex-col gap-6">
            <ShipmentInfoHeader service={service} />
            <BookADelivery
                choseToShip={choseToShip}
                setChoseToShip={setChoseToShip}
            />
        </section>
    )
}

export default ChooseToShip