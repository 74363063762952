import React, { useState } from "react";
import Modal from "./Modal";
import Btn from "../../Components/Button/btn";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl, config } from '../../Utils/constants';
import { useSelector } from "react-redux";

const GoodsForm = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [formData, setFormData] = useState(null);
  const [formData, setFormData] = useState({
    full_name: "",
    address: "",
    email: "",
    phone_number: "",
    type_of_goods: "",
    description: "",
    number_of_goods: "",
  });

 

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };


  const handleRegister = async () => {
    const url = `${baseUrl}add-fulfilment-package`;
    setIsLoading(true);
    try {
      const { data } = await axios.post(url, formData, config(token));
      console.log(data);
      toast.success(data?.message);
      setShowModal(false);
      navigate("/dashboard/fulfilment");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {!showModal ? (
        <form
          onSubmit={handleSubmit}
          className="bg-white p-8 rounded-lg shadow-md w-full max-w-3xl"
        >
          <div className="p-3">
            {" "}
            <IoArrowBackOutline onClick={goBackHandler} />
          </div>

          <h2 className="text-2xl mb-4 text-center">Fulfillment Center</h2>
          <p className="mb-6 text-center">
            Kindly fill out the information below
          </p>
          <label className="block mb-2">Full Name</label>
          <input
            type="text"
            name="full_name"
            value={formData.full_name}
            onChange={handleChange}
            placeholder="John Doe"
            required
            className="mb-4 p-2 border border-gray-300 rounded w-full"
          />
             <label className="block mb-2">Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleChange}
            placeholder="15, John Doe Street Ojota Lagos Nigeria"
            required
            className="mb-4 p-2 border border-gray-300 rounded w-full"
          />
           <label className="block mb-2">Phone Number</label>
          <input
            type="tel"
            name="phone_number"
            value={formData.phone_number}
            required
            onChange={handleChange}
            placeholder="0812345678"
            className="mb-4 p-2 border border-gray-300 rounded w-full"
          />
          <label className="block mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            required
            onChange={handleChange}
            placeholder="Johndoe@gmail.com"
            className="mb-4 p-2 border border-gray-300 rounded w-full"
          />
          <label className="block mb-2">Type of goods</label>
          <select
            name="type_of_goods"
            value={formData.type_of_goods}
            required
            onChange={handleChange}
            className="mb-4 p-2 border border-gray-300 rounded w-full"
          >
            <option value="">Select type of goods</option>
            <option value="Electronics & Gadgets">Electronics & Gadgets</option>
            <option value="Beauty & Fashion">Beauty & Fashion</option>
            <option value="Furniture">Furniture</option>
            <option value="Health">Health</option>
            <option value="Home & Office">Home & Office</option>
            <option value="Baby Product">Baby Product</option>
            <option value="Sporting Goods">Sporting Goods </option>
            <option value="Car Parts & Engine">Car Parts & Engine</option>
            <option value="Computing & Phone">Computing & Phone</option>
            <option value="Education">Education</option>
            <option value="Other">Other</option>
          </select>
          <label className="block mb-2">List the goods you are sending</label>
          <textarea
            name="description"
            value={formData.description}
            required
            onChange={handleChange}
            placeholder="List Full Details Of The Product You Are Sending"
            className="mb-4 p-2 border border-gray-300 rounded w-full"
            rows="4"
          />
          <label className="block mb-2">
            Enter total number of goods you are sending
          </label>
          <textarea
            name="number_of_goods"
            value={formData.number_of_goods}
            required
            onChange={handleChange}
            placeholder="List Total Number Each"
            className="mb-4 p-2 border border-gray-300 rounded w-full"
            rows="4"
          />
          <Btn
            text={"Continue"}
            className="bg-sec w-full text-white font-medium"
          />
        </form>
      ) : (
        <Modal
          formData={formData}
          onClose={closeModal}
            onRegister={handleRegister}
            isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default GoodsForm;
