import React, { useState, useEffect } from "react";

import * as XLSX from 'xlsx';

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl } from "../../../Utils/constants";
import { FormatCurrency } from "../../../Utils/currency";

const NgnZone = () => {
  const [uploadedPrices, setUploadedPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);

  const columns = [
    { 
      header: "S/N", 
      accessorKey: "sn", 
      cell: ({ row }) => row.index + 1 
    },
    { 
      header: "KG", 
      accessorKey: "kg" 
    },
    {
      header: "Zone 1 A (south west)",
      accessorKey: "zone1",
      cell: ({ row }) => FormatCurrency(row.original.zone1),
    },
    {
      header: "Zone 2 B (East & south)",
      accessorKey: "zone2",
      cell: ({ row }) => FormatCurrency(row.original.zone2),
    },
    {
      header: "Zone 3 C (North)",
      accessorKey: "zone3",
      cell: ({ row }) => FormatCurrency(row.original.zone3),
    },
  ];

  // Delete pricing
  const handleDeletePricing = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`${baseUrl}admin/delete-dhl-domestic-price`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      setUploadedPrices([]);
      const successMessage = response.data.message || "Pricing deleted successfully!";
      toast.success(successMessage);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to delete pricing. Please try again.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  //  Fetch Domestic Pricing Data with improved error handling
  const fetchDomesticPricing = async () => {
    if (!adminToken) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}admin/get-dhl-domestic-pricing`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });

      //  Robust data formatting with fallback
      const formattedData = response.data.data.map(item => ({
        kg: String(item.kg || ''),
        zone1: String(item.zone1 || '0'),
        zone2: String(item.zone2 || '0'),
        zone3: String(item.zone3 || '0')
      }));

      // CORRECTION: Ensure data is set even if empty
      setUploadedPrices(formattedData || []);
    } catch (error) {
      //console.error("Error fetching domestic pricing data:", error);
      const errorMessage = error.response?.data?.message || "Failed to fetch domestic pricing data.";
      toast.error(errorMessage);
      // CORRECTION: Set empty array in case of error
      setUploadedPrices([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setIsLoading(true);
  
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
  
            // Transform data to match expected format
            const formattedData = data.map(row => ({
              kg: String(row.KG),
              zone1: String(row['Zone 1']),
              zone2: String(row['Zone 2']),
              zone3: String(row['Zone 3'])
            }));
  
            // Prepare form data for upload
            const formData = new FormData();
            formData.append("dhl_domestic_price", file);
            formData.append("prices", JSON.stringify(formattedData));
  
            // Upload to backend
            const response = await axios.post(`${baseUrl}admin/upload-domestic-price`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${adminToken}`,
              },
            });
  
        // Immediately fetch updated pricing after successful upload
          
            await fetchDomesticPricing();
  
            // Use backend success message
            const successMessage = response.data.message || "Domestic price list uploaded successfully!";
            toast.success(successMessage);
  
          } catch (error) {
            // More detailed error handling
            if (error.response?.data?.errors) {
              const errors = error.response.data.errors;
              const errorMessages = Object.values(errors).flat().join(', ');
              toast.error(errorMessages);
            } else {
              const errorMessage = error.response?.data?.message || "Failed to upload file. Please check the file format.";
              toast.error(errorMessage);
            }
          } finally {
            setIsLoading(false);
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        console.error("File reading error:", error);
        toast.error("Error reading file.");
        setIsLoading(false);
      }
    }
  };

  // Initial data fetch when component mounts
  useEffect(() => {
    if (adminToken) {
      fetchDomesticPricing();
    }
  }, [adminToken]);

  return (
    <div className='w-full'>
      <ToastContainer position="top-right" />
      <div className='mb-4 flex justify-between items-center'>
        <label 
          htmlFor="excel-upload" 
          className='block w-full p-4 border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-100 mr-4 '
        >
          Upload Excel File For Domestic Price
          <input 
            type="file" 
            id="excel-upload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className='hidden'
          />
        </label>
        {uploadedPrices.length > 0 && (
          <button
            onClick={handleDeletePricing}
            disabled={isLoading}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:opacity-50"
          >
            {isLoading ? "Deleting..." : "Delete Pricing"}
          </button>
        )}
      </div>

      <h1 className='text-center mb-4'>
        {uploadedPrices.length > 0 
          ? "Uploaded Price List" 
          : "Below are the price list belonging to different states in Nigeria based on their zones"}
      </h1>

      <TanTable 
        columnData={columns} 
        data={uploadedPrices} 
        loadingState={isLoading} 
      />
    </div>
  );
};

export default NgnZone;