import React from 'react'
import CardDetails from "../../Components/Dashboard/VirtualCard/CardDetails"
const CardDetailsPage = () => {
  return (
    <div>
      <CardDetails/>
    </div>
  )
}

export default CardDetailsPage
