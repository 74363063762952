import React, { useState } from "react";
import Express from "./Express";
import NonExpress from "./NonExpress";
import Others from "./Others";
import { useNavigate } from "react-router-dom";

const ManageProhibitedItems = () => {
  const [activeTab, setActiveTab] = useState("express");
  const navigate = useNavigate();

  const renderContent = () => {
    switch (activeTab) {
      case "express":
        return <Express />;
      case "nonexpress":
        return <NonExpress />;
      case "others":
        return <Others />;
      default:
        return <Express />;
    }
  };

  return (
    <div className="mx-auto p-4">
      <p className="text-center font-bold pb-2 text-lg md:text-base sm:text-sm">
        Prohibited Items
      </p>
      <p className="text-center p-4">
        The following items will not be accepted for transportation under any
        circumstance
      </p>

      <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "express"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("express")}
        >
          Express
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "nonexpress"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("nonexpress")}
        >
          Non Express
        </button>
        <button
          className={`py-2 px-4 text-sm md:text-sm sm:text-sm ${
            activeTab === "others"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("others")}
        >
          Others
        </button>
      </div>
      <div className="">{renderContent()}</div>
    </div>
  );
};

export default ManageProhibitedItems;
