import React from "react";

import { AiOutlineCreditCard } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";

import { useNavigate } from "react-router-dom";

import BtnGrid from "../../Button/BtnGrid";
import Currency from "../../../Utils/currency";

const WalletDetails = ({ setIsTopupModalVisible, walletDetails }) => {
  const navigate = useNavigate();
  return (
    <div className='bg-black text-white p-8 rounded-md my-2 sm:w-96 h-fit'>
      <h2 className='text-sm'>Total Balance</h2>
      <Currency
        className={"my-3 font-bold text-xl"}
        amount={walletDetails?.wallet_balance || 0}
      />
      <div className='mt-16'>
        <BtnGrid
          className={"bg-blue-600"}
          onClick={() => {
            setIsTopupModalVisible(true);
          }}
        >
          <IoMdAdd />
          <h2>Top Up</h2>
        </BtnGrid>
        {/* <BtnGrid className={"bg-red-700"}   onClick={() => {
             navigate("/dashboard/ngn-withdraw");
          }}>
          <AiOutlineCreditCard />
          <h2>Withdraw</h2>
        </BtnGrid> */}
      </div>
    </div>
  );
};

export default WalletDetails;
