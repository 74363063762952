// import OTPInput from "otp-input-react";

// const OTP = ({ OTP, setOTP, length }) => {
//   return (
//     <>
//       <OTPInput
//         value={OTP}
//         onChange={setOTP}
//         autoFocus
//         OTPLength={length || 4}
//         otpType='number'
//         disabled={false}
//         // secure
//         inputStyles={{
//           margin: "30px",
//           padding: "30px",
//           backgroundColor: "#dddd",
//           fontSize: "1rem",
//           fontWeight: "bold",
//         }}
//       />
//     </>
//   );
// };

// export default OTP;

import React from 'react';

import OTPInput from "otp-input-react";

const OTP = ({ otp, setOtp, length }) => {
  return (
    <div className="my-4">
      <OTPInput
        value={otp}
        onChange={setOtp}
        autoFocus
        OTPLength={length}
        otpType="number"
        disabled={false}
        inputClassName="m-2 w-12 h-12 text-center border-2 border-gray-300 rounded-lg bg-white text-xl font-semibold text-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500"
        containerClassName="flex gap-2"
        inputStyles={{
          width: '2rem',
          height: '3rem'
        }}
      />
    </div>
  );
};

export default OTP;