import React, { useEffect } from "react";
import Hero from "../../Components/Exchange/hero";
import Contents from "../../Components/Exchange/contents";

const ExchangeRate = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <Hero />
      <Contents />
    </div>
  );
};

export default ExchangeRate;
