import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import axios from "axios";

import Btn from "../../Button/btn";
import OtpCustomInput from "../../../Utils/OtpCustomInput";
import { baseUrl, config } from "../../../Utils/constants";
const ConfirmPin = ({ isLoading, createCardPin, confirmPinDetails, setConfirmPinDetails, setCurrentSlide }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [initialPin, setInitialPin] = useState(["", "", "", ""]);
  const [confirmPin, setConfirmPin] = useState(["", "", "", ""]);

  const [loading, setLoading] = useState(false)

  const { token } = useSelector((state) => state.auth)

  const handleSubmit = async (e) => { 
    e.preventDefault();
    if (step === 1) {
      setStep(2);
      setConfirmPin(["", "", "", ""]); // Clear the input for confirmation step
    } else {
      setLoading(true)
      if (initialPin.join("") === confirmPin.join("")) {
        const dataToBackend = {
          pin: initialPin.join(""),
          confirm_pin: confirmPin.join("")
        }
        console.log(dataToBackend, "This is the data to backend")
        try {
          const response = await axios.post(`${baseUrl}create-dollar-pin`, dataToBackend, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          console.log("Response:", response.data.data);
          toast.success("Pin created successfully!");
          // navigate("/dashboard/virtual-card");
          setCurrentSlide("CreationProcess")
        } catch (error) {
          console.error("Error:", error);
          toast.error(
            error.response?.data?.message ||
              "An error occurred while creating the card"
          );
        } finally {
          setLoading(false)
        }
        // navigate("/dashboard/virtual-card");
      } else {
        alert("Pins do not match. Please try again.");
        setStep(1);
        setInitialPin("");
        setConfirmPin("");
      }
    }
  }

  return (
    <div className="text-center">
      <h2 className="font-medium text-xl sm:text-2xl md:text-3xl mb-2 sm:mb-3">
        {step === 1 ? "Kindly create your Pin" : "Confirm your Pin"}
      </h2>
      <p className="text-sm sm:text-base mb-4 sm:mb-6">
        {step === 1 
          ? "Please remember this pin. It will be used to keep your account secure."
          : "Please re-enter your pin for confirmation."}
      </p>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="mb-4 sm:mb-6">
          {/* <OTPInput
            OTP={step === 1 ? initialPin : confirmPin} 
            setOTP={step === 1 ? setInitialPin : setConfirmPin} 
          /> */}
          <OtpCustomInput
            otp={step === 1 ? initialPin : confirmPin}
            setOtp={step === 1 ? setInitialPin : setConfirmPin}
          />
        </div>
        <Btn
          loadingState={loading}
          text={step === 1 ? "Continue" : "Finish"}
          className='bg-green-600 my-6 justify-center text-white flex items-center mx-auto'
        />
      </form>
    </div>
  );
};

export default ConfirmPin;