import React, { useState } from "react";
import NairaWallet from "./NairaWallet";
import UsdtWallet from "./UdstWallet";

const ManageAdminWallet = () => {
  const [activeTab, setActiveTab] = useState("naira");
  const renderContent = () => {
    switch (activeTab) {
      case "naira":
        return <NairaWallet />;
      case "usdt":
        return <UsdtWallet />;
      default:
        return <NairaWallet />;
    }
  };
  return (
    <div className="mx-auto p-4">
      <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "naira"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("naira")}
        >
          NGN
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "usdt"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("usdt")}
        >
          USDT
        </button>
      </div>
      <div className="">{renderContent()}</div>
    </div>
  );
};

export default ManageAdminWallet;
