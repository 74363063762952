import React from 'react'
import CardDetailsPin from '../../Components/Dashboard/VirtualCard/CardDetailsPin'

const CardDetailsPinPage = () => {
  return (
    <div>
      <CardDetailsPin/>
    </div>
  )
}

export default CardDetailsPinPage
