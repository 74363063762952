import React, { useState, useEffect } from "react";

import { CiFilter } from "react-icons/ci";

import axios from "axios";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl } from "../../../Utils/constants";

const NairaWallet = () => {
  const { adminToken } = useSelector((state) => state.admin);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);

  const columns = [
    { 
      header: "Name", 
      accessorKey: "user.firstname",
      cell: ({ cell }) => {
        const firstName = cell.getValue();
        const lastName = cell.row.original.user.lastname;
        return `${firstName} ${lastName}`;
      }
    },
    { 
      header: "Amount", 
      accessorKey: "amount",
      cell: ({ cell }) => `₦${parseFloat(cell.getValue()).toLocaleString()}` 
    },
    { 
      header: "Date", 
      accessorKey: "created_at",
      cell: ({ cell }) => {
        const date = new Date(cell.getValue());
        return date.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        });
      }
    },
    { 
      header: "Type", 
      accessorKey: "transaction_type" 
    },
    {
      header: "Status",
      accessorKey: "amount_type",
      cell: ({ cell }) => {
        const status = cell.getValue();
        let displayStatus = status === 'debit' ? 'Debit' : 'Credit';
        let color;

        switch (displayStatus) {
          case "Debit":
            color = "#E31352";
            break;
          case "Credit":
            color = "#055429";
            break;
          default:
            color = "black";
        }

        return <span style={{ color }}>{displayStatus}</span>;
      },
    },
  ];

  // Fetch wallet data
  useEffect(() => {
    const fetchWalletData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}admin/wallet?wallet_type=NGN`, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        setData(response.data.data); // Update to use response.data.data
        setFilteredData(response.data.data);
      } catch (error) {
        console.error("Error fetching wallet data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWalletData();
  }, [adminToken]);

  // Function to handle filter change and update filtered data
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "") {
      setFilteredData(data);
    } else {
      const newFilteredData = data.filter(
        (item) =>
          item.amount_type === selectedFilter.toLowerCase() ||
          item.transaction_type.toLowerCase() === selectedFilter.toLowerCase() ||
          `${item.user.firstname} ${item.user.lastname}`.toLowerCase().includes(selectedFilter.toLowerCase())
      );
      setFilteredData(newFilteredData);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-center font-bold p-2 text-lg md:text-base sm:text-sm">
          Transactions ({data.length})
        </p>

        {/* Filter Dropdown with Icon */}
        <div className="flex items-center pb-4">
          <CiFilter className="mr-1" />
          <select
            value={filter}
            onChange={handleFilterChange}
            className="border p-2 rounded"
          >
            <option value="">Filter</option>
            <option value="Debit">Debit</option>
            <option value="Credit">Credit</option>
            <option value="Wallet Debit">Wallet Debit</option>
            <option value="Wallet Credit">Wallet Credit</option>
          </select>
        </div>
      </div>

      {/* Spinner or Table */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-green-500 border-solid"></div>
        </div>
      ) : (
        <TanTable columnData={columns} data={filteredData} />
      )}
    </div>
  );
};

export default NairaWallet;