import React from "react";
import dollarcard from "../../Assets/images/dollarcard.png";
import Btn from "../../Components/Button/btn";
import { useNavigate } from "react-router-dom";

const Content = () => {
  const navigateTo = useNavigate();
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-4'>
      <div>
        <h2 className='font-bold text-xl '>Dollar Card</h2>
        <p className=''>
          Tired of card declines, hidden fees, and frustrating payment restrictions? With the <b>eShopAfrica Virtual Dollar Card</b>, you can shop, subscribe, and pay for anything online <b>without limits or worries</b>. Whether you're making international purchases, paying for ads, subscribing to Netflix, or shopping on Amazon, our card <b>never declines</b>. <br /><br />
          <b> Why Choose eShopAfrica Virtual Dollar Card?</b> <br />
          ✅ <b>No Card Top-Up Fee</b>  Fund your card at no extra cost. <br />
          ✅ <b>No Monthly Maintenance Fees</b> Keep your balance intact no deductions! <br />
          ✅ <b>Absolutely Free to Get</b> No charges for creating your card. <br />
          ✅ <b>100% Guaranteed Transactions</b>  Say goodbye to payment failures and restrictions. <br />
          ✅ <b>Works for ALL Payments</b> From shopping and subscriptions to digital ads and international transactions. <br /><br />
          <b> Only Load What You Need</b> <br />
          With eShopAfrica, <b>you’re in control</b> just fund your card with the exact amount you need. No unnecessary balances, no surprises! <br /><br />
          <b> Where Can You Use It?</b> <br />
          💳 Amazon, Netflix, Apple Music, Google Ads, Facebook Ads, AliExpress, Shein, PayPal, and more! <br />
          💳 Works perfectly for online shopping, digital marketing, SaaS payments, and international transactions. <br /><br />
          <b>🔒 Safe, Secure & Reliable</b> <br />
          Your funds and transactions are protected with <b>top-tier encryption and security protocols</b>. Shop, pay, and subscribe <b>without fear of fraud or payment failures</b>. <br /><br />
           Say goodbye to payment headaches and experience seamless global transactions today! <br />
        </p>
        <Btn
          text='Create Account'
          className='bg-pry my-5'
          onClick={() => navigateTo("/signup")}
        />
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={dollarcard} alt='' />
      </div>
    </div>
  );
};

export default Content;
