import { useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import OtpCustomInput from "../../Utils/OtpCustomInput";
import { baseUrl, config } from "../../Utils/constants";
import Btn from "../../Components/Button/btn";
import HistoryTab from "../../Components/Dashboard/VirtualCard/History";
import ManageCard from "../../Components/Dashboard/VirtualCard/ManageCard";

const ManageCardPage = () => {
  const [dollarCardDetails, setDollarCardDetails] = useState();
  const [pinVerified, setPinVerified] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("manage"); // New state for tracking active tab

  const { token } = useSelector((state) => state.auth);

  const getDollarCard = async (pin) => {
    if (loading) return;
    setLoading(true);
    const url = `${baseUrl}dollar-card-details`;
    try {
      const { data } = await axios.post(url, { pin }, config(token));
      const cardDetails = data.data.data;
      setDollarCardDetails(cardDetails);
      if (data.status === 200) {
        setPinVerified(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setPinVerified(false);
    } finally {
      setLoading(false);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "manage":
        return (
          <ManageCard
            otp={otp}
            setDollarCardDetails={setDollarCardDetails}
            dollarCardDetails={dollarCardDetails}
          />
        );
      case "history":
        return dollarCardDetails?.dollar_card_details?.card_id ? (
          <HistoryTab cardId={dollarCardDetails.dollar_card_details.card_id} />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <div>
      {pinVerified ? (
        <div className="space-y-4">
          {/* Tab Navigation */}
          <div className="border-b border-gray-200">
            <nav className="flex space-x-8" aria-label="Tabs">
              <button
                onClick={() => setActiveTab("manage")}
                className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                  activeTab === "manage"
                    ? "border-green-500 text-green-500" // Change to green when active
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                Manage Card
              </button>

              <button
                onClick={() => setActiveTab("history")}
                className={`py-4 px-1 inline-flex items-center border-b-2 font-medium text-sm ${
                  activeTab === "history"
                    ? "border-green-500 text-green-500" // Change to green when active
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                }`}
              >
                Transaction History
              </button>
            </nav>
          </div>

          {/* Tab Content */}
          {renderTabContent()}
        </div>
      ) : (
        <div className="w-full flex justify-center items-center flex-col">
          <h2 className="font-medium text-2xl">
            Enter your unique 4-digit pin!
          </h2>
          <form
            className="flex flex-col justify-center items-center"
            onSubmit={(e) => {
              e.preventDefault();
              getDollarCard(otp.join(""));
            }}
          >
            <div>
              <OtpCustomInput otp={otp} setOtp={setOtp} />
            </div>
            <Btn
              text={"Proceed"}
              className="bg-pry my-6"
              loadingState={loading}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default ManageCardPage;


