// import React, { useState } from "react";

// import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
// import { IoIosArrowBack } from "react-icons/io";

// import { Link, useNavigate } from "react-router-dom";

// import { toast, ToastContainer } from "react-toastify";

// import { useDispatch } from "react-redux";

// import axios from "axios";

// import { useEffect } from "react";

// import { baseUrl } from "../../Utils/constants";
// import bg from "../../Assets/images/signin-bg.png";
// import Sidebar from "../../Components/Auth/sidebar";
// import Btn from "../../Components/Button/btn";
// import Input from "../../Components/Form/Input";
// import { setDollarCardAvailable, setToken, setUserProfile, setVerification  } from "../../Redux/features/authSlice";
// const Login = () => {
//   useEffect(() => {
//     window.scrollTo({ top: 0, behavior: "smooth" });
//   }, []);

//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const [isPasswordVisible, setIsPasswordVisible] = useState(false);
//   const [formData, setFormData] = useState({});
//   const [isLoading, setIsLoading] = useState(false);

//   const handleInputChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleLogin = async (e) => {
//     setIsLoading(true);
//     e.preventDefault();
//     const URL = `${baseUrl}login`;
//     let msg;
//     try {
//       const res = await axios.post(URL, formData);
//       setIsLoading(false);
//       msg = res.data.message;
//       const user = res.data.data;
//       const token = res.data.token;
//       const card = res.data.dollar_card;
//       const verification = res.data.verification;
//       dispatch(setUserProfile(user));
//       dispatch(setDollarCardAvailable(card));
//       dispatch(setToken(token));
//       dispatch(setVerification(verification));
//       toast.success(msg);
//       setTimeout(() => {
//         navigate("/dashboard");
//       }, 2000);
//     } catch (error) {
//       msg = error?.response?.data?.message;
//       if (msg === "Email is unverified. Please verify your account") {
//         setTimeout(() => {
//           navigate("/verify-signup", { state: { from: "login" } });
//         }, 2000);
//       } else console.log("second");
//       console.log(error);
//       setIsLoading(false);
//       toast.error(msg);
//     }
//   };

//   return (
//     <div className='relative flex'>
//       <ToastContainer autoClose={3000} />
//       <Sidebar />
//       <div className='w-full md:w-[65%] ml-auto h-[600px]  justify-center my-5 p-8 overflow-y-scroll'>
//         <h2 className='font-bold text-2xl'>Log in</h2>
//         <p className='font-medium text-sm my-3'>
//           <span className='block text-xl'>Welcome!</span>Enter your credentials
//           to access your account.
//         </p>

//         <div className='flex items-center gap-2'>
//           <form action='' className='max-w-lg w-full' onSubmit={handleLogin}>
//             <Input
//               input
//               id='email'
//               type='email'
//               className={"border-2"}
//               title='Email'
//               required={true}
//               setItem={handleInputChange}
//             />

//             <div className='relative'>
//               <Input
//                 input
//                 id='password'
//                 type={`${isPasswordVisible ? "text" : "password"}`}
//                 className={"border-2"}
//                 title='Password'
//                 required={true}
//                 setItem={handleInputChange}
//               />
//               <i
//                 className='absolute bottom-4 cursor-pointer right-5 text-2xl text-gray-400'
//                 onClick={() => setIsPasswordVisible(!isPasswordVisible)}
//               >
//                 {isPasswordVisible ? (
//                   <AiOutlineEyeInvisible />
//                 ) : (
//                   <AiOutlineEye />
//                 )}
//               </i>
//             </div>
//             <Link to='/forgot-password' className='text-xs text-right italic'>
//               Forgot Password?
//             </Link>

//             <Btn
//               loadingState={isLoading}
//               text={"Login"}
//               className='bg-pry w-full my-4 font-bold'
//             />
//             <h2 className='text-sm'>
//               Don't have an account?{" "}
//               <Link to='/signup' className='text-green-600 font-bold'>
//                 Sign up
//               </Link>
//             </h2>
//             <Link to='/' className='flex items-center text-sm my-5'>
//               <i>
//                 <IoIosArrowBack />
//               </i>
//               <h2>Back to Homepage</h2>
//             </Link>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;




import React, { useState } from "react";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";

import { Link, useNavigate } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";

import { useDispatch } from "react-redux";

import axios from "axios";

import { useEffect } from "react";

import { baseUrl } from "../../Utils/constants";
import Sidebar from "../../Components/Auth/sidebar";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";
import { setDollarCardAvailable, setToken, setUserProfile, setVerification } from "../../Redux/features/authSlice";

const Login = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const URL = `${baseUrl}login`;
    let msg;
    try {
      const res = await axios.post(URL, formData);
      setIsLoading(false);
      msg = res.data.message;
      const user = res.data.data;
      const token = res.data.token;
      const card = res.data.dollar_card;
      const verification = res.data.data.verification;

      dispatch(setUserProfile(user));
      dispatch(setDollarCardAvailable(card));
      dispatch(setToken(token));
      dispatch(setVerification(verification));

      toast.success(msg);

      // CHANGE: Navigate based on verification status
      if (verification !== null) {
        // User is verified, navigate to KYC
        navigate("/dashboard");
      } else {
        // User is not verified, navigate to KYC
        navigate("/dashboard/kyc");
      }
    } catch (error) {
      msg = error?.response?.data?.message;
      if (msg === "Email is unverified. Please verify your account") {
        setTimeout(() => {
          navigate("/verify-signup", { state: { from: "login" } });
        }, 2000);
      } else console.log("second");
      console.log(error);
      setIsLoading(false);
      toast.error(msg);
    }
  };

  return (
    <div className='relative flex'>
      <ToastContainer autoClose={3000} />
      <Sidebar />
      <div className='w-full md:w-[65%] ml-auto h-[600px] justify-center my-5 p-8 overflow-y-scroll'>
        <h2 className='font-bold text-2xl'>Log in</h2>
        <p className='font-medium text-sm my-3'>
          <span className='block text-xl'>Welcome!</span>Enter your credentials
          to access your account.
        </p>

        <div className='flex items-center gap-2'>
          <form action='' className='max-w-lg w-full' onSubmit={handleLogin}>
            <Input
              input
              id='email'
              type='email'
              className={"border-2"}
              title='Email'
              required={true}
              setItem={handleInputChange}
            />

            <div className='relative'>
              <Input
                input
                id='password'
                type={`${isPasswordVisible ? "text" : "password"}`}
                className={"border-2"}
                title='Password'
                required={true}
                setItem={handleInputChange}
              />
              <i
                className='absolute bottom-4 cursor-pointer right-5 text-2xl text-gray-400'
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <AiOutlineEyeInvisible />
                ) : (
                  <AiOutlineEye />
                )}
              </i>
            </div>
            <Link to='/forgot-password' className='text-xs text-right italic'>
              Forgot Password?
            </Link>

            <Btn
              loadingState={isLoading}
              text={"Login"}
              className='bg-pry w-full my-4 font-bold'
            />
            <h2 className='text-sm'>
              Don't have an account?{" "}
              <Link to='/signup' className='text-green-600 font-bold'>
                Sign up
              </Link>
            </h2>
            <Link to='/' className='flex items-center text-sm my-5'>
              <i>
                <IoIosArrowBack />
              </i>
              <h2>Back to Homepage</h2>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;