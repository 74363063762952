import React from "react";
import heroImg from "../../Assets/images/privacyhero.png";
const PrivacyHero = () => {
  return (
    <div className='h-[300px] relative flex flex-col justify-center'>
      <div className='max-width1'>
        <h2 className='relative z-10 font-bold text-3xl text-white'>
          eshopAfrica Privacy,Terms and Condition Information
        </h2>
      </div>
      <div>
        <img
          src={heroImg}
          className='absolute top-0 right-0 object-cover w-full h-[300px]'
          alt='bg'
        />
      </div>
    </div>
  );
};

export default PrivacyHero;
