import React, { useState } from "react";
import Shipment from "./Shipment";
import ExpressShipment from "./ExpressShipment";

const ManageShipment = () => {
  const [activeTab, setActiveTab] = useState("shipment");
  const renderContent = () => {
    switch (activeTab) {
      case "shipment":
        return <Shipment />;
      case "express":
        return <ExpressShipment />;
      default:
        return <Shipment />;
    }
  };
  return (
    <div className="mx-auto p-4">
      <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "shipment"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("shipment")}
        >
          Imports
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "express"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("express")}
        >
          Express
        </button>
      </div>
      <div className="">{renderContent()}</div>
    </div>
  );
};

export default ManageShipment;
