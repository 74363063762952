import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../../Utils/constants";
import { toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import { Spinner } from "react-activity";

const ConfirmShipmentPayment = () => {

    const { token } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
  
    const useQuery = () => {
      return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
  
    const verifyPayment = async () => {
      if(isLoading) return
      setIsLoading(true);
      const trans = query.get("trxref");
      const shipmentId = query.get("shipmentId")
      const url = `${baseUrl}verify-shipment-payment`;
      if (trans) {
        try {
          const res = await axios.post(url, { shipment_id: Number(shipmentId), reference: trans }, config(token));
          toast.success(res?.data.message);
          setIsLoading(false);
          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    };

    useEffect(() => {
      verifyPayment()
    }, [query]) // eslint-disable-line react-hooks/exhaustive-deps

  

    return (
    <div className='flex min-h-screen justify-center items-center flex-col gap-2'>
      <Spinner size={30} />
      <h1 className="text-[#282828] text-center font-[500] text-[24px]">
          Verifying Payment...
      </h1>

      {/* <Spinner size={30} /> */}
    </div>
    )
}

export default ConfirmShipmentPayment