import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { IoMdArrowBack } from "react-icons/io";

import { toast, ToastContainer } from "react-toastify";

import { baseUrl, config } from "../../../Utils/constants";

const RegGoodsFulfilmentDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { adminToken } = useSelector((state) => state.admin);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]); // Initialize with an empty array
  const ordersPerPage = 10;

  const filteredOrders = orders.filter((order) =>
    order.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  // const handleStatusChange = (event, id) => {
  //   const updatedOrders = orders.map((order) =>
  //     order.id === id ? { ...order, status: event.target.value } : order
  //   );
  //   setOrders(updatedOrders);
  // };

  const renderPagination = () => {
    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => setCurrentPage(i)}
          className={`px-3 py-1 border ${
            currentPage === i ? "bg-gray-200" : "bg-white"
          }`}
        >
          {i}
        </button>
      );
    }

    return pages;
  };

  // Status

  const handleStatusChange = async (event, id) => {
    const newStatus = event.target.value;
  
    // Update the status locally for immediate feedback
    const updatedOrders = orders.map((order) =>
      order.id === id ? { ...order, status: newStatus } : order
    );
    setOrders(updatedOrders);
  
    // Send the updated status to the backend
    try {
      const url = `${baseUrl}admin/fulfilment/${id}`;
      const payload = {
        fulfilment_type: "fulfilment", // Adjust based on the requirement
        status: newStatus,
      };
  
      const response = await axios.put(url, payload, config(adminToken));
  
      if (response.data.status) {
        // Display the success message using toast
        toast.success(response.data.message || "Status updated successfully!");
      } else {
        toast.error("Unexpected response from the server.");
      }
    } catch (error) {
      console.error(
        `Failed to update status for order ${id}:`,
        error.response || error
      );
  
      // Revert the status locally in case of an error
      const revertedOrders = orders.map((order) =>
        order.id === id ? { ...order, status: order.status } : order
      );
      setOrders(revertedOrders);
  
      // Display error message using toast
      toast.error(
        error.response?.data?.message || "Failed to update status. Please try again."
      );
    }
  };
  
  // end

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const url = `${baseUrl}admin/fulfilment/${id}`;
      console.log("Fetching order details from URL:", url);
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(url, config(adminToken));
        console.log("Received data:", data);

        // Correction: Accessing orders inside the `fulfilment` key in the backend response
        setOrders(data.fulfilment || []); // Ensure data is an array or default to an empty array
      } catch (error) {
        console.error("Error fetching order details:", error.response || error);
        setError(
          error.response?.data?.message || "Failed to fetch order details"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id, adminToken]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search"
          className="p-2 border rounded-full"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {currentOrders.length === 0 ? (
        <div className="flex flex-col items-center justify-center">
          <p className="mt-3 text-gray-500 text-sm md:text-base">
            No order yet
          </p>
        </div>
      ) : (
        currentOrders.map((order) => (
          <div key={order.id} className="border rounded-lg shadow-lg p-4 mb-4">
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm">
                Date: {new Date(order.created_at).toLocaleDateString("en-GB")}
              </p>
              <div>
                <select
                  value={order.status}
                  onChange={(e) => handleStatusChange(e, order.id)}
                  className={`px-2 py-1 rounded text-white ${
                    order.status === "Pending"
                      ? "bg-red-500"
                      : order.status === "Processed"
                      ? "bg-[#3B40BF]"
                      : "bg-[#055429]"
                  }`}
                >
                  <option value="Pending">Pending</option>
                  <option value="Processed">Processed</option>
                  <option value="Delivered">Delivered</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm">
              <p>Name of Customer: {order.full_name}</p>
              <p>Email: {order.email}</p>
              <p>Phone Number: {order.phone_number}</p>
              <p>Customer's Address: {order.address}</p>
              <p>Type of Goods: {order.type_of_goods}</p>
              <p>List of Goods: {order.description}</p>
              <p>Number of Goods: {order.number_of_goods}</p>
              <p>Status: {order.status}</p>
            </div>
          </div>
        ))
      )}

      {filteredOrders.length > 0 && (
        <div className="flex flex-col sm:flex-row justify-between items-center mt-6">
          <span className="text-xs sm:text-sm text-gray-600 mb-4 sm:mb-0">
            Showing {indexOfFirstOrder + 1} to{" "}
            {Math.min(indexOfLastOrder, filteredOrders.length)} of{" "}
            {filteredOrders.length} entries
          </span>
          <div className="flex flex-wrap justify-center space-x-2">
            <button
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className="px-3 py-1 border bg-white"
            >
              Previous
            </button>
            {renderPagination()}
            <button
              onClick={() =>
                setCurrentPage((prev) =>
                  Math.min(
                    prev + 1,
                    Math.ceil(filteredOrders.length / ordersPerPage)
                  )
                )
              }
              className="px-3 py-1 border bg-white"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
  <ToastContainer position="top-right" autoClose={3000} />
};

export default RegGoodsFulfilmentDetails;
