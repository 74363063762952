import React, { useEffect, useState } from "react";

import { Outlet, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import Header from "../../Components/Admin/Layout/header";
import Sidebar from "../../Components/Admin/Layout/sidebar";

const Admin = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);
  const navigateTo = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (!adminToken) {
      navigateTo("/secretroute/login");
    }
  }, []);

  return (
    <div className='max-width2 relative overflow-x-hidden'>
      <div
        className={`${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 md:block md:w-[25%] lg:w-[20%] fixed top-0 left-0 z-10 bg-white transition`}
      >
        <Sidebar closeSidebar={closeSidebar} />
      </div>
      <div className='md:w-[75%] lg:w-[80%] ml-auto'>
        <Header toggleSidebar={toggleSidebar} />
        <div className='bg-gray-50 p-4 min-h-screen'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Admin;
