import React from 'react'
import InfoIcon from '../../../Icons/InfoIcon'

const InfoBox = ({ content }) => {
  return (
    <div className='rounded-[10px] bg-[#49917066] p-4 flex flex-row gap-4 w-fit'>
        <InfoIcon styles={"h-[22px] w-[20px] min-h-[20px] min-w-[20px]"} />
        <p className='text-[#282828]'>
            {content}
        </p>
    </div>
  )
}

export default InfoBox