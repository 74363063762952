import React from "react";
import price1 from "../../Assets/images/price1.png";
import price2 from "../../Assets/images/price2.png";
import price3 from "../../Assets/images/price3.png";
import price4 from "../../Assets/images/price4.png";
import price5 from "../../Assets/images/price5.png";
import price6 from "../../Assets/images/price6.png";
import error from "../../Assets/images/error.png";
import del from "../../Assets/images/del.png";
import time from "../../Assets/images/time.png";

const PricingDetails = () => {
  const details = [
    {
      img: price1,
      detail: "Free and secure handling of shipment in our warehouse",
    },
    { img: price2, detail: "30 days free pre shipping storage in the US" },
    { img: price3, detail: "Weekly dispatch of shipment to Nigeria" },
    { img: price4, detail: "Clearing taxes and insurance included" },
    { img: price5, detail: "Free office pickup" },
    { img: price6, detail: "14 days storage after arrival" },
  ];

  const details2 = [
    {
      img: error,
      title: "What we don’t ship",
      detail: "Due to regulations we don’t ship some items at the moment",
    },
    {
      img: time,
      title: "Exchange rate",
      detail:
        "Use our currency converter to compare the price of naira to dollar at current rate.",
    },
    {
      img: del,
      title: "Delivery time frame",
      detail:
        "Track your shipment and get information on the delivery timefreme.",
    },
    {
      img: price4,
      title: "Dollar card",
      detail:
        "Enjoy seamless transactions with no hidden charges when you use our dollar card.",
    },
  ];

  return (
    <div className='max-width1 my-10'>
      <h2 className='font-bold text-xl'>Our pricing in details</h2>
      <div className='flex flex-col gap-5 my-10'>
        {details.map(({ img, detail }, index) => {
          return (
            <div
              className='flex gap-4 items-center bg-gray-50 p-2 rounded-sm'
              key={index}
            >
              <img src={img} className='w-5' alt='imag' />
              <p className='text-base'>{detail}</p>
            </div>
          );
        })}
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
        {details2.map(({ img, detail, title }, ind) => {
          return (
            <div className='bg-gray-50 rounded-md shadow-md p-4'>
              <img className='w-8 h-8' src={img} alt={title} />
              <h2 className='font-bold my-4'>{title}</h2>
              <p className='text-sm'>{detail}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PricingDetails;
