import React from "react";

import { Spinner } from "react-activity";

import InfoBox from "../ExpressShipping/NewImplementation/InfoBox";
const OrderSummary = ({
  formData,
  handleChange,
  currentStep,
  handleSubmit,
  loading,
  prevStep,
}) => {
  const formatNaira = (amount) => {
    if (!amount) return "₦0.00";
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    })
      .format(amount)
      .replace("NGN", "₦");
  };

  const { baseShippingCost, vat, insuranceCost, pickupFee, totalCost } =
    React.useMemo(() => {
      const baseShippingCost = formData.calculatedShippingRate || 0;
      const vat = baseShippingCost * 0.075;
      const insuranceCost =
        formData.insurance && typeof formData.insurance === "object"
          ? parseFloat(formData.insurance.insurance_cost) || 0
          : 0;
      const pickupFee =
        formData.pickupArea && typeof formData.pickupArea === "object"
          ? parseFloat(formData.pickupArea.price) || 0
          : 0;
      const total = baseShippingCost + vat + insuranceCost + pickupFee;

      return {
        baseShippingCost,
        vat,
        insuranceCost,
        pickupFee,
        totalCost: parseFloat(total.toFixed(2)),
      };
    }, [
      formData.calculatedShippingRate,
      formData.insurance,
      formData.pickupArea,
    ]);

  React.useEffect(() => {
    if (totalCost > 0 && currentStep === 4) {
      if (formData.price !== totalCost) {
        handleChange({
          target: {
            name: "price",
            value: totalCost,
          },
        });
      }
    }
  }, [totalCost, currentStep, handleChange, formData.price]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col gap-4 w-full px-4 md:px-6 lg:px-0">
      <h3 className="text-[#282828] text-center font-[500] text-[12px] md:text-[14px] mt-4">
        Review your shipping information, don't worry if you are not sure of the
        shipment weight, once we receive your shipment we will send you the
        exact weight and you can proceed to make payment on orders & Payment
        tab. Please note that all shipments more than 2kg, are packed in cartons
        and dimensional weight is calculated. carton fee is not included in the
        total cost.
      </h3>

      <div className="flex flex-row w-full justify-center items-center">
        <InfoBox
          content={
            "Ship it right the first time. Avoid being charged modification fees by confirming all details are correct."
          }
        />
      </div>

      <div className="flex flex-col gap-2 w-full bg-[#E4E4E466] rounded-2xl">
        <div className="flex flex-col p-3 md:p-5">
          <div className="flex flex-col md:flex-row w-full justify-between">
            <div className="flex flex-row gap-2 md:gap-3">
              <div className="flex flex-col items-center h-full mt-1">
                <div className="h-2 w-2 md:h-3 md:w-3 rounded-full bg-[#3B40BF]" />
                <div className="h-full flex flex-1 w-[1px] bg-[#3B40BF]" />
              </div>
              <div className="flex flex-col gap-1 md:gap-1.5 pb-3.5">
                <p className="text-[#28282899] text-[12px] md:text-[14px] font-[500]">
                  Pickup From
                </p>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500]">
                  {formData.senderName}
                </h2>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500] break-words">
                  {`${formData.senderAddress}, ${formData.senderCity}, ${formData.senderState} ${formData.senderCountry}`}
                </h2>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500]">
                  {formData.senderEmail}
                </h2>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500]">
                  {formData.senderPhone}
                </h2>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full justify-between mt-4 md:mt-0">
            <div className="flex flex-row gap-2 md:gap-3">
              <div className="flex flex-col items-center h-[80%] mt-1">
                <div className="h-2 w-2 md:h-3 md:w-3 rounded-full bg-[#008E56]" />
                <div className="h-full flex flex-1 w-[1px] bg-[#008E56]" />
              </div>
              <div className="flex flex-col gap-1 md:gap-1.5">
                <p className="text-[#28282899] text-[12px] md:text-[14px] font-[500]">
                  Deliver To
                </p>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500]">
                  {formData.receiverName}
                </h2>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500] break-words">
                  {`${formData.receiverAddress}, ${formData.receiverCity}, ${formData.receiverState} ${formData.receiverCountry}`}
                </h2>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500]">
                  {formData.receiverEmail}
                </h2>
                <h2 className="text-[#282828] text-[14px] md:text-base font-[500]">
                  {formData.receiverPhone}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-[1px] bg-[#28282866]" />

        <div className="flex flex-col gap-3 p-3 md:p-5">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
            <p className="text-[12px] md:text-[14px] font-[500] text-[#28282899]">
              Cargo type
            </p>
            <div className="flex justify-end md:justify-center items-center">
              <p className="text-[12px] md:text-[14px] font-[500] text-[#28282899]">
                Cost
              </p>
            </div>
            <div className="hidden md:flex justify-end pr-6">
              <p className="text-[12px] md:text-[14px] font-[500] text-[#28282899]">
                Clearance fee
              </p>
            </div>
            <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]">
              {formData.cargoType}
            </h3>
            <div className="flex justify-end md:justify-center">
              <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]">
                {formatNaira(baseShippingCost)}
              </h3>
            </div>
            <div className="col-span-2 md:col-span-1 flex justify-end pr-0 md:pr-8">
              {/* <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]">
                {formatNaira(clearanceFee)}
              </h3> */}
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
            <p className="col-span-2 md:col-span-3 text-[12px] md:text-[14px] font-[500] text-[#28282899]">
              Insurance cover
            </p>
            <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]">
              {formData.insurance?.insurance_name || "No insurance selected"}
            </h3>
            <div className="flex justify-end md:justify-center">
              <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]">
                {formatNaira(
                  parseFloat(formData.insurance?.insurance_cost) || 0
                )}
              </h3>
            </div>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
            <p className="col-span-2 md:col-span-3 text-[12px] md:text-[14px] font-[500] text-[#28282899]">
              Pickup method
            </p>
            <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]">
              {formData.shipmentMethod === "pickup" ? "Pick Up" : "Drop Off"}
            </h3>
            <div className="flex justify-end md:justify-center">
              {/* <h3 className="font-[500] text-[#282828] text-[16px] md:text-[20px]"></h3> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 w-full rounded-2xl bg-[#e4e4e466] p-3 md:p-5">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-[12px] md:text-[14px] text-[#28282899] font-[500]">
              Item Category
            </p>
            <h3 className="font-[500] text-[#282828] text-[16px] md:text-[18px]">
              {formData.itemCategory}
            </h3>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-[12px] md:text-[14px] text-[#28282899] font-[500]">
              Item Value
            </p>
            <h3 className="font-[500] text-[#282828] text-[16px] md:text-[18px]">
              {formatNaira(formData.itemValue)}
            </h3>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <p className="text-[12px] md:text-[14px] text-[#28282899] font-[500]">
            Item Description
          </p>
          <h3 className="font-[500] text-[#282828] text-[16px] md:text-[18px]">
            {formData.itemDescription}
          </h3>
        </div>

        <div className="flex flex-row justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-[12px] md:text-[14px] text-[#28282899] font-[500]">
              Weight
            </p>
            <h3 className="font-[500] text-[#282828] text-[16px] md:text-[18px]">
              {formData.weight}kg
            </h3>
          </div>
        </div>

        <div>
          <p className="text-[12px] md:text-[14px] whitespace-nowrap text-[#28282899] font-[500]">
            Additional Note
          </p>
          <div className="flex flex-col gap-1">
            <h3 className="font-[500] text-[#282828] text-[14px] md:text-base">
              {formData.additionalNote}
            </h3>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 w-full rounded-2xl bg-[#e4e4e466] p-3 md:p-5">
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] text-[14px] md:text-base font-[500]">
            Shipping Fee:
          </h3>
          <p className="text-[#282828] text-[14px] md:text-base font-semibold">
            {formatNaira(baseShippingCost)}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] text-[14px] md:text-base font-[500]">
            VAT
          </h3>
          <p className="text-[#282828] text-[14px] md:text-base font-semibold">
            {formatNaira(vat)}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] text-[14px] md:text-base font-[500]">
            Clearance
          </h3>
          {/* <p className="text-[#282828] text-[14px] md:text-base font-semibold">
            {formatNaira(clearanceFee)}
          </p> */}
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] text-[14px] md:text-base font-[500]">
            Insurance
          </h3>
          <p className="text-[#282828] text-[14px] md:text-base font-semibold">
            {formatNaira(insuranceCost)}
          </p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] text-[14px] md:text-base font-[500]">
            Pickup fee
          </h3>
          <p className="text-[#282828] text-[14px] md:text-base font-semibold">
            {formatNaira(pickupFee)}
          </p>
        </div>

        <div className="w-full h-[2px] bg-[#28282866]" />
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] text-[14px] md:text-base font-[500]">
            Payment Due:
          </h3>
          <p className="text-[#282828] text-[14px] md:text-base font-semibold">
            {formatNaira(totalCost)}
          </p>
        </div>
      </div>
      {/*Submit button */}
      <div className="p-6 bg-gray-50 flex justify-center gap-4 sm:gap-12">
        <button
          type="button"
          onClick={prevStep} // Use prevStep prop
          className="px-4 py-2 bg-gray-200 rounded flex-1 max-w-xs hover:bg-gray-300"
          disabled={loading} // Disable if loading
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSubmit} // Use handleSubmit prop
          className="px-4 py-2 bg-[#008E56] text-white rounded flex-1 max-w-xs hover:bg-[#006E44]"
          disabled={loading} // Disable if loading
        >
          {loading ? ( // Show spinner if loading
            <div className="flex items-center justify-center">
              <Spinner size={16} />
              <span className="ml-2">Processing...</span>
            </div>
          ) : (
            "Submit Order"
          )}
        </button>
      </div>
    </div>
  );
};

export default OrderSummary;
