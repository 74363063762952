import React from 'react'
import ClientAddress from '../../Components/Dashboard/Address/UserAddress/ClientAddress'

const ClientAddressPage = () => {
  return (
    <div>
      <ClientAddress/>
    </div>
  )
}

export default ClientAddressPage
