import React from 'react'
import fulservice from '../../Assets/images/fulfillment2.jpg'
import delivery2 from '../../Assets/images/delivery2.jpg'
import processing from '../../Assets/images/processing.jpg'
import warehouse2 from '../../Assets/images/warehouse2.jpg'
import { Link } from "react-router-dom";
import Btn from "../Button/btn";

const Fulfillment = () => {
  return (
    <div className="w-full overflow-x-hidden">
        
      {/* Hero Banner Section */}
      <div className="relative w-full mb-6 md:mb-10">
        <img
          src={fulservice}
          className="w-full h-64 sm:h-80 md:h-96 lg:h-[450px] object-cover"
          alt="Large warehouse with shelves of inventory"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white text-center p-4">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-2 px-2 md:px-8 lg:px-16">eShopAfrica Fulfillment Services: Your Gateway to Nationwide Success!</h2>
          <p className="mb-2 text-sm sm:text-base md:text-lg max-w-2xl">Attention Nigerian Business Owners! Unlock Lagos Markets from Anywhere in Nigeria for Just ₦3,000 monthly!</p>
          <p className="mb-4 text-sm sm:text-base md:text-lg">From Lagos to the world, sell like you're right here in Lagos!</p>
          <Link to='/signup'>
            <Btn className='bg-pry text-black font-bold py-2 px-4 md:py-3 md:px-6 rounded-md inline-flex items-center text-sm sm:text-base' text=' Talk to an Expert ' />
          </Link>
        </div>
      </div>

      {/* Header Section */}
      <div className="text-center py-6 md:py-10 px-4 max-w-4xl mx-auto">
        <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-3 md:mb-4">Services for Your Business</h1>
        <p className="text-sm sm:text-base md:text-lg px-2 md:px-4">
          Are you Struggling to reach your Lagos or southwest customers? Tired of complicated shipping logistics? 
          Want a Lagos presence without relocating? Say goodbye to these headaches with eShopAfrica Fulfillment Services!
        </p>
      </div>

      {/* Inventory Management Section */}
      <div className="bg-green-50 rounded-lg overflow-hidden max-w-6xl mx-auto mb-6 md:mb-10 mx-4 sm:mx-6 md:mx-8 lg:mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="p-4 sm:p-6 md:p-8 md:w-1/2 flex flex-col justify-center">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-3 md:mb-4">Here's What You Get:</h2>
            <p className="text-sm sm:text-base md:text-lg pb-2">
              We store and manage your products with real-time tracking, ensuring you're always in control.
            </p>
            <ul className="list-none space-y-1 md:space-y-2 font-bold text-sm sm:text-base">
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Secure Lagos storage: Only ₦3,000/month</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Expert packaging & sorting: Just ₦200/delivery depends on shipment</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>Flexible delivery options</span>
              </li>
              <li className="flex items-start">
                <span className="mr-2">•</span>
                <span>We handle cash on delivery</span>
              </li>
            </ul>
          </div>
          <div className="md:w-1/2 h-60 sm:h-64 md:h-auto">
            <img
              src={delivery2}
              className="w-full h-full object-cover"
              alt="Worker managing inventory in warehouse"
            />
          </div>
        </div>
      </div>

      {/* Order Processing & Returns Management Section */}
      <div className="flex flex-col md:flex-row gap-4 sm:gap-5 md:gap-6 max-w-6xl mx-auto mb-6 md:mb-10 px-4 sm:px-6 md:px-8 lg:px-4">
        <div className="bg-green-50 rounded-lg overflow-hidden flex-1">
          <div className="p-4 sm:p-5 md:p-6 text-center">
            <h2 className="text-xl sm:text-2xl font-bold mb-2 md:mb-3">Order Processing & Shipping</h2>
            <p className="mb-3 md:mb-4 text-sm sm:text-base">
              Fast, accurate order fulfillment and shipping, ensuring your customers receive their products on time.
            </p>
          </div>
          <div className="h-48 sm:h-56 md:h-64 lg:h-80">
            <img
              src={processing}
              className="w-full h-full object-cover"
              alt="Person receiving package delivery"
            />
          </div>
        </div>
        
        <div className="bg-green-50 rounded-lg overflow-hidden flex-1">
          <div className="p-4 sm:p-5 md:p-6 text-center">
            <h2 className="text-xl sm:text-2xl font-bold mb-2 md:mb-3">Returns Management</h2>
            <p className="mb-3 md:mb-4 text-sm sm:text-base">
              Hassle-free returns processing for your customers, keeping them satisfied and saving you time.
            </p>
          </div>
          <div className="h-48 sm:h-56 md:h-64 lg:h-80">
            <img
              src={warehouse2}
              className="w-full h-full object-cover"
              alt="Workers processing returns in warehouse"
            />
          </div>
        </div>
      </div>

      {/* Why Partner With Kay Express Section */}
      <div className="bg-white rounded-lg overflow-hidden max-w-6xl mx-auto mb-6 md:mb-10 mx-4 sm:mx-6 md:mx-8 lg:mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2 h-56 sm:h-64 md:h-auto order-2 md:order-1">
            <img
              src={fulservice}
              className="w-full h-full object-cover"
              alt="Worker checking inventory"
            />
          </div>
          <div className="p-4 sm:p-6 md:p-8 md:w-1/2 bg-green-50 order-1 md:order-2">
            <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-3 md:mb-4">Why Partner With Kay Express</h2>
            <p className="text-sm sm:text-base md:text-lg mb-4 md:mb-6">
              Our goal is to help your business grow by handling the logistics so you can focus on what matters.
            </p>
            
            <div className="mb-3 md:mb-4">
              <h3 className="text-lg sm:text-xl font-bold">Scalability</h3>
              <p className="text-sm sm:text-base">From 100 Orders To 10,000, We Can Scale With Your Growth.</p>
            </div>
            
            <div className="mb-3 md:mb-4">
              <h3 className="text-lg sm:text-xl font-bold">Fast & Reliable Shipping</h3>
              <p className="text-sm sm:text-base">Get Your Products To Customers Faster, With Real-Time Tracking</p>
            </div>
            
            <div className="mb-3 md:mb-4">
              <h3 className="text-lg sm:text-xl font-bold">Dedicated Support</h3>
              <p className="text-sm sm:text-base">Our Dedicated Support Team Is Here To Help, Anytime.</p>
            </div>
            
            <div className="mb-2 md:mb-4">
              <h3 className="text-lg sm:text-xl font-bold">Cost-Efficient Solutions</h3>
              <p className="text-sm sm:text-base">We Offer Competitive Pricing To Save You Money While Optimizing Your Operations.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="relative w-full h-56 sm:h-64 md:h-72 lg:h-80 mb-6 md:mb-10">
        <img
          src={fulservice}
          className="w-full h-full object-cover"
          alt="Large warehouse with shelves of inventory"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-center justify-center text-white text-center p-4">
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-2">Ready to Streamline Your Fulfillment Process?</h2>
          <p className="mb-4 md:mb-6 text-sm sm:text-base max-w-2xl">Join hundreds of businesses who trust us to handle their fulfillment needs.</p>
          <Link to='/signup'>
            <Btn className='bg-pry text-black font-bold py-2 px-4 md:py-3 md:px-6 rounded-md inline-flex items-center text-sm sm:text-base' text=' Talk to an Expert ' />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Fulfillment