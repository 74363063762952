import React, { useEffect, useState } from "react";

import { IoCopyOutline, IoArrowBackOutline } from "react-icons/io5";

import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast } from "react-toastify";

import { Spinner } from "react-activity";

import { countries } from "../../Constants";
import { baseUrl } from "../../Utils/constants";
import { FormatCurrency } from "../../Utils/currency";
import Btn from "../../Components/Button/btn";
import DefaultModal from "../../Components/Modals/defaultModal";

const ShipmentInformation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shipmentInformation, setShipmentInformation] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  const { token } = useSelector((state) => state.auth);
  const [cancelChanged, setCancelChanged] = useState(false);
  const [cancelShipmentLoading, setCancelShipmentLoading] = useState(false);

  useEffect(() => {
    const handleFetchData = async () => {
      try {
        if (!params.id) return;
        const response = await axios.get(
          `${baseUrl}express-shipments/${params.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setShipmentInformation(response.data.data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to get shipment information");
      }
    };
    handleFetchData();
  }, [params, token, cancelChanged]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="p-4 md:p-8">
      <IoArrowBackOutline
        onClick={() => navigate(-1)}
        className="cursor-pointer mb-4"
      />
      <h2 className="text-center font-medium text-lg mb-6">
        Shipment Information
      </h2>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="flex justify-between">
            <div>
              <h2 className="font-medium text-sm uppercase text-gray-500">
                Est Delivery Date
              </h2>
              <h2 className="mt-1">
                {shipmentInformation?.shipment_type === "dhl"
                  ? "3-5 Working Days After Payment"
                  : "7-10 Working Days After Payment"}
              </h2>
            </div>
          </div>
          <div>
            <h2 className="font-medium text-sm uppercase text-gray-500">
              Shipment Method
            </h2>
            <h2 className="mt-1">
              {shipmentInformation?.shipment_method === "drop_off"
                ? "Drop Off"
                : shipmentInformation?.shipment_method === "pickup"
                ? "Pick Up"
                : ""}
            </h2>
          </div>
          <div>
            <h2 className="font-medium text-sm uppercase text-gray-500">
              Amount Paid
            </h2>
            <h2 className="mt-1">
              {FormatCurrency(Number(shipmentInformation?.total_amount_to_pay))}
            </h2>
          </div>
          <div>
            <h2 className="font-medium text-sm uppercase text-gray-500">
              Insurance Cover
            </h2>
            <h2 className="mt-1">Basic Insurance</h2>
          </div>
          <div className="pb-1">
            {/* Added optional chaining to safely check if delivery_status is defined before calling .toLowerCase() */}
            <h2
              className={` ${
                shipmentInformation?.shipment_status?.toLowerCase() ===
                "cancelled"
                  ? "bg-[#f00]"
                  : "bg-[#008E56]"
              } text-white rounded-md text-center p-1 lg:p-3 capitalize w-2/3 sm:w-1/2 md:w-1/3 lg:w-1/4`}
            >
              {shipmentInformation?.shipment_status}
            </h2>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <h2 className="uppercase text-gray-500 text-sm">Pickup From</h2>
            <h2 className="mt-2">
              {shipmentInformation?.sender_name} <br />
              {shipmentInformation?.sender_address_1},{" "}
              {shipmentInformation?.sender_city},{" "}
              {shipmentInformation?.sender_state},{" "}
              {countries[shipmentInformation?.sender_country]} <br />
              {shipmentInformation?.sender_email}{" "}
              {shipmentInformation?.sender_phone}
            </h2>
          </div>
          <div>
            <h2 className="uppercase text-gray-500 text-sm">Deliver To</h2>
            <h2 className="mt-2">
              {shipmentInformation?.receiver_name} <br />
              {shipmentInformation?.receiver_address_1},{" "}
              {shipmentInformation?.receiver_city},{" "}
              {shipmentInformation?.receiver_state},{" "}
              {countries[shipmentInformation?.receiver_country]} <br />
              {shipmentInformation?.receiver_email}{" "}
              {shipmentInformation?.receiver_phone}
            </h2>
          </div>
        </div>
      </div>

      {/* Added optional chaining here as well to avoid errors when checking status conditions */}
      {shipmentInformation?.shipment_status?.toLowerCase() !== "cancelled" &&
      shipmentInformation?.payment_status?.toLowerCase() !== "paid" &&
      shipmentInformation?.shipment_status?.toLowerCase() !== "delivered" ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
          <Btn
            className="font-medium border-2 text-gray-900 w-full"
            text="Cancel Shipment"
            loadingState={cancelShipmentLoading}
            onClick={async () => {
              try {
                if (cancelShipmentLoading) return;
                setCancelShipmentLoading(true);
                const response = await axios.post(
                  `${baseUrl}cancel-shipment/${shipmentInformation?.id}`,
                  {},
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                if (response.data.status === 200) {
                  setCancelChanged((prev) => !prev);
                  toast.success("Shipment canceled successfully");
                } else {
                  toast.error("Failed to cancel shipment");
                }
              } catch (error) {
                toast.error("Failed to cancel shipment");
              } finally {
                setCancelShipmentLoading(false);
              }
            }}
          />
          <Btn
            text="Track Shipment"
            onClick={() => setIsModalOpen(true)}
            className="bg-sec w-full text-white font-medium"
          />
        </div>
      ) : shipmentInformation?.payment_status?.toLowerCase() === "paid" &&
        shipmentInformation?.shipment_status?.toLowerCase() !== "cancelled" ? (
        <div className="mt-8">
          <Btn
            text="Track Shipment"
            onClick={() => setIsModalOpen(true)}
            className="bg-sec w-full text-white font-medium"
          />
        </div>
      ) : null}

      <DefaultModal
        visibilityState={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      >
        <h1 className="text-lg font-medium text-center">Track Shipment</h1>
        <h3 className="my-4">
          Please copy your tracking ID, access your dashboard, and paste the ID
          into the "Track Shipment" bar.
        </h3>
        <div className="flex justify-between items-center">
          <div>
            <div>
              <h2 className="text-gray-600 uppercase">EST. Delivery Date</h2>
              <h2 className="font-medium">
                {shipmentInformation?.shipment_type === "dhl"
                  ? "3-5 Working Days After Payment"
                  : "7-10 Working Days After Payment"}
              </h2>
            </div>
            <div className="mt-4">
              <h2 className="text-gray-600 uppercase">Tracking ID</h2>
              <h2 className="font-medium">{shipmentInformation?.tracking_number}</h2>
            </div>
          </div>
          <div className="text-sec flex flex-col items-center justify-center cursor-pointer">
            <IoCopyOutline size={32} />
            <h2 className="text-sm">Copy</h2>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default ShipmentInformation;
