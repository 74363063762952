import React from "react";

import { logos } from "../../Utils/logos";
import adidas from "../../Assets/images/adidas.png";
import amazon from "../../Assets/images/amazon.png";
import ebay from "../../Assets/images/ebay.png";
import nike from "../../Assets/images/nike.png";
import samsung from "../../Assets/images/samsung.png";
import walmart from "../../Assets/images/walmart.png";

import Solutions from "./solutions";
const Sponsors = () => {
  const images = [walmart, ebay, amazon, nike, adidas, samsung];

  return (
    <div className="text-center  my-8 px-4">
      <h2 className="font-bold text-gray-700 text-lg sm:text-xl md:text-2xl lg:text-3xl text-center leading-snug sm:leading-normal">
        Shop from your favorite global stores and ship with eShopAfrica's
        address. Enjoy seamless delivery straight to your doorstep!
      </h2>

      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8 gap-4 p-5">
        {logos.map(({ image, url }, i) => (
          <a
            href={url}
            target="__blank"
            className="w-[100px] h-[100px] shadow-md p-4 rounded-full flex items-center justify-center"
          >
            <img
              className="w-32 h-32 object-contain"
              key={i}
              src={image}
              alt="sponsors"
            />
          </a>
        ))}
      </div>

      {/*  */}
      <div className="">
        <Solutions />
      </div>
    </div>
  );
};

export default Sponsors;
