import React, { useEffect, useState, useCallback} from "react";
import { useNavigate,  useSearchParams } from "react-router-dom"; // Import useNavigate instead of useHistory
import BtnGrid from "../../../Button/BtnGrid";
import axios from "axios";
import { baseUrl, config } from "../../../../Utils/constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const AddressForm = () => {
	const navigate = useNavigate(); // useNavigate hook from react-router-dom
	const { token } = useSelector((state) => state.auth);
	const [formData, setFormData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [params] = useSearchParams();
	const addressId = params.get("id");

	const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const url = addressId
			? `${baseUrl}update-address/${addressId}`
			: `${baseUrl}add-address`;
		setIsLoading(true);
		try {
			const { data } = await axios.post(
				url,
				{ ...formData, country: "Nigeria" },
				config(token)
			);
			console.log(data);
			toast.success(data?.message);
			navigate("/dashboard/client-address");
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};
	// Function to handle cancel action
	const handleCancel = () => {
		navigate("/dashboard/client-address");
	};


	const getAddress = useCallback(async () => {
		const url = `${baseUrl}get-single-address/${addressId}`;
		setIsLoading(true);
		try {
		  const { data } = await axios.get(url, config(token));
		  console.log(data);
		  setFormData(data.data);
		} catch (error) {
		  console.log(error);
		} finally {
		  setIsLoading(false);
		}
	  }, [addressId, token]);
	  
	  useEffect(() => {
		if (addressId) {
		  getAddress();
		} else {
		  setFormData(null);
		}
	  }, [addressId, getAddress]);


	// const getAddress = async () => {
	// 	const url = `${baseUrl}get-single-address/${addressId}`;
	// 	setIsLoading(true);
	// 	try {
	// 		const { data } = await axios.get(url, config(token));
	// 		console.log(data);
	// 		setFormData(data.data);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	// useEffect(() => {
	// 	if (addressId) {
	// 		getAddress();
	// 	} else {
	// 		setFormData(null);
	// 	}
	// }, [addressId]);

	return (
		<div className='mt-6 p-6 bg-white rounded shadow-lg'>
			<h2 className='text-lg font-bold mb-2 text-center'>
				{addressId ? "Edit Address" : "Add New Address"}{" "}
			</h2>
			<p className='text-gray-600 mb-6 text-center'>Enter Address Details</p>
			<form onSubmit={handleSubmit}>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
					<input
						className='border p-2 rounded'
						type='text'
						name='full_name'
						placeholder='Full Name'
						value={formData?.full_name || ""}
						onChange={handleChange}
						required
					/>
					<input
						className='border p-2 rounded'
						type='email'
						name='email'
						placeholder='Email Address'
						value={formData?.email || ""}
						onChange={handleChange}
						required
					/>
					<input
						className='border p-2 rounded'
						type='tel'
						name='phone_number'
						placeholder='Phone Number'
						value={formData?.phone_number || ""}
						onChange={handleChange}
						required
					/>
					<input
						className='border p-2 rounded'
						type='text'
						name='address'
						placeholder='Address'
						value={formData?.address || ""}
						onChange={handleChange}
						required
					/>
					<input
						className='border p-2 rounded'
						type='text'
						name='landmark'
						placeholder='Nearest Landmark'
						value={formData?.landmark || ""}
						onChange={handleChange}
					/>
					<input
						className='border p-2 rounded'
						type='text'
						name='address_line3'
						placeholder='Address Line 3'
						value={formData?.address_line3 || ""}
						onChange={handleChange}
					/>
					<select
						className='border p-2 rounded'
						name='country'
						value={formData?.country || ""}
						onChange={handleChange}
						required
					>
						<option value='Nigeria'>Nigeria</option>
						{/* Add more countries as needed */}
					</select>
					<input
						className='border p-2 rounded'
						type='text'
						name='state'
						placeholder='State'
						value={formData?.state || ""}
						onChange={handleChange}
						required
					/>
					<input
						className='border p-2 rounded'
						type='text'
						name='city'
						placeholder='City'
						value={formData?.city || ""}
						onChange={handleChange}
						required
					/>
					<input
						className='border p-2 rounded'
						type='text'
						name='postal_code'
						placeholder='Postal code'
						value={formData?.postal_code || ""}
						onChange={handleChange}
						required
					/>
				</div>
				<div className='mt-6 flex justify-between'>
					<button
						type='button'
						onClick={handleCancel}
						className='bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400 transition duration-300'
					>
						Cancel
					</button>
					<BtnGrid
						type='submit'
						loadingState={isLoading}
						className='bg-[#008E56] text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300'
					>
						Save
					</BtnGrid>
				</div>
			</form>
		</div>
	);
};

export default AddressForm;