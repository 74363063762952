import React, { useEffect, useState, useCallback } from "react"; // Added useCallback
import { Outlet } from "react-router-dom";
import Header from "../../Components/Dashboard/Layout/header";
import Sidebar from "../../Components/Dashboard/Layout/sidebar";
import { baseUrl, config } from "../../Utils/constants";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUserDollarRate } from "../../Redux/features/dashboardSlice";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Removed unused `openSidebar` function
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  // Correction: Wrapped `getDollarRate` in useCallback
  const getDollarRate = useCallback(async () => {
    const url = `${baseUrl}admin/get-rate`;
    try {
      const { data } = await axios.get(url, config(token));
      dispatch(setUserDollarRate(data.data.rate));
    } catch (error) {
      console.log(error);
    }
  }, [token, dispatch]); // Added dependencies

  // Correction: Added `getDollarRate` to the dependency array
  useEffect(() => {
    getDollarRate();
  }, [getDollarRate]); // Added `getDollarRate` here

  return (
    <div>
      <Header
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Sidebar isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <div className='bg-gray-50 md:w-[70%] lg:w-[80%] md:ml-auto p-8 min-h-screen pt-36'>
        <div className=''>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;