import React from "react";

const NonExpress = () => {
  const nonExpressItems = [
    "Cooked meals",
    "Raw meat",
    "Snails",
    "Fruits & Vegetables",
    "Pets",
    "Alcohol",
    "Grains",
    "Pasta (Noodles)",
    "Perfumes",
    "Gold",
    "Minerals & Precious Stones",
    "Ammunition",
    "Bank bills, notes or currency",
    "Fireworks",
    "Hazardous waste",
    "Perishables",
    "Radioactive materials",
    "Illegal items, according to law enforcement",
    "All forms of fish",
    "Bees",
    "Bearer Bonds",
    "Cash, currency, negotiable instruments",
    "Cash on Delivery (COD) shipment",
    "Gambling devices",
    "Hazardous Waste",
    "Human Remains",
    "Live Fish",
    "Live Household Pets",
    "Live Monkeys",
    "Lottery tickets and materials",
    "Money (Paper/coins)",
    "Negotiable Instrument (stocks, bonds, cash letters, and other negotiable instruments equivalent to cash)",
    "Pornography and items of pornography",
    "Shipments prohibited by any law, statute, or regulation",
    "Switchblades and flick knives",
    "Arms and ammunition",
    "Maize",
    "Firearms",
    "Scrap metals",
    "Dry fish/meat/crayfish",
    "Maggi cubes of chicken flavour/ crayfish flavour",
    "meat flavour",
    "Timber (rough or sawn)",
    "Artifacts and Antiquities",
    "Certain fruits and vegetables",
    "Red Star Express Plc",
    "Unprocessed rubber latex and rubber lumps",
    "Animal products, by products and some animals (elephant tusks, original coral beads)",
    "Raw hides and skin (including wet blue and all unfinished leather)",
    "Wildlife animals classified as endangered species and their products",
  ];

  return (
    <div className="px-4 py-6">
      <h2 className="text-xl font-bold mb-4 text-center">Non Express Items</h2>
      <ul className="grid grid-cols-1 md:grid-cols-2 gap-2 list-decimal list-inside">
        {nonExpressItems.map((item, index) => (
          <li key={index} className="mb-2">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NonExpress;
