import React, { useEffect, useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import { FiMoreHorizontal } from "react-icons/fi";

import { useNavigate } from "react-router-dom";

import { MdModeEditOutline } from "react-icons/md";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import Export from "../../../Utils/Admin/export";
import Filters from "../../../Utils/Admin/filters";
import Order from "../../../Components/Admin/Orders/order";

const OrdersAndPayment = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [ordersData, setOrdersData] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { adminToken } = useSelector((state) => state.admin);

  const columns = [
    { header: "Name", accessorKey: "user.fullname" },
    { header: "Dimension", accessorKey: "dimension" },
    { header: "Tracking ID", accessorKey: "tracking_number" },
    { header: "Amount", accessorKey: "amount" },
    { header: "Batch", accessorKey: "batch_number" },
    { header: "Address", accessorKey: "user.address" },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ cell }) => {
        const rawDate = cell.getValue();
        const formattedDate = new Date(rawDate).toLocaleDateString("en-GB");
        return <span>{formattedDate}</span>;
      },
    },
    { header: "Ship From", accessorKey: "country" },
    {
      header: "Payment Status",
      accessorKey: "payment_status",
      cell: ({ cell }) => {
        const status = cell.getValue();
        return (
          <span
            className={`${
              status === "paid"
                ? "bg-[#E3FFDC] text-[#055429]"
                : status === "pending"
                ? "bg-blue-100 text-blue-800"
                : status === "processed"
                ? "bg-yellow-100 text-yellow-800"
                : "bg-red-100 text-red-800"
            } p-3 rounded-md font-medium text-center`}
          >
            {status}
          </span>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "shipment_status",
      cell: ({ cell }) => {
        const status = cell.getValue();
        return (
          <span
            className={`${
              status === "successful"
                ? "bg-green-100 text-green-800"
                : status === "pending"
                ? "bg-blue-100 text-blue-800"
                : status === "processed"
                ? "bg-yellow-100 text-yellow-800"
                : "bg-red-100 text-red-800"
            } p-3 rounded-md font-medium text-center`}
          >
            {status}
          </span>
        );
      },
    },
    {
      header: "More",
      accessorKey: "more",
      cell: ({ row }) => (
        <FiMoreHorizontal
          className="cursor-pointer"
          onClick={() => handleIconClick(row)}
        />
      ),
    },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <MdModeEditOutline
          className="cursor-pointer"
          onClick={() => handleEditClick(row)}
        />
      ),
    },
  ];

  const getOrders = async () => {
    // const url = `${baseUrl}admin/get-shipments`;
    const url = `${baseUrl}admin/get-shipments?shipment_type=import&status=all`;
    setIsLoading(true);
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setOrdersData(data.data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleIconClick = (row) => {
    navigate(`/secretroute/admin/import-details/${row.original.id}`);
  };

  const handleEditClick = (row) => {
    setSelectedOrder(row.original);
    setIsModalOpen(true);
  };

  if (isLoading) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (ordersData.length < 1) {
    return (
      <div className='h-[20vh] flex items-center justify-center'>
        <h2 className='font-medium text-lg'>No order at the moment</h2>
      </div>
    );
  }

  return (
    <div>
      <div className='flex justify-between gap-4 items-center my-5 bg-white p-4 rounded-md'>
        <h2 className='font-bold'>
          Orders and Payments
          <span className='text-gray-500 font-normal'>
            ({ordersData?.length})
          </span>
        </h2>
        <div className='hidden md:block'>
          <Filters />
        </div>
        <Export
          data={ordersData}
          filename='orders'
          title='Orders and Payments'
          headers={columns}
        />
      </div>
      <TanTable columnData={columns} data={ordersData} />
      {isModalOpen && selectedOrder && (
        <Order
          item={selectedOrder}
          getOrders={getOrders}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default OrdersAndPayment;