import React, { useEffect } from "react";
import HowHero from "../../Components/HowItWorks/hero";
import How from "../../Components/HowToShip/how";

const HowToShip = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <HowHero />
      <How />
    </div>
  );
};

export default HowToShip;
