import { RiArrowDropDownLine } from "react-icons/ri";

import address from "../Assets/images/address.png";
import addressclient from "../Assets/images/addressclient.png";
// import card from "../Assets/images/card2.png";
import dash from "../Assets/images/dash.png";
import delivery from "../Assets/images/delivery.png";
import express_shipping from "../Assets/images/express_shipping.png";import dhl from "../Assets/images/la_dhl.png";
import kyc from "../Assets/images/kyc.png";
import ups from "../Assets/images/la_ups.png";
import order from "../Assets/images/order.png";
import planecar from "../Assets/images/planecar.png";
import profile from "../Assets/images/profile2.png";
import referral from "../Assets/images/referral.png";
import wallet from "../Assets/images/wallet.png";
import pricetag from "../Assets/images/pricetag.png"
export const menus = [
	{ menu: "Home", url: "/dashboard", img: dash },
	{
		menu: "Express Shipment",
		dropdownIcon: <RiArrowDropDownLine />,
		img: express_shipping,
		submenus: [
		// shipment type 
			{ submenu: "DHL", url: "express-shipment?service=dhl", img: dhl },
			{ submenu: "UPS", url: "express-shipment?service=ups", img: ups },
		],
	},
	{
		menu: "Cargo Shipments", url: "cargo", img: planecar},
	{ menu: "Orders & Payment", url: "order-info", img: order },
	// { menu: "Dollar Card", url: "virtual-card", img: card },
	// { menu: "Dollar Card", url: "#", img: card },
	{ menu: "Kyc", url: "kyc", img: kyc },
	{ menu: "Wallet", url: "wallet-Tabs", img: wallet },
	{
		menu: "Express Price",
		dropdownIcon: <RiArrowDropDownLine />,
		img: pricetag,
		submenus: [
			{ submenu: "DHL", url: "dhlprice", img: dhl },
			{ submenu: "UPS", url: "upsprice", img: ups },
			// { submenu: "FED EX", url: "fedexprice", img: fedex },
		],
	},
	{ menu: "Delivery Calculator", url: "calculator", img: delivery },
	{ menu: "Profile", url: "profile", img: profile },
	{ menu: "My Addresses", url: "client-address", img: addressclient },
	{ menu: "Warehouse Address", url: "address", img: address },
	{ menu: "Fulfillment Services", url: "fulfilment", img: address },
	{ menu: "Referrals", url: "referral", img: referral },
];