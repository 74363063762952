import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-activity";

import DoorstepIcon from "../../Icons/DoorstepIcon";
import MarkIcon from "../../Icons/MarkIcon";
import SignatureIcon from "../../Icons/SignatureIcon";
import SwiftDeliveryIcon from "../../Icons/SwiftDeliveryIcon";

import { baseUrl, config } from "../../../Utils/constants";
import { convertCurrencyToNaira } from "../../../Utils/currency";

const DeliveryOptions = ({ formData, handleChange, onShippingRateError }) => {
  const { token } = useSelector((state) => state.auth);
  const [shippingRate, setShippingRate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const prevInputsRef = useRef({ city: "", type: "", weight: 0 });

  // Get the cargo type in the format expected by the API
  const getShipmentType = useCallback(() => {
    switch (formData.cargoType) {
      case "air":
        return "air cargo";
      case "sea":
        return "sea cargo";
      case "frozenAir":
        return "frozen air";
      case "frozenSea":
        return "frozen sea";
      case "xtraFast":
        return "XtraFast Aircargo General";
      case "XtraFastEdibles":
        return "XtraFast Food items (USA)";
      default:
        return "";
    }
  }, [formData.cargoType]);

  // Get display name for cargo type
  const getCargoTypeDisplay = useCallback(() => {
    switch (formData.cargoType) {
      case "air":
        return "Air Cargo";
      case "sea":
        return "Sea Cargo";
      case "frozenAir":
        return "Frozen Air Cargo";
      case "frozenSea":
        return "Frozen Sea Cargo";
      case "xtraFast":
        return "XtraFast Air Cargo";
      case "XtraFastEdibles":
        return "XtraFast Food Items";
      default:
        return "Cargo";
    }
  }, [formData.cargoType]);

  // Fetch shipping rate when required inputs change
  const fetchShippingRate = useCallback(async () => {
    // Check if we have all required data to make the API call
    if (!formData.receiverCity || !formData.cargoType || !formData.weight) {
      // Add validation messages for missing fields
      if (!formData.receiverCity) {
        toast.error("Please select a receiver city first");
      }
      if (!formData.cargoType) {
        toast.error("Please select a cargo type");
      }
      if (!formData.weight) {
        toast.error("Please enter the cargo weight");
      }
      onShippingRateError(true);
      return;
    }

    // Get the location name from the receiverLocations array
    const location = formData.receiverCity;
    const shipment_type = getShipmentType();
    const kg = formData.weight;

    if (!location || !shipment_type || !kg) {
      // Additional validation for data format
      toast.error("Please check if all cargo details are filled correctly ");
      onShippingRateError(true);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${baseUrl}cargo-price`,
        {
          location,
          shipment_type,
          kg,
        },
        config(token)
      );

      if (response.data.success) {
        const rate = response.data.data.data || 0;
        setShippingRate(rate);
        onShippingRateError(false);

        // Update both price and calculatedShippingRate in the parent form data
        handleChange({
          target: {
            name: "price",
            value: rate,
          },
        });

        handleChange({
          target: {
            name: "calculatedShippingRate",
            value: rate,
          },
        });
      } else {
        setError(response.data.message || "Failed to get shipping rate");
        onShippingRateError(true);
        // Enhanced error message with guidance
        toast.error(
          `${
            response.data.message || "Failed to get shipping rate"
          }. Please verify your cargo details and try again.`
        );
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Error fetching shipping rate";
      setError(errorMessage);
      onShippingRateError(true);
      // Enhanced error message with guidance
      toast.error(
        `${errorMessage}. Please check your form entries and ensure all information is correct especially Kg, location, & country .`
      );
    } finally {
      setLoading(false);
    }
  }, [
    formData.receiverCity,
    formData.cargoType,
    formData.weight,
    token,
    getShipmentType,
    handleChange,
    onShippingRateError,
  ]);

  // Trigger shipping rate calculation when inputs change
  useEffect(() => {
    const shouldFetch =
      formData.receiverCity && formData.cargoType && formData.weight;
    const inputsChanged =
      prevInputsRef.current.city !== formData.receiverCity ||
      prevInputsRef.current.type !== formData.cargoType ||
      prevInputsRef.current.weight !== formData.weight;

    if (shouldFetch && inputsChanged) {
      prevInputsRef.current = {
        city: formData.receiverCity,
        type: formData.cargoType,
        weight: formData.weight,
      };
      fetchShippingRate();
    }
  }, [formData.receiverCity, formData.cargoType, formData.weight]);

  return (
    <div>
      <ToastContainer position="top-right" />
      <div className="flex flex-col gap-6 w-full">
        <h2 className="text-xl font-semibold mt-6 text-center">
          Delivery options{" "}
          {formData.receiverCity ? `to ${formData.receiverCity}` : ""}
        </h2>
        <div className="flex flex-col gap-4 w-full">
          <p className="text-[#282828] text-center">
            All shipping rates include{" "}
            <span className="font-semibold">doorstep delivery</span> and{" "}
            <span className="font-semibold">receiver&apos;s signature</span>{" "}
            would be required.
          </p>
          <p className="text-center text-sm">
            kindly note that Frozen Aircargo to canada is to be pickup at
            Toronto or Calgary Not doorstep
          </p>
          <p className="text-center text-sm">
            kindly note that for Seacargo customs clearance will be paid at the receiving destination or country
            
          </p>
        </div>
        <div className="p-1 flex flex-col gap-2 bg-[#E4E4E466] rounded-2xl w-full">
          <div className="flex flex-col gap-2 p-4 md:p-6">
            {loading ? (
              <div className="flex justify-center items-center p-4">
                <Spinner />
              </div>
            ) : error ? (
              <div className="text-red-500 text-center p-4">{error}</div>
            ) : (
              <>
                <div className="flex flex-row items-center justify-between">
                  <h2 className="font-[500] text-[24px] text-[#282828]">
                    {getCargoTypeDisplay()}
                  </h2>
                  <h2 className="font-[500] text-[24px] text-[#282828]">
                    {convertCurrencyToNaira(shippingRate)}
                  </h2>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <p className="text-[#282828] text-sm">
                    {/* Delivery in 2 - 3 weeks,  */}
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="rounded-full h-1 w-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-4 md:gap-6 p-4 md:p-6">
            <div className="flex flex-row items-center gap-2.5">
              <DoorstepIcon styles={"h-[24px] w-[24px]"} color={"#282828"} />
              <p className="text-[#282828]">Doorstep Delivery</p>
              <MarkIcon />
            </div>
            <div className="flex flex-row items-center gap-2.5">
              <SignatureIcon styles={"h-[24px] w-[24px]"} color={"#282828"} />
              <p className="text-[#282828]">Receiver&apos;s Signature</p>
              <MarkIcon />
            </div>
            <div className="flex flex-row items-center gap-2.5">
              <SwiftDeliveryIcon
                styles={"h-[24px] w-[24px]"}
                color={"#282828"}
              />
              <p className="text-[#282828]">Swift Delivery Price Included</p>
              <MarkIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryOptions;
