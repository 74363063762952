import React from "react";
import ellipse1 from "../../Assets/images/ellipse1.png";
import ellipse2 from "../../Assets/images/ellipse2.png";
const DollarCardHero = () => {
  return (
    <div className='bg-green-100 relative'>
      <section className='max-width1'>
        <div className='md:w-1/2 py-12 relative z-10'>
         
          <h2 className='font-bold my-3 text-2xl pb-2'> eShopAfrica Virtual Dollar Card. The Ultimate Global Payment Solution!</h2>
          <p className='text-base'>
            The eShopAfrica Dollar Card is the perfect way to shop and make payment online.
             With eShopAfrica's card, you can make payments securely and
            conveniently at over 3,000 merchants across the world. The card
            is pre-loaded with the dollar currency, so you can shop without
            worrying about exchange rates. Plus, you get access to exclusive
            discounts and offers from our partner merchants.
          </p>
        </div>
      </section>
      <img
        src={ellipse1}
        className='absolute bottom-0 right-0 w-24 md:w-96'
        alt='ellipse'
      />
      <img
        src={ellipse2}
        className='absolute top-0 left-0 w-8 md:w-16'
        alt='ellipse'
      />
    </div>
  );
};

export default DollarCardHero;
