import React from "react";
import { FaRegCopy } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const CardDetails = () => {
  
  const goBackHandler = () => {
    navigate("/dashboard/card");
  };

  const navigate = useNavigate();

  return (
    <div className="p-6 bg-gray-100">
      {/* Back Button */}
      <button onClick={goBackHandler} className="text-gray-500 mb-4">
        &larr; Back
      </button>

      {/* Card Details */}
      <div className="lg:flex justify-around">
        <div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Cardholder Name
            </label>
            <div className="mb-4 flex justify-between items-center">
              <p className="text-lg">Adewale Adeniyi</p>
              <FaRegCopy />
            </div>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Card Number
            </label>
            <div className="mb-4 flex justify-between gap-4 items-center">
              <p className="text-lg">1897654329087654</p>
              <FaRegCopy />
            </div>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Expiry Date
            </label>
            <div className="mb-4 flex justify-between items-center">
              <p className="text-lg">06/29</p>
              <FaRegCopy />
            </div>
          </div>
        </div>

        <div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              CVV
            </label>
            <div className="mb-4 flex justify-between items-center">
              <p className="text-lg ml-2">890</p>
              <FaRegCopy />
            </div>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Billing Address
            </label>
            <div className="mb-4 flex justify-between items-center gap-4">
              <p className="ml-2 text-lg">
                256 chapman road STE 109-8 Newark, Delaware, US
              </p>
              <FaRegCopy />
            </div>
          </div>
          <div>
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Postal Code
            </label>
            <div className="mb-4 flex justify-between items-center">
              <p className="text-lg ml-2">1098</p>
              <FaRegCopy />
            </div>
          </div>
        </div>
        {/* text */}
      </div>
        <div>
          <p className="text-[#008E56]">
            Ensure your billing address matches the address associated with your
            card to prevent transactions from being declined.
          </p>
        </div>
    </div>
  );
};

export default CardDetails;
