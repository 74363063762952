import React from "react";
import { AiOutlineCreditCard } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import BtnGrid from "../../Button/BtnGrid";
import History from "./history";

const USDTWallet = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <h2 className="text-xl font-medium">Wallet</h2>
        <p className="text-sm my-3">
          All transaction are secured and encrypted
        </p>
      </div>
      <div className="bg-black text-white p-8 rounded-md my-2 sm:w-96 h-fit">
        <h2 className="text-sm">Total Balance</h2>
        <div className="my-3 font-bold text-xl">USDT 0.00</div>
        <div className="flex justify-end gap-4 mt-16">
          <BtnGrid
            className={"bg-blue-600"}
            onClick={() => {
              navigate("/dashboard/usdt-wallet-topup");
            }}
          >
            <IoMdAdd />
            <h2>Top Up</h2>
          </BtnGrid>
          <BtnGrid className={"bg-red-700"}   onClick={() => {
             navigate("/dashboard/usdt-withdraw");
          }}>
          <AiOutlineCreditCard />
          <h2>Withdraw</h2>
        </BtnGrid>
        </div>
      </div>
      {/* kindly note that the transactn history here is for NGN wallet 
    but it will be changed to Usdt wallet when consuming endpoint  */}
      <div className="pt-4">
        <History />
      </div>
    </div>
  );
};

export default USDTWallet;
