import { useState, useEffect, useCallback} from "react";

import { MdCancel } from "react-icons/md";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import Btn from "../../Button/btn";
import { baseUrl } from "../../../Utils/constants";

const FundCardModal = ({ isOpen, onClose, setDollarCardDetails }) => {
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [walletDetails, setWalletDetails] = useState(null);
  const [amount, setAmount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [topupLoading, setTopupLoading] = useState(false);
  const [error, setError] = useState("");
  const [UsdRate, setUsdRate] = useState(null);
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const getWallet = async () => {
    setIsLoading(true);
    const url = `${baseUrl}wallet`;
    try {
      const res = await axios.get(url, config);
      setWalletDetails(res.data.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDollarCard = async (pin) => {
    const url = `${baseUrl}dollar-card-details`;
    console.log("dollar card details called");
    try {
      const { data } = await axios.post(url, { pin }, config);
      console.log(data.data.data);
      setDollarCardDetails(data.data.data);
    } catch (error) {
      toast.success("Please refresh to see new card balance ");
    }
  };

  const fetchDollarRate = useCallback(async () => {
    setIsLoading(true);
    const url = `${baseUrl}current-dollar-rate`;
    
    try {
      const res = await axios.get(url, config);
      const rate = res.data?.data;
      
      if (rate && !isNaN(rate)) {
        const formattedRate = parseFloat(rate).toFixed(2);
        setUsdRate(formattedRate);
        toast.success("Dollar rate fetched successfully");
      } else {
        setUsdRate("N/A");
      }
    } catch (error) {
      const msg = error?.response?.data?.message || "Failed to fetch dollar rate";
      toast.error(msg);
      setUsdRate("N/A");
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getWallet();
    fetchDollarRate();
  }, [fetchDollarRate]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
      setError("");
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
    setError("");
  };

  const handleFundingSuccess = async (responseData) => {
    try {
      toast.success(responseData?.message);
      await getDollarCard();
      setAmount("");
      setSelectedCurrency("");
      onClose();
    } catch (error) {
      console.error("Error in funding success handler:", error);
      toast.error("Card was funded but there was an error refreshing the details");
    }
  };

  const handleConfirmFund = async (e) => {
    e.preventDefault();
    
    // Fetch latest dollar rate before proceeding
    await fetchDollarRate();
    
    if (selectedCurrency === "NGN" && (!walletDetails?.wallet_balance || walletDetails.wallet_balance <= 0)) {
      setError("Insufficient NGN balance");
      return;
    }

    if (selectedCurrency === "USDT" && (!walletDetails?.usdtBalance || walletDetails.usdtBalance <= 0)) {
      setError("Insufficient USDT balance");
      return;
    }

    if (!amount) {
      setError("Please enter an amount");
      return;
    }
    if (!selectedCurrency) {
      setError("Please select a funding source");
      return;
    }

    const parsedAmount = parseFloat(amount);
    if (selectedCurrency === "NGN" && parsedAmount > walletDetails.wallet_balance) {
      setError("Amount exceeds available NGN balance");
      return;
    }
    if (selectedCurrency === "USDT" && parsedAmount > walletDetails.usdtBalance) {
      setError("Amount exceeds available USDT balance");
      return;
    }

    setTopupLoading(true);
    setError("");

    try {
      const url = `${baseUrl}topup-dollar-card`;
      const payload = {
        amount: parsedAmount,
        currency: selectedCurrency
      };

      const response = await axios.post(url, payload, config);
      if (response.data.success) {
        await handleFundingSuccess(response.data);
      } else {
        toast.error(response.data.message || "Topup failed. Please try again.");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "An error occurred while processing your request. Please try again.";
      toast.error(errorMessage);
      setError(errorMessage);
    } finally {
      setTopupLoading(false);
    }
  };

  const formatBalance = (balance) => {
    return balance ? balance.toLocaleString() : "0.00";
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <ToastContainer position="top-right" />
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      <div className="bg-white rounded-lg p-8 max-w-md w-full relative z-10">
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 transition-colors"
          onClick={onClose}
          aria-label="Close modal"
        >
          <MdCancel size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-6 text-center">Fund Card</h2>
        <form onSubmit={handleConfirmFund}>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              You are funding card from
            </label>
            <select
              className="w-full p-2 border border-gray-300 rounded-md"
              value={selectedCurrency}
              onChange={handleCurrencyChange}
            >
              <option value="">Select Source of funds</option>
              <option 
                value="NGN" 
                disabled={isLoading || !walletDetails?.wallet_balance || walletDetails.wallet_balance <= 0}
              >
                {isLoading
                  ? "Loading NGN balance..."
                  : `Balance NGN ${formatBalance(walletDetails?.wallet_balance)}`}
              </option>
              <option 
                value="USDT" 
                disabled={isLoading || !walletDetails?.usdtBalance || walletDetails.usdtBalance <= 0}
              >
                {isLoading
                  ? "Loading USDT balance..."
                  : `Balance USDT ${formatBalance(walletDetails?.usdtBalance)}`}
              </option>
            </select>
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Enter Amount in USD
            </label>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-md"
              placeholder="Enter amount"
              value={amount}
              onChange={handleAmountChange}
            />
          </div>
          {error && <div className="mb-4 text-red-500 text-sm">{error}</div>}
          <div className="mb-6">
            <p className="text-sm text-gray-600">
              Estimated amount in Naira:{" "}
              {amount && UsdRate 
                ? `₦${(parseFloat(amount) * UsdRate).toFixed(2)}` 
                : "₦0.00"}
            </p>
          </div>
          <div className="flex justify-center">
            <Btn
              text={topupLoading ? "Processing..." : "Submit"}
              className="bg-[#008E56] text-white px-6 py-3 rounded-lg w-full md:w-auto"
              disabled={topupLoading}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default FundCardModal;