import React from "react";

const Content = () => {
  return (
    <div className=' max-width1'>
      <h2 className='font-bold text-2xl my-4'>
        PRIVACY POLICY- ESHOP AFRICA LIMITED
      </h2>
      <p className='text-sm'>
        PRIVACY POLICY- ESHOP AFRICA LIMITED <br />
        <br /> Introduction: Eshop Africa Limited is the trading name for Eshop
        Africa Limited, registered company number RC; 1926432, and whose
        business address is at 7, Budland Street off Ishola Bello, Akiode Ojodu,
        Lagos State, Nigeria. The email address for service of any legal notices
        is eeshopafrica@gmail.com This privacy policy applies generally to the
        business of ESHOP AFRICA including offline and online interactions you
        have with us and the personal data that we hold about you. Persons
        accessing the ESHOP AFRICA Limited web site, subject themselves to and
        agree to ESHOP AFRICA Limited’s privacy policy & Cookie Notice, as set
        out below. ESHOP AFRICA Limited respects your privacy and is committed
        to keeping your information confidential. <br />
        <br /> ESHOP AFRICA Limited is a controller and processor for the
        personal data that it collects and uses about you. We respect your
        privacy and we are committed to ensuring that your information is kept
        confidential in accordance with applicable data protection legislation.
        Our use of your personal data will be governed by this Privacy policy.
        This Privacy Policy describes your privacy rights regarding our
        collection, use, storage, sharing and protection of your Personal data.
        We may amend this Privacy Policy at any time by posting a revised
        version on our website. <br />
        <br />
        <br />
        This Privacy policy explains;
        <br /> • What Personal data is? <br />• The types of Personal data we
        collect <br />• How we collect your personal data <br />• How we use
        your personal Data <br /> • The legal basis for processing your Personal
        data <br />• How your Personal data is kept secure <br />• How long we
        keep your Personal data <br />• Your rights in relation to your Personal
        data <br />• Transferring your personal data outside Nigeria <br />• How
        we use your personal data and the purpose for which it is collected{" "}
        <br />• Who we share your personal data with
        <br />
        <br /> What is Personal Data? <br />
        Personal data is any information that can be used in identifying a
        natural individual. This could include information such as name, contact
        details such as telephone number, date of birth, address or any
        information about your needs or circumstances which would allow us to
        identify you. Your Personal data is used as is appropriate in the normal
        course of our business with you to provide the products and services you
        have requested. With your consent, we will only retain any information
        for purposes of ongoing business relationships or to communicate
        directly with you.
        <br />
        <br /> The types of Personal data we collect
        <br /> We may sometimes collect your name and contact details (such as
        phone number and email address) from you whenever you contact us to
        enable us to provide our services. Depending on the service that we
        provide to you (or on your behalf) we may also collect additional
        Personal data as detailed below; We collect your personal data in order
        to provide and continually improve our products and services. We may
        collect, use, store and transfer the following different kinds of
        personal data about you: Here for marketing and personal data
        optimization purposes. ESHOP AFRICA Limited also uses Google Digital
        Marketing to propose targeted offers. Information you provide to us: We
        receive and store the information you provide to us including your
        identity data, contact data, delivery address and financial data.
        Information on your use of our website and/or mobile applications: We
        automatically collect and store certain types of information regarding
        your use of the ESHOP AFRICA Limited marketplace including information
        about your searches, views, downloads and purchases. Information from
        third parties and publicly available sources: We may receive information
        about you from third parties including our carriers; payment service
        providers; merchants/brands; and advertising service providers. <br />
        <br />
        Regulatory compliance
        <br /> To comply with certain regulatory requirements and as the case
        may demand, we will collect information required to identify you to meet
        anti-money laundering and counter terrorist financing requirements. We
        will collect information relating to your identity, which will include
        at least one form of photographic identification (such as a passport, a
        driving license or an identification card) and one form of documentation
        with proof of your place of residence (such as a recent utility bill).
        If you are an entity such as a company, trust or charity, we may need to
        collect Personal data about the controllers and the beneficiaries of the
        entity. <br />
        <br /> Other <br />
        If you subscribe to receive research, news or other market updates from
        us, then in addition to the above we will collect details such as email
        address and the type of content that you would like to receive. <br />
        <br />
        Cookies and How We Use Them <br /> A cookie is a small file of letters
        and numbers that we put on your computer if you agree. Cookies allow us
        to distinguish you from other users of our website and mobile
        applications, which helps us to provide you with an enhanced browsing
        experience. For example, we use cookies for the following purposes:
        Recognizing and counting the number of visitors and to see how visitors
        move around the site when they are using it (this helps us to improve
        the way our website works, for example by ensuring that users can find
        what they are looking for). Identifying your preferences and
        subscriptions e.g. language settings, saved items, items stored in your
        basket and Prime membership; and Sending you newsletters and
        commercial/advertising messages tailored to your interests. Our approved
        third parties may also set cookies when you use our marketplace. Third
        parties include search engines, providers of measurement and analytics
        services, social media networks and advertising companies. We maintain a
        list of e-mail addresses to which ESHOP AFRICA Limited Newsletter is
        sent. Individuals must affirmatively request to join this list by using
        the subscribe form on the subscriptions page. Any members of this list
        may choose to unsubscribe at any time by using the same form. Our list
        server has been configured in such a way that the e-mail addresses can
        only be accessed by authorized ESHOP AFRICA Limited staff. We will only
        record your e-mail address if you send us a message. It will only be
        used for the purpose for which you provide it and will not be added to a
        mailing list unless you request that this be done. We will not disclose
        it without your consent. <br />
        <br />
        We may also collect the following: <br />• Account or profile
        information when you connect with us on our social media platforms;{" "}
        <br />• Other publicly available personal data, including any which you
        have shared via a public platform (such as a LinkedIn, Twitter feed or
        public Facebook page); <br />• Information about your computer,
        including where available your IP address, operating system and browser
        type. This is statistical data about our users' browsing actions and
        patterns, and does not identify any individual. Our website is not
        intended for children and we do not knowingly collect data relating to
        children. <br />
        <br />
        How we collect your personal data We use different methods to collect
        data from and about you. Our legal channels of information collection
        are itemized below: <br />
        1. Direct interactions:
        <br /> We collect your data through direct provision of data in the
        course of your use of our services with your consent. We collect
        information about your use of our website and services through our
        analytics tracking system in order to carry out our legitimate interests
        in monitoring and improving our website and services. You may give us
        your data by filling in forms or by corresponding with us by post,
        phone, and email or otherwise. <br />
        2. Use of technology and automated interactions: <br />
        We collect your data through direct provision of data in the course of
        your use of our services with your consent. As you interact with our
        website, we will automatically collect technical data about your
        equipment, browsing actions and patterns. We collect this personal data
        by using cookies and other similar technologies. Cookies are small files
        placed on your computer’s hard drive that enables the website to
        identify your computer as you view different pages. Cookies allow
        websites and applications to store your preferences in order to present
        contents, options or functions that are specific to you. Like most
        interactive websites, our website uses cookies to enable the tracking of
        your activity for the duration of a session. Our website uses only
        encrypted session cookies which are erased either after a predefined
        timeout period or once the user logs out of the platform and closes the
        browser. Session cookies do not collect information from the user’s
        computer. They will typically store information in the form of a session
        identification that does not personally identify the user. We may
        collect your personal data such as recorded images from technology we
        use in our offices and facilities we manage, such as security cameras
        (CCTV) and visitor management systems. We operate cameras for security
        and operational purposes. <br />
        3. Third Parties: <br />
        We may also receive your personal data from third parties to whom you
        have legally provided such information to, and we may use such
        information in accordance with our policies. To the extent that such
        data is disclosed by third parties, whether our clients, service
        providers, advertisers or other sites throughout the Internet, different
        rules may apply to their use or disclosure of your information. We do
        not control how they use or share your data and we cannot make any
        representations or warranties as to these. Web pages on our website may
        contain cookies or related technology to support your experience and
        enable our systems to recognize your browser or device. These cookies
        are text files placed in your computer's internet browser to store your
        preferences. <br />
        <br />
        How We Use Your Personal Data
        <br /> We use your personal data to operate, provide, develop and
        improve the products and services that we offer, including the
        following: <br />• Registering you as a new customer. <br />• Processing
        and delivering your orders. <br />• Managing your relationship with us.
        <br />• Enabling you to participate in promotions, competitions and
        surveys. <br />• Improving our website, applications, products and
        services <br />• Recommending/advertising products or services which may
        be of interest to you. <br />• Complying with our legal obligations,
        including verifying your identity where necessary. <br />• Detecting
        fraud. We will only disclose or report Personal data if and when
        required to do so by law or any regulatory authority and to our
        employees who require such information to carry out their duties. The
        legal basis for processing your personal data Under data protection
        legislation we are only permitted to use your Personal data if we have a
        legal basis for doing so. We rely on the following legal bases to use
        your information; <br />• Where we have received consent from you <br />
        • Where we need information to perform the contract we have entered into
        with you <br />• Where we need to comply with a legal obligation <br />•
        Where we need to protect your interests (or someone else's interests);
        and/or
        <br />• Where it is needed in the public interest or for official
        purposes We may process special categories of Personal data and criminal
        conviction information in the following limited circumstances: <br />•
        With your explicit consent, in which case we will explain the purpose
        for which the information will be used at the point where we ask for
        your consent. <br />
        <br />
        What constitutes consent? <br />• Consent of th subject means any freely
        given, specific, informed and unambiguous indication of the data
        subject’s wishes by which he or she, by a statement or by a clear
        affirmative action, signifies agreement to the processing of personal
        data relating to him or her. <br />• You have the right to withdraw your
        consent at any time. <br />
        <br /> How we keep your information secure The security of information
        is very important to us and we have measures in place (physical,
        technical and administrative security measures) which are designed to
        reduce the risk of loss, misuse, disclosure and unauthorized access of
        your Personal data including but not limited to: <br />• Use of
        firewalls and data encryption. <br />• Storage of files on our secure
        systems. Access is restricted on our client systems to those within the
        business who are required to have access to your information. <br />•
        Hard copy documentation is stored in locked cabinets. We ensure access
        to Personal data is restricted to ESHOP AFRICA Limited employees and
        workers or other persons working within the ESHOP AFRICA Limited Group
        on a need to know basis. Training on data protection is provided to any
        of those ESHOP AFRICA Limited employees and workers who need access to
        Personal data. <br />
        <br /> How long we keep your Personal data <br /> Whenever we collect or
        process your personal data, we’ll only keep it for as long as is
        necessary for the purpose for which it was collected. At the end of the
        necessary period, we will either delete your data completely or
        anonymize your data so that it can be used in a non-identifiable way for
        statistical analysis and business planning. You may request information
        on whether your data has been deleted or anonymized. In some cases, we
        may hold your data longer where we have statutory or regulatory
        obligations to retain Personal data for a longer period, or where we may
        need to retain the information in case of a legal claim. <br />
        <br />
        Your right in relation to your Personal data You have a number of rights
        in relation to your Personal data, these include the right to: <br />•
        Be informed about how we use your Personal data. <br />• Obtain access
        to your Personal data that we hold. <br />• Request that your Personal
        data is corrected if you believe it is incorrect, incomplete or
        inaccurate. <br />• Request that we erase your Personal data in the
        following circumstances: <br />o If ESHOP AFRICA Limited is continuing
        to process Personal data beyond the period when it is necessary to do so
        for the purpose for which it was originally collected. <br />o If ESHOP
        AFRICA Limited is relying on consent as the legal basis for processing
        and you withdraw consent. <br />o If the Personal data has been
        processed unlawfully (i.e. in breach of the requirements of the data
        protection legislation). <br />o If it is necessary to delete the
        Personal data to comply with a legal obligation. <br />• Ask us to
        restrict our data processing activities where you consider that:
        <br /> o Personal data is inaccurate. o Our processing of your Personal
        data is unlawful. <br />o Where we no longer need the Personal data but
        you require us to keep it to enable you to establish, exercise or defend
        a legal claim. <br />o Where you have raised an objection to our use of
        your Personal data.
        <br /> • Request a copy of certain Personal data that you have provided
        to us in a commonly used electronic format. This right relates to
        Personal data that you have provided to us that we need in order to
        perform our agreement with you and Personal data where we are relying on
        consent to process your Personal data. <br />• to be informed of wholly
        automated decision-making, the logic behind it and the resultant effect
        it could have on you. If you would like to exercise any of your rights
        you may submit your request via our Subject Access Request form saved on
        our website and sending the form to eeshopafrica@gmail.com Upon a
        request made, we will provide you with details of the Personal data we
        hold about you. Should you believe that any information we hold about
        you is incorrect, please inform us at the above email address and we
        will correct it. Please note that we will keep a record of the fact that
        you have made a request to exercise your rights, and our response to
        your request, in order to demonstrate compliance with our data
        protection obligations and so that we can handle any queries, complaints
        or claims in relation to your request. This record will be kept in
        accordance with our retention policies as explained above. Please note
        that, while all attempts are made to secure information transmitted to
        this Web Site, if you are submitting personal details or other
        information over the Internet which you wish to remain private, there is
        a possibility that information you submit could be observed by a third
        party while in transit. Transferring your personal data outside of
        Nigeria: We store and process your Personal data on our computers in
        Nigeria however we may sometimes share your personal data with our
        parent company based in other territories when this becomes the case via
        our online software over the course of our operational activities in
        providing our services to you. We may also share your personal data with
        affiliated entities located in other countries where it is necessary for
        the provision of our services to you. Where we need to transfer your
        data to another country, we will always ensure that such country must
        have an adequate data protection law. How we use your personal data With
        your consent, we use your personal data to fulfil requests to receive
        information or materials from us, to carry out services for your benefit
        and to process applications and requests from you. Specifically, we use
        your personal information for the purposes of fulfilling our obligations
        to you to let or sell your property or, if you are a buyer or tenant,
        for the purposes of arranging a contract for sale or a lease for you. We
        do not use your information for any other purpose than for the purposes
        listed out in this privacy policy and we do not sell, lend or rent any
        personal information about you to any third parties outside of ESHOP
        AFRICA Limited. How we share your personal data We may share your
        personal data in the following ways: <br />• With Governmental bodies,
        regulators, law enforcement agencies, courts/tribunals and insurers,
        where we are required to do so <br />• In order to comply with our
        regulatory and legal obligations; <br />• In order to exercise our legal
        rights (for example in court cases); <br />• In order to do so for the
        prevention, detection, investigation of crime or prosecution of
        offenders; <br />• For the protection of our employees and customers;
        and <br />• In the case of employees, with any companies or third
        parties responsible for payment of salary, employee benefits or where we
        have a legal duty to do so. <br />• With third party contractors whom we
        have engaged to carry out maintenance on your properties. <br />• If you
        are purchasing a property for which we are acting as agents, or for the
        sale of a property through us, we will share your personal information
        with your appointed solicitors and the solicitors acting for the other
        side of the deal as is necessary to facilitate the sale or purchase.{" "}
        <br />• Where we are managing property on your behalf we may need to
        pass your personal information from time to time on to third party
        contractors whom we have engaged to carry out maintenance on your
        properties. <br />• If we sell any part of our business and/or integrate
        it with another organization your details may be disclosed to our
        advisers and to prospective purchasers or joint venture partners and
        their advisers. If this occurs the new owners of the business will only
        be permitted to use your information in the same or similar way as set
        out in this privacy policy. Where we share your information, we will
        ensure to communicate our data protection policy to the party receiving
        your information and endeavor to inform them of their obligations with
        respect to your information. We cannot, however, be responsible for how
        they use your information and we disclaim any liabilities for their use
        of your information. We will never share our customer data to other
        organizations for their own marketing purposes. Governing law This
        Privacy Policy is made pursuant to the Nigeria Data Protection
        Regulation (2019) and other relevant Nigerian laws, regulations or
        international conventions applicable to Nigeria. Where any provision of
        this Policy is deemed inconsistent with a law, regulation or convention,
        such provision shall be subject to the overriding law, regulation or
        convention.
      </p>
    </div>
  );
};

export default Content;
