import React from "react";
import rate from "../../Assets/images/rate.png";
import Btn from "../Button/btn";
import { useNavigate } from "react-router-dom";

const Contents = () => {
  const navigateTo = useNavigate();
  return (
    <div className='max-width1 my-10 grid grid-cols-1 md:grid-cols-2 gap-4 items-center'>
      <div className=''>
        <h2 className='font-bold text-xl'>
          Currency Converter & Exchange Rates
        </h2>
        <p className='my-4 text-sm'>
          Before you buy, check the price in your own currency. When shopping
          for items in US retailers online from outside the US, you must know
          the price in your own currency, not only the USD listed on the
          retailer's website. It's simple: just choose your country's currency
          and the amount you want to convert into dollars. Because exchange
          rates fluctuate, keep this currency converter bookmarked so you can
          easily verify the local pricing of a US product before you buy it.
          When you're ready to shop and ship your purchase home, use our
          international shipping cost calculator to rapidly estimate your
          shipping costs.
        </p>
        <Btn
          text='Create Account'
          onClick={() => navigateTo("/signup")}
          className='bg-pry font-medium'
        />
      </div>
      <img src={rate} className='w-full md:h-80 object-cover' alt='rate' />
    </div>
  );
};

export default Contents;
