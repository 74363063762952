import React, { useState, useEffect } from "react";

import * as XLSX from 'xlsx';

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl } from "../../../Utils/constants";

const DhlDomestic = () => {
  const [uploadedZones, setUploadedZones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);

  const columns = [
    { 
      header: "S/N", 
      accessorKey: "sn", 
      cell: ({ row }) => row.index + 1 
    },
    { 
      header: "Zones", 
      accessorKey: "zone" 
    },
    { 
      header: "States", 
      accessorKey: "state" 
    },
  ];

  // Fetch Zones Data
  const fetchDomesticZones = async () => {
    if (!adminToken) return;

    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}admin/get-dhl-domestic-zone`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });

      // Robust data formatting
      const formattedData = response.data.data.map(item => ({
        zone: String(item.zone || ''),
        state: String(item.state || '')
      }));

      setUploadedZones(formattedData || []);
    } catch (error) {
      console.error("Error fetching domestic zones data:", error);
      const errorMessage = error.response?.data?.message || "Failed to fetch domestic zones data.";
      toast.error(errorMessage);
      setUploadedZones([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete zones
  const handleDeleteZones = async () => {
    setIsLoading(true);
    try {
      const response = await axios.delete(`${baseUrl}admin/delete-dhl-domestic-zone`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      setUploadedZones([]);
      const successMessage = response.data.message || "Zones deleted successfully!";
      toast.success(successMessage);
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to delete zones. Please try again.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    setIsLoading(true);

    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = async (event) => {
          try {
            const workbook = XLSX.read(event.target.result, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);

            // Transform data to match expected format
            const formattedData = data.map(row => ({
              zone: row.Zone || row['Zones'],
              state: row.State || row['States']
            }));

            // Prepare form data for upload
            const formData = new FormData();
            formData.append("dhl_domestic_zone", file);
            formData.append("zones", JSON.stringify(formattedData));

            // Upload to backend
            const response = await axios.post(`${baseUrl}admin/upload-domestic-zone`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${adminToken}`,
              },
            });

            // Immediately fetch updated zones after successful upload
            await fetchDomesticZones();

            // Use backend success message
            const successMessage = response.data.message || "Domestic zones uploaded successfully!";
            toast.success(successMessage);

          } catch (error) {
            // Detailed error handling
            if (error.response?.data?.errors) {
              const errors = error.response.data.errors;
              const errorMessages = Object.values(errors).flat().join(', ');
              toast.error(errorMessages);
            } else {
              const errorMessage = error.response?.data?.message || "Failed to upload file. Please check the file format.";
              toast.error(errorMessage);
            }
          } finally {
            setIsLoading(false);
          }
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        console.error("File reading error:", error);
        toast.error("Error reading file.");
        setIsLoading(false);
      }
    }
  };

  // Initial data fetch when component mounts
  useEffect(() => {
    if (adminToken) {
      fetchDomesticZones();
    }
  }, [adminToken]);

  return (
    <div className='w-full'>
      <ToastContainer position="top-right" />
      <div className='mb-4 flex justify-between items-center'>
        <label 
          htmlFor="excel-upload" 
          className='block w-full p-4 border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-100 mr-4'
        >
          Upload Excel File For Ng Zone
          <input 
            type="file" 
            id="excel-upload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className='hidden'
          />
        </label>
        {uploadedZones.length > 0 && (
          <button
            onClick={handleDeleteZones}
            disabled={isLoading}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:opacity-50"
          >
            {isLoading ? "Deleting..." : "Delete Zones"}
          </button>
        )}
      </div>

      <h1 className='text-center mb-4'>
        {uploadedZones.length > 0 
          ? "Uploaded Zones and States" 
          : "Below are the states belonging to Different Zones"}
      </h1>

      <TanTable 
        columnData={columns} 
        data={uploadedZones} 
        loadingState={isLoading} 
      />
    </div>
  );
};

export default DhlDomestic;