import React from 'react'
import Cargo from "../../Components/Dashboard/Cargo/Cargo"
const CargoPage = () => {
  return (
    <div>
      <Cargo/>
    </div>
  )
}

export default CargoPage
