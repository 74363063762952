export const logos = [
  { image: "/logos/amazon.png", url: "https://www.amazon.com" },
  { image: "/logos/ebay.png", url: "https://www.ebay.com/" },
  { image: "/logos/walmart.png", url: "https://www.walmart.com/" },
  { image: "/logos/addidas.png", url: " https://www.adidas.com/us" },
  { image: "/logos/nike.png", url: "https://www.nike.com/" },
  {
    image: "/logos/apple.png",
    url: "https://www.apple.com/store",
  },
  {
    image: "/logos/asos.jpeg",
    url: "https://www.asos.com/",
  },
  {
    image: "/logos/autozone.png",
    url: "https://www.autozone.com/",
  },
  {
    image: "/logos/bath.jpg",
    url: "https://www.bathandbodyworks.com/",
  },
  {
    image: "/logos/bestbuy.png",
    url: "https://www.bestbuy.com/",
  },
  {
    image: "/logos/boom.png",
    url: "https://www.bloomingdales.com/",
  },
  {
    image: "/logos/centers.jpg",
    url: "https://www.carters.com/",
  },
  {
    image: "/logos/childrenplace.png",
    url: "https://www.childrensplace.com/us/home",
  },
  {
    image: "/logos/christian.png",
    url: "https://us.christianlouboutin.com/",
  },
  {
    image: "/logos/disney.svg",
    url: "https://www.shopdisney.com/",
  },
  {
    image: "/logos/dsw.jpg",
    url: "https://www.dsw.com/",
  },
  {
    image: "/logos/fashion.png",
    url: "https://www.fashionnova.com/",
  },
  {
    image: "/logos/home.png",
    url: "https://www.homedepot.com/",
  },
  {
    image: "/logos/lowe.png",
    url: "https://www.lowes.com/",
  },
  {
    image: "/logos/macy.png",
    url: "https://www.macys.com/",
  },
  {
    image: "/logos/mk.png",
    url: "https://www.michaelkors.global/en_NG/",
  },
  {
    image: "/logos/nordstrom.png",
    url: "https://www.nordstrom.com/",
  },
  {
    image: "/logos/oldnavy.png",
    url: "https://oldnavy.gap.com/",
  },
  {
    image: "/logos/perfumia.jpg",
    url: "https://perfumania.com/",
  },
  {
    image: "/logos/reilly.png",
    url: "https://www.oreilly.com/",
  },
  {
    image: "/logos/samsung.png",
    url: "https://www.samsung.com/us/smartphones/",
  },
  {
    image: "/logos/shein.png",
    url: "https://us.shein.com/",
  },
  {
    image: "/logos/shoe.png",
    url: "https://www.shoecarnival.com/",
  },
  {
    image: "/logos/shopdisney-logo.jpeg",
    url: "https://www.shoecarnival.com/",
  },
  {
    image: "/logos/ultra.jpg",
    url: "https://www.ulta.com/",
  },
  {
    image: "/logos/vic.png",
    url: "https://www.victoriassecret.com/us/",
  },
  {
    image: "/logos/wayfair.png",
    url: "https://www.wayfair.com/",
  },
  {
    image: "/logos/zaraa.webp",
    url: "https://www.zara.com/",
  },
];
