import { useLocation, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import axios from "axios";

import { useEffect, useState, useRef } from "react";

import { Spinner } from "react-activity";

import { baseUrl, config } from "../../../Utils/constants";

const ConfirmShipmentPayment = () => {

    const { token } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
  
    const useQuery = () => {
      return new URLSearchParams(useLocation().search);
    };
    const query = useQuery();
const hasVerifiedPayment = useRef(false);
    
  
    const verifyPayment = async () => {
      if(isLoading) return
      setIsLoading(true);
      const trans = query.get("trxref");
      const shipmentId = query.get("shipmentId")
      const url = `${baseUrl}verify-cargo-payment`;
      if (trans) {
        try {
          const res = await axios.post(url, { shipment_id: Number(shipmentId), reference: trans }, config(token));
          toast.success(res?.data.message);
          setIsLoading(false);
          setTimeout(() => {
            navigate("/dashboard/order-info");
          }, 1500);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    };

    useEffect(() => {
       if (!hasVerifiedPayment.current) {
         hasVerifiedPayment.current = true;
         verifyPayment(); 
       }
     }, []);
  

    return (
    <div className='flex min-h-screen justify-center items-center flex-col gap-2'>
      <Spinner size={30} />
      <h1 className="text-[#282828] text-center font-[500] text-[24px]">
          Verifying Payment...
      </h1>

      {/* <Spinner size={30} /> */}
    </div>
    )
}

export default ConfirmShipmentPayment