import React, { useState } from "react";

import { IoArrowBackOutline } from "react-icons/io5";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import { baseUrl, config } from "../../Utils/constants";
import Btn from "../../Components/Button/btn";

import Modal from "./Modal";

const SentOutForm = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    customer_name: "",
    email: "",
    phone_number: "",
    type_of_goods: "",
    goods_you_are_sending_out: "",
    number_of_goods_sending_out: "",
    customer_address: "",
    area: "",
    dispatch_service: "",
    useOwnDispatch: "",
    pickupFromStore: "",
    mode_of_payment: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
    console.log("Form submitted:", formData);
    // Handle form submission logic here
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleRegister = async () => {
    const url = `${baseUrl}send-out-goods`;
    setIsLoading(true);
    try {
      const { data } = await axios.post(url, formData, config(token));
      console.log(data);
      toast.success(data?.message);
      setShowModal(false);
      navigate("/dashboard/fulfilment");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto p-4 sm:p-6 bg-white shadow-md rounded-lg">
      <IoArrowBackOutline onClick={goBackHandler} className="mb-4" />
      <h1 className="text-xl sm:text-2xl text-center mb-2 sm:mb-4">
        Fufillment Center
      </h1>
      <p className="text-center mb-4 sm:mb-6 text-sm sm:text-base">
        Kindly fill out the information of the customer
      </p>
      {!showModal ? (
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Name of customer
              </label>
              <input
                type="text"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
              />
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Email
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
              />
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
              />
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Type of goods
              </label>
              <select
                name="type_of_goods"
                value={formData.type_of_goods}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
              >
                <option value="">Select type of goods</option>
                <option value="Electronics & Gadgets">
                  Electronics & Gadgets
                </option>
                <option value="Beauty & Fashion">Beauty & Fashion</option>
                <option value="Furniture">Furniture</option>
                <option value="Health">Health</option>
                <option value="Home & Office">Home & Office</option>
                <option value="Baby Product">Baby Product</option>
                <option value="Sporting Goods">Sporting Goods </option>
                <option value="Car Parts & Engine">Car Parts & Engine</option>
                <option value="Computing & Phone">Computing & Phone</option>
                <option value="Education">Education</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                List the goods you are sending
              </label>
              <textarea
                name="goods_you_are_sending_out"
                value={formData.goods_you_are_sending_out}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
                rows="4"
              ></textarea>
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Enter the number of goods you are sending
              </label>
              <textarea
                name="number_of_goods_sending_out"
                value={formData.number_of_goods_sending_out}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
                rows="4"
              ></textarea>
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Enter Customers Address
              </label>
              <input
                type="text"
                name="customer_address"
                value={formData.customer_address}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
              />
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Enter Area
              </label>
              <input
                type="text"
                name="area"
                value={formData.area}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm sm:text-base"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mt-4 sm:mt-6">
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Would you like to use our dispatch services or other dispatch
                services?
              </label>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="dispatch_service"
                    value="Yes"
                    checked={formData.dispatch_service === "Yes"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  <span className="text-sm sm:text-base">Yes</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="dispatch_service"
                    value="No"
                    checked={formData.dispatch_service === "No"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  <span className="text-sm sm:text-base">
                    No other dispatch
                  </span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="dispatch_service"
                    value="No"
                    checked={formData.dispatch_service === "No"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  <span className="text-sm sm:text-base">Customer Pickup</span>
                </label>
              </div>
            </div>
            <div>
              <label className="block mb-1 sm:mb-2 text-sm sm:text-base">
                Customers Mode of Payment?
              </label>
              <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="mode_of_payment"
                    value="Cash on delivery"
                    checked={formData.mode_of_payment === "Cash on delivery"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  <span className="text-sm sm:text-base">Cash (POD)</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="mode_of_payment"
                    value="Transfer on delivery"
                    checked={
                      formData.mode_of_payment === "Transfer on delivery"
                    }
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  <span className="text-sm sm:text-base">Transfer (TOD)</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="mode_of_payment"
                    value="Paid"
                    checked={formData.mode_of_payment === "Paid"}
                    onChange={handleInputChange}
                    className="mr-2"
                  />
                  <span className="text-sm sm:text-base">Paid Already</span>
                </label>
              </div>
            </div>
          </div>

          {/* <Btn
          text={"Submit"}
          className="w-full text-white py-2 px-4 rounded mt-4 sm:mt-6  transition duration-300 text-sm sm:text-base"
        /> */}
          <div className="pt-4">
            <Btn
              text={"Submit"}
              // onClick={() => setIsModalOpen(true)}
              className="bg-sec w-full text-white font-medium"
            />
          </div>
        </form>
      ) : (
        <Modal
          formData={formData}
          onClose={closeModal}
          onRegister={handleRegister}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default SentOutForm;
