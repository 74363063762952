import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { Spinner } from "react-activity";
import { useSelector } from "react-redux";
import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

const CargoPrice = () => {
  const { adminToken } = useSelector((state) => state.admin);
  const [data, setData] = useState([]);
  const [country, setCountry] = useState("");
  const [location, setLocation] = useState("");
  const [shipmentType, setShipmentType] = useState("");
  const [kg, setKg] = useState("");
  const [shippingFee, setShippingFee] = useState("");
  const [clearanceFee, setClearanceFee] = useState("");
  const [fixedPayment, setFixedPayment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingCargo, setEditingCargo] = useState(null);

  const columnsData = [
    {
      header: "Country",
      accessorKey: "country",
    },
    {
      header: "Location",
      accessorKey: "location",
    },
    {
      header: "Shipment Type",
      accessorKey: "shipment_type",
    },
    {
      header: "kg",
      accessorKey: "kg",
    },
    {
      header: "Shipping Fee",
      accessorKey: "shipping_fee",
    },
    {
      header: "Clearance Fee",
      accessorKey: "clearance_fee",
    },
    {
      header: "Fixed payment",
      accessorKey: "fixed_payment",
    },
    {
      header: "Action",
      accessorKey: "id",
      cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <AiOutlineEdit
            size={20}
            className="text-blue-500 cursor-pointer"
            onClick={() => handleEdit(row.original)}
          />
          <AiOutlineDelete
            size={20}
            className="text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.original.id)}
          />
        </div>
      ),
    },
  ];

  // Fetch cargo prices on component mount
  useEffect(() => {
    fetchCargoPrices();
  }, []);

  const fetchCargoPrices = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}admin/shipment-cargo-prices`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      const nestedData = response.data.data[0];
      const mappedData = nestedData.map((item) => ({
        id: item.id,
        country: item.country,
        location: item.location,
        shipment_type: item.shipment_type,
        kg: item.kg,
        shipping_fee: item.shipping_fee,
        clearance_fee: item.clearance_fee,
        fixed_payment: item.fixed_payment,
      }));
      setData(mappedData);
    } catch (error) {
      toast.error("Failed to fetch cargo prices");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (cargoId) => {
    if (window.confirm("Are you sure you want to delete this cargo price?")) {
      setIsLoading(true);
      try {
        const response = await axios.delete(
          `${baseUrl}admin/shipment-cargo-prices/${cargoId}`,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
        toast.success(response.data.message);
        fetchCargoPrices(); // Refresh the table
      } catch (error) {
        toast.error(error.response?.data?.message || "Failed to delete cargo price");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      country: country,
      location: location,
      shipment_type: shipmentType,
      kg: kg,
      shipping_fee: Number(shippingFee), // Convert to number
      clearance_fee: Number(clearanceFee), // Convert to number
      fixed_payment: Number(fixedPayment), // Convert to number
    };

    try {
      let response;
      if (editingCargo) {
        // Update existing cargo price
        response = await axios.put(
          `${baseUrl}admin/shipment-cargo-prices/${editingCargo.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
      } else {
        // Create new cargo price
        response = await axios.post(`${baseUrl}admin/shipment-cargo-prices`, payload, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
      }

      toast.success(response.data.message);
      fetchCargoPrices(); // Refresh the table
      resetForm(); // Clear the form
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (cargo) => {
    setEditingCargo(cargo);
    setCountry(cargo.country);
    setLocation(cargo.location);
    setShipmentType(cargo.shipment_type);
    setKg(cargo.kg);
    setShippingFee(cargo.shipping_fee);
    setClearanceFee(cargo.clearance_fee);
    setFixedPayment(cargo.fixed_payment);
  };

  const resetForm = () => {
    setEditingCargo(null);
    setCountry("");
    setLocation("");
    setShipmentType("");
    setKg("");
    setShippingFee("");
    setClearanceFee("");
    setFixedPayment("");
  };

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <form
        onSubmit={handleSubmit}
        className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md"
      >
        <div className="mb-4">
          <label htmlFor="country" className="block text-gray-700 mb-2">
            Country
          </label>
          <input
            type="text"
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="country"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="location" className="block text-gray-700 mb-2">
            Location
          </label>
          <input
            type="text"
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="location"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="shipmenttype" className="block text-gray-700 mb-2">
            Shipment Type
          </label>
          <input
            type="text"
            id="shipmenttype"
            value={shipmentType}
            onChange={(e) => setShipmentType(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="shipment type"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="kg" className="block text-gray-700 mb-2">
            Weight in Kg
          </label>
          <input
            type="text"
            id="kg"
            value={kg}
            onChange={(e) => setKg(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="kg"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="shippingfee" className="block text-gray-700 mb-2">
            Shipping Fee
          </label>
          <input
            type="text"
            id="shippingfee"
            value={shippingFee}
            onChange={(e) => setShippingFee(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="shipping Fee"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="clearanceFee" className="block text-gray-700 mb-2">
            Clearance Fee
          </label>
          <input
            type="text"
            id="clearanceFee"
            value={clearanceFee}
            onChange={(e) => setClearanceFee(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="clearance Fee"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="clearanceFee" className="block text-gray-700 mb-2">
           Fixed payment
          </label>
          <input
            type="text"
            id="clearanceFee"
            value={fixedPayment}
            onChange={(e) => setFixedPayment(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="fixed payment"
            required
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-sec2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : editingCargo ? "Update" : "Upload"}
          </button>
          {editingCargo && (
            <button
              type="button"
              onClick={resetForm}
              className="bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      {/* Table */}
      <div className="py-3">
        <h2 className="font-medium text-lg mb-4 text-center">
          Cargo Delivery Location and Amount
        </h2>
        {isLoading ? <Spinner /> : <TanTable columnData={columnsData} data={data} />}
      </div>
    </div>
  );
};

export default CargoPrice;