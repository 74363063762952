import { useState, useEffect } from "react";

import { Spinner } from "react-activity";

import SingleExpress from "./singleExpress";

const ExpressShipping = ({ loading, expressData, walletDetails }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const itemsPerPage = 10;

  // Update filtered data whenever search term or express data changes
  useEffect(() => {
    const filtered = expressData.filter((item) => {
      const searchableFields = [
        item?.sender,
        item?.receiver,
        item?.trackingId,
        item?.pickup,
        item?.delivery,
        item?.shipmentType,
        item?.estimatedDelivery,
      ].map(field => (field || "").toString().toLowerCase());

      return searchableFields.some(field => 
        field.includes(searchTerm.toLowerCase())
      );
    });
    
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page when search changes
  }, [searchTerm, expressData]);

  if (loading) {
    return (
      <div className="flex flex-row h-full w-full justify-center my-10">
        <Spinner />
      </div>
    );
  }

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset page when searching
  };

  return (
    <div className="mt-4">
      <h2 className="font-medium text-2xl text-center mt-4">Order Info</h2>
      <p className="my-4 text-base text-center">
        Kindly note that once shipment has been processed it can&apos;t be
        cancelled.
      </p>
      <h3 className="text-base font-medium text-center mb-4">Recent Orders</h3>
      
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by sender, receiver, tracking ID..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {currentItems.length === 0 ? (
        <div className="text-center py-4 text-gray-500">
          No matching orders found
        </div>
      ) : (
        currentItems.map((item, index) => (
          <SingleExpress key={index} data={item} walletDetails={walletDetails} />
        ))
      )}

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="flex justify-center items-center gap-2 mt-6 mb-4">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded ${
              currentPage === 1
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
          >
            Previous
          </button>
          
          <div className="flex gap-2">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`px-3 py-1 rounded ${
                  currentPage === index + 1
                    ? 'bg-blue-600 text-white'
                    : 'bg-gray-200 hover:bg-gray-300'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>

          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded ${
              currentPage === totalPages
                ? 'bg-gray-200 cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
          >
            Next
          </button>
        </div>
      )}

      {/* Showing results text */}
      <div className="text-center text-sm text-gray-600 mt-2">
        {filteredData.length > 0 ? (
          `Showing ${indexOfFirstItem + 1} to ${Math.min(indexOfLastItem, filteredData.length)} of ${filteredData.length} entries`
        ) : (
          "No entries to show"
        )}
      </div>
    </div>
  );
};

export default ExpressShipping;