import React, { useEffect, useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import { ToastContainer, toast } from "react-toastify";

import { baseUrl, config } from "../../Utils/constants";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";
import DefaultModal from "../../Components/Modals/defaultModal";

const CalculatorImport = () => {
	const data = [
		"Lagos - Pickup",
		"Lagos - Home Delivery",
		"Home Delivery - Outside Lagos",
	];
	const [locationData, setLocationDate] = useState({});
	const [total, setTotal] = useState(0);
	const [types, setTypes] = useState([]);
	const [delTypes, setDelTypes] = useState([]);
	const [location, setLocation] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [delType, setDelType] = useState([]);
	const [isModalShown, setIsModalShown] = useState(false);

	const [shippingCost, setShippingCost] = useState(0);

	const { token } = useSelector((state) => state.auth);

	const handleInputChange = (e) => {
		setTotal(0);
		setLocationDate({ ...locationData, [e.target.name]: e.target.value });
	};

	const getShippingCost = async () => {
		const url = `${baseUrl}shipping-rate`;
		try {
			const { data } = await axios.get(url, config(token));
			console.log(data);
			setShippingCost(data.data.shipping_cost);
		} catch (error) {
			console.log(error);
		}
	};

	const getShippingType = async () => {
		const url = `${baseUrl}get-shipment-type`;
		try {
			const { data } = await axios.get(url, config(token));
			setTypes(data.data);
		} catch (error) {
			console.log(error);
		}
	};
	const getDeliveryLocation = async () => {
		const url = `${baseUrl}get-delivery-location`;
		try {
			const { data } = await axios.get(url, config(token));
			setLocation(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getDeliveryType = async () => {
		const url = `${baseUrl}get-delivery-type`;
		try {
			const { data } = await axios.get(url, config(token));
			setDelTypes(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getShippingType();
		getDeliveryLocation();
		getDeliveryType();
		getShippingCost();
	}, []);

	const loc = location.map(({ delivery_location }) => delivery_location);
	const shipType = types.map(({ shipment_type }) => shipment_type);

	useEffect(() => {
		let tempType;
		if (locationData?.location?.toLowerCase().includes("nigeria")) {
			tempType = delTypes.map(({ delivery_type }) => delivery_type);
		} else {
			tempType = delTypes
				.filter(
					({ delivery_type }) => delivery_type.toLowerCase() === "doorstep"
				)
				.map(({ delivery_type }) => delivery_type);
		}

		setDelType(tempType);
	}, [locationData]);

	const calcCost = async () => {
		const {
			delivery_type,
			weight,
			shipment_type,
			location: locat,
		} = locationData || {};

		if (locat.toLowerCase() === "china to other countries") {
			setIsModalShown(true);
			return;
		}
		setIsLoading(true);
		const delivery_location_id = location?.find(
			(loc) => loc.delivery_location === locat
		)?.id;
		const delivery_type_id = delTypes?.find(
			(typ) => typ.delivery_type === delivery_type
		)?.id;
		const shipment_type_id = types?.find(
			(typ) => typ.shipment_type === shipment_type
		)?.id;

		const data = {
			delivery_location_id,
			delivery_type_id,
			shipment_type_id,
			weight,
		};

		const url = `${baseUrl}delivery-calculator`;
		try {
			const res = await axios.post(url, data, config(token));
			setIsLoading(false);
			setTotal(res.data.data);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			toast.error(
				`${error.request.status} - ${error?.response?.data?.message} `
			);
		}
	};

	return (
		<div className='bg-white p-6 rounded-md'>
			<ToastContainer autoClose={3000} />
			<div className='max-w-xl'>
				<h2 className='text-lg font-medium'>
					Calculate your import shipping cost here
				</h2>

				<div className='flex gap-2 items-center'>
					<h2>Shipping rate:</h2>
					<h2>${shippingCost}</h2>
				</div>
				<form
					action=''
					className='my-8 text-sm'
					onSubmit={(e) => {
						e.preventDefault();
						calcCost();
					}}
				>
					<Input
						className='shadow-sm bg-gray-100'
						title='Weight of shipment in kg'
						input
						type='number'
						id='weight'
						setItem={handleInputChange}
						required={true}
						step={"any"}
					/>
					<Input
						className='shadow-sm bg-gray-100'
						title='Shipment Type'
						dropdown
						id='shipment_type'
						data={["", ...shipType]}
						setItem={handleInputChange}
						required={true}
					/>
					<Input
						className='shadow-sm bg-gray-100'
						title='Location'
						dropdown
						id='location'
						data={["", ...loc]}
						setItem={handleInputChange}
						required={true}
					/>
					<Input
						className='shadow-sm bg-gray-100'
						title='Delivery Type'
						dropdown
						id='delivery_type'
						data={["", ...delType]}
						setItem={handleInputChange}
						required={true}
					/>
					<p className='bg-green-100 p-4 text-sm rounded-md'>
						<span className='font-bold'>Volumetric Weight</span> may apply if
						your package is shipped in a box OR has visible dimensions (i.e.
						square or rectangularly shaped). This means that your final shipping
						charge may be based on the Volumetric Weight of your package, rather
						than the Actual Weight. Volumetric weight formula: L x B x H / 5000
					</p>
					<Btn
						loadingState={isLoading}
						text='Calculate'
						className={"bg-sec text-white mt-5"}
					/>
				</form>

				<h2 className='text-sm font-bold'>Total Payment</h2>
				<h2 className='bg-gray-200 p-3 font-medium text-lg my-4 rounded-md'>
					{" "}
					{isLoading ? <Spinner /> : ` $ ${total}`}
				</h2>
			</div>
			<DefaultModal
				visibilityState={isModalShown}
				closeModal={() => setIsModalShown(false)}
			>
				<h2 className='text-sm'>
					Please Send us an email at{" "}
					<span className='text-sec'>info@eshopafrica.com</span> and tell us the
					details of your shipment, the location it's going to from China, the
					weight, the dimensions and the type of products. Our team will send
					you a quote for the shipment
				</h2>
				<Btn
					text='Okay'
					className={"bg-pry mt-5"}
					onClick={() => setIsModalShown(false)}
				/>
			</DefaultModal>
		</div>
	);
};

export default CalculatorImport;
