import React from 'react';

import { Spinner } from 'react-activity'; 

const AddressModals = ({
  selectedAddress,
  setSelectedAddress,
  userSavedAddresses = [],
  setModalShowing,
  isLoadingAddresses, 
}) => {

  const addresses = Array.isArray(userSavedAddresses) ? userSavedAddresses : [];


  const handleAddressSelection = (address) => {
    //console.log('Selecting address:', address);
    if (address && address.id) {
      setSelectedAddress(address.id);
      setTimeout(() => setModalShowing(false), 100);
    }
  };

  // Handle click outside modal
  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      setModalShowing(false);
    }
  };

  return (
    <div
      className="fixed h-screen w-full left-0 top-0 bg-[#33333354] backdrop-blur-sm flex justify-center items-center z-[50] p-5"
      onClick={handleBackdropClick}
    >
      <div
        className="bg-white rounded-md p-5 flex flex-col justify-center items-center gap-4 w-full max-w-[550px]"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl font-semibold mb-4">Select Address</h2>

        {/* Loading Indicator */}
        {isLoadingAddresses ? (
          <div className="flex justify-center items-center p-4">
            <Spinner size={20} color="#008E56" /> {/* Show spinner while loading */}
            <p className="ml-2">Loading addresses...</p>
          </div>
        ) : (
          <div className="w-full flex flex-col gap-4 max-h-[60vh] overflow-y-auto">
            {addresses.length > 0 ? (
              addresses.map((address) => (
                <div
                  key={address.id}
                  className={`
                    flex flex-row items-center justify-between p-4 rounded-[10px] 
                    ${
                      selectedAddress === address.id
                        ? 'bg-[#E4E4E4] border-[#008E56]'
                        : 'bg-[#E4E4E480] border-[#2828284D]'
                    }
                    border-[1px] cursor-pointer w-full hover:border-[#008E56] transition-colors
                  `}
                  onClick={() => handleAddressSelection(address)}
                  role="button"
                  tabIndex={0}
                >
                  <div className="flex flex-col gap-2.5 flex-1">
                    <h3 className="text-[#282828] text-[24px] font-[500]">
                      {address.full_name || 'Unnamed Address'}
                    </h3>
                    <p className="text-[#28282899] break-words">
                      {address.address || 'No address provided'}
                    </p>
                    {address.phone_number && (
                      <p className="text-[#28282899]">{address.phone_number}</p>
                    )}
                  </div>
                  <div className="border-4 border-[#008E56] h-[24px] w-[24px] rounded-full flex justify-center items-center ml-4">
                    {selectedAddress === address.id && (
                      <div className="h-[12px] w-[12px] rounded-full bg-[#008E56]" />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-4">
                No saved addresses found
              </div>
            )}
          </div>
        )}

        <div className="w-full flex justify-end mt-4">
          <button
            onClick={() => setModalShowing(false)}
            className="px-6 py-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressModals;