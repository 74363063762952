import React, { useState, useEffect, useCallback } from "react";

import { Link } from "react-router-dom";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import axios from "axios";

import { baseUrl, config } from "../../../Utils/constants";

import CardDetailsModal from "./CardDetailsModal";
import FundCardModal from "./FundCardModal";

import Card from "./DollarCard/card";

const CardScreen = ({ dollarCardDetails }) => {
  const [usdRate, setUsdRate] = useState(() => {
    const storedRate = localStorage.getItem("usdRate");
    return storedRate ? JSON.parse(storedRate) : null;
  });
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [cardDetailsModalShowing, setCardDetailsModalShowing] = useState(false);
  const [showFundCardModal, setShowFundCardModal] = useState(false);

  const getDollarRate = useCallback(async () => {
    setIsLoading(true);
    const url = `${baseUrl}current-dollar-rate`;

    try {
        const res = await axios.get(url, config(token));
        const rate = res.data?.data;
        
        if (rate && !isNaN(rate)) {
            const formattedRate = parseFloat(rate).toFixed(2);
            setUsdRate(formattedRate);
            toast.success("Dollar rate fetched successfully");
        } else {
            setUsdRate("N/A");
        }
    } catch (error) {
        const msg = error?.response?.data?.message || "Failed to fetch dollar rate";
        toast.error(msg);
        setUsdRate("N/A");
    } finally {
        setIsLoading(false);
    }
}, [token]);

useEffect(() => {
    getDollarRate();
}, [getDollarRate]);

  return (
    <div>
      <h1 className="text-1xl font-bold mb-6 text-center">Virtual Card</h1>
      <h1 className="font-semibold mb- text-center">
        {isLoading ? "Loading..." : `Rate NGNs: ${usdRate || "N/A"} = $1`}
      </h1>
      <div className="bg-white w-full rounded-lg drop-shadow-md p-4 mb-6 flex justify-center items-center">
        <Card dollarCardDetails={dollarCardDetails} />
      </div>
      <div className="">
        <div className="w-full ">
          {/* <Link to="/dashboard/fund-card">
            <div className="bg-white rounded-lg shadow-md p-4 mb-4 flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">Fund Card</h2>
                <p className="text-sm text-gray-600">
                  Instantly add funds to your card
                </p>
              </div>
              <span className="text-xl">&rarr;</span>
            </div>
          </Link> */}

          <button onClick={() => setShowFundCardModal(true)} className="w-full">
            <div className="bg-white rounded-lg shadow-md p-4 mb-4 flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">Fund Card</h2>
                <p className="text-sm text-gray-600">
                  Instantly add funds to your card
                </p>
              </div>
              <span className="text-xl">&rarr;</span>
            </div>
          </button>

          <button
            onClick={() => setCardDetailsModalShowing((prev) => !prev)}
            className="w-full"
          >
            <div className="bg-white rounded-lg shadow-md p-4 mb-4 flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">Card Details</h2>
                <p className="text-sm text-gray-600">
                  View and manage your card details
                </p>
              </div>
              <span className="text-xl">&rarr;</span>
            </div>
          </button>
          <Link to="/dashboard/manage-card">
            <div className="bg-white rounded-lg shadow-md p-4 mb-4 flex justify-between items-center">
              <div>
                <h2 className="text-lg font-semibold">Manage Card</h2>
                <p className="text-sm text-gray-600">
                  Take full control of your card
                </p>
              </div>
              <span className="text-xl">&rarr;</span>
            </div>
          </Link>
        </div>
      </div>
      {showFundCardModal && (
        <FundCardModal
          isOpen={showFundCardModal}
          onClose={() => setShowFundCardModal(false)}
        />
      )}

      {cardDetailsModalShowing && (
        <CardDetailsModal
          setModalShowing={setCardDetailsModalShowing}
          dollarCardDetails={dollarCardDetails}
        />
      )}
    </div>
  );
};

export default CardScreen;
