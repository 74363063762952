import React from 'react'
import WalletBankWithdraw from "../../Components/Dashboard/Wallet/Withdraw/WalletBankwithdraw"

const WalletBankWithPage = () => {
  return (
    <div>
      <WalletBankWithdraw/>
    </div>
  )
}

export default WalletBankWithPage
