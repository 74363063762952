import React from 'react'
import Fulfillment from '../../Components/Home/fulfillment'
const FulfillmentPage = () => {
  return (
    <div>
        <Fulfillment />
    </div>
  )
}

export default FulfillmentPage
