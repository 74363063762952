import React from "react";

const AddressModal = ({
  selectedAddress,
  setSelectedAddress,
  userSavedAddresses,
  setModalShowing,
}) => {
  return (
    <div
      className="fixed h-screen w-full left-0 top-0 bg-[#33333354] backdrop-blur-sm flex justify-center items-center z-[50] p-5"
      onClick={() => setModalShowing(false)} // Close modal when clicking outside
    >
      <div
        className="bg-white rounded-md p-5 flex flex-col justify-center items-center gap-4 w-full max-w-[550px]"
        onClick={(e) => e.stopPropagation()} // Prevent modal from closing when clicking inside
      >
        <div className="flex w-full flex-row justify-between items-center">
          <div className="h-[24px] w-[24px]" />
          <h2 className="text-center text-[24px] text-[#282828]">
            Choose Address
          </h2>
          <button
            onClick={() => setModalShowing(false)} // Close modal when clicking the close button
            className="h-[24px] w-[24px] flex justify-center items-center"
          >
            <svg
              className="h-[16px] w-[16px]"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.3499 10.001L19.5166 2.85096C19.8304 2.53712 20.0067 2.11146 20.0067 1.66763C20.0067 1.22379 19.8304 0.798133 19.5166 0.484293C19.2027 0.170454 18.7771 -0.00585938 18.3332 -0.00585938C17.8894 -0.00585938 17.4637 0.170454 17.1499 0.484293L9.9999 7.65096L2.8499 0.484293C2.53606 0.170454 2.1104 -0.00585938 1.66657 -0.00585938C1.22273 -0.00585937 0.797071 0.170454 0.483232 0.484293C0.169392 0.798133 -0.00692129 1.22379 -0.00692129 1.66763C-0.00692129 2.11146 0.169392 2.53712 0.483232 2.85096L7.6499 10.001L0.483232 17.151C0.327018 17.3059 0.203027 17.4902 0.118413 17.6933C0.0337985 17.8964 -0.00976562 18.1143 -0.00976562 18.3343C-0.00976562 18.5543 0.0337985 18.7722 0.118413 18.9753C0.203027 19.1784 0.327018 19.3627 0.483232 19.5176C0.63817 19.6738 0.822505 19.7978 1.0256 19.8824C1.2287 19.9671 1.44655 20.0106 1.66657 20.0106C1.88658 20.0106 2.10443 19.9671 2.30753 19.8824C2.51063 19.7978 2.69496 19.6738 2.8499 19.5176L9.9999 12.351L17.1499 19.5176C17.3048 19.6738 17.4892 19.7978 17.6923 19.8824C17.8954 19.9671 18.1132 20.0106 18.3332 20.0106C18.5533 20.0106 18.7711 19.9671 18.9742 19.8824C19.1773 19.7978 19.3616 19.6738 19.5166 19.5176C19.6728 19.3627 19.7968 19.1784 19.8814 18.9753C19.966 18.7722 20.0096 18.5543 20.0096 18.3343C20.0096 18.1143 19.966 17.8964 19.8814 17.6933C19.7968 17.4902 19.6728 17.3059 19.5166 17.151L12.3499 10.001Z"
                fill="#282828"
              />
            </svg>
          </button>
        </div>
        <div className="w-full flex flex-col gap-4">
          {userSavedAddresses.map((address, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-between p-4 rounded-[10px] bg-[#E4E4E480] border-[#2828284D] border-[1px] cursor-pointer w-full"
              onClick={() => {
                setSelectedAddress(address.id); // Set the selected address
                setModalShowing(false); // Close the modal after selection
              }}
            >
              <div className="flex flex-col gap-2.5">
                <h3 className="text-[#282828] text-[24px] font-[500]">
                  {address.full_name}
                </h3>
                <p className="text-[#28282899]">{address.address}</p>
              </div>
              <div className="border-4 border-[#008E56] h-[24px] w-[24px] rounded-full flex justify-center items-center">
                {address.id === selectedAddress && (
                  <div className="h-[12px] w-[12px] rounded-full bg-[#008E56]"></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddressModal;