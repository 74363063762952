import ShipmentMethodInput from "./ShipmentMethodInput";

const ChooseShipmentMethod = (
    {
        shipmentMethod,
        setShipmentMethod,
        setPickupPrice,
        pickupArea,
        setPickupArea,
        pickupDate,
        setPickupDate,
        areaOptions,
    }
) => {
    return (
        <section className="flex flex-col gap-6 w-full">
            <h3 className='text-[#282828] text-center font-[500] text-[24px]'>
                Shipment Method
            </h3>
            <ShipmentMethodInput
                shipmentMethod={shipmentMethod}
                setShipmentMethod={setShipmentMethod}
                setPickupPrice={setPickupPrice}
                pickupArea={pickupArea}
                setPickupArea={setPickupArea}
                pickupDate={pickupDate}
                setPickupDate={setPickupDate}
                areaOptions={areaOptions}
            />
        </section>
    )
}

export default ChooseShipmentMethod;