import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import FreezeCardTab from "./FreezeCard";
// import HistoryTab from "./History";
// import WithdrawTab from "./Withdraw";

const ManageVirtualCard = ({ otp, setDollarCardDetails, dollarCardDetails }) => {
  const [activeTab, setActiveTab] = useState("history");
  const navigate = useNavigate();

  // const renderContent = () => {
  //   switch (activeTab) {
  //     case "history":
  //       return <HistoryTab />;
  //     case "withdraw":
  //       return <WithdrawTab />;
  //     case "freeze":
  //       return <FreezeCardTab otp={otp} setDollarCardDetails={setDollarCardDetails} dollarCardDetails={dollarCardDetails} />;
  //     default:
  //       return <HistoryTab />;
  //   }
  // };

  const renderContent = () => {
    switch (activeTab) {
      case "freeze":
        return <FreezeCardTab otp={otp} setDollarCardDetails={setDollarCardDetails} dollarCardDetails={dollarCardDetails} />;
      // case "withdraw":
      //   return <WithdrawTab />;
      // case "freeze":
      //   return <FreezeCardTab otp={otp} setDollarCardDetails={setDollarCardDetails} dollarCardDetails={dollarCardDetails} />;
      default:
        return <FreezeCardTab otp={otp} setDollarCardDetails={setDollarCardDetails} dollarCardDetails={dollarCardDetails} />;
    }
  };

  const goBackHandler = () => {
    navigate("/dashboard/virtual-card");
  };

  return (
    <div className="mx-auto p-4">
      <button onClick={goBackHandler} className="text-gray-500 mb-4 text-sm md:text-sm sm:text-sm">
        &larr; Back
      </button>
      <p className="text-center font-bold pb-4 text-lg md:text-base sm:text-sm">Manage Virtual Card</p>

      <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2">
        {/* <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "history"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("history")}
        >
          History
        </button> */}
        {/* <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "withdraw"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("withdraw")}
        >
          Withdraw
        </button> */}
        <button
          className={`py-2 px-4 text-sm md:text-sm sm:text-sm ${
            activeTab === "freeze"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("freeze")}
        >
          Freeze Card
        </button>
      </div>
      <div className="p-4 border rounded-lg shadow-lg">{renderContent()}</div>
    </div>
  );
};

export default ManageVirtualCard;