import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BtnGrid from "../../../Button/BtnGrid";

const WithdrawUsdt = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [network, setNetwork] = useState("");
  const [amount, setAmount] = useState("");
  const navigate = useNavigate();

  const handleContinue = (e) => {
    e.preventDefault();
    navigate("/dashboard/confirm-transaction", {
      state: { walletAddress, network, amount },
    });
  };

  return (
    <div className="flex flex-col p-6 md:p-8 lg:p-10">
      <h1 className="text-lg font-semibold mb-6 text-center">Withdraw USDT</h1>
      <form onSubmit={handleContinue} className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <label className="text-lg">Wallet Address</label>
          <div className="relative">
            <input
              type="text"
              value={walletAddress}
              onChange={(e) => setWalletAddress(e.target.value)}
              placeholder="Long press to paste address"
              className="border p-2 rounded w-full"
            />
       
          </div>
        </div>
        {/* <div className="flex flex-col">
          <label className="text-lg">Network</label>
          <input
            type="text"
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
            className="border p-2 rounded w-full"
          />
        </div> */}

        <div className="mt-4 w-full">
          <label
            className="text-lg"
            htmlFor="network"
          >
            Network
          </label>
          <select
            id="network"
            value={network}
            onChange={(e) => setNetwork(e.target.value)}
            className="block w-full bg-gray-100 border border-gray-300 rounded py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value="" disabled>
              Select Network
            </option>
            <option value="Tron">Tron (TRC-20) arrival time (2 min)</option>
            <option value="Ethereum">Solana arrival time (2 min)</option>
            <option value="Binance">
              BNB Smart Chain (BEP-20) arrival time (3 min)
            </option>
            <option value="Ethereum">
              Ethereum (ERC-20) arrival time (4 min)
            </option>
          </select>
        </div>

        <div className="flex flex-col">
          <label className="text-lg">Amount</label>
          <input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="border p-2 rounded w-full"
             placeholder="Enter Amount"
          />
        </div>
        <div className="text-gray-600">Available Balance: 0.00</div>
        <div className="flex items-center justify-center pt-8">
        <BtnGrid
          className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24"
             onClick={handleContinue}
        >
          Continue
        </BtnGrid>
      </div>
      </form>
    </div>
  );
};

export default WithdrawUsdt;
