import { Spinner } from "react-activity"

const Button = ({ styles, content, onClick, disabled, isLoading }) => {
    return (
        <button
            className={`
                ${styles}
                px-4 py-3 rounded-[10px] flex justify-center items-center font-[500] hover:scale-105
                ${disabled ? "opacity-50" : ""}
            `}
            onClick={onClick}
            disabled={disabled}
        >
            {isLoading ? <Spinner className="h-3 w-3 max-h-3 max-w-3" /> :content}
        </button>
    )
}

export default Button