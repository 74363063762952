import React from 'react'
import InfoBox from './InfoBox'
import { shipmentInfoText } from '../../../../Constants'

const ShipmentInfoHeader = ({ service }) => {
    return (
        <div className='w-full flex flex-col gap-5 justify-center items-center'>
            <div className='flex flex-col gap-1'>
                <h3 className='text-[#282828] text-center font-[500] text-[24px]'>
                    Book Shipment For {service.toUpperCase()}
                </h3>
                <h3 className='text-[#282828] text-center text-[20px]'>
                    Ship From Nigeria to Over 200 Countries Within{" "} 
                    <span>
                        {service === "dhl" ? "3-5" : "7-10"}{" "}
                    </span>
                    Working Days
                </h3>
            </div>
            <InfoBox content={shipmentInfoText} />
        </div>
    )
}

export default ShipmentInfoHeader