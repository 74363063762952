import React, { useState, useEffect } from "react";

import { IoArrowBackOutline } from "react-icons/io5";

import { useNavigate, useParams } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import Btn from "../../Button/btn";
import DefaultModal from "../../Modals/defaultModal";
import { baseUrl } from "../../../Utils/constants";

import PaymentModal from "./PaymentModal";
import useWallet from './useWallet';


const SingleCargo = ({ data }) => {
  const { walletDetails, loading, walletBalance } = useWallet();
  //console.log("wallet balance", walletDetails)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [shipmentInformation, setShipmentInformation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  

  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useSelector((state) => state.auth);
  const [selectedPayment, setSelectedPayment] = useState(null);

// Add new state for payment status
  const [payStatus, setPayStatus] = useState(
    shipmentInformation?.payment_status || "not_paid"
  );
  
 // Update the payment modal handling
 const handlePaymentClick = () => {
  setIsPaymentModalOpen(true);
};

  useEffect(() => {
    const fetchShipmentDetails = async () => {
      if (!id) {
        setError("No shipment ID provided");
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(`${baseUrl}cargo-shipments/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setShipmentInformation(response.data.data.data);
        } else {
          setError(response.data.message || "Failed to fetch shipment details");
        }
      } catch (err) {
        setError(
          err.response?.data?.message || "Failed to fetch shipment details"
        );
      } finally {
        setIsLoading(false);
      }
    };

    fetchShipmentDetails();
  }, [id, token]);

  
  // cancel shipment
  const handleCancelShipment = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}cancel-cargo-shipment/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message); // Success toast
        setShipmentInformation((prev) => ({
          ...prev,
          status: "cancelled",
          payment_status: "cancelled"
        }));
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to cancel shipment"); // Error toast
    } finally {
      setIsCancelModalOpen(false);
    }
  };

  const handlePayment = () => {
    if (!selectedPayment) return;
   // console.log("Processing payment with:", selectedPayment);
    // Add your payment processing logic here
  };

  const handleCopyTrackingNumber = () => {
    if (shipmentInformation?.tracking_number) {
      navigator.clipboard
        .writeText(shipmentInformation.tracking_number)
        .then(() => {
          alert("Tracking number copied to clipboard!");
        })
        .catch((err) => {
         // console.error("Failed to copy tracking number:", err);
        });
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 text-center">
        <IoArrowBackOutline
          onClick={() => navigate(-1)}
          className="cursor-pointer mb-4"
        />
        <div className="bg-red-50 text-red-800 p-4 rounded-md">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-4 md:p-8">
      <ToastContainer />
      <IoArrowBackOutline
        onClick={() => navigate(-1)}
        className="cursor-pointer mb-4"
      />
      <h2 className="text-center font-medium text-lg mb-6">
        Shipment Information
      </h2>

      {shipmentInformation && (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Left Column */}
          <div className="space-y-4">
            <div className="flex justify-between">
              <div>
                <h2 className="font-medium text-sm uppercase text-gray-500">
                  Est Delivery Date
                </h2>
                <h2 className="mt-1">
                  {shipmentInformation?.cargo_type === "air"
                    ? "3-5 Working Days After Payment"
                    : "7-10 Working Days After Payment"}
                </h2>
              </div>
            </div>
            <div>
              <h2 className="font-medium text-sm uppercase text-gray-500">
                Shipment Method
              </h2>
              <h2 className="mt-1 capitalize">
                {shipmentInformation?.shipment_method?.replace("_", " ")}
              </h2>
            </div>
            <div>
              <h2 className="font-medium text-sm uppercase text-gray-500">
                Cargo Type
              </h2>
              <h2 className="mt-1 capitalize">
                {shipmentInformation?.cargo_type}
              </h2>
            </div>
            <div>
              <h2 className="font-medium text-sm uppercase text-gray-500">
                Amount Paid
              </h2>
              <h2 className="mt-1">
                ₦{Number(shipmentInformation?.price).toLocaleString()}
              </h2>
            </div>
            <div>
              <h2 className="font-medium text-sm uppercase text-gray-500">
                Insurance Cover
              </h2>
              <h2 className="mt-1">
                ₦{Number(shipmentInformation?.insurance).toLocaleString()}
              </h2>
            </div>
            <div className="pb-1">
              <h2
                className={`${
                  shipmentInformation?.status?.toLowerCase() === "cancelled"
                    ? "bg-[#f00]"
                    : "bg-[#008E56]"
                } text-white rounded-md text-center p-1 lg:p-3 capitalize w-2/3 sm:w-1/2 md:w-1/3 lg:w-1/4`}
              >
                {shipmentInformation?.status}
              </h2>
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-4">
            <div>
              <h2 className="uppercase text-gray-500 text-sm">Pickup From</h2>
              <h2 className="mt-2">
                {shipmentInformation?.sender_name} <br />
                {shipmentInformation?.sender_address},{" "}
                {shipmentInformation?.sender_city},{" "}
                {shipmentInformation?.sender_state},{" "}
                {shipmentInformation?.sender_country} <br />
                {shipmentInformation?.sender_email}{" "}
                {shipmentInformation?.sender_phone}
              </h2>
            </div>
            <div>
              <h2 className="uppercase text-gray-500 text-sm">Deliver To</h2>
              <h2 className="mt-2">
                {shipmentInformation?.receiver_name} <br />
                {shipmentInformation?.receiver_address},{" "}
                {shipmentInformation?.receiver_city},{" "}
                {shipmentInformation?.receiver_state},{" "}
                {shipmentInformation?.receiver_country} <br />
                {shipmentInformation?.receiver_email}{" "}
                {shipmentInformation?.receiver_phone}
              </h2>
            </div>
            <div>
              <h2 className="uppercase text-gray-500 text-sm">
                Item Description
              </h2>
              <h2 className="mt-2">
                <p>
                  Item value : ₦
                  {Number(shipmentInformation?.item_value).toLocaleString()}
                </p>
                <p>Item category : {shipmentInformation?.category}</p>
                <p>Item Weight : {shipmentInformation?.weight}kg</p>
                <p>
                  Insurance : ₦
                  {Number(shipmentInformation?.insurance).toLocaleString()}
                </p>
              </h2>
            </div>
          </div>
        </div>
      )}

<div className="flex justify-center items-center mt-4">
        {shipmentInformation?.payment_status === "not_paid" && 
         shipmentInformation?.status !== "cancelled" && (
          <Btn
            text="Make Payment"
            onClick={handlePaymentClick}
            className="bg-blue-600 text-white font-medium text-sm py-4 px-3"
          />
        )}
      </div>

<div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8">
        {shipmentInformation?.status === "pending" && 
         shipmentInformation?.payment_status === "not_paid" && (
          <Btn
            className="font-medium border-2 text-gray-900 w-full"
            text="Cancel Shipment"
            onClick={() => setIsCancelModalOpen(true)}
          />
        )}
        <Btn
          text="Track Shipment"
          onClick={() => setIsModalOpen(true)}
          className="bg-sec w-full text-white font-medium"
        />
      </div>

      {/* Cancel Confirmation Modal */}
      <DefaultModal
        visibilityState={isCancelModalOpen}
        closeModal={() => setIsCancelModalOpen(false)}
      >
        <div className="p-6">
          <h2 className="text-xl font-medium text-center mb-4">
            Cancel Shipment
          </h2>
          <p className="text-center mb-6">
            Are you sure you want to cancel this shipment?
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={() => setIsCancelModalOpen(false)}
              className="px-6 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              No
            </button>
            <button
              onClick={handleCancelShipment}
              className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
            >
              Yes
            </button>
          </div>
        </div>
      </DefaultModal>

      {/* Track Shipment Modal */}
      <DefaultModal
        visibilityState={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      >
        <h1 className="text-lg font-medium text-center">Track Shipment</h1>
        <h3 className="my-4">
          Please copy your tracking ID, access your dashboard, and paste the ID
          into the "Track Shipment" bar.
        </h3>
        <div className="flex justify-between items-center">
          <div>
            <div>
              <h2 className="text-gray-600 uppercase">EST. Delivery Date</h2>
              <h2 className="font-medium">
                {shipmentInformation?.cargo_type === "air"
                  ? "3-5 Working Days After Payment"
                  : "7-10 Working Days After Payment"}
              </h2>
            </div>
            <div className="mt-4">
              <h2 className="text-gray-600 uppercase">Tracking ID</h2>
              <h2 className="font-medium">
                {shipmentInformation?.tracking_number || "Not yet assigned"}
              </h2>
            </div>
          </div>
          <div
            className="text-sec flex flex-col items-center justify-center cursor-pointer"
            onClick={handleCopyTrackingNumber}
          >
            <h2 className="text-sm">Copy</h2>
          </div>
        </div>
      </DefaultModal>

      {/* Payment Modal */}
      {isPaymentModalOpen && (
        <PaymentModal
        setPayStatus={(status) => {
          setPayStatus(status);
          // Update the shipmentInformation state when payment is successful
          if (status === "paid") {
            setShipmentInformation((prev) => ({
              ...prev,
              payment_status: "paid"
            }));
          }
        }}
          // setPayStatus={setPayStatus}
          setModalShowing={setIsPaymentModalOpen}
          balance={walletBalance}
          amount={shipmentInformation?.price || 0}
          shipmentId={shipmentInformation?.id}
        />
      )}
    </div>
  );
};

export default SingleCargo;
