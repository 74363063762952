// src/App.js
import React, { useState } from "react";

import Wallet from "../../../Pages/Dashboard/Wallet";

import USDTWallet from "./UsdtWallet";

const WalletTab = () => {
  const [activeTab, setActiveTab] = useState("NGN");

  return (
    // <div className=" bg-gray-100 flex flex-col items-center justify-center">
    <div className="w-full  p-4">
      {/* Ensure this part is not commented out if you want to show title and description */}
      {/* <h1 className="text-xl font-bold text-center mb-4">Wallet</h1>
        <p className="text-center mb-4">All transactions are secured and encrypted</p> */}
      {/* Tabs */}
      <div className="flex justify-center mb-4 gap-3">
        <button
          className={`px-4 py-2 rounded-full ${
            activeTab === "NGN" ? "bg-[#008E56]  text-white" : "bg-gray-300"
          }`}
          onClick={() => setActiveTab("NGN")}
        >
          NGN Wallet
        </button>
        {/* <button
          className={`px-4 py-2 rounded-full ${
            activeTab === "USDT" ? "bg-[#008E56]  text-white" : "bg-gray-300"
          }`}
          onClick={() => setActiveTab("USDT")}
        >
          USDT Wallet
        </button> */}
      </div>
      {/* Wallet Details */}
      <div className="flex justify-center">
        {activeTab === "NGN" ? (
          <Wallet
            walletDetails={{ wallet_balance: 0 }}
            setIsTopupModalVisible={() => {}}
          />
        ) : (
          <USDTWallet />
        )}
      </div>
    </div>
  );
};

export default WalletTab;
