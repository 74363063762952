import React, { useEffect, useState } from "react";

import { IoMdAdd } from "react-icons/io";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import { ToastContainer, toast } from "react-toastify";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import Btn from "../../../Components/Button/btn";
import Input from "../../../Components/Form/Input";
import DefaultModal from "../../../Components/Modals/defaultModal";

const Tracking = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false);
  const [isUpdateBatchModalOpen, setIsUpdateBatchModalOpen] = useState(false);
  const [currentBatch, setCurrentBatch] = useState(null);
  const [batchDetails, setBatchDetails] = useState("");
  const { adminToken } = useSelector((state) => state.admin);
  const [newBatch, setNewBatch] = useState({});
  const [batches, setBatches] = useState([]);

  const handeleInputChange = (e) => {
    setNewBatch({ ...newBatch, [e.target.name]: e.target.value });
  };

  const columns = [
    {
      header: "S/N",
      cell: ({ row }) => row.index + 1,
    },
    { header: "Batch No", accessorKey: "batch_number" },
    { header: "Start Date", accessorKey: "start_date" },
    { header: "End Date", accessorKey: "end_date" },
    { header: "Desc", accessorKey: "description" },
    { header: "Shipment Type", accessorKey: "shipment_type" },
    {
      header: "Action",
      cell: ({ row }) => (
        <Btn
          text="Update"
          className="text-sec bg-green-50 text-sm"
          onClick={() => {
            setCurrentBatch(row.original);
            setIsUpdateBatchModalOpen(true);
          }}
        />
      ),
    },
  ];

  const getAllBatches = async () => {
    setIsLoading(true);
    const url = `${baseUrl}admin/get-batch`;
    try {
      const res = await axios.get(url, config(adminToken));
      setBatches(res.data.date);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const updateBatch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}admin/update-batch`;
    try {
      const res = await axios.post(
        url,
        {
          batch_id: currentBatch.id,
          details: batchDetails,
        },
        config(adminToken)
      );

      // Update local batches state
      setBatches((prevBatches) =>
        prevBatches.map((batch) =>
          batch.id === currentBatch.id
            ? { ...batch, details: batchDetails }
            : batch
        )
      );

      toast.success(res.data.message);
      setIsLoading(false);
      setIsUpdateBatchModalOpen(false);
      setBatchDetails("");
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message || "Update failed");
    }
  };

  const createBatch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}admin/create-batch`;
    try {
      const res = await axios.post(url, newBatch, config(adminToken));
      await getAllBatches();
      toast.success(res.data.message || "Batch Created Successfully");
      setIsLoading(false);
      setIsCreateBatchModalOpen(false);
    } catch (error) {
      setIsLoading(false);
      toast.error(error?.response?.data?.message || "Create batch failed");
    }
  };

  useEffect(() => {
    getAllBatches();
  }, []);

  if (isLoading) {
    return (
      <div className="h-[20vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <ToastContainer autoClose={3000} />

      <button
        className="flex items-center text-sec2 my-4 rounded-md text-sm gap-2 hover:text-gray-900 transition"
        onClick={() => setIsCreateBatchModalOpen(true)}
      >
        <IoMdAdd className="text-base" />
        Create Batch Number
      </button>

      {/* Create Batch Modal */}
      <CreateBatch
        closeModal={() => setIsCreateBatchModalOpen(false)}
        visibilityState={isCreateBatchModalOpen}
        handeleInputChange={handeleInputChange}
        createBatch={createBatch}
        isLoading={isLoading}
      />

      {/* Update Batch Modal */}
      <DefaultModal
        visibilityState={isUpdateBatchModalOpen}
        closeModal={() => setIsUpdateBatchModalOpen(false)}
      >
        {currentBatch && (
          <form onSubmit={updateBatch}>
            <Input
              title="Enter comment about this batch"
              setItem={(e) => setBatchDetails(e.target.value)}
              value={batchDetails}
              textarea
              id="details"
              placeholder="Enter comment"
              className="bg-gray-100"
            />
            <Btn
              loadingState={isLoading}
              className="bg-sec2 text-white text-sm"
              text="Send Comment"
              type="submit"
            />
          </form>
        )}
      </DefaultModal>

      <TanTable columnData={columns} data={batches} isLoading={isLoading} />
    </div>
  );
};

export default Tracking;

// CreateBatch component remains the same
const CreateBatch = ({
  closeModal,
  visibilityState,
  handeleInputChange,
  createBatch,
  isLoading,
}) => {
  return (
    <DefaultModal closeModal={closeModal} visibilityState={visibilityState}>
      <div className="bg-white">
        <h2 className="my-2 font-medium text-lg text-sec">
          Select Shipment that will fall into this batch
        </h2>
        <form action="" onSubmit={createBatch} className="flex flex-col">
          <Input
            id="start_date"
            setItem={handeleInputChange}
            input
            className="border"
            title="Start Date"
            type="date"
          />
          <Input
            id="end_date"
            setItem={handeleInputChange}
            input
            className="border"
            title="End Date"
            type="date"
          />
            {/* <Input
            id="description"
            setItem={handeleInputChange}
            input
            className="border"
            title="Desc"
            type="text"
          /> */}

           <select
            name="description" // Change this to match the backend expectation
            onChange={handeleInputChange} // Ensure this handler updates the state
            className="w-full mb-4 p-2 border rounded"
            required // Ensure the field is required
          >
            <option value="">Select Discription Type</option>
            <option value="express">Express</option>
            <option value="import">Import</option>
            <option value="air">Air</option>
            <option value="sea">Sea</option>
            <option value="frozenAir">Air Frozen</option>
            <option value="frozenSea">Sea Frozen</option>
            {/* <option value="frozenAir">Frozen Air</option>
            <option value="frozenSea">Frozen Sea</option> */}
          </select>
          
          <select
            name="shipment_type" // Change this to match the backend expectation
            onChange={handeleInputChange} // Ensure this handler updates the state
            className="w-full mb-4 p-2 border rounded"
            required // Ensure the field is required
          >
            <option value="">Select Shipment Type</option>
            <option value="express">Express</option>
            <option value="import">Import</option>
            <option value="cargo">cargo</option>
            {/* <option value="frozenAir">Frozen Air</option>
            <option value="frozenSea">Frozen Sea</option> */}
          </select>

          <Btn
            className="bg-sec2 text-white my-5"
            text="Save"
            loadingState={isLoading}
          />
        </form>
      </div>
    </DefaultModal>
  );
};
