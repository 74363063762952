import React, { useEffect, useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import { ToastContainer, toast } from "react-toastify";

import { baseUrl, config } from "../../Utils/constants";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";
import DefaultModal from "../../Components/Modals/defaultModal";

const CalculatorExport = () => {
  const [locationData, setLocationDate] = useState({});
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dollarRate, setDollarRate] = useState("");
  const [cargoPrices, setCargoPrices] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isLoadingCountries, setIsLoadingCountries] = useState(true);
  const { token } = useSelector((state) => state.auth);
const [isModalShown, setIsModalShown] = useState(false);



  
  const handleInputChange = (e) => {
    setTotal(0); // Reset total when input changes
    setLocationDate({ ...locationData, [e.target.name]: e.target.value });
  };

  const fetchDollarRate = async () => {
    try {
      const response = await axios.get(`${baseUrl}current-dollar-rate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.status) {
        const rate = response.data.data;
        if (!isNaN(rate)) {
          setDollarRate(parseFloat(rate).toFixed(2)); // Set the dollar rate as a formatted number
        } else {
          throw new Error("Invalid dollar rate received from API");
        }
      } else {
        throw new Error(response.data?.message || "Failed to fetch dollar rate");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || "Error fetching dollar rate";
      toast.error(errorMessage);
      setDollarRate("N/A"); // Set a fallback value if the API call fails
    }
  };

  const fetchCargoPrice = async (country) => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}cargo-price/${country}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        // Set cargo prices for the selected country
        setCargoPrices(response.data.data.data);
      } else {
        throw new Error(response.data.message || 'Failed to fetch cargo price');
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || err.message || 'Error fetching cargo price';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateTotalCost = () => {
    const { weight, cargoType, location } = locationData;

    // Validate inputs
    if (!weight || !cargoType || !location) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (!cargoPrices) {
      toast.error("Cargo prices are not available. Please try again later.");
      return;
    }

    // Get cargo price based on selected cargo type
    const cargoPrice = getCargoPrice(cargoType);
    if (cargoPrice === 0) {
      toast.error("Invalid cargo type selected.");
      return;
    }

    // Parse weight and dollar rate
    const weightInKg = parseFloat(weight);
    const dollarRateValue = dollarRate === "N/A" ? 0 : parseFloat(dollarRate);

    if (isNaN(weightInKg) || isNaN(dollarRateValue)) {
      toast.error("Invalid weight or dollar rate.");
      return;
    }

    // Calculate total cost: dollar rate × weight × cargo price
    const totalCost = dollarRateValue * weightInKg * cargoPrice;
    setTotal(totalCost.toFixed(2)); // Set the total cost with 2 decimal places
  };

  const getCargoPrice = (cargoType) => {
    if (!cargoPrices) return 0;

    switch (cargoType) {
      case 'air':
        return parseFloat(cargoPrices.air);
      case 'sea':
        return parseFloat(cargoPrices.sea);
      case 'frozenAir':
        return parseFloat(cargoPrices.air_frozen);
      case 'frozenSea':
        return parseFloat(cargoPrices.sea_frozen);
      default:
        return 0;
    }
  };

  const fetchCountries = async () => {
    try {
      setIsLoadingCountries(true);
      const response = await axios.get(`${baseUrl}cargo-country`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data?.success && Array.isArray(response.data.data?.data)) {
        const countryNames = response.data.data.data.map((item) => item.country);
        setCountries(countryNames);
      } else {
        console.warn("Invalid countries data format:", response.data);
        setCountries([]);
      }
    } catch (error) {
      console.error("Failed to fetch countries:", error.message || error);
      setCountries([]);
    } finally {
      setIsLoadingCountries(false);
    }
  };

  useEffect(() => {
    if (locationData.location) {
      fetchCargoPrice(locationData.location); // Fetch cargo prices when location changes
    }
  }, [locationData.location]);

  useEffect(() => {
    fetchDollarRate();
    fetchCountries();
  }, []);

  return (
    <div className="bg-white p-6 rounded-md">
      <ToastContainer autoClose={3000} />
      <div className="max-w-xl">
        <h2 className="text-lg font-medium">
          Calculate your Cargo shipping cost here export from Nigeria
        </h2>

        <form
          action=""
          className="my-8 text-sm"
          onSubmit={(e) => {
            e.preventDefault();
            calculateTotalCost(); // Trigger calculation on form submission
          }}
        >
          <Input
            className="shadow-sm bg-gray-100"
            title="Weight of shipment in kg"
            input
            type="number"
            id="weight"
            name="weight"
            setItem={handleInputChange}
            required={true}
            step={"any"}
          />

          <label htmlFor="cargoType" className="block mb-2 font-medium">
            Cargo Type
          </label>

          <select
            name="cargoType"
            className="w-full mb-4 p-2 border rounded"
            required
            onChange={handleInputChange}
          >
            <option value="">Select cargo Type</option>
            <option value="air">Air</option>
            <option value="sea">Sea</option>
            <option value="frozenAir">Frozen Air</option>
            <option value="frozenSea">Frozen Sea</option>
          </select>

          <Input
            className="shadow-sm bg-gray-100"
            title="Country"
            dropdown
            id="location"
            name="location"
            data={["", ...countries]}
            setItem={handleInputChange}
            required={true}
          />

          <label htmlFor="delivery_type" className="block mb-2 font-medium">
            Delivery Type
          </label>
          <select
            id="delivery_type"
            name="delivery_type"
            className="w-full mb-4 p-2 border rounded"
            required
            onChange={handleInputChange}
          >
            <option value="">Delivery Type</option>
            <option value="doorstep">Doorstep</option>
          </select>

          <p className="bg-green-100 p-4 text-sm rounded-md">
            <span className="font-bold">Volumetric Weight</span> may apply if
            your package is shipped in a box OR has visible dimensions (i.e.
            square or rectangularly shaped). This means that your final shipping
            charge may be based on the Volumetric Weight of your package, rather
            than the Actual Weight. Volumetric weight formula: L x B x H / 5000
          </p>
          <Btn
            loadingState={isLoading}
            text="Calculate"
            className={"bg-sec text-white mt-5"}
            type="submit" // Ensure the button submits the form
          />
        </form>

        <h2 className="text-sm font-bold">Total Payment</h2>
        <h2 className="bg-gray-200 p-3 font-medium text-lg my-4 rounded-md">
        {isLoading ? <Spinner /> : `₦ ${total}`}
        </h2>
      </div>
      <DefaultModal
        visibilityState={isModalShown}
        closeModal={() => setIsModalShown(false)}
      >
        <h2 className="text-sm">
          Please Send us an email at{" "}
          <span className="text-sec">info@eshopafrica.com</span> and tell us the
          details of your shipment, the location it's going to from China, the
          weight, the dimensions and the type of products. Our team will send
          you a quote for the shipment
        </h2>
        <Btn
          text="Okay"
          className={"bg-pry mt-5"}
          onClick={() => setIsModalShown(false)}
        />
      </DefaultModal>
    </div>
  );
};

export default CalculatorExport;