import React from "react";
import { IoReturnUpBack } from "react-icons/io5";

const BackButton = ({ action }) => {
  return (
    <div className='flex items-center gap-2 text-gray-400' onClick={action}>
      <IoReturnUpBack />
      <h2>Back</h2>
    </div>
  );
};

export default BackButton;
