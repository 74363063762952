import React from 'react'
import FedexPrice from '../../Components/Dashboard/Price/FedexPrice'


const FedexPricePage = () => {
  return (
    <div>
      <FedexPrice/>
    </div>
  )
}

export default FedexPricePage
