// hooks/useWallet.js
import { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import axios from 'axios';

import { baseUrl } from "../../../Utils/constants";  // Adjust this import path based on your project structure

const useWallet = () => {
    const [walletDetails, setWalletDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(0);
    
    // Get token from Redux store
    const { token } = useSelector(state => state.auth);

    // Function to fetch wallet details
    const fetchWalletDetails = useCallback(async () => {
        if (!token) {
            setError('No authentication token available');
            setLoading(false);
            return;
        }

        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`${baseUrl}wallet`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = response.data.data;
            console.log("Wallet details fetched:", data);
            setWalletDetails(data);
            setError(null);
        } catch (error) {
            console.error("Error fetching wallet:", error);
            setError(error.response?.data?.message || 'Failed to fetch wallet details');
            // Set default wallet details to prevent undefined
            setWalletDetails({ wallet_balance: 0 });
        } finally {
            setLoading(false);
        }
    }, [token]);

    // Function to manually refresh wallet details
    const refreshWallet = useCallback(() => {
        setRefreshTrigger(prev => prev + 1);
    }, []);

    // Effect to fetch wallet details
    useEffect(() => {
        fetchWalletDetails();
    }, [fetchWalletDetails, refreshTrigger]);

    // Format wallet balance with proper error handling
    const formattedBalance = useCallback(() => {
        try {
            const balance = walletDetails?.wallet_balance || 0;
            return Number(balance).toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN',
            });
        } catch (error) {
            console.error('Error formatting balance:', error);
            return '₦0.00';
        }
    }, [walletDetails]);

    return {
        walletDetails,
        loading,
        error,
        refreshWallet,
        formattedBalance: formattedBalance(),
        walletBalance: walletDetails?.wallet_balance || 0,
        isWalletEmpty: !walletDetails?.wallet_balance,
    };
};

export default useWallet;