import React from "react";
import TanHeader from "./header";
import TanRows from "./rows";

const TanBody = ({ table }) => {
	return (
		<div className='overflow-x-scroll text-sm'>
			<table className='w-full'>
				<TanHeader table={table} />
				<TanRows table={table} />
			</table>
		</div>
	);
};

export default TanBody;
