import React, { useEffect, useState } from "react";
import TanTable from "../../../Utils/TanTable";
import axios from "axios";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import { toast } from "react-toastify";

const UpsZone = ({ upsZones, isLoading }) => {
	const columns = [
		{
			header: "Serial Number",
			accessorKey: "sn",
			cell: ({ row }) => row.index + 1,
		},
		{ header: "Zone Numbers", accessorKey: "zone" },
		{ header: "Countries", accessorKey: "country_name" },
		// { header: "Action", accessorKey: "action" }
	];

	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the countries belonging to different Zones
			</h1>
			<TanTable columnData={columns} loadingState={isLoading} data={upsZones} />
		</div>
	);
};

export default UpsZone;
