import { useEffect, useState } from "react";
import Button from "../ExpressShipping/NewImplementation/Button";
import { FaRegCopy } from "react-icons/fa6"


const CardDetailsModal = ({ setModalShowing, dollarCardDetails }) => {

  console.log(dollarCardDetails, "This is the dollar card details received")

    return (
        <div className="fixed h-screen w-full left-0 top-0 bg-[#33333354] backdrop-blur-sm flex justify-center items-center z-[50] p-5" onClick={() => setModalShowing(false)}>
            <div className="bg-white rounded-md p-5 flex flex-col justify-center items-center gap-4 w-full max-w-[550px]" onClick={(e) => e.stopPropagation()}>
                <div className="flex flex-row items-center justify-between pb-2 border-b-[1px] border-solid border-[#ccc] w-full">
                  <h3 className="text-[#282828] text-[20px] font-semibold">
                      Card Details
                  </h3>
                  <button className="text-[#000] font-[700] text-[20px]" onClick={() => setModalShowing(false)}>
                    X
                  </button>
                </div>
                <div>
                <div className="lg:flex justify-around">
                  <div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Cardholder Name
                      </label>
                      <div className="mb-4 flex justify-between items-center">
                        <p className="text-lg">{dollarCardDetails?.user?.firstname} {dollarCardDetails?.user?.lastname}</p>
                        <FaRegCopy />
                      </div>
                    </div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Card Number
                      </label>
                      <div className="mb-4 flex justify-between gap-4 items-center">
                        <p className="text-lg">{dollarCardDetails?.dollar_card_details?.number}</p>
                        <FaRegCopy />
                      </div>
                    </div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Expiry Date
                      </label>
                      <div className="mb-4 flex justify-between items-center">
                        <p className="text-lg">{dollarCardDetails?.dollar_card_details?.expiry}</p>
                        <FaRegCopy />
                      </div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        CVV
                      </label>
                      <div className="mb-4 flex justify-between items-center">
                        <p className="text-lg ml-2">{dollarCardDetails?.dollar_card_details?.ccv}</p>
                        <FaRegCopy />
                      </div>
                    </div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Billing Address
                      </label>
                      <div className="mb-4 flex justify-between items-center gap-4">
                        <p className="ml-2 text-lg">
                        {dollarCardDetails?.user?.address ?? ""}
                        </p>
                        <FaRegCopy />
                      </div>
                    </div>
                    <div>
                      <label className="block text-gray-700 text-sm font-bold mb-2">
                        Brand
                      </label>
                      <div className="mb-4 flex justify-between items-center">
                        <p className="text-lg ml-2">{dollarCardDetails?.dollar_card_details?.brand}</p>
                        <FaRegCopy />
                      </div>
                    </div>
                  </div>
                  {/* text */}
                </div>
        <div>
          <p className="text-[#008E56]">
            Ensure your billing address matches the address associated with your
            card to prevent transactions from being declined.
          </p>
        </div>
                </div>
                <div className="w-full flex flex-row items-center justify-end gap-4 mt-3">
                    <Button
                        styles="bg-[#008E56] text-white"
                        content="Close"
                        onClick={() => setModalShowing(false)}
                    />
                </div>
            </div>
        </div>
    )
}

export default CardDetailsModal;