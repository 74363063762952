import React, { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import DefaultModal from "../../Modals/defaultModal";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const SingleNotification = ({ item, getNotifications }) => {
	const { token } = useSelector((state) => state.auth);
	const [isContentShown, setContentShown] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { title, details: content, id } = item || {};

	const deleteNotification = async (id) => {
		const url = `${baseUrl}delete-notification/${id}`;
		setIsLoading(true);
		let msg;
		try {
			const res = await axios.delete(url, config(token));
			getNotifications();
			setIsLoading(false);
			msg = res.data.message;
			toast.success(msg);
		} catch (error) {
			setIsLoading(false);
			msg = toast.error(error?.response?.data?.message);
		}
	};

	return (
		<div className='my-5 p-8 shadow-sm'>
			<ToastContainer autoClose={3000} />
			<div className='flex items-center gap-2 text-sm'>
				<h2 className='bg-blue-800 p-1 rounded-full px-3 text-white'>New</h2>
				<h2 className='font-medium text-lg'>{title}</h2>
			</div>
			<hr className='my-3' />
			<p className='my-3 text-sm'>
				{content.slice(0, 50)}
				{content.length > 50 && "......"}
				{content.length > 50 && (
					<span
						className='text-blue-800 italic text-sm cursor-pointer select-none'
						onClick={() => setContentShown(true)}
					>
						Read More
					</span>
				)}
			</p>
			{isLoading ? (
				<Spinner />
			) : (
				<div
					className='flex items-center gap text-red-600 italic text-sm cursor-pointer select-none'
					onClick={() => deleteNotification(id)}
				>
					<AiOutlineDelete className='text-base' />
					<h2 className=''>Delete</h2>
				</div>
			)}

			<DefaultModal
				closeModal={() => setContentShown(false)}
				visibilityState={isContentShown}
			>
				<div className='flex items-center gap-2 text-sm'>
					<h2 className='bg-blue-800 p-1 rounded-full px-3 text-white'>New</h2>
					<h2 className='font-medium text-lg'>{title}</h2>
				</div>
				<p className='text-sm my-4'>{content}</p>
			</DefaultModal>
		</div>
	);
};

export default SingleNotification;
