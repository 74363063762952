import React from "react";
import GuidelinesHero from "../../Components/ShippingGuidelines/hero";
import Possible from "../../Components/ShippingGuidelines/possible";
import Items from "../../Components/ShippingGuidelines/Items";
import Help from "../../Components/Contact/help";
import { useEffect } from "react";

const ShippingGuidelines = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <GuidelinesHero />
      <Possible />
      <Items />
      <Help />
    </div>
  );
};

export default ShippingGuidelines;
