
import React, { useState } from "react";
import BtnGrid from "../../Button/BtnGrid";
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";


const UsdtDeposit = () => {
  const [selectedNetwork, setSelectedNetwork] = useState("");

  const handleNetworkChange = (event) => {
    setSelectedNetwork(event.target.value);
    };
    
    const handleContinue = () => {
        navigate("/dashboard/barcode"); 
      };
  const navigate = useNavigate();

  const goBackHandler = () => {
    navigate(-1);
  };
  return (
    <div className="w-full p-8">
       <IoArrowBackOutline onClick={goBackHandler} />
      <h1 className="text-2xl font-semibold text-center">USDT Deposit</h1>
      <div className="mt-4 w-full">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="network"
        >
          Network
        </label>
        <select
          id="network"
          value={selectedNetwork}
          onChange={handleNetworkChange}
          className="block w-full bg-gray-100 border border-gray-300 rounded py-3 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        >
          <option value="" disabled>
            Select Network
          </option>
          <option value="Tron">Tron (TRC-20) arrival time (2 min)</option>
          <option value="Ethereum">Solana arrival time (2 min)</option>
          <option value="Binance">
            BNB Smart Chain (BEP-20) arrival time (3 min)
          </option>
          <option value="Ethereum">
            Ethereum (ERC-20) arrival time (4 min)
          </option>
        </select>
      </div>
      <div className="mt-4 w-full text-sm text-red-600">
        <p className="pt-2">
          ⚠️ Warning: Send only USDT using the network you have chosen.
        </p>
        <p className="pt-2">
          ⚠️ Warning: Sending coins or tokens other than USDT to this
          address may result in the loss of funds.
        </p>
      </div>
      <div className="flex items-center justify-center pt-8">
        <BtnGrid
          className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24"
             onClick={handleContinue}
        >
          Continue
        </BtnGrid>
      </div>
    </div>
  );
};

export default UsdtDeposit;
