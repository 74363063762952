import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { Spinner } from "react-activity";

import { baseUrl, config } from "../../../Utils/constants";
import activeCard from "../../../Assets/images/activeCard.png";
import blockedCard from "../../../Assets/images/blockedCard.png";
import canShipment from "../../../Assets/images/canShipment.png";
import cancelledImport from "../../../Assets/images/cancelledImports.png";
import card from "../../../Assets/images/card5.png";
import delShipment from "../../../Assets/images/delShipment.png";
import fdelivered from "../../../Assets/images/fdelivered.png";
import fgoods from "../../../Assets/images/fgoods.png";
import freezedCard from "../../../Assets/images/freezedCard.png";
import fstore from "../../../Assets/images/fstore.png";
import fusers from "../../../Assets/images/fusers.png";
import importImg from "../../../Assets/images/importImg.png";
import pendShipment from "../../../Assets/images/pendShipment.png";
import pendingImport from "../../../Assets/images/pendingImport.png";
import shipment from "../../../Assets/images/shipment.png";
import sucessfullImport from "../../../Assets/images/successfulImport.png";
import users from "../../../Assets/images/users.png";

const AdminDashboard = () => {
  const { adminToken } = useSelector((state) => state.admin);

  const [isLoading, setIsLoading] = useState(true);
  const [dashDetails, setDashDetails] = useState({});

  const getDashboardData = async () => {
    const url = `${baseUrl}admin/dashboard`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setDashDetails(data);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  const {
    cancelled_shipments,
    delivered_shipments,
    pending_shipments,
    total_dollar_cards,
    total_shipments,
    total_users,
    active_cards,
    blocked_cards,
    freezed_cards,
    total_fulfilement_delivered,
    total_fulfilement_pending,
    total_fulfilment_goods,
    total_fulfilment_users,
    total_goods_sentout,
    total_send_out_goods_delivered,
    total_send_out_goods_pending
  } = dashDetails || {};

  const data = [
    { title: "Total Number of users", numbers: total_users, image: users },
    {
      title: "Total Number of dollar card users",
      numbers: total_dollar_cards,
      image: card
    },
    {
      title: "Total Number of shipments",
      numbers: total_shipments,
      image: shipment
    },
    {
      title: "Total Number of delivered shipments",
      numbers: delivered_shipments,
      image: delShipment
    },
    {
      title: "Total Number of pending shipments",
      numbers: pending_shipments,
      image: pendShipment
    },
    {
      title: "Total Number of cancelled shipments",
      numbers: cancelled_shipments,
      image: canShipment
    },
    {
      title: "Total Number of active cards",
      numbers: active_cards,
      image: activeCard
    },
    {
      title: "Total Number of freezed cards",
      numbers: freezed_cards,
      image: freezedCard
    },
    {
      title: "Total Number of blocked cards",
      numbers: blocked_cards,
      image: blockedCard
    },
    // {
    //   title: "Total Number of imports",
    //   numbers: 0,
    //   image: importImg
    // },
    // {
    //   title: "Total Number of pending imports",
    //   numbers: 0,
    //   image: pendingImport
    // },
    // {
    //   title: "Total Number of successful imports",
    //   numbers: 0,
    //   image: sucessfullImport
    // },
    // {
    //   title: "Total Number of cancelled imports",
    //   numbers: 0,
    //   image: cancelledImport
    // },
    // //

    // {
    //   title: "Total Number of Exports",
    //   numbers: 0,
    //   image: importImg
    // },
    // {
    //   title: "Total Number of pending Exports",
    //   numbers: 0,
    //   image: pendingImport
    // },
    // {
    //   title: "Total Number of successful Exports",
    //   numbers: 0,
    //   image: sucessfullImport
    // },
    // {
    //   title: "Total Number of cancelled Exports",
    //   numbers: 0,
    //   image: cancelledImport
    // },

    {
      title: "Total Number of fulfillment users",
      numbers: total_fulfilment_users,
      image: fusers
    },
    {
      title: "Total No of goods reg in fulfillment center",
      numbers:  total_fulfilment_goods,
      image: fstore
    },
    {
      title: "Total goods sent out from fulfillment",
      numbers: total_goods_sentout,
      image: fdelivered
    },
    {
      title: "Total sentout goods pending for fulfillment",
      numbers:  total_send_out_goods_pending,
      image: fgoods
    },
    {
      title: "Total reg goods pending in fulfillment center",
      numbers: total_fulfilement_pending,

      image: fgoods
    },
    {
      title: "Total sentout goods delivered for fulfillment",
      numbers: total_send_out_goods_delivered,
      image: fgoods
    },
    {
      title: "Total reg goods delivered in fulfillment center",
      numbers: total_fulfilement_delivered,

      image: fgoods
    }
  ];

  if (isLoading) {
    return (
      <div className="h-[20vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }
  return (
    <div>
      <div className="grid my-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {data.map(({ title, numbers, image }, ind) => {
          return (
            <div
              key={ind}
              className="bg-white rounded-md p-4 flex flex-col justify-between"
            >
              <h2 className="text-gray-500 text-sm">{title}</h2>
              <div className="flex justify-between gap-4 items-center my-3">
                <h2 className="text-2xl font-medium">{numbers}</h2>
                <img className="w-7" src={image} alt="image" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminDashboard;
