import { Spinner } from "react-activity";
import { convertCurrencyToNaira } from "../../../../Utils/currency";
import EditIcon from "../../../Icons/EditIcon";
import AdditionalNoteModal from "./AdditionalNoteModal";
import InfoBox from "./InfoBox";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../../Utils/constants";
import axios from "axios";
import { toast } from "react-toastify"; // Corrected import

const OrderSummary = ({
  handleChangeStep,
  name,
  addressLine1,
  city,
  state,
  country,
  email,
  phone,
  receiverName,
  receiverAddressLine1,
  receiverAddressLine2,
  receiverAddressLine3,
  receiverCity,
  receiverState,
  receiverCountry,
  receiverEmail,
  receiverPhone,
  price,
  insurance,
  shipmentMethod,
  items,
  service,
  additionalNote,
  setAdditionalNote,
  setPrice,
  receiverPostalAddress,
  pickupArea,
  areaOptions,
  pickupPrice,
  submitLoading,
  setSubmitLoading,
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const [loading, setLoading] = useState(true);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleFetchRateAndInsurance = async () => {
      try {
        // Check the service type and fetch the rate accordingly
        if (service === "dhl") {
          const dhlRateResponse = await axios.post(
            `${baseUrl}dhl-rate`,
            {
              receiver_address_1: receiverAddressLine1,
              receiver_address_2: receiverAddressLine2,
              receiver_address_3: receiverAddressLine3,
              receiver_country_code: receiverCountry,
              receiver_state: receiverState,
              receiver_city: receiverCity,
              receiver_postal_address: receiverPostalAddress,
              weight: Number(items[0].weight),
              length: Number(items[0].length),
              height: Number(items[0].height),
              width: Number(items[0].width),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setPrice(dhlRateResponse.data.data.price);
        } else if (service === "ups") {
          const upsRateResponse = await axios.post(
            `${baseUrl}ups-rate`,
            {
              receiver_address_1: receiverAddressLine1,
              receiver_address_2: receiverAddressLine2,
              receiver_address_3: receiverAddressLine3,
              country: receiverCountry,
              receiver_state: receiverState,
              receiver_city: receiverCity,
              receiver_postal_address: receiverPostalAddress,
              weight: Number(items[0].weight),
              length: Number(items[0].length),
              height: Number(items[0].height),
              width: Number(items[0].width),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setPrice(upsRateResponse.data?.data.data);
        }
      } catch (error) {
        console.log("An error occurred");
        toast.error("Failed to get rate, crosscheck receiver's details.");
        handleChangeStep(3);
      } finally {
        setLoading(false);
      }
    };

    handleFetchRateAndInsurance();
  }, [
    service,
    receiverAddressLine1,
    receiverAddressLine2,
    receiverAddressLine3,
    receiverCity,
    receiverState,
    receiverPostalAddress,
    receiverCountry,
    items,
    token,
    handleChangeStep,
    setPrice,
  ]);

  if (loading) {
    return (
      <div className="flex flex-row h-full w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 w-full">
      <h3 className="text-[#282828] text-center font-[500] text-[14px] px-2 sm:px-0">
        Review your shipping information, don't worry if you are not sure of the shipment weight, 
        once we receive your shipment we will send you the exact weight and you can proceed to make payment on orders & Payment tab.
        Volumteric weight may apply if your package is shipped in a box or has visible dimensions (i.e square or rectangularly shaped)
      </h3>
      <div className="flex flex-row w-full justify-center items-center px-2 sm:px-0">
        <InfoBox content={"Ship it right the first time. Avoid being charged modification fees by confirming all details are correct."} />
      </div>
      <div className="flex flex-col gap-2 w-full bg-[#E4E4E466] rounded-2xl mx-2 sm:mx-0">
        <div className="flex flex-col p-3 sm:p-5">
          <div className="flex flex-col sm:flex-row w-full justify-between">
            <div className="flex flex-row gap-3">
              <div className="flex flex-col items-center h-full mt-1">
                <div className="h-3 w-3 rounded-full bg-[#3B40BF]" />
                <div className="h-full flex flex-1 w-[1px] bg-[#3B40BF]" />
              </div>
              <div className="flex flex-col gap-1.5 pb-3.5">
                <p className="text-[#28282899] text-[14px] font-[500]">Pickup From</p>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base">{name}</h2>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base break-words">{`${addressLine1}, ${city}, ${state} ${country}`}</h2>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base break-words">{email}</h2>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base">{phone}</h2>
              </div>
            </div>
            <button className="flex flex-row gap-2 text-white items-center px-3 py-2 rounded-[10px] bg-[#008E56] h-fit w-fit mt-2 sm:mt-4 text-sm sm:text-base" onClick={() => handleChangeStep(2)}>
              <EditIcon styles="h-[16px] w-[16px] sm:h-[20px] sm:w-[20px]" />
              <p className="font-semibold">Edit</p>
            </button>
          </div>
          <div className="flex flex-col sm:flex-row w-full justify-between mt-4 sm:mt-0">
            <div className="flex flex-row gap-3">
              <div className="flex flex-col items-center h-[80%] mt-1">
                <div className="h-3 w-3 rounded-full bg-[#008E56]" />
                <div className="h-full flex flex-1 w-[1px] bg-[#008E56]" />
              </div>
              <div className="flex flex-col gap-1.5">
                <p className="text-[#28282899] text-[14px] font-[500]">Deliver To</p>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base">{receiverName}</h2>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base break-words">{`${receiverAddressLine1}, ${receiverCity}, ${receiverState} ${receiverCountry}`}</h2>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base break-words">{receiverEmail}</h2>
                <h2 className="text-[#282828] font-[500] text-sm sm:text-base">{receiverPhone}</h2>
              </div>
            </div>
            <button className="flex flex-row gap-2 text-white items-center px-3 py-2 rounded-[10px] bg-[#008E56] h-fit w-fit mt-2 sm:mt-4 text-sm sm:text-base" onClick={() => handleChangeStep(3)}>
              <EditIcon styles="h-[16px] w-[16px] sm:h-[20px] sm:w-[20px]" />
              <p className="font-semibold">Edit</p>
            </button>
          </div>
        </div>
        <div className="w-full h-[1px] bg-[#28282866]" />
        <div className="flex flex-col gap-3 p-3 sm:p-5">
          <div className="grid grid-cols-3 gap-2">
            <p className="text-[12px] sm:text-[14px] font-[500] text-[#28282899]">Shipping method</p>
            <div className="flex justify-center items-center">
              <p className="text-[12px] sm:text-[14px] font-[500] text-[#28282899]">Cost</p>
            </div>
            <div className="flex justify-end pr-2 sm:pr-6">
              <p className="text-[12px] sm:text-[14px] font-[500] text-[#28282899]">Action</p>
            </div>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[20px]">{service.toUpperCase()}</h3>
            <div className="flex justify-center">
              <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[20px]">{convertCurrencyToNaira(Number(price))}</h3>
            </div>
            <div className="flex justify-end">
              <button className="flex flex-row gap-1 sm:gap-2 text-white items-center px-2 sm:px-3 py-2 sm:py-2.5 rounded-[10px] bg-[#008E56] h-fit w-fit opacity-80 text-sm sm:text-base" disabled={true}>
                <EditIcon styles="h-[16px] w-[16px] sm:h-[20px] sm:w-[20px]" />
                <p className="font-semibold">Edit</p>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <p className="col-span-3 text-[12px] sm:text-[14px] font-[500] text-[#28282899]">Insurance cover</p>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[20px]">{insurance.insurance_name}</h3>
            <div className="flex justify-center">
              <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[20px]">{convertCurrencyToNaira(Number(insurance.cost))}</h3>
            </div>
            <div className="flex justify-end">
              <button className="flex flex-row gap-1 sm:gap-2 text-white items-center px-2 sm:px-3 py-2 sm:py-2.5 rounded-[10px] bg-[#008E56] h-fit w-fit text-sm sm:text-base" onClick={() => handleChangeStep(5)}>
                <EditIcon styles="h-[16px] w-[16px] sm:h-[20px] sm:w-[20px]" />
                <p className="font-semibold">Edit</p>
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2">
            <p className="col-span-3 text-[12px] sm:text-[14px] font-[500] text-[#28282899]">Pickup method</p>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[20px]">{shipmentMethod === "drop_off" ? "Drop Off" : "Pick Up"}</h3>
            <div className="flex justify-center">
              <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[20px]">
                {shipmentMethod === "drop_off" ? convertCurrencyToNaira(Number(0)) : convertCurrencyToNaira(Number(pickupPrice))}
              </h3>
            </div>
            <div className="flex justify-end">
              <button className="flex flex-row gap-1 sm:gap-2 text-white items-center px-2 sm:px-3 py-2 sm:py-2.5 rounded-[10px] bg-[#008E56] h-fit w-fit text-sm sm:text-base" onClick={() => handleChangeStep(1)}>
                <EditIcon styles="h-[16px] w-[16px] sm:h-[20px] sm:w-[20px]" />
                <p className="font-semibold">Edit</p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full rounded-2xl bg-[#e4e4e466] p-3 sm:p-5 mx-2 sm:mx-0">
        <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-0">
          <div className="flex flex-col gap-1">
            <p className="text-[12px] sm:text-[14px] text-[#28282899] font-[500]">Item Category</p>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[18px]">{items[0].itemCategory}</h3>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-[12px] sm:text-[14px] text-[#28282899] font-[500]">Item Value</p>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[18px]">₦{Number(items[0].itemValue)}</h3>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-[12px] sm:text-[14px] text-[#28282899] font-[500]">Item Description</p>
          <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[18px] break-words">{items[0].itemDescription}</h3>
        </div>
        <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-0">
          <div className="flex flex-col gap-1">
            <p className="text-[12px] sm:text-[14px] text-[#28282899] font-[500]">Quantity</p>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[18px]">{items[0].quantity}</h3>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-[12px] sm:text-[14px] text-[#28282899] font-[500]">Weight</p>
            <h3 className="font-[500] text-[#282828] text-[16px] sm:text-[18px]">{items[0].weight}kg</h3>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 justify-between">
          <div className="flex flex-col gap-1">
            <p className="text-[12px] sm:text-[14px] whitespace-nowrap text-[#28282899] font-[500]">Additional Note</p>
          </div>
          <div className="flex flex-col gap-1">
            <h3 className="font-[500] text-[#282828] text-sm sm:text-base break-words">{additionalNote}</h3>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-between gap-2">
          <button className="flex flex-row gap-2 text-white items-center px-4 sm:px-5 py-2 sm:py-2.5 rounded-[10px] bg-[#008E56] h-fit w-fit mt-2 sm:mt-4 text-sm sm:text-base" onClick={() => handleChangeStep(4)}>
            <EditIcon styles="h-[16px] w-[16px] sm:h-[20px] sm:w-[20px]" />
            <p className="font-semibold">Edit</p>
          </button>
          <button
            className="bg-[#FFF62C] text-[#282828] font-[500] py-2 sm:py-2.5 px-3 sm:px-4 rounded-md mt-2 sm:mt-3 text-sm sm:text-base w-full sm:w-auto"
            onClick={() => setModalShowing((prev) => !prev)}
          >
            + Add Additional Info
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full rounded-2xl bg-[#e4e4e466] p-3 sm:p-5 mx-2 sm:mx-0">
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] font-[500] text-sm sm:text-base">Shipping Fee:</h3>
          <p className="text-[#282828] font-semibold text-sm sm:text-base">{convertCurrencyToNaira(Number(price) + Number(insurance.cost) + Number(pickupPrice))}</p>
        </div>
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] font-[500] text-sm sm:text-base">VAT</h3>
          <p className="text-[#282828] font-semibold text-sm sm:text-base">{convertCurrencyToNaira(Number(price) * 0.075)}</p>
        </div>
        <div className="w-full h-[2px] bg-[#28282866]" />
        <div className="flex flex-row items-center justify-between">
          <h3 className="text-[#282828] font-[500] text-sm sm:text-base">Payment Due:</h3>
          <p className="text-[#282828] font-semibold text-sm sm:text-base">{convertCurrencyToNaira(Number(price) * 0.075 + Number(price) + Number(insurance.cost) + Number(pickupPrice))}</p>
        </div>
      </div>
      {modalShowing && <AdditionalNoteModal setModalShowing={setModalShowing} additionalNote={additionalNote} setAdditionalNote={setAdditionalNote} />}
    </div>
  );
};

export default OrderSummary;