const Input = (
    {
        name,
        label,
        value,
        onChange,
        maxLength,
        placeholder
    }
) => {
    return (
        <div className="flex flex-col gap-1.5">
            <label htmlFor="name" className="text-[#282828]">{label}</label>
            <input
                type="text"
                id={name}
                className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] px-3 py-2.5 outline-[#008E56] focus:outline-[#008E56]"
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                placeholder={placeholder}
            />
        </div>
    )
}

export default Input