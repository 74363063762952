import React, { useState, useEffect, useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";

import { IoIosMore } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { FaEdit, FaTrashAlt, FaCheck } from "react-icons/fa";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import { toast } from "react-toastify";

import BtnGrid from "../../../Button/BtnGrid";
import { baseUrl, config } from "../../../../Utils/constants";

import AddressForm from "./AddressForm";
import DeleteConfirmationModal from "./DelConfirmModal";

const ClientAddress = () => {
  const [addresses, setAddresses] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [showForm, setShowForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAddresses = addresses.slice(indexOfFirstItem, indexOfLastItem);
  const [isDeleting, setIsDeleting] = useState(false);
  const totalPages = Math.ceil(addresses.length / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-2 py-1 mx-1 border text-sm sm:text-base ${
            currentPage === i ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleAddAddressClick = () => {
    setShowForm(true);
  };

  const handleSaveAddress = (newAddress) => {
    newAddress.id = addresses.length + 1;
    setAddresses([...addresses, newAddress]);
    setShowForm(false);
  };

  const handleCancelForm = () => {
    setShowForm(false);
  };

  const handleMoreClick = (address) => {
    setSelectedAddress(address);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedAddress(null);
  };

  const handleEditClick = () => {
    navigate(`/dashboard/add-address?id=${selectedAddress.id}`);
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    const url = `${baseUrl}delete-address/${selectedAddress?.id}`;
    setIsDeleting(true);
    try {
      const { data } = await axios.delete(url, config(token));
      getAddresses();
      toast.success(data?.message);
      setShowDeleteModal(false);
      setShowModal(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setShowModal(false);
  };

  // const getAddresses = async () => {
  //   const url = `${baseUrl}get-user-address`;
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios.get(url, config(token));

  //     const updatedAddresses = data.data.map((address) => ({
  //       ...address,

  //       is_default: address.default === "yes",
  //     }));
  //     setAddresses(updatedAddresses);
  //   } catch (error) {
  //     console.error("Error fetching addresses:", error);
  //     toast.error("Failed to fetch addresses. Please try again.");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const getAddresses = useCallback(async () => {
    const url = `${baseUrl}get-user-address`;
    setIsLoading(true);
    try {
      const { data } = await axios.get(url, config(token));
      const updatedAddresses = data.data.map((address) => ({
        ...address,
        is_default: address.default === "yes",
      }));
      setAddresses(updatedAddresses);
    } catch (error) {
      console.error("Error fetching addresses:", error);
      toast.error("Failed to fetch addresses. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);
  

  const handleSetAsDefault = async (addressId) => {
    const url = `${baseUrl}set-as-default/${addressId}`;
    try {
      await axios.post(url, {}, config(token));
      // After setting a new default, refetch all addresses
      await getAddresses();
      toast.success("Default address updated successfully");
    } catch (error) {
      console.error("Error setting default address:", error);
      toast.error("Failed to set default address. Please try again.");
    }
  };

  return (
    <div className="w-full p-4 sm:p-6 font-sans">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-lg sm:text-xl font-bold mb-4 sm:mb-0">
          {addresses?.length} saved Addresses
        </h2>
        <Link to="/dashboard/add-address">
          <BtnGrid
            onClick={handleAddAddressClick}
            className="bg-[#008E56] text-white px-3 py-2 sm:px-4 sm:py-2 rounded hover:bg-green-700 transition duration-300"
          >
            Add A New Address
          </BtnGrid>
        </Link>
      </div>
      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {showForm ? (
            <AddressForm
              onSave={handleSaveAddress}
              onCancel={handleCancelForm}
            />
          ) : addresses.length === 0 ? (
            <p className="text-center text-gray-500">
              You don't have any saved address
            </p>
          ) : (
            <>
              <div>
                <ul>
                  {currentAddresses.map((address) => (
                    <li
                      key={address.id}
                      className="mb-4 p-4 border rounded-lg shadow flex justify-between"
                    >
                      <div>
                        <p className="font-bold">{address?.full_name}</p>
                        <p>{address?.email}</p>
                        <p>{address?.phone_number}</p>
                        <p>{address?.address}</p>
                        <p>{address?.landmark}</p>
                        <p>{address.address_line3}</p>
                        <p>
                          {address.city}, {address.state}, {address.country}
                        </p>
                        <p>{address.postal_code}</p>

                        {address.is_default && (
                          <p className="font-bold text-green-600">
                            Default Address
                          </p>
                        )}
                      </div>
                      <IoIosMore onClick={() => handleMoreClick(address)} />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="flex flex-col sm:flex-row justify-between items-center mt-6">
                <span className="text-xs sm:text-sm text-gray-600 mb-4 sm:mb-0">
                  Showing {indexOfFirstItem + 1} to{" "}
                  {Math.min(indexOfLastItem, addresses.length)} of{" "}
                  {addresses.length} entries
                </span>
                <div className="flex flex-wrap justify-center">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    className="px-2 py-1 border bg-white mr-2 text-sm sm:text-base"
                  >
                    Previous
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    className="px-2 py-1 border bg-white ml-2 text-sm sm:text-base"
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}

          {showModal && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div
                className="fixed inset-0 bg-black opacity-50"
                onClick={handleModalClose}
              ></div>
              <div className="bg-white rounded-lg shadow-lg p-6 z-50 lg:w-1/6 max-w-md">
                <div className="flex justify-between items-center mb-4">
                  {/* <h3 className="text-lg font-bold"></h3> */}
                  <IoMdClose
                    className="cursor-pointer"
                    onClick={handleModalClose}
                  />
                </div>
                <div className="flex flex-col">
                  <button
                    onClick={handleEditClick}
                    className="flex items-center text-green-500 mb-4"
                  >
                    <FaEdit className="mr-2" /> Edit
                  </button>

                  {!selectedAddress?.is_default && (
                    <button
                      onClick={() => handleSetAsDefault(selectedAddress?.id)}
                      className="flex items-center text-green-500 mb-4"
                    >
                      <FaCheck className="mr-2" /> Set as default
                    </button>
                  )}
                  <button
                    onClick={handleDeleteClick}
                    className="flex items-center text-red-500 mb-4"
                  >
                    <FaTrashAlt className="mr-2" /> Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {showDeleteModal && (
        <DeleteConfirmationModal
          onConfirm={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
          isLoading={isDeleting}
        />
      )}
    </div>
  );
};

export default ClientAddress;
