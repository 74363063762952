// DeleteConfirmationModal.js
import React from "react";
import { Spinner } from "react-activity";
import { IoMdClose } from "react-icons/io";

const DeleteConfirmationModal = ({ onConfirm, onCancel, isLoading }) => {
	return (
		<div className='fixed inset-0 flex items-center justify-center z-50'>
			<div
				className='fixed inset-0 bg-black opacity-50'
				onClick={onCancel}
			></div>
			<div className='bg-white rounded-lg shadow-lg p-6 z-50 max-w-md'>
				<div className='flex justify-between items-center mb-4'>
					<h3 className='text-lg font-bold'>Confirm Delete</h3>
					<IoMdClose className='cursor-pointer' onClick={onCancel} />
				</div>
				<p className='mb-4'>Do you want to delete this address?</p>
				<div className='flex justify-between'>
					<button
						onClick={onConfirm}
						disabled={isLoading}
						className='bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300'
					>
						{isLoading ? <Spinner /> : "Yes"}
					</button>
					<button
						onClick={onCancel}
						className='bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2 hover:bg-gray-400 transition duration-300'
					>
						No
					</button>
				</div>
			</div>
		</div>
	);
};

export default DeleteConfirmationModal;
