import axios from "axios";

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import { Spinner } from "react-activity";

import { baseUrl, config } from "../../Utils/constants";
import OTP from "../../Components/Dashboard/VirtualCard/CardType";
import DollarCard from "../../Components/Dashboard/VirtualCard/DollarCard";
import ConfirmPin from "../../Components/Dashboard/VirtualCard/confirmPin";
import Generate from "../../Components/Dashboard/VirtualCard/generate";
import Success from "../../Components/Dashboard/VirtualCard/success";

const Virtual = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [virtualCardDetails, setVirtualCardDetails] = useState({});
  const [pinDetails, setPinDetails] = useState("");
  const [confirmPinDetails, setConfirmPinDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dollarCardState, setDollarCardState] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dollarCardDetails, setDollarCardDetails] = useState({});
  const { token, user, card } = useSelector((state) => state.auth);
  const [isCardShown, setIsCardShown] = useState(false);
  const [isPinLoading, setIsPinLoading] = useState(false);

  const nextSlide = () => {
    setActiveSlide(activeSlide + 1);
  };

  const prevSlide = () => {
    setActiveSlide(activeSlide - 1);
  };

  const handleInputChange = (e) => {
    setVirtualCardDetails({
      ...virtualCardDetails,
      [e.target.name]: e.target.value,
    });
  };

  const createDollarCard = async () => {
    const url = `${baseUrl}create-dollar-details`;
    let msg;
    setIsLoading(true);
    try {
      const res = await axios.post(url, virtualCardDetails, config(token));
      msg = res.data.message;
      toast.success(msg);
      nextSlide();
      setIsLoading(false);
    } catch (error) {
      msg = error?.response?.data?.message;
      toast.error(msg);
      setIsLoading(false);
    }
  };

  const createCardPin = async () => {
    const url = `${baseUrl}create-dollar-pin`;
    let msg;
    setIsLoading(true);
    try {
      const res = await axios.post(
        url,
        { pin: pinDetails, confirm_pin: confirmPinDetails },
        config(token)
      );
      msg = res.data.message;
      toast.success(msg);
      nextSlide();
      setIsLoading(false);
    } catch (error) {
      msg = error?.response?.data?.message;
      toast.error(msg);
      setIsLoading(false);
    }
  };

  const verifyBVN = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}verify-bvn`;
    let msg;
    try {
      const res = await axios.post(url, virtualCardDetails, config(token));
      setIsLoading(false);
      toast.success("Verification Successful");
      setActiveSlide(1);
    } catch (error) {
      setIsLoading(false);
      msg = error?.response?.data?.message;
      toast.error(msg);
    }
  };

  const getDollarCard = async (pin) => {
    const url = `${baseUrl}dollar-card-details`;
    setIsPageLoading(true);
    try {
      const { data } = await axios.post(url, { pin }, config(token));
      setDollarCardDetails(data.data.data);
      setIsCardShown(true);
      setIsPageLoading(false);
    } catch (error) {
      setIsPageLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const getDollarCardStatus = async () => {
    setIsPageLoading(true);
    if (card) {
      setDollarCardState(true);
    } else {
      setDollarCardState(false);
    }
    setIsPageLoading(false);
  };

  useEffect(() => {
    getDollarCardStatus();
  }, [card]);

  if (isPageLoading) {
    return (
      <div className="h-[50vh] flex item-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (dollarCardState) {
    return (
      <DollarCard
        dollarCardDetails={dollarCardDetails}
        getDollarCard={getDollarCard}
        isCardShown={isCardShown}
        setIsCardShown={setIsCardShown}
        isLoading={isPageLoading}
      />
    );
  }

  return (
    <div>
      <ToastContainer autoClose={3000} />
      <div>
        {activeSlide === 0 && (
          <Generate
            handleInputChange={handleInputChange}
            isLoading={isLoading}
            verifyBVN={verifyBVN}
          />
        )}
        {activeSlide === 1 && (
          <OTP
            pinDetails={pinDetails}
            setPinDetails={setPinDetails}
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        )}
        {activeSlide === 2 && (
          <ConfirmPin
            confirmPinDetails={confirmPinDetails}
            setConfirmPinDetails={setConfirmPinDetails}
            prevSlide={prevSlide}
            nextSlide={nextSlide}
            isLoading={isLoading}
            createCardPin={createCardPin}
          />
        )}
        {activeSlide === 3 && <Success />}
      </div>
    </div>
  );
};

export default Virtual;








