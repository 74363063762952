// import React, { useState } from "react";

// import { useNavigate } from "react-router-dom";

// import { useSelector } from "react-redux";

// import axios from "axios";

// import { toast, ToastContainer } from "react-toastify";

// import Btn from "../../Button/btn";
// import { baseUrl } from "../../../Utils/constants";
// import SuccessModal from "../../../Components/Modals/success";

// const Kyc = () => {
//   const [showSuccessModal, setShowSuccessModal] = useState(false);
//   const [nin, setNin] = useState("");
//   const navigate = useNavigate();
//   const { token } = useSelector((state) => state.auth);

//   const handleNinNumber = async (e) => {
//     e.preventDefault();
//     console.log(`${baseUrl}verify-nin`);

//     try {
//       const response = await axios.post(
//         `${baseUrl}verify-nin`,
//         { nin }, // Data payload
//         {
//           headers: {
//             Authorization: `Bearer ${token}`, // Authorization token if required
//             userid: "1679605581411",
//             apiKey: "LgfbLgowZ8vDO1EUzUpE"
//           }
//         }
//       );

//       if (response.status === 200) {
//         toast.success(response.data.message); // Display success message from backend
//         setShowSuccessModal(true);
//       }
//     } catch (error) {
//       // Display backend error message if available, otherwise show a default message
//       const errorMessage =
//         error.response && error.response.data && error.response.data.message
//           ? error.response.data.message
//           : "NIN verification failed. Please try again.";
//       toast.error(errorMessage);
//       console.error("Error verifying NIN:", error);
//     }
//   };

//   const handleModalClose = () => {
//     setShowSuccessModal(false);
//     navigate("/dashboard");
//   };

//   return (
//     <div>
//       <div className="p-3">
//         <h2 className="font-medium text-center text-2xl">
//           Kindly Enter Your NIN Identity Number
//         </h2>
//         <p className="my-2 text-center">
//           Your NIN Number is used to keep your account secure and enables you to
//           have access to all our features.
//         </p>
//         <form className="" onSubmit={handleNinNumber}>
//           <div className="w-100 flex flex-col items-center">
//             <label htmlFor="nin" className="mr-2 mt-2">
//               NIN:
//             </label>
//             <input
//               type="number"
//               id="nin"
//               name="nin"
//               placeholder="Enter your NIN"
//               value={nin}
//               onChange={(e) => setNin(e.target.value)}
//               required
//               className="border border-gray-300 rounded p-2 w-1/3 mt-2"
//             />
//           </div>
//           <Btn
//             text={"Confirm NIN"}
//             className="bg-green-600 my-6 text-white justify-center flex items-center mx-auto"
//           />
//         </form>
//       </div>

//       {showSuccessModal && (
//         <SuccessModal
//           visibilityState={showSuccessModal}
//           setShowSuccessModal={handleModalClose}
//         >
//           <h2 className="text-center text-lg font-semibold">Success!</h2>
//           <p className="text-center mt-2">
//             Your NIN has been successfully submitted. You can now proceed to the
//             next step.
//           </p>
//         </SuccessModal>
//       )}
//       <ToastContainer />
//     </div>
//   );
// };

// export default Kyc;

import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import Btn from "../../Button/btn";
import { baseUrl } from "../../../Utils/constants";
import SuccessModal from "../../../Components/Modals/success";
import { setVerification, setUserProfile } from "../../../Redux/features/authSlice";

const KYC = () => {
  const [nin, setNin] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);

  const handleModalClose = () => {
    navigate("/dashboard");
  };

  const handleNinNumber = async (e) => {
    e.preventDefault();

    if (nin.length !== 11) {
      toast.error("NIN must be exactly 11 digits long.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}verify-nin`,
        { nin },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            userid: "1679605581411",
            apiKey: "LgfbLgowZ8vDO1EUzUpE",
          },
        }
      );

      if (response.status === 200) {
        if (response.data.status) {
          dispatch(setVerification(response.data.data.verification));
          dispatch(setUserProfile(response.data.data));
          toast.success(response.data.message);
        }
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "NIN verification failed. Please try again.";
      toast.error(errorMessage);
      console.error("Error verifying NIN:", error);
    } finally {
      setLoading(false);
    }
  };

  // CHANGE: Render success modal or form based on verification status
  if (user?.verification !== null) {
    return (
      <div className="p-4">
        <SuccessModal
          visibilityState={true}
          setShowSuccessModal={handleModalClose}
        >
          <h2 className="text-center text-lg font-semibold">Success!</h2>
          <p className="text-center mt-2">
            Your NIN has been successfully verified. You can now proceed to the next step.
          </p>
        </SuccessModal>
      </div>
    );
  }

  return (
    <div className="p-4">
      <div>
        <h2 className="font-medium text-center text-2xl">
          Kindly Enter Your National Identity Number (NIN)
        </h2>
        <p className="my-2 text-center">
          Your NIN is used to keep your account secure and enables you to have access to all our features.
        </p>
        <form onSubmit={handleNinNumber}>
          <div className="flex flex-col items-center">
            <label htmlFor="nin" className="mt-2 mb-1">
              NIN:
            </label>
            <input
              type="text"
              id="nin"
              name="nin"
              placeholder="Enter your NIN"
              value={nin}
              onChange={(e) => setNin(e.target.value.replace(/\D/g, ""))}
              required
              className="border border-gray-300 rounded p-2 w-1/3"
              maxLength="11"
            />
          </div>
          <Btn
            text={loading ? "Verifying..." : "Confirm NIN"}
            className={`bg-green-600 my-6 text-white justify-center flex items-center mx-auto ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          />
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default KYC;