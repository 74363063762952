import React, { useEffect, useState } from 'react';
import AddressModal from './AddressModal';
import Input from './Input';

const SenderDetails = ({
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    addressLine3,
    setAddressLine3,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
    postalAddress,
    setPostalAddress,
    handleChangeStep,
    userSavedAddresses,
    selectedAddress,
    setSelectedAddress
}) => {

    const [modalShowing, setModalShowing] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='flex flex-col gap-6 w-full'>
            <h3 className='text-[#282828] text-center font-[500] text-[24px]'>
                Sender Details
            </h3>
            <div className='w-full flex justify-center items-center'>
                <button
                    className='flex flex-row items-center gap-5 border-[1px] rounded-lg border-[#ddd] border-solid px-5 py-2.5 w-fit font-[400]'
                    onClick={() => setModalShowing(prev => !prev)}
                >
                    Choose from a saved address
                </button>
            </div>
            <div className='w-full'>
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <Input
                        label="Full Name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Input
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Input
                        label="Phone"
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <Input
                        label="Address Line 1"
                        type="text"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                        maxLength={45}
                    />
                    <Input
                        label="Address Line 2 (45 char)"
                        type="text"
                        value={addressLine2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                        maxLength={45}
                    />
                    <Input
                        label="Address Line 3 (45 char)"
                        type="text"
                        value={addressLine3}
                        onChange={(e) => setAddressLine3(e.target.value)}
                        maxLength={45}
                    />
                    <Input
                        label="Country"
                        type="text"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    />
                    <Input
                        label="State"
                        type="text"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                    />
                    <Input
                        label="City"
                        type="text"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <Input
                        label="Postal Address"
                        type="text"
                        value={postalAddress}
                        onChange={(e) => setPostalAddress(e.target.value)}
                    />
                </div>
            </div>
            {modalShowing && <AddressModal selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} userSavedAddresses={userSavedAddresses} setModalShowing={setModalShowing} />}
        </div>
    );
};

export default SenderDetails;