import React, { useState } from "react";
import Register from "./Register";
import SentoutGoods from "./SentoutGoods";

import fulservice from "../../Assets/images/fulfillment2.jpg";

const FulfilmentTab = () => {
  // Correction: Set initial active tab to "register" instead of "zone"
  const [activeTab, setActiveTab] = useState("register");


  // Correction: Changed switch to if statements
  const renderContent = () => {
    if (activeTab === "register") {
      return <Register />;
    } else if (activeTab === "SentoutGoods") {
      return <SentoutGoods />;
    } else {
      return <div>Invalid tab selected</div>;
    }
  };

  return (
    <div className="">
      <p className="font-bold text-center p-2 text-lg sm:text-2xl md:text-2xl mb-6">
        Fulfillment Services
      </p>

      <div className="p-2 sm:p-4 md:p-6">
        <img
          src={fulservice}
          alt="goods"
          className="w-full h-48 sm:h-64 md:h-80 lg:h-[25rem] object-cover rounded-lg shadow-md"
        />
      </div>

      <div className="p-2 sm:p-4 md:p-6 max-w-4xl mx-auto">
        <h1 className="font-bold text-center p-4 text-lg sm:text-2xl md:text-2xl mb-6">
          eShopAfrica Fulfillment Services: Your Gateway to Nationwide Success!
        </h1>
        <div className="space-y-4 text-sm sm:text-base">
          <p className="font-semibold">
            Attention Nigerian Entrepreneurs! 🚀 Unlock Lagos Markets from
            Anywhere in Nigeria for Just ₦3,000 monthly!
          </p>
          <ul className="list-none space-y-2">
            <li>Are you Struggling to reach your Lagos customers?</li>
            <li> Tired of complicated shipping logistics?</li>
            <li>
               Want a Lagos presence without relocating? Say goodbye to these
              headaches with eShopAfrica Fulfillment Services!
            </li>
          </ul>
          <h2 className="font-bold text-xl sm:text-2xl mt-6 mb-4">
            Here's What You Get:
          </h2>
          <ul className="list-none space-y-2">
            <li> Secure Lagos storage: Only ₦3,000/month</li>
            <li> Expert packaging & sorting: Just ₦200/delivery depends on shipment</li>
            <li> Flexible delivery options</li>
            <li> We handle cash on delivery</li>
          </ul>
          <p className="mt-6">
             From Lagos to the world, sell like you're right here in Lagos! 
          </p>
          <p className="font-semibold">
            Expand Your Reach | Cut Costs | Delight Customers
          </p>
          <p>
            Ready to dominate the Lagos market? Register your Goods now with
            eShopAfrica fulfillment center today.
          </p>
        </div>
      </div>

      <div className="flex justify-around bg-[#E5E5E5] rounded-full mb-4 p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "register"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("register")}
        >
          Register Good
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "SentoutGoods"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("SentoutGoods")}
        >
          Sent out Goods
        </button>
      </div>
      <div className="p-4 border rounded-lg shadow-lg">{renderContent()}</div>
    </div>
  );
};

export default FulfilmentTab;
