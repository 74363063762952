import React, { useState } from 'react';

const EditOrderModal = ({ isOpen, onClose, orderData, onSubmit }) => {
  const [editedOrder, setEditedOrder] = useState(orderData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedOrder((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(editedOrder);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white w-4/5 md:w-1/2 max-h-[90vh] rounded-lg shadow-xl p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-medium">Edit Shipment Details</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="receiver_name" className="block text-sm mb-1">Receiver Name</label>
            <input
              type="text"
              id="receiver_name"
              name="receiver_name"
              value={editedOrder.receiver_name || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_email" className="block text-sm mb-1">Receiver Email</label>
            <input
              type="email"
              id="receiver_email"
              name="receiver_email"
              value={editedOrder.receiver_email || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_phone" className="block text-sm mb-1">Receiver Phone</label>
            <input
              type="text"
              id="receiver_phone"
              name="receiver_phone"
              value={editedOrder.receiver_phone || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_address_1" className="block text-sm mb-1">Receiver Address Line 1</label>
            <input
              type="text"
              id="receiver_address_1"
              name="receiver_address_1"
              value={editedOrder.receiver_address_1 || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_address_2" className="block text-sm mb-1">Receiver Address Line 2</label>
            <input
              type="text"
              id="receiver_address_2"
              name="receiver_address_2"
              value={editedOrder.receiver_address_2 || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_address_3" className="block text-sm mb-1">Receiver Address Line 3</label>
            <input
              type="text"
              id="receiver_address_3"
              name="receiver_address_3"
              value={editedOrder.receiver_address_3 || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_city" className="block text-sm mb-1">City</label>
            <input
              type="text"
              id="receiver_city"
              name="receiver_city"
              value={editedOrder.receiver_city || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_state" className="block text-sm mb-1">State</label>
            <input
              type="text"
              id="receiver_state"
              name="receiver_state"
              value={editedOrder.receiver_state || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_country" className="block text-sm mb-1">Country</label>
            <input
              type="text"
              id="receiver_country"
              name="receiver_country"
              value={editedOrder.receiver_country || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Postal Address</label>
            <input
              type="text"
              id="receiver_postal_address"
              name="receiver_postal_address"
              value={editedOrder.receiver_postal_address || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Item Category</label>
            <input
              type="text"
              id="item_category"
              name="item_category"
              value={editedOrder.item_category || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Item Value</label>
            <input
              type="text"
              id="item_value"
              name="item_value"
              value={editedOrder.item_value || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Item Description</label>
            <input
              type="text"
              id="item_description"
              name="item_description"
              value={editedOrder.item_description || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Quantity</label>
            <input
              type="text"
              id="quantity"
              name="quantity"
              value={editedOrder.quantity || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Weight</label>
            <input
              type="text"
              id="weight"
              name="weight"
              value={editedOrder.weight || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Length</label>
            <input
              type="text"
              id="length"
              name="length"
              value={editedOrder.length || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Height</label>
            <input
              type="text"
              id="height"
              name="height"
              value={editedOrder.height || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Width</label>
            <input
              type="text"
              id="width"
              name="width"
              value={editedOrder.width || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_postal_address" className="block text-sm mb-1">Payment Due in total</label>
            <input
              type="text"
              id="amount"
              name="amount"
              value={editedOrder.amount || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>
  
          <div className="flex justify-end space-x-2 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border rounded text-gray-600 hover:bg-gray-100"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrderModal;