import React, { useState, useEffect } from "react";

import { toast, ToastContainer } from "react-toastify";

import { CiFilter } from "react-icons/ci";
import { FiMoreHorizontal } from "react-icons/fi";

import { Spinner } from "react-activity";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";

const CargoOrderAndPayment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [ordersData, setOrdersData] = useState([]);
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();
  const { adminToken } = useSelector((state) => state.admin);
  
  const [filter, setFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  // Fetch cargo shipments
  const getOrders = async () => {
    const url = `${baseUrl}admin/get-shipments?shipment_type=cargo&status=all`;
    setIsLoading(true);
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setOrdersData(data.data);
      setFilteredData(data.data);
    } catch (error) {
      //console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleStatusChange = (newStatus, row) => {
    // Directly use the row's original ID for the API call
    updateShipmentStatus(row.original.id, newStatus);
  };

  const updateShipmentStatus = async (shipmentId, newStatus) => {
    const url = `${baseUrl}admin/update-cargo-shipment-status/${shipmentId}`;
    
    try {
      setIsLoading(true);
      const response = await axios.post(
        url, 
        { status: newStatus },
        config(adminToken)
      );
      
      // Update local state to reflect the status change
      setOrdersData(prevData => 
        prevData.map(item => 
          item.id === shipmentId 
            ? { ...item, status: newStatus, shipment_status: newStatus } 
            : item
        )
      );

      // Also update filtered data
      setFilteredData(prevData => 
        prevData.map(item => 
          item.id === shipmentId 
            ? { ...item, status: newStatus, shipment_status: newStatus } 
            : item
        )
      );
      
      toast.success("Shipment status updated successfully");
    } catch (error) {
      console.error("Error updating shipment status:", error);
      toast.error("Failed to update shipment status");
    } finally {
      setIsLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch(status) {
      case "delivered": return "bg-green-100 text-green-800";
      case "enroute": return "bg-blue-100 text-blue-800";
      case "processed": return "bg-yellow-100 text-yellow-800";
      case "pending": return "bg-red-100 text-red-800";
      default: return "bg-gray-100 text-gray-800";
    }
  };


  const handleIconClick = (row) => {
    navigate(`/secretroute/admin/cargoorderdetails/${row.original.id}`);
  };

  const columns = [
    { header: "Senders Name", accessorKey: "sender_name" },
    { header: "Senders Email", accessorKey: "sender_email" },
    { header: "Batch No", accessorKey: "batch_number" },
    { header: "Senders Country", accessorKey: "sender_country" },
    { header: "Senders State", accessorKey: "sender_state" },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ cell }) => {
        const rawDate = cell.getValue();
        const formattedDate = new Date(rawDate).toLocaleDateString("en-GB");
        return <span>{formattedDate}</span>;
      },
    },
    { header: "Senders City", accessorKey: "sender_city" },
    { header: "Senders Phone", accessorKey: "sender_phone" },
    { header: "Tracking ID", 
      accessorKey: "tracking_number" ,
      cell: ({ row }) => row.original.tracking_number || "Not Assigned"
    },
    { 
      header: "Req Status", 
      accessorKey: "shipment_status",
      cell: ({ cell }) => {
        const status = cell.getValue();
        return <span className={`p-2 rounded-md font-medium ${getStatusColor(status)}`}>
          {status}
        </span>;
      }
    },
    { header: "Payment Status", accessorKey: "payment_status" },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <FiMoreHorizontal
          className="cursor-pointer"
          onClick={() => handleIconClick(row)}
        />
      ),
    },
    {
      header: "Delivery Status",
      accessorKey: "shipment_status",
      cell: ({ row }) => {
        const currentStatus = row.original.status;

        return (
          <select
            value={currentStatus}
            onChange={(e) => updateShipmentStatus(row.original.id, e.target.value)}
            className={`${getStatusColor(currentStatus)} p-2 rounded-md font-medium`}
          >
            <option value="enroute">Enroute</option>
            <option value="delivered">Delivered</option>
            <option value="processed">Processed</option>
            <option value="pending">Pending</option>
          </select>
        );
      },
    },
  ];

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "") {
      setFilteredData(ordersData);
    } else {
      const newFilteredData = ordersData.filter(
        (item) => item.shipmentstatus === selectedFilter
      );
      setFilteredData(newFilteredData);
    }
  };

  const exportToCSV = (exportData) => {
    const csvData = exportData.map((row) =>
      columns.map((col) => `"${row[col.accessorKey] || ''}""`).join(",")
    );
    const csvContent =
      ["Name,Email,Phone Number,Ship From,Ship To,Status", ...csvData].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "express_shipment_data.csv");
    link.click();
  };

  const handleExportClick = () => {
    exportToCSV(filteredData);
  };

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <Spinner size={32} color="#055429" />
        </div>
      ) : error ? (
        <div className="text-red-500 text-center p-4">
          {error}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <p className="text-center font-bold p-2 text-lg md:text-base sm:text-sm">
              All Cargo Shipments ({ordersData.length})
            </p>

            <div className="flex items-center pb-4">
              <CiFilter className="mr-1" />
              <select
                value={filter}
                onChange={handleFilterChange}
                className="border p-2 rounded"
              >
                <option value="">Filter</option>
                <option value="successful">Successful</option>
                <option value="pending">Pending</option>
                <option value="processed">Processed</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </div>

            <button
              onClick={handleExportClick}
              className="bg-[#055429] text-white p-2 rounded"
            >
              Export
            </button>
          </div>

          <TanTable columnData={columns} data={filteredData} />
          <ToastContainer />
        </>
      )}
    </div>
  );
};

export default CargoOrderAndPayment;