import copy from "copy-to-clipboard";

export const baseUrl = `https://api.eshopafrica.co/api/v1/`;
export const imageUrl = `https://api.eshopafrica.co/`;

export const dollarRate = 750.0;

export const copyText = (text) => {
	copy(text);
	alert(`${text} copied to clipboard`);
};

export const config = (token) => {
	return {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};
};

export const createRequest = (path) => {
	// const url = `${baseUrl}${path}`;
	// let msg;
};

