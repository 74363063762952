import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../../../Utils/constants";
import { convertCurrencyToNaira } from "../../../../Utils/currency";
import Button from "./Button";
import PaymentComponent from "./PaymentComponent";

const PaymentModal = ({ setPayStatus, setModalShowing, balance, amount, shipmentId }) => {
    const [paymentMethod, setPaymentMethod] = useState();
    const [loading, setLoading] = useState(false);
    const { token } = useSelector((state) => state.auth);

    return (
        <div className="fixed h-screen w-full left-0 top-0 bg-[#33333354] backdrop-blur-sm flex justify-center items-center z-[50] p-5" onClick={() => setModalShowing(false)}>
            <div className="bg-white rounded-md p-5 flex flex-col justify-center items-center gap-4 w-full max-w-[550px]" onClick={(e) => e.stopPropagation()}>
                <h3 className="text-[#282828] text-[20px] font-semibold">
                    Pay for shipment
                </h3>
                <PaymentComponent paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} balance={balance} />
                <div className="w-full flex flex-row items-center justify-end gap-4 mt-3">
                    <Button
                        styles="bg-[#fff] border-[1px] border-[#499170]"
                        content="Cancel"
                        onClick={() => setModalShowing(false)}
                    />
                    <Button
                        styles="bg-[#008E56] text-white"
                        content={`Pay ${convertCurrencyToNaira(Number(amount))}`}
                        isLoading={loading}
                        onClick={async () => {
                            if (loading) return;
                            setLoading(true);
                            try {
                                if (paymentMethod === 0) {
                                    await axios.post(
                                        `${baseUrl}express-shipment-wallet-payment`,
                                        { shipment_id: shipmentId },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                            },
                                        }
                                    );
                                    toast.success("You have successfully paid from your wallet.");
                                    setPayStatus("Paid");
                                    setLoading(false);
                                    setModalShowing(false);
                                } else if (paymentMethod === 1) {
                                    const callbackUrl = `${process.env.REACT_APP_BASE_URL}/confirm-shipment-payment?shipmentId=${shipmentId}`;
                                    console.log(callbackUrl, "this is the callback url");
                                    const { data } = await axios.post(
                                        `${baseUrl}express-shipment-with-card`,
                                        { shipment_id: shipmentId, callback_url: callbackUrl },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                            },
                                        }
                                    );
                                    console.log(data.data.data, "This is the response pay shipment with card");
                                    const payLink = data.data.data.data.authorization_url;
                                    window.location.href = payLink;
                                    setLoading(false);
                                    setModalShowing(false);
                                }
                            } catch (error) {
                                toast.error(error.response.data.message);
                                console.log(error.response.data, "This is the error from payment");
                                setLoading(false);
                            }
                        }}
                        disabled={paymentMethod !== 0 && paymentMethod !== 1 ? true : false}
                    />
                </div>
            </div>
        </div>
    );
};

export default PaymentModal;