import React, { useEffect, useState, useCallback } from "react";

import { ImNotification, ImUpload } from "react-icons/im";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import InfoBox from "../ExpressShipping/NewImplementation/InfoBox";
import VolumetricInfoBox from "../ExpressShipping/NewImplementation/VolumetricInfoBox";
import { baseUrl } from "../../../Utils/constants";

const ItemDescription = ({
  formData,
  handleChange,
  handleFileChange,
  onValidationChange,
}) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [insuranceOptions, setInsuranceOptions] = useState([]);
  const [isLoadingInsurance, setIsLoadingInsurance] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth);

  // Get minimum weight based on receiver country and cargo type
  const getMinWeight = () => {
    // Set minimum weight for sea cargo
    if (formData.cargoType === "sea") {
      return 50;
    }

    // Only apply minimum weight restrictions for air cargo
    if (
      formData.cargoType !== "air" &&
      formData.cargoType !== "frozenAir" &&
      formData.cargoType !== "xtraFast" &&
      formData.cargoType !== "XtraFastEdibles"
    ) {
      return 1;
    }

    // Special case for frozen air cargo to USA
    if (
      (formData.cargoType === "frozenAir" ||
        formData.cargoType === "xtraFast" ||
        formData.cargoType === "XtraFastEdibles") &&
      formData.receiverCountry?.toLowerCase() === "usa" &&
      formData.receiverCountry?.toLowerCase() === "europe"
    ) {
      return 10;
    }

    switch (formData.receiverCountry?.toLowerCase()) {
      case "uk":
      case "canada":
        return 10;
      case "usa":
        return 10;
      case "europe":
        return 10;
      default:
        return 1;
    }
  };

  // Check if weight is valid
  const isWeightValid = () => {
    const value = parseFloat(formData.weight);
    const minWeight = getMinWeight();

    // For air cargo and sea cargo, weight must be at least the minimum
    if (
      formData.cargoType === "air" ||
      formData.cargoType === "frozenAir" ||
      formData.cargoType === "xtraFast" ||
      formData.cargoType === "XtraFastEdibles" ||
      formData.cargoType === "sea"
    ) {
      return value >= minWeight;
    }

    // For other cargo types, any positive weight is valid
    return value > 0;
  };

  // Validate weight based on receiver country and cargo type
  const validateWeight = (e) => {
    const value = parseFloat(e.target.value);
    const minWeight = getMinWeight();

    // Always update the value
    handleChange(e);

    // Show warning for air cargo and sea cargo
    if (
      (formData.cargoType === "air" ||
        formData.cargoType === "frozenAir" ||
        formData.cargoType === "xtraFast" ||
        formData.cargoType === "XtraFastEdibles" ||
        formData.cargoType === "sea") &&
      value < minWeight
    ) {
      setError(
        `Warning: Minimum weight for ${
          formData.cargoType === "sea"
            ? "sea"
            : formData.receiverCountry?.toUpperCase()
        } cargo is ${minWeight}kg, if your kg is less than ${minWeight}kg, you will still be charged ${minWeight}kg, as minimum weight`
      );
    } else {
      setError(null);
    }

    // Notify parent component about validation status
    if (onValidationChange) {
      onValidationChange(isWeightValid());
    }
  };

  // Check validation when cargo type or receiver country changes
  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(isWeightValid());
    }
  }, [formData.cargoType, formData.receiverCountry]);

  const fetchInsuranceOptions = useCallback(async () => {
    try {
      setIsLoadingInsurance(true);
      const response = await axios.get(`${baseUrl}get-insurance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data.success) {
        setInsuranceOptions(response.data.data.insurance);
      } else {
        setError("Failed to fetch insurance options");
      }
    } catch (err) {
      setError(
        err.response?.data?.message || "Failed to fetch insurance options"
      );
    } finally {
      setIsLoadingInsurance(false);
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchInsuranceOptions();
  }, [fetchInsuranceOptions]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      handleFileChange(e);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInsuranceChange = (e) => {
    const { value } = e.target;
    const selectedInsurance = insuranceOptions.find(
      (insurance) => insurance.id.toString() === value
    );

    handleChange({
      target: {
        name: "insurance",
        value: selectedInsurance
          ? {
              id: value,
              insurance_name: selectedInsurance.insurance_name,
              insurance_cost: selectedInsurance.cost,
              insurance_coverage: selectedInsurance.insurance_coverage,
            }
          : "",
      },
    });
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-6 text-center">
        Item Description
      </h2>

      <div className="bg-[#BDD4C9] rounded-md p-3 my-5 flex gap-x-2">
        <ImNotification />
        <p className="text">
          Enter details about the item you want to ship, don't worry if you are
          not sure of the weight, we will check when we receive your shipment
          and get back to you.
        </p>
      </div>

      <label className="block mb-2 text-sm font-medium text-gray-700">
        Item value (₦)
      </label>
      <input
        type="number"
        name="itemValue"
        placeholder="Item Value (Naira)"
        value={formData.itemValue}
        onChange={handleChange}
        min="1"
        onKeyPress={(e) => {
          if (e.key === "+" || e.key === "-") {
            e.preventDefault();
          }
        }}
        className="w-full mb-4 p-2 border rounded"
      />

      <select
        name="itemCategory"
        value={formData.itemCategory}
        onChange={handleChange}
        className="w-full mb-4 p-2 border rounded"
      >
        <option value="">Select Category</option>
        <option value="food">Food or Edibles</option>
        <option value="electronics">Electronics</option>
        <option value="books">Books</option>
        <option value="fashion">Fashion</option>
        <option value="home">Home & Kitchen</option>
        <option value="beauty">Beauty & Personal Care</option>
        <option value="sports">Sports & Outdoors</option>
        <option value="health">Health & Wellness</option>
        <option value="automotive">Automotive</option>
        <option value="office">Office Supplies</option>
        <option value="pets">Pet Supplies</option>
        <option value="baby">Baby Products</option>
        <option value="jewelry">Jewelry & Accessories</option>
        <option value="furniture">Furniture</option>
        <option value="art">Art & Craft Supplies</option>
        <option value="music">Music & Instruments</option>
        <option value="movies">Movies & Entertainment</option>
        <option value="garden">Garden & Outdoor</option>
        <option value="tools">Tools & Hardware</option>
        <option value="travel">Travel & Luggage</option>
        <option value="shoes">Shoes</option>
        <option value="watches">Watches</option>
        <option value="stationery">Stationery</option>
        <option value="party">Party Supplies</option>
        <option value="industrial">Industrial & Scientific</option>
        <option value="electrical">Electrical</option>
        <option value="software">Software</option>
        <option value="games">Video Games</option>
        <option value="collectibles">Collectibles</option>
        <option value="others">Others</option>
      </select>

      <label className="block mb-2 text-sm font-medium text-gray-700">
        Give a detailed description about the item
      </label>
      <textarea
        name="itemDescription"
        placeholder="Give full details of Item you are shipping it is very important"
        value={formData.itemDescription}
        onChange={handleChange}
        className="w-full mb-4 p-2 border rounded h-32"
      />

      <label className="block mb-2 text-sm font-medium text-gray-700">
        Weight (kg){" "}
        {(formData.cargoType === "air" || formData.cargoType === "frozenAir") &&
          formData.receiverCountry && (
            <span className="text-sm text-gray-500">
              (Minimum required: {getMinWeight()}kg for{" "}
              {formData.receiverCountry.toUpperCase()} air cargo)
            </span>
          )}
      </label>
      <input
        type="number"
        name="weight"
        placeholder="Enter weight in kg"
        value={formData.weight}
        onChange={validateWeight}
        min="0"
        onKeyPress={(e) => {
          if (e.key === "+" || e.key === "-") {
            e.preventDefault();
          }
        }}
        className="w-full mb-4 p-2 border rounded"
      />

      {error && (
        <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">
          {error}
        </div>
      )}

      <div className="w-full flex justify-center p-4">
        <InfoBox content="We recommend purchasing Premium/Extended insurance to protect your goods in transit." />
      </div>

      {isLoadingInsurance ? (
        <div className="w-full mb-4 p-2 border rounded flex justify-center">
          <Spinner />
        </div>
      ) : (
        <select
          name="insurance"
          value={formData.insurance?.id || ""}
          onChange={handleInsuranceChange}
          className="w-full mb-4 p-2 border rounded"
        >
          <option value="">Choose an insurance option</option>
          {insuranceOptions.map((insurance) => (
            <option key={insurance.id} value={insurance.id}>
              {insurance.insurance_name} ₦
              {Number(insurance.cost).toLocaleString()}
              (Covers damages up to ₦
              {insurance.insurance_coverage.match(/\d+/g)?.join("") || 0})
            </option>
          ))}
        </select>
      )}

      <label className="block mb-2 text-sm font-medium text-gray-700">
        Additional Note
      </label>
      <textarea
        name="additionalNote"
        placeholder="Tell us anything you think we should know about this item"
        value={formData.additionalNote}
        onChange={handleChange}
        className="w-full mb-4 p-2 border rounded h-32"
      />

      {/* Image Upload Section */}
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Upload Shipment Image
        </label>
        <div className="border-2 border-dashed rounded-lg p-4 text-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="cursor-pointer flex flex-col items-center justify-center gap-2"
          >
            <ImUpload className="w-8 h-8 text-gray-500" />
            <span className="text-sm text-gray-500">
              Click to upload or drag and drop
            </span>
            <span className="text-xs text-gray-400">
              PNG, JPG, GIF up to 10MB
            </span>
          </label>
          {previewUrl && (
            <div className="mt-4">
              <img
                src={previewUrl}
                alt="Preview"
                className="max-w-full h-auto max-h-48 mx-auto rounded"
              />
            </div>
          )}
        </div>
      </div>

      <div className="py-4">
        <VolumetricInfoBox />
      </div>

      <p className="text-[#008E56] font-semibold">
        Please be transparent. Declaring a lower value than your item could
        attract an additional fee from custom.
      </p>
    </div>
  );
};

export default ItemDescription;
