import React, { useState } from "react";

import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";


const EditOrderModal = ({ isOpen, onClose, orderData, onSubmit }) => {
  const [editedOrder, setEditedOrder] = useState(orderData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedOrder((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      console.log("Edited Order Data:", editedOrder);
      await onSubmit(editedOrder);
    } catch (error) {
      console.error("Error submitting changes:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white w-4/5 md:w-1/2 max-h-[90vh] rounded-lg shadow-xl p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-medium">Edit Shipment Details</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* Receiver Details */}
          <div>
            <label htmlFor="receiver_name" className="block text-sm mb-1">
              Receiver Name
            </label>
            <input
              type="text"
              id="receiver_name"
              name="receiver_name"
              value={editedOrder.receiver_name || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_email" className="block text-sm mb-1">
              Receiver Email
            </label>
            <input
              type="email"
              id="receiver_email"
              name="receiver_email"
              value={editedOrder.receiver_email || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_phone" className="block text-sm mb-1">
              Receiver Phone
            </label>
            <input
              type="text"
              id="receiver_phone"
              name="receiver_phone"
              value={editedOrder.receiver_phone || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          {/* Sender Details */}
          <div>
            <label htmlFor="sender_name" className="block text-sm mb-1">
              Sender Name
            </label>
            <input
              type="text"
              id="sender_name"
              name="sender_name"
              value={editedOrder.sender_name || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
              required
            />
          </div>

          <div>
            <label htmlFor="sender_phone" className="block text-sm mb-1">
              Sender Phone
            </label>
            <input
              type="text"
              id="sender_phone"
              name="sender_phone"
              value={editedOrder.sender_phone || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
              required
            />
          </div>

          <div>
            <label htmlFor="sender_address" className="block text-sm mb-1">
              Sender Address
            </label>
            <input
              type="text"
              id="sender_address"
              name="sender_address"
              value={editedOrder.sender_address || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
              required
            />
          </div>

          <div>
            <label htmlFor="sender_country" className="block text-sm mb-1">
              Sender Country
            </label>
            <input
              type="text"
              id="sender_country"
              name="sender_country"
              value={editedOrder.sender_country || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
              required
            />
          </div>

          <div>
            <label htmlFor="sender_state" className="block text-sm mb-1">
              Sender State
            </label>
            <input
              type="text"
              id="sender_state"
              name="sender_state"
              value={editedOrder.sender_state || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
              required
            />
          </div>

          <div>
            <label htmlFor="sender_city" className="block text-sm mb-1">
              Sender City
            </label>
            <input
              type="text"
              id="sender_city"
              name="sender_city"
              value={editedOrder.sender_city || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          {/* Cargo and Shipment Details */}
          <div>
            <label htmlFor="cargo_type" className="block text-sm mb-1">
              Cargo Type
            </label>
            <input
              type="text"
              id="cargo_type"
              name="cargo_type"
              value={editedOrder.cargo_type || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="shipment_method" className="block text-sm mb-1">
              Shipment Method
            </label>
            <input
              type="text"
              id="shipment_method"
              name="shipment_method"
              value={editedOrder.shipment_method || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="insurance_id" className="block text-sm mb-1">
              Insurance ID
            </label>
            <input
              type="text"
              id="insurance_id"
              name="insurance_id"
              value={editedOrder.insurance_id || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          {/* Receiver Location Details */}
          <div>
            <label htmlFor="receiver_city" className="block text-sm mb-1">
              Receiver City
            </label>
            <input
              type="text"
              id="receiver_city"
              name="receiver_city"
              value={editedOrder.receiver_city || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_state" className="block text-sm mb-1">
              Receiver State
            </label>
            <input
              type="text"
              id="receiver_state"
              name="receiver_state"
              value={editedOrder.receiver_state || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="receiver_country" className="block text-sm mb-1">
              Receiver Country
            </label>
            <input
              type="text"
              id="receiver_country"
              name="receiver_country"
              value={editedOrder.receiver_country || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label
              htmlFor="receiver_postal_address"
              className="block text-sm mb-1"
            >
              Receiver Postal Address
            </label>
            <input
              type="text"
              id="receiver_postal_address"
              name="receiver_postal_address"
              value={editedOrder.receiver_postal_address || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          {/* Item Details */}
          <div>
            <label htmlFor="category" className="block text-sm mb-1">
              Item Category
            </label>
            <input
              type="text"
              id="category"
              name="category"
              value={editedOrder.category || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="item_value" className="block text-sm mb-1">
              Item Value
            </label>
            <input
              type="text"
              id="item_value"
              name="item_value"
              value={editedOrder.item_value || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="item_description" className="block text-sm mb-1">
              Item Description
            </label>
            <input
              type="text"
              id="item_description"
              name="item_description"
              value={editedOrder.item_description || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="quantity" className="block text-sm mb-1">
              Quantity
            </label>
            <input
              type="text"
              id="quantity"
              name="quantity"
              value={editedOrder.quantity || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="weight" className="block text-sm mb-1">
              Weight
            </label>
            <input
              type="text"
              id="weight"
              name="weight"
              value={editedOrder.weight || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="height" className="block text-sm mb-1">
              Height
            </label>
            <input
              type="text"
              id="height"
              name="height"
              value={editedOrder.height || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="width" className="block text-sm mb-1">
              Width
            </label>
            <input
              type="text"
              id="width"
              name="width"
              value={editedOrder.width || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          <div>
            <label htmlFor="amount" className="block text-sm mb-1">
              Payment Due in Total
            </label>
            <input
              type="text"
              id="price"
              name="price"
              value={editedOrder.price || ""}
              onChange={handleInputChange}
              className="border rounded px-3 py-2 w-full"
            />
          </div>

          {/* Buttons */}
          <div className="flex justify-end space-x-2 mt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center justify-center"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner size={16} color="#fff" />
              ) : (
                "Save Changes"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrderModal;
