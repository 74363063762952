import React from "react";

const TanHeader = ({ table }) => {
	return (
		<thead className='bg-green-50'>
			{table.getHeaderGroups().map((headerGroup) => (
				<tr key={headerGroup.id}>
					{headerGroup.headers.map((header) => (
						<th
							className='p-3 font-medium text-left whitespace-nowrap'
							key={header.id}
							w={header.getSize()}
							onClick={header.column.getToggleSortingHandler()}
						>
							{header.column.columnDef.header}
						</th>
					))}
				</tr>
			))}
		</thead>
	);
};

export default TanHeader;
