import React from 'react'
import WalletTab from '../../Components/Dashboard/Wallet/walletTabs'

const WalletTabPage = () => {
  return (
    <div>
      <WalletTab/>
    </div>
  )
}

export default WalletTabPage
