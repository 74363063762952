import React from "react";
import { IoSearchOutline } from "react-icons/io5";

const Search = () => {
  return (
    <div className='flex items-center'>
      <input
        type='text'
        placeholder='search'
        className='p-4 border rounded-md w-full lg:w-[400px] text-sm'
      />
      <IoSearchOutline className='text-4xl rounded-md text-gray-600 p-2 relative right-10' />
    </div>
  );
};

export default Search;
