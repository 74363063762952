import React from 'react';

const ServiceCard = ({ title, description }) => (
  <div className="bg-grad p-8 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 h-full flex flex-col">
    <h3 className="text-xl font-bold mb-4 text-white">{title}</h3>
    <p className="text-white leading-relaxed flex-grow">{description}</p>
  </div>
);

const SolutionsProvider = () => {
  const services = [
    {
      title: "VIRTUAL USD CARD",
      description: "Shop online globally with ease! Introducing eShopAfrica's Virtual USD Card. Load, pay, and shop online in USD, securely and conveniently. No US bank account needed. Sign up now and start shopping!"
    },
    {
      title: "EXPRESS SHIPMENT",
      description: "Send packages from Nigeria to over 200 countries worldwide with eShopAfrica's express shipping  delivered with in 3-5 business days"
    },
    {
      title: "AIR CARGO & SEA CARGO",
      description: "Trust eShopAfrica's air and sea cargo services to deliver your shipments quickly, reliably, and cost-effectively to anywhere in the world."
    },
    {
      title: "FROZEN AIR & SEA CARGO",
      description: "Experience seamless global shipping with eShopAfrica's specialized frozen air and sea cargo services, delivering your perishable goods quickly, reliably, and affordably to any destination worldwide."
    },
    {
      title: "FULFILLMENT SERVICES",
      description: "eShopAfrica Fulfillment Services helps you reach your Lagos customers effortlessly, eliminates complicated shipping logistics, and gives you a Lagos presence without relocating, sell globally as if you’re right here in Lagos."
    },
    {
      title: "IMPORT FROM UK USA & CHINA",
      description: "Shop and ship from your favorite stores in the US, UK, and China, use eShopAfrica's address and get your shipment delivered to Nigeria or worldwide."
    }
  ];

  return (
    <div className="min-h-screen bg-white text-black py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16 space-y-4">
          <h2 className="text-2xl md:text-2xl font-bold mb-3 leading-tight">
          The ultimate solutions provider, delivering services designed to fit your needs.
          </h2>
          <p className="text-xl text-black max-w-3xl mx-auto">
          We're here to collaborate with you, addressing everything from your unique needs to seamless platform integrations
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 auto-rows-fr ">
          {services.map((service, index) => (
            <div key={index} 
                 className="opacity-0 animate-fade-in h-full"
                 style={{ animationDelay: `${index * 150}ms` }}>
              <ServiceCard {...service} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const styles = `
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .animate-fade-in {
    animation: fadeIn 0.6s ease-out forwards;
  }
`;

export default () => (
  <>
    <style>{styles}</style>
    <SolutionsProvider />
  </>
);