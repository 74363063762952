import React, { useEffect, useState, useCallback } from "react";
import { IoNotificationsOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import { RiMenu5Fill } from "react-icons/ri";
import { BsChevronDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl, config } from "../../../Utils/constants";
import profileImage from "../../../Assets/images/profile.jpg";
import { setDollarRate } from "../../../Redux/features/adminSlice";

const Header = ({ toggleSidebar }) => {
  const location = useLocation();
  const { adminToken, dollarRate } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const [activeScreen, setActiveScreen] = useState("");

  useEffect(() => {
    const path = location.pathname;

    if (path === "/secretroute/admin") {
      setActiveScreen("Dashboard");
    } else if (path.includes("admin/total-users")) {
      setActiveScreen("Users");
    } else if (path.includes("admin/virtual-dollar-card")) {
      setActiveScreen("Virtual Dollar Card");
    } else if (path.includes("admin/manage-shipment")) {
      setActiveScreen("Shipments");
    } else if (path.includes("admin/dollar-rate")) {
      setActiveScreen("Dollar Rate");
    } else if (path.includes("admin/insurance")) {
      setActiveScreen("Insurance");
    } else if (path.includes("admin/referrals")) {
      setActiveScreen("Referrals");
    } else if (path.includes("admin/tracking")) {
      setActiveScreen("Tracking");
    } else if (path.includes("admin/manageorder-and-payment")) {
      setActiveScreen("Orders and Payment");
    } else if (path.includes("admin/delivery-calculator-cargo")) {
      setActiveScreen("Delivery Calculator cargo");
    } else if (path.includes("admin/delivery-calculator")) {
      setActiveScreen("Delivery Calculator imports");
    } else if (path.includes("admin/manage-wallet")) {
      setActiveScreen("Wallets");
    } else if (path.includes("admin/fulfillment")) {
      setActiveScreen("Fulfillment Services");
    } else if (path.includes("admin/know-your-customer")) {
      setActiveScreen("KYC");
    } else if (path.includes("admin/cargo-price")) {
      setActiveScreen("Cargo Price");
    } else if (path.includes("admin/pricing")) {
      setActiveScreen("Pricing");
    } else {
      setActiveScreen("");
    }
  }, [location]);

  const getDollarRate = useCallback(async () => {
    const url = `${baseUrl}admin/get-rate`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      dispatch(setDollarRate(data.data.rate));
    } catch (error) {
      console.log(error);
    }
  }, [adminToken, dispatch]); // Add dependencies

  useEffect(() => {
    getDollarRate();
  }, [getDollarRate]); // Now safe to include getDollarRate in the dependency array

  return (
    <div className='flex justify-between gap-4 items-center shadow-sm p-4'>
      <RiMenu5Fill
        className='text-2xl text-sec2 md:hidden'
        onClick={toggleSidebar}
      />
      <h2 className='font-medium'>{activeScreen}</h2>
      <div className='hidden lg:flex items-center'>
        <h2 className='font-medium'>Dollar rate: ₦{dollarRate}</h2>
      </div>
      <div className='flex items-center gap-2'>
        <div className='flex items-center gap-2'>
          <Link
            to='/secretroute/admin/notifications'
            className='flex items-center'
          >
            <IoNotificationsOutline className='text-xl' />
          </Link>
          <img
            className='w-8 h-8 object-cover rounded-full'
            src={profileImage}
            alt='profile'
          />
          <div className='hidden sm:flex items-center gap-2'>
            <h2 className='font-medium'>eShopAfrica</h2>
            <BsChevronDown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;