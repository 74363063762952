import React, { useState, useEffect } from "react";

import { MdClose } from "react-icons/md";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";

import Btn from "../../Button/btn";
import { baseUrl, copyText } from "../../../Utils/constants";
import { sortLists } from "../../../Utils/sort";
import Timestamp from "../../../Utils/timeStamp";

const TrackShipment = ({ isSidebarOpen, setIsbarOpen }) => {
  const [slideStatus, setSlideStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [trackDetails, setTrackDetails] = useState([]);
  const [shipmentData, setShipmentData] = useState(null);
  const [trackID, setTrackID] = useState(null);
  const [shipmentType, setShipmentType] = useState("");

  useEffect(() => {
    if (!isSidebarOpen) {
      setSlideStatus(false);
      setIsLoading(false);
      setTrackDetails([]);
      setShipmentData(null);
      setTrackID(null);
      setShipmentType("");
    }
  }, [isSidebarOpen]);

  const trackShipment = async () => {
    if (!shipmentType) {
      toast.error("Please select a shipment type.");
      return;
    }
    setIsLoading(true);
    const url = `${baseUrl}track`;
    try {
      const res = await axios.post(url, {
        tracking_number: trackID,
        shipment_type: shipmentType,
      });

      // Handle response based on shipment type
      if (shipmentType === "import") {
        if (Array.isArray(res.data.data)) {
          setTrackDetails(res.data.data);
          setSlideStatus(true);
        } else {
          throw new Error("Invalid import tracking data format");
        }
      } else if (shipmentType === "dhl") {
        if (res.data && res.data.shipments && res.data.shipments.length > 0) {
          setShipmentData(res.data.shipments[0]);
          setSlideStatus(true);
        } else {
          throw new Error("No tracking information found for DHL shipment");
        }
      } else if (shipmentType === "cargo") {
        // Handle cargo shipment type
        if (Array.isArray(res.data.data)) {
          setTrackDetails(res.data.data); // Assuming cargo data is structured similarly to import
          setSlideStatus(true);
        } else {
          throw new Error("Invalid cargo tracking data format");
        }
      }

      // Display success message if available
      if (res.data.message) {
        toast(res.data.message);
      }
    } catch (error) {
      // Handle errors
      if (error.response) {
        const errorMessage =
          error.response.data.message ||
          error.response.data.error ||
          "Failed to retrieve tracking information.";
        toast.error(errorMessage);
      } else {
        toast.error(
          error.message || "Failed to retrieve tracking information."
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderDHLEvents = () => {
    if (!shipmentData || !shipmentData.events) return null;
    return shipmentData.events.map((event, index) => (
      <div key={index} className="flex gap-4 my-6">
        <div className="w-3 h-3 rounded-full bg-green-500 mt-1" />
        <div className="text-sm">
          <h2 className="my-1">{`${event.date} ${event.time}`}</h2>
          <h2 className="my-1">{event.description}</h2>
          <h2 className="text-gray-500 text-xs">
            {event.serviceArea[0]?.description || "Unknown Location"}
          </h2>
        </div>
      </div>
    ));
  };

  const renderImportTrackDetails = () => {
    return trackDetails?.map(({ trak_details, updated_at }, ind) => (
      <div key={ind} className="flex gap-4 my-6">
        <div className="w-3 h-3 rounded-full bg-green-500 mt-1" />
        <div className="text-sm">
          <Timestamp time={updated_at} />
          <h2 className="my-2">{trak_details}</h2>
        </div>
      </div>
    ));
  };

  return (
    <div
      className={`${
        isSidebarOpen ? "translate-x-0" : "translate-x-full"
      } fixed w-full md:w-[50%] h-screen bg-white top-0 right-0 transition mt-20 p-8 py-20 z-20 shadow-md overflow-y-auto`}
    >
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-medium">Track Shipment</h2>
        <i className="text-xl" onClick={() => setIsbarOpen(false)}>
          <MdClose />
        </i>
      </div>

      <hr className="-mx-8 my-6" />

      <div className="my-6">
        <h2 className="text-sm">Enter Shipment ID</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            trackShipment();
          }}
        >
          <input
            value={trackID || ""}
            onChange={(e) => setTrackID(e.target.value)}
            type="number"
            className="rounded-md p-2 my-2 border-2 w-full"
          />

          <h2 className="text-sm mt-4">Select Shipment Type</h2>
          <select
            className="rounded-md p-2 my-2 border-2 w-full"
            value={shipmentType}
            onChange={(e) => setShipmentType(e.target.value)}
          >
            <option value="">Select Shipment Type</option>
            <option value="dhl">DHL</option>
            <option value="import">Import</option>
            <option value="cargo">Cargo</option> {/* Added cargo option */}
          </select>

          <button
            type="submit"
            className="bg-pry w-full rounded-md text-black py-2 mt-4"
            disabled={isLoading}
          >
            {isLoading ? "Tracking..." : "Track Shipment"}
          </button>
        </form>
      </div>

      {slideStatus && (
        <div>
          {/* Render tracking details based on shipment type */}
          {shipmentType === "import" || shipmentType === "cargo"
            ? renderImportTrackDetails()
            : renderDHLEvents()}

          <hr className="-mx-8 my-6" />

          <div className="flex justify-between gap-4 my-5">
            <div className="text-sm">
              <h2>Tracking ID</h2>
              <h2>{trackID}</h2>
            </div>
            <h2
              onClick={() => copyText(trackID, shipmentType)}
              className="italic text-xs text-white bg-green-500 rounded-md h-fit p-1 px-3 select-none cursor-pointer"
            >
              Copy
            </h2>
          </div>

          {/* Render additional details for DHL shipments */}
          {shipmentType === "dhl" && shipmentData && (
            <div className="text-sm">
              <h2 className="font-bold mb-2">Shipment Details</h2>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <p className="font-semibold">Product</p>
                  <p>{shipmentData.description}</p>
                </div>
                <div>
                  <p className="font-semibold">Status</p>
                  <p>{shipmentData.status}</p>
                </div>
                <div>
                  <p className="font-semibold">Weight</p>
                  <p>
                    {shipmentData.totalWeight} {shipmentData.unitOfMeasurements}
                  </p>
                </div>
                <div>
                  <p className="font-semibold">Origin</p>
                  <p>
                    {shipmentData.shipperDetails.serviceArea[0]?.description ||
                      "N/A"}
                  </p>
                </div>
                <div>
                  <p className="font-semibold">Destination</p>
                  <p>
                    {shipmentData.receiverDetails.serviceArea[0]?.description ||
                      "N/A"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default TrackShipment;
