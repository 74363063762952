import React from 'react';

const Others = () => {
  const data = [
    {
      country: 'UK',
      items: [
        'Fruits and Vegetables',
        'Ammunition',
        'Bank bills, notes, or currency',
        'Fireworks',
        'Hazardous waste',
        'Radioactive materials',
        'Illegal items, according to law enforcement',
        'Perfumes',
        'Unground Ogbono',
        'Pepper/Melon/Crayfish',
        'Dairy products',
        'Yam',
        'Beef/Ponmo',
        "Medication - Must have a doctor's prescription",
        'Snails',
        'Alcohol',
      ],
    },
    {
      country: 'USA',
      items: [
        'Fruits and Vegetables',
        'Ammunition',
        'Bank bills, notes, or currency',
        'Fireworks',
        'Hazardous waste',
        'Radioactive materials',
        'Illegal items, according to law enforcement',
        'Perfumes',
        'Unground Ogbono',
        'Pepper/Melon/Crayfish',
        'Dairy products',
        'Yam',
        'Beef/Ponmo',
        "Medication - Must have a doctor's prescription",
        'Snails',
        'Alcohol',
      ],
    },
    {
      country: 'Canada',
      items: [
        'Fruits and Vegetables',
        'Ammunition',
        'Bank bills, notes, or currency',
        'Fireworks',
        'Hazardous waste',
        'Radioactive materials',
        'Illegal items, according to law enforcement',
        'Perfumes',
        'Unground Ogbono',
        'Pepper/Melon/Crayfish',
        'Dairy products',
        'Yam',
        'Beef/Ponmo',
        "Medication - Must have a doctor's prescription",
        'Snails',
        'Alcohol',
      ],
    },
  ];

  return (
    <div className="px-4 py-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {data.map((countryData, index) => (
          <div key={index} className="border p-4 rounded shadow">
            <h2 className="text-xl font-bold mb-4 text-center">{countryData.country}</h2>
            <ul className="list-decimal list-inside text-left">
              {countryData.items.map((item, index) => (
                <li key={index} className="mb-2">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Others;
