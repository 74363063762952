import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { format } from "date-fns";

import { toast } from "react-toastify";

import { Spinner } from "react-activity";

import axios from "axios";

import { MdDelete } from "react-icons/md";

import { baseUrl, config } from "../../Utils/constants";
import sadface from "../../Assets/images/sadface.png";

import RegisterDeleteModal from "./RegisterDeleteModal";

const RegisterGoods = () => {
  const navigate = useNavigate();
  const [registeredGoods, setRegisteredGoods] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const goodsPerPage = 10;
  const { token } = useSelector((state) => state.auth);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const getFulfillmentReg = async () => {
    const url = `${baseUrl}get-fulfilment-packages`;
    setIsLoading(true);
    try {
      const { data } = await axios.get(url, config(token));
      const sortedData = data.data.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setRegisteredGoods(sortedData);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteItem = async () => {
    const url = `${baseUrl}get-fulfilment-packages/${currentId}`;
    setIsLoading(true);
    try {
      await axios.delete(url, config(token));
      setRegisteredGoods((prevData) =>
        prevData.filter((item) => item.id !== currentId)
      );
      toast.success("Item deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete the item!");
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    getFulfillmentReg();
  }, []);

  const filteredGoods = registeredGoods.filter(
    (good) =>
      good.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      good.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      good.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
      good.phone_number.includes(searchTerm)
  );

  const indexOfLastItem = currentPage * goodsPerPage;
  const indexOfFirstItem = indexOfLastItem - goodsPerPage;
  const currentGoods = filteredGoods.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredGoods.length / goodsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`px-2 py-1 mx-1 border text-sm sm:text-base ${
            currentPage === i ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const handleDeleteClick = (id) => {
    setCurrentId(id);
    setIsModalOpen(true);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-2 sm:p-4">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4 md:mb-6 space-y-2 md:space-y-0 md:space-x-4">
        <input
          type="text"
          placeholder="Search"
          className="border rounded-lg px-3 py-2 w-full md:w-64 text-sm"
          value={searchTerm}
          onChange={handleSearch}
        />
        <Link
          to="/dashboard/register-goods"
          className="bg-[#FFF62C] text-black px-4 py-2 rounded-lg w-full md:w-auto text-sm"
        >
          Register Goods
        </Link>
      </div>

      <h2 className="text md:text-xl font-bold mb-3 md:mb-4">
        Registered Goods
      </h2>

      {isLoading ? (
        <div className="w-full flex items-center justify-center">
          <Spinner size={32} color="#3B40BF" />
        </div>
      ) : (
        <>
          {currentGoods.length === 0 ? (
            <div className="flex flex-col items-center justify-center">
              <img
                src={sadface}
                alt="emoji"
                className="w-16 h-16 md:w-auto md:h-auto"
              />
              <p className="mt-3 text-gray-500 text-sm md:text-base">
                No registered goods found
              </p>
              <div className="p-3">
                <Link
                  to="/dashboard/register-goods"
                  className="bg-[#FFF62C] text-black px-4 py-2 rounded-lg w-full md:w-auto text-sm"
                >
                  Register Goods
                </Link>
              </div>
            </div>
          ) : (
            <>
              {currentGoods.map((good, index) => (
                <div
                  key={index}
                  className="p-3 md:p-4 rounded-lg mb-3 md:mb-4 text-sm md:text-base"
                >
                  <div className="flex flex-col md:flex-row justify-between items-start mb-2">
                    <div className="w-full md:w-auto mb-2 md:mb-0">
                      <p>
                        <span className="text-sm">Date:</span>{" "}
                        {format(new Date(good.created_at), "MMMM d, yyyy")}
                      </p>
                      <p>
                        <span className="text-sm">Name:</span> {good.full_name}
                      </p>
                      <p>
                        <span className="text-sm whitespace-pre-line break-words">
                          Email:
                        </span>{" "}
                        {good.email}
                      </p>
                      <p>
                        <span className="text-sm">Phone:</span>{" "}
                        {good.phone_number}
                      </p>
                      <p>
                        <span className="text-sm whitespace-pre-line break-words">
                          Full Address:
                        </span>{" "}
                        {good.address}
                      </p>
                    </div>
                    <div className="">
                    
                    <span className="text-sm whitespace-pre-line break-words px-2 py-1 rounded-full text-white bg-[#008E56]">
                      {good.status}
                    </span>
                  </div>
                  </div>
                  <p>
                    <span className="text-sm whitespace-pre-line break-words">
                      List of Good:
                    </span>{" "}
                    {good.description}
                  </p>
                  <p>
                    <span className="text-sm whitespace-pre-line break-words">
                      Type of Good:
                    </span>{" "}
                    {good.type_of_goods}
                  </p>
                  <p>
                    <span className="text-sm">Number of goods:</span>{" "}
                    {good.number_of_goods}
                  </p>
                  <div className="p-2">
                    <MdDelete
                      size={24}
                      onClick={() => handleDeleteClick(good.id)}
                      className="cursor-pointer text-red-600"
                    />
                  </div>
                </div>
              ))}

              <div className="flex flex-col sm:flex-row justify-between items-center mt-6">
                <span className="text-xs sm:text-sm text-gray-600 mb-4 sm:mb-0">
                  Showing {indexOfFirstItem + 1} to{" "}
                  {Math.min(indexOfLastItem, filteredGoods.length)} of{" "}
                  {filteredGoods.length} entries
                </span>
                <div className="flex flex-wrap justify-center">
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.max(prev - 1, 1))
                    }
                    className="px-2 py-1 border bg-white mr-2 text-sm sm:text-base"
                  >
                    Previous
                  </button>
                  {renderPageNumbers()}
                  <button
                    onClick={() =>
                      setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                    }
                    className="px-2 py-1 border bg-white ml-2 text-sm sm:text-base"
                  >
                    Next
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      <RegisterDeleteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={deleteItem}
      />
    </div>
  );
};

export default RegisterGoods;
