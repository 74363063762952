import React, {useState} from 'react';

import Calculator from "./Calculator";
import CalculatorExport from "./CalculatorExport"
const CalculatorTab = () => {
    
    const [activeTab, setActiveTab] = useState('calculator');

    const renderContent = () => {
        if (activeTab === "calculator") {
          return <Calculator />;
        } else if (activeTab === "calculatorexport") {
          return <CalculatorExport />;
        } else {
          return <div>Invalid tab selected</div>;
        }
      };

  return (
    <div>
      <h1 className='font-bold text-center p-4'>Delivery Calculator</h1>
      <div className="flex justify-around bg-[#E5E5E5] rounded-full mb-4 p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "calculator"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("calculator")}
        >
          Delivery Calculator Import
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "calculatorexport"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
              : ""
          }`}
          onClick={() => setActiveTab("calculatorexport")}
        >
         Delivery Calculator cargo
        </button>
      </div>
      <div className="p-4 border rounded-lg shadow-lg">{renderContent()}</div>
    </div>
  );
}

export default CalculatorTab;
