import React from 'react'
import AddressForm from '../../Components/Dashboard/Address/UserAddress/AddressForm'

const AddressFormPage = () => {
  return (
    <div>
      <AddressForm/>
    </div>
  )
}

export default AddressFormPage
