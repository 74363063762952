import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Spinner } from 'react-activity'
import BookShipmentStep from './BookShipmentStep'

const ShipmentPage = () => {

    const [service, setService] = useState()

    const location = useLocation()

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const handleSetService = () => {
            // Initializing the query parameters
            const queryParams = new URLSearchParams(location.search);
            // Getting the selected service
            const selectedService = queryParams.get("service")
            // Setting it to ups or dhl and default to dhl
            if (
                selectedService === "ups" ||
                selectedService === "dhl"
            ) {
                setService(selectedService)
            } else {
                setService("dhl")
            }
            setLoading(false)
        }
        // Calling function to set service
        handleSetService()
    }, [location.search])

    if(loading) {
        return (
            <div className='flex flex-row h-full w-full justify-center'>
                <Spinner />
            </div>
        )
    }

    return (
        <div className='w-full flex justify-center items-center p-3'>
            <div className='max-w-[900px] w-full'>
                <BookShipmentStep service={service} />
            </div>
        </div>
    )
}

export default ShipmentPage