import React from "react";
import { Spinner } from "react-activity";

const Btn = ({ text, className, onClick, loadingState, disabled }) => {
	return (
		<button
			className={`p-3 font-medium px-8 rounded-md hover:scale-105 whitespace-nowrap w-fit text-sm ${className} flex justify-center disabled:opacity-50`}
			onClick={onClick}
			disabled={(disabled || loadingState) ? true : false}
		>
			{loadingState ? <Spinner /> : text}
		</button>
	);
};

export default Btn;
