import React, { useEffect } from 'react';
import { itemCategories } from '../../../../Constants';
import InfoIcon from '../../../Icons/InfoIcon';
import Input from './Input';
import VolumetricInfoBox from './VolumetricInfoBox';

const ItemDescription = ({ items, setItems }) => {
    // eslint-disable-next-line no-unused-vars
    const handleAddItem = () => {
        setItems(prevItems => [
            ...prevItems,
            {
                itemCategory: '',
                itemValue: '',
                itemDescription: '',
                useVolumetricWeight: true,
                quantity: '',
                weight: '',
                length: '',
                height: '',
                width: ''
            }
        ]);
    };

    // Updated validateNumberInput function to allow decimal values
    function validateNumberInput(value) {
        // Allow digits and a single decimal point
        const decimalRegex = /^\d*\.?\d*$/;

        // Check if the input matches the decimal regex
        if (decimalRegex.test(value)) {
            return value; // Return the valid input
        }
        return ''; // Return an empty string for invalid input
    }

    const handleInputChange = (index, field, value) => {
        console.log(index, field, value, "This is the index field and value");
        const updatedItems = items.map((item, i) => {
            if (i === index) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setItems(updatedItems);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='flex flex-col gap-6 w-full'>
            <h3 className='text-[#282828] text-center font-[500] text-[18px]'>
                Enter details about the item you want to ship, don't worry
                if you are not sure of the weight, we will check when we receive your shipment and get back to you.
            </h3>
            <div className='w-full flex flex-col gap-4'>
                <div className='bg-[#49917066] py-4 px-5 rounded-lg flex gap-2.5 w-fit'>
                    <InfoIcon styles="h-[20px] w-[21px] mt-[2px]" color="#FA111F" />
                    <p className='text-[#FA111F]'>
                        See list of prohibited items and restricted items. {" "}
                        <a
                            href='/manage-prohibited-items'
                            target="_blank"
                            className='font-semibold border-b-[#fa111f] border-b-2'
                        >
                            View items
                        </a>
                    </p>
                </div>
                <div className='w-full'>
                    {items.map((item, index) => (
                        <div key={index} className="w-full flex flex-col gap-4">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4 w-full">
                                <div className='flex flex-col gap-1.5'>
                                    <label htmlFor="name" className="text-[#282828]">Select Category</label>
                                    <select
                                        value={item.itemCategory}
                                        onChange={(e) => handleInputChange(index, 'itemCategory', e.target.value)}
                                        className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] px-3 py-3"
                                    >
                                        <option value="" disabled>Select Item Category</option>
                                        {itemCategories.map((category) => (
                                            <option key={category} value={category}>
                                                {category}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <Input
                                    label="Item value (₦)"
                                    type="number"
                                    value={item.itemValue}
                                    onChange={(e) => handleInputChange(index, 'itemValue', e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col gap-1.5 w-full">
                                <label htmlFor="name" className="text-[#282828]">Give a detailed description about the item</label>
                                <textarea
                                    value={item.itemDescription}
                                    onChange={(e) => handleInputChange(index, 'itemDescription', e.target.value)}
                                    placeholder="Item Description"
                                    className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] px-3 py-2.5"
                                    rows="3"
                                />
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full">
                                <Input
                                    label="Quantity"
                                    type="number"
                                    placeholder="min value is 1"
                                    value={item.quantity}
                                    onChange={(e) => handleInputChange(index, 'quantity', validateNumberInput(e.target.value))}
                                />
                                <Input
                                    label="Weight (KG) Decimal values are not allowed for UPS"
                                    type="number"
                                    placeholder="min value is 1"
                                    value={item.weight}
                                    onChange={(e) => handleInputChange(index, 'weight', validateNumberInput(e.target.value))}
                                />
                                {item.useVolumetricWeight && (
                                    <>
                                        <Input
                                            label="Length (CM)"
                                            type="number"
                                            placeholder="min value is 1"
                                            value={item.length}
                                            onChange={(e) => handleInputChange(index, 'length', validateNumberInput(e.target.value))}
                                        />
                                        <Input
                                            label="Width (CM)"
                                            type="number"
                                            placeholder="min value is 1"
                                            value={item.width}
                                            onChange={(e) => handleInputChange(index, 'width', validateNumberInput(e.target.value))}
                                        />
                                        <div className="col-span-1 sm:col-span-2">
                                            <Input
                                                label="Height (CM)"
                                                type="number"
                                                placeholder="min value is 1"
                                                value={item.height}
                                                onChange={(e) => handleInputChange(index, 'height', validateNumberInput(e.target.value))}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <VolumetricInfoBox />
                            <div className='flex flex-row gap-1.5'>
                                <InfoIcon styles="h-[20px] w-[21px] mt-1" color="#008E56" />
                                <p className='text-[#008E56] font-semibold'>
                                    Please be transparent. Declaring a lower value than your item could attract an additional fee from custom.
                                </p>
                            </div>
                        </div>
                    ))}
                    <div className='w-full flex justify-end items-center'>
                        {/* <button
                            onClick={handleAddItem}
                            className="bg-[#FFF62C] text-[#282828] font-semibold py-2.5 px-4 rounded-md mt-3"
                        >
                            Add Another Item
                        </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ItemDescription;