import React from "react";
import Table from "../Layout/Table";
import Referral from "./referral";
import { referrals } from "../../../Utils/Admin/referralData";

const TotalReferrals = ({ referralHeader, isLoading }) => {
  return (
    <Table
      cols='8'
      minSize='1000px'
      headerContent={referralHeader}
      loadingState={isLoading}
      data={referrals}
    >
      {referrals?.map((item, index) => (
        <Referral item={item} key={index} index={index} isLoading={isLoading} />
      ))}
    </Table>
  );
};

export default TotalReferrals;
