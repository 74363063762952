import React, { useState } from "react";

import TanTable from "../../../Utils/TanTable";
import { FormatCurrency } from "../../../Utils/currency";
import { DateFormatter } from "../../../Utils/DateFormatter/dateFormatter";

import Cargo from "./cargo"; 
import ExpressShipping from "./expressShipping";

const OrderBase = ({ getSingleOrder, allOrders, allShipping, isLoading, walletDetails }) => {
  const columns = [
    {
      header: "S/N",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Date",
      cell: ({ row }) => DateFormatter(row.original.created_at),
    },
    {
      header: "Unique Code",
      accessorKey: "unique_code",
    },
    {
      header: "Tracking ID",
      accessorKey: "tracking_number",
    },
    {
      header: "Kg/Dimension",
      accessorKey: "kg",
    },
    {
      header: "Amount",
      cell: ({ row }) => FormatCurrency(row.original.delivery_price),
    },
    {
      header: "Ship from",
      cell: ({ row }) => row.original.address.country,
    },
    {
      header: "Payment status",
      accessorKey: "payment_status",
    },
    {
      header: "Status",
      cell: ({ row }) => {
        const status = row.original.shipment_status;
        return (
          <div
            className={`${
              status === "processed"
                ? "text-sec"
                : status === "cancelled"
                ? "text-red-600"
                : "text-black"
            }`}
          >
            {status}
          </div>
        );
      },
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <h2
          onClick={() => getSingleOrder(row.original?.id)}
          className="text-green-600 italic underline select-none cursor-pointer"
        >
          More
        </h2>
      ),
    },
  ];

  const optArr = ["Imports", "Express Shipping", "Cargo"];
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="">
      <div className="flex bg-gray-200 rounded-full p-3">
        {optArr.map((opt, index) => (
          <div
            key={index}
            className={`${
              selectedIndex === index ? "bg-sec text-white" : "bg-gray-200"
            } rounded-full p-3 cursor-pointer w-full text-center`}
            onClick={() => setSelectedIndex(index)}
          >
            <h2>{opt}</h2>
          </div>
        ))}
      </div>
      {selectedIndex === 0 ? (
        <>
          <h2 className="font-medium text-2xl text-center mt-4">Order Info</h2>
          <p className="my-4 text-base text-center">
            Kindly note that once shipment has been processed it can’t be
            cancelled.
          </p>
          <h3 className="text-base font-medium text-center mb-4">
            Recent Orders
          </h3>

          <TanTable
            data={allOrders}
            loadingState={isLoading}
            columnData={columns}
          />
        </>
      ) : selectedIndex === 1 ? (
        <ExpressShipping
          loading={isLoading}
          expressData={allShipping}
          walletDetails={walletDetails}
        />
      ) : (
        <Cargo />
      )}
    </div>
  );
};

export default OrderBase;
