import React, { useEffect, useState } from "react";
// import TanTable from "../../../Components/Utils/TanTable";
import TanTable from "../../../Utils/TanTable";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { toast } from "react-toastify";

const FedexDomestic = ({ zones, isLoading }) => {
	const columns = [
		{ header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
		{ header: "Zones", accessorKey: "zone" },
		{ header: "States", accessorKey: "state" },
	];

	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the states belonging to Different Zones
			</h1>
			<TanTable columnData={columns} data={zones} loadingState={isLoading} />
		</div>
	);
};

export default FedexDomestic;
