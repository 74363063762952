import React, { useState } from "react";
import check from "../../Assets/images/check.png";
import Btn from "../Button/btn";
import DefaultModal from "../Modals/defaultModal";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Why = () => {
  const whys = [
    "Access to Shipping Address",
    "Trusted",
    "Fast Delivery shipping",
    "Ability to pay with virtual dollar card",
  ];
  const [isModalShown, setIsModalShown] = useState(false);
  return (
    <section className='bg-green-50'>
      <div className='max-width1 py-10'>
        <h2 className='font-bold text-sec text-center capitalize my-8'>
          Why you should choose us
        </h2>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
          {whys.map((w, i) => (
            <div key={i} className='flex items-center gap-4'>
              <img className='w-5' src={check} alt='check' />
              <h2 className='text-base'>{w}</h2>
            </div>
          ))}
        </div>
        <div className='flex justify-center items-center my-6'>
          <AnimationOnScroll animateIn='animate__pulse animate__infinite'>
            <Btn
              text='Special Offer'
              className={"bg-pry"}
              onClick={() => setIsModalShown(true)}
            />
          </AnimationOnScroll>
        </div>
      </div>
      <DefaultModal
        visibilityState={isModalShown}
        closeModal={() => setIsModalShown(false)}
      >
        <p className='text-center'>
          Struggling to pay for shipment in naira because of your location?
          Don't worry, we have a solution. Pay in dollars by creating your
          shipment and contacting our customer service at{" "}
          <a href='mailto:info@eshopafrica.co' className='text-sec font-medium'>
            info@eshopafrica.co
          </a>
        </p>
      </DefaultModal>
    </section>
  );
};

export default Why;
