import React from "react";

 import AdminUps from "../../Admin/AdminDashboard/AdminUps"

import AdminDhl from "../../Admin/AdminDashboard/AdminDhl"
//<AdminDhl />, <AdminUps />
const AdminPricing = () => {
	const [activeTab, setActiveTab] = React.useState(0);

	const tabs = ["DHL", "UPS"];
	const components = [<AdminDhl />, <AdminUps /> ];
	return (
		<div>
			<div className='flex justify-around bg-[#E5E5E5] rounded-full mb-4 p-2'>
				{tabs.map((tab, index) => (
					<button
						className={`w-full py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
							activeTab === index
								? "font-bold border-b-2 bg-pry2 text-black rounded-full "
								: ""
						}`}
						onClick={() => setActiveTab(index)}
					>
						{tab}
					</button>
				))}
			</div>
			{components[activeTab]}
		</div>
	);
};

export default AdminPricing;
