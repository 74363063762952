import React, { useEffect, useState, useCallback, useRef } from "react";
import { ImNotification } from "react-icons/im";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";
import { baseUrl, config } from "../../../Utils/constants";
import AddressModals from "./AddressModals";


const SenderReceiverDetails = ({
  formData,
  handleChange,
  handleBatchChange,
}) => {
  const [pickupAreas, setPickupAreas] = useState([]);
  const [isLoadingAreas, setIsLoadingAreas] = useState(true);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const [modalShowing, setModalShowing] = useState(false);
  const [userSavedAddresses, setUserSavedAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);

  // Import the sea cargo country data arrays
  const usaDestinations = [
    "Maryland",
    "Chicago",
    "Indianapolis",
    "New York",
    "New Jersey",
    "Minnesota",
    "California",
    "Florida",
    "Boston",
    "Atlanta",
    "Alaska",
    "Virginia",
    "Connecticut",
    "Texas"
  ];

  const canadaDestinations = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan"
  ];

  // Organize locations by country
  const locationsByCountry = {
    usa: [
      { id: "Washington", name: "Washington" },
      { id: "Florida", name: "Florida" },
      { id: "California", name: "California" },
      { id: "Los Angeles", name: "Los Angeles" },
      { id: "USA Other States", name: "USA Other States" },
    ],
    canada: [
      { id: "Toronto", name: "Toronto" },
      { id: "Calgary", name: "Calgary" },
      { id: "Vancouver", name: "Vancouver" },
      { id: "Halifax", name: "Halifax" },
      { id: "Ottawa", name: "Ottawa" },
      { id: "Quebec", name: "Quebec" },
      { id: "Saskatoon", name: "Saskatoon" },
      { id: "Winnipeg", name: "Winnipeg" },
      { id: "Edmonton", name: "Edmonton" },
      { id: "NovaScotia", name: "NovaScotia" },
      { id: "New Brunswick", name: "New Brunswick" },
      { id: "Ontario", name: "Ontario" },
      { id: "New Foundland", name: "New Foundland" },
      { id: "Columbia", name: "Columbia" },
      { id: "British Columbia", name: "British Columbia" },
      { id: "Manitoba", name: "Manitoba" },
      { id: "Alberta", name: "Alberta" },
    ],
    uk: [
      { id: "England", name: "England" },
      { id: "London", name: "London" },
      { id: "Manchester", name: "Manchester" },
    ],
    europe: [
      { id: "Austria", name: "Austria" },
      { id: "Portland", name: "Portland" },
      { id: "Spain", name: "Spain" },
      { id: "Germany", name: "Germany" },
      { id: "Italy", name: "Italy" },
      { id: "France", name: "France" },
      { id: "Ireland", name: "Ireland" },
      { id: "Slovakia", name: "Slovakia" },
    ],
  };

  // Get available locations based on selected country
  const getAvailableLocations = () => {
    if (!formData.receiverCountry) return [];

    // Special case for frozen air cargo to Canada
    if (
      formData.cargoType === "frozenAir" &&
      formData.receiverCountry.toLowerCase() === "canada"
    ) {
      return [
        { id: "Calgary", name: "Calgary" },
        { id: "Toronto", name: "Toronto" },
      ];
    }

    // Special case for xtraFast cargo to UK
    if (
      formData.cargoType === "xtraFast" &&
      formData.receiverCountry.toLowerCase() === "uk"
    ) {
      return [{ id: "United Kingdom", name: "United Kingdom" }];
    }

    // For sea cargo to USA, use the USA sea cargo destinations from SeaCargoCountrydata
    if (
      (formData.cargoType === "sea" || formData.cargoType === "frozenSea") &&
      formData.receiverCountry.toLowerCase() === "usa"
    ) {
      return usaDestinations.map(destination => ({ 
        id: destination, 
        name: destination 
      }));
    }

    // For sea cargo to Canada, use the Canada sea cargo destinations from SeaCargoCountrydata
    if (
      (formData.cargoType === "sea" || formData.cargoType === "frozenSea") &&
      formData.receiverCountry.toLowerCase() === "canada"
    ) {
      return canadaDestinations.map(destination => ({ 
        id: destination, 
        name: destination 
      }));
    }

    return locationsByCountry[formData.receiverCountry.toLowerCase()] || [];
  };

  const handleBatchChangeRef = useRef(handleBatchChange);

  // Update ref when handleBatchChange changes
  useEffect(() => {
    handleBatchChangeRef.current = handleBatchChange;
  }, [handleBatchChange]);

  const fetchAddresses = useCallback(async () => {
    setIsLoadingAddresses(true);
    const url = `${baseUrl}get-user-address`;
    try {
      const { data } = await axios.get(url, config(token));
      setUserSavedAddresses(data.data);
    } catch (error) {
      // Handle error silently
    } finally {
      setIsLoadingAddresses(false);
    }
  }, [token]);

  useEffect(() => {
    if (!selectedAddress) {
      return;
    }

    const address = userSavedAddresses.find(
      (addr) => addr.id === selectedAddress
    );

    if (!address) return;

    // Update all fields at once
    const fieldsToUpdate = {
      senderName: address.full_name || "",
      senderEmail: address.email || "",
      senderPhone: address.phone_number || "",
      senderAddress: address.address || "",
      senderCity: address.city || "",
      senderState: address.state || "",
      senderCountry: address.country || "",
      senderZipcode: address.postal_code || "",
    };

    handleBatchChangeRef.current(fieldsToUpdate);
  }, [selectedAddress, userSavedAddresses]);

  const fetchPickupAreas = useCallback(async () => {
    try {
      setIsLoadingAreas(true);
      const response = await axios.get(`${baseUrl}delivery-prices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {
        setPickupAreas(response.data.data.data);
      } else {
        setError("Failed to fetch pickup areas");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch pickup areas");
    } finally {
      setIsLoadingAreas(false);
    }
  }, [token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPickupAreas();
    fetchAddresses();
  }, [fetchPickupAreas, fetchAddresses]);

  // Check if pickup is selected
  const isPickupSelected =
    formData.shipmentMethod === "pickup" || formData.shipmentMethod === "van";

  // Generate shipment method options based on cargo type
  const getShipmentMethodOptions = () => {
    if (formData.cargoType === "sea" || formData.cargoType === "frozenSea") {
      return (
        <>
          <option value="">Shipment Method</option>
          <option value="dropoff">Dropoff</option>
          <option value="van">Van Pickup for Sea</option>
        </>
      );
    }
    if (
      formData.cargoType === "air" ||
      formData.cargoType === "frozenAir" ||
      formData.cargoType === "xtraFast" ||
      formData.cargoType === "XtraFastEdibles"
    ) {
      return (
        <>
          <option value="">Shipment Method</option>
          <option value="dropoff">Dropoff</option>
          <option value="pickup">Riders Pickup</option>
        </>
      );
    }
    return <option value="">Select Cargo Type First</option>;
  };

  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold text-center mb-6">
        Book Shipment For Cargo
      </h2>
      <p className="font-medium text-xl text-center mb-6">
        Ship From Nigeria to other Countries Within Few Days
      </p>
      <p className="font-medium text-center ">
        Kindly note that we don't ship frozen air or sea to UK for now
      </p>
      <div className="bg-[#BDD4C9] rounded-md p-3 my-5 flex gap-x-2">
        <ImNotification />
        <p className="text">
          Please note that pickup shipments booked after 3pm WAT will be
          processed on the next business day. Pick-up times vary depending on
          rider availability and pick-up location. Please be patient with us as
          we attend to your orders.{" "}
          <span className="font-bold">
            {" "}
            Air Cargo 14 - 21 days, Sea Cargo 90 days, Xtra fast : Usa 5 - 7
            days, Uk 3 - 5 days, Europe 9 - 12 days.{" "}
          </span>
        </p>
      </div>

      {/* Address Modal Trigger */}
      <div className="w-full flex justify-center items-center pb-4">
        <button
          className="flex flex-row items-center gap-5 border-[1px] rounded-lg border-[#ddd] border-solid px-5 py-2.5 w-fit font-[400]"
          onClick={() => {
            setModalShowing(true);
            fetchAddresses();
          }}
          disabled={isLoadingAddresses}
        >
          {isLoadingAddresses ? (
            <Spinner size={20} />
          ) : (
            "Choose from a saved address"
          )}
        </button>
      </div>

      {/* Sender Details */}
      <div className="mb-6">
        <h3 className="font-medium mb-4">Sender Details</h3>
        <select
          name="cargoType"
          value={formData.cargoType}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        >
          <option value="">Select Cargo Type</option>
          <option value="air">Air Cargo</option>
          <option value="sea">Sea Cargo</option>
          <option value="frozenAir">Frozen Air</option>
          <option value="frozenSea">Frozen Sea</option>
          <option value="xtraFast">XtraFast Aircargo General</option>
          <option value="XtraFastEdibles">XtraFast Food items (USA)</option>
        </select>

        <select
          name="shipmentMethod"
          value={formData.shipmentMethod}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        >
          {getShipmentMethodOptions()}
        </select>

        {/* Pickup Details (if applicable) */}
        {isPickupSelected && (
          <div className="space-y-4 mb-4">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Pickup Date
              </label>
              <input
                type="date"
                name="pickupDate"
                value={formData.pickupDate}
                onChange={handleChange}
                min={new Date().toISOString().split("T")[0]}
                className="w-full p-2 border rounded"
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Pickup Area
              </label>
              {isLoadingAreas ? (
                <div className="w-full p-2 border rounded flex justify-center">
                  <Spinner />
                </div>
              ) : error ? (
                <div className="text-red-500 text-sm">{error}</div>
              ) : (
                <select
                  name="pickupArea"
                  value={formData.pickupArea?.id || ""}
                  onChange={(e) => {
                    const selectedArea = pickupAreas.find(
                      (area) => area.id.toString() === e.target.value
                    );
                    handleChange({
                      target: {
                        name: "pickupArea",
                        value: selectedArea || "",
                      },
                    });
                  }}
                  className="w-full p-2 border rounded"
                >
                  <option value="">Select Pickup Area</option>
                  {pickupAreas.map((area) => (
                    <option key={area.id} value={area.id}>
                      {area.location} (₦{Number(area.price).toLocaleString()})
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        )}

        {/* Sender Form Fields */}
        <input
          type="text"
          name="senderName"
          placeholder="Sender Name"
          value={formData.senderName}
          onChange={handleChange}
          required
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="email"
          name="senderEmail"
          placeholder="Sender Email"
          value={formData.senderEmail}
          onChange={handleChange}
          required
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="tel"
          name="senderPhone"
          placeholder="Sender Phone"
          value={formData.senderPhone}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="text"
          name="senderAddress"
          placeholder="Sender Address"
          value={formData.senderAddress}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="text"
          name="senderCity"
          placeholder="Sender City"
          value={formData.senderCity}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="text"
          name="senderState"
          placeholder="Sender State"
          value={formData.senderState}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <select
          name="senderCountry"
          value={formData.senderCountry}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        >
          <option value="">Select Country</option>
          <option value="Nigeria">Nigeria</option>
        </select>
        <input
          type="text"
          name="senderZipcode"
          placeholder="Zip Code"
          value={formData.senderZipcode}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
      </div>

      {/* Receiver Details */}
      <div>
        <h3 className="font-medium mb-4">Receiver Details</h3>
        <input
          type="text"
          name="receiverName"
          placeholder="Receiver Name"
          value={formData.receiverName}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="email"
          name="receiverEmail"
          placeholder="Receiver Email"
          value={formData.receiverEmail}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="tel"
          name="receiverPhone"
          placeholder="Receiver Phone"
          value={formData.receiverPhone}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
        <input
          type="text"
          name="receiverAddress"
          placeholder="Receiver Address"
          value={formData.receiverAddress}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />

        <select
          name="receiverCountry"
          value={formData.receiverCountry}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        >
          <option value="">Select Receiver Country</option>
          <option value="usa">Usa</option>
          {formData.cargoType !== "xtraFast" && (
            <option value="canada">Canada</option>
          )}
          <option value="uk">UK</option>
          {formData.cargoType === "xtraFast" && (
            <option value="europe">Europe</option>
          )}
        </select>

        <select
          name="receiverCity"
          value={formData.receiverCity || ""}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
          required
        >
          <option value="">Select Receiver Location</option>
          {getAvailableLocations().map((location) => (
            <option key={location.id} value={location.id}>
              {location.name}
            </option>
          ))}
        </select>

        <input
          type="text"
          name="receiverState"
          placeholder="Receiver State"
          value={formData.receiverState}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />

        <input
          type="text"
          name="receiverZipcode"
          placeholder="Zip Code"
          value={formData.receiverZipcode}
          onChange={handleChange}
          className="w-full mb-4 p-2 border rounded"
        />
      </div>

      {/* Render the Address Modal */}
      {modalShowing && (
        <AddressModals
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          userSavedAddresses={userSavedAddresses}
          setModalShowing={setModalShowing}
          isLoadingAddresses={isLoadingAddresses}
        />
      )}
    </div>
  );
};

export default SenderReceiverDetails;