import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import { toast } from "react-toastify";

import { baseUrl } from "../../../Utils/constants";

const Payment = ({ shipmentID, setPayStatus }) => {
  const [selectedMethod, setSelectedMethod] = useState("null");
  const [walletDetails, setWalletDetails] = useState({ balance: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Fetch wallet details
  useEffect(() => {
    const getWallet = async () => {
      setLoading(true);
      setError(null);
      try {
        const url = `${baseUrl}wallet`;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const res = await axios.get(url, config);
        setWalletDetails(res.data.data);
      } catch (error) {
        setError("Failed to fetch wallet details.");
        toast.error("Failed to fetch wallet details.");
      } finally {
        setLoading(false);
      }
    };
    getWallet();
  }, [token]);

  const handlePayment = async (method) => {
    if (!shipmentID) {
      toast.error('No shipment ID available');
      return;
    }

    if (loading) return;
    setLoading(true);

    try {
      if (method === "wallet") {
        const response = await axios.post(
          `${baseUrl}cargo-shipment-wallet-payment`,
          { 
            shipment_id: shipmentID
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        toast.success(response.data.message);
        //  setPayStatus("paid");
        navigate("/dashboard/order-info");  // Direct navigation after wallet payment
      } else if (method === "paystack") {
        const callbackUrl = `${process.env.REACT_APP_BASE_URL}/confirm-cargo-payment?shipmentId=${shipmentID}`;
        const response = await axios.post(
          `${baseUrl}cargo-shipment-with-card`,
          {
            shipment_id: shipmentID,
            callback_url: callbackUrl
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const payLink = response.data.data.data.data.authorization_url;
        window.location.href = payLink;
      }
    } catch (error) {
      toast.error(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // const handleMethodChange = (method) => {
  //   setSelectedMethod(method);
  //   handlePayment(method);
  // };

  const handleFinalPayment = () => {
    if (!selectedMethod) {
      toast.error('Please select a payment method');
      return;
    }
  
    if (!shipmentID) {
      toast.error('No shipment ID available');
      return;
    }
  
    handlePayment(selectedMethod);
  };
  
  return (
    <div className="max-w-2xl mx-auto px-4 py-8">
      <h1 className="text-[25px] text-[#333333] font-medium text-center mb-8">
        Choose a Payment Option
      </h1>

      {error && <p className="text-red-500">{error}</p>}

      <div className="space-y-4">
        <div
          onClick={() => !loading && setSelectedMethod("wallet")}
          className={`relative flex items-center justify-between p-6 rounded-lg bg-[#F7F7F7] cursor-pointer
            ${loading ? "opacity-50 cursor-not-allowed" : ""}
            ${selectedMethod === "wallet" ? "ring-2 ring-[#008E56]" : ""}`}
        >
          <div className="flex-1">
            <h2 className="text-[24px] font-medium text-[#333333]">
              Pay From Wallet
            </h2>
            <p className="mt-2 text-[#666666]">
              Current balance:{" "}
              {loading ? "Loading..." : walletDetails?.wallet_balance || 0}
            </p>
            {loading && (
              <p className="text-sm text-[#666666]">Processing payment...</p>
            )}
          </div>
          <div
            className={`${
              selectedMethod === "wallet"
                ? "bg-[#008E56]"
                : "bg-white border-2 border-[#D1D5DB]"
            }
            w-6 h-6 rounded-full flex items-center justify-center`}
          >
            {selectedMethod === "wallet" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            )}
          </div>
        </div>

        <div
           onClick={() => !loading && setSelectedMethod("paystack")}
           className={`relative flex items-center justify-between p-6 rounded-lg bg-[#F7F7F7] cursor-pointer
             ${loading ? "opacity-50 cursor-not-allowed" : ""}
             ${selectedMethod === "paystack" ? "ring-2 ring-[#008E56]" : ""}`}
         >
          <div className="flex-1">
            <h2 className="text-[24px] font-medium text-[#333333]">
              Pay With Paystack
            </h2>
            <p className="mt-2 text-[#666666]">
              Pay with card, transfer or USSD via Paystack
            </p>
          </div>
          <div
            className={`${
              selectedMethod === "paystack"
                ? "bg-[#008E56]"
                : "bg-white border-2 border-[#D1D5DB]"
            }
            w-6 h-6 rounded-full flex items-center justify-center`}
          >
            {selectedMethod === "paystack" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            )}
          </div>
        </div>

        {(selectedMethod === "wallet" || selectedMethod === "paystack") && (
          <button
            onClick={handleFinalPayment}
            disabled={loading}
            className={`w-full py-4 rounded-lg text-white font-medium transition-colors duration-300 
              bg-[#008E56] hover:bg-[#006E36]`}
          >
            {loading ? 'Processing...' : 'Pay Now'}
          </button>
        )}
      </div>
    </div>
  );
};

export default Payment;