import React, { useEffect, useState } from "react";
import TanTable from "../../../Utils/TanTable";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { FormatCurrency } from "../../../Utils/currency";
import { toast } from "react-toastify";

const FedexZone = ({ prices, isLoading }) => {
	const columns = [
		{ header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
		{ header: "KG", accessorKey: "kg" },
		{
			header: "Zone 1",
			accessorKey: "zone1",
			cell: ({ row }) => FormatCurrency(row.original.zone1),
		},
		{
			header: "Zone 2",
			accessorKey: "zone2",
			cell: ({ row }) => FormatCurrency(row.original.zone2),
		},
		{
			header: "Zone 3",
			accessorKey: "zone3",
			cell: ({ row }) => FormatCurrency(row.original.zone3),
		},
	];

	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the price list belonging to different states in Nigeria based
				on their zones
			</h1>
			<TanTable columnData={columns} data={prices} loadingState={isLoading} />
		</div>
	);
};

export default FedexZone;
