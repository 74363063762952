import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import DefaultModal from "../../Modals/defaultModal";
import Btn from "../../Button/btn";
import Input from "../../Form/Input";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";
const Types = ({ item, getShippingType }) => {
	const { delivery_type, amount, id } = item || {};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [calcDetails, setCalcDetails] = useState({});
	const { adminToken } = useSelector((state) => state.admin);
	const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (e) => {
		setCalcDetails({ ...calcDetails, [e.target.name]: e.target.value });
	};

	const editType = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const url = `${baseUrl}admin/update-delivery-type/${id}`;
		try {
			const res = await axios.post(
				url,
				{ amount: calcDetails?.amount },
				config(adminToken)
			);
			setIsModalOpen(false);
			setIsLoading(false);
			toast.success(res.data.message);
			getShippingType();
		} catch (error) {
			setIsLoading(false);
			toast.error(
				`${error?.response?.request?.status} - ${error?.response?.data?.message}`
			);
		}
	};

	const deleteType = async () => {
		const url = `${baseUrl}admin/delete-delivery-type/${id}`;
		setIsLoading(true);
		try {
			const res = await axios.delete(url, config(adminToken));
			setIsLoading(false);
			getShippingType();
			toast.success(res.data.message);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			toast.error(
				`${error?.response?.request?.status} - ${error?.response?.data?.message}`
			);
		}
	};

	useEffect(() => {
        setCalcDetails((prevCalcDetails) => ({
            amount,
            ...prevCalcDetails,
        }));
    }, [amount]); 
	return (
		<>
			<ToastContainer autoClose={3000} />
			<div className='grid gap-2 grid-cols-3 text-xs items-center font-medium'>
				<h2>{delivery_type}</h2>
				<h2>{amount}</h2>
				{isLoading ? (
					<Spinner />
				) : (
					<div className='flex gap-4'>
						<AiOutlineDelete className='text-red-600' onClick={deleteType} />
						<AiOutlineEdit
							onClick={() => setIsModalOpen(true)}
							className='text-blue-600'
						/>
					</div>
				)}
			</div>
			<hr className='h-[1px] bg-gray-50 my-4' />

			<DefaultModal
				closeModal={() => setIsModalOpen(false)}
				visibilityState={isModalOpen}
			>
				<form action='' onSubmit={editType} className='flex flex-col  max-w-xl'>
					<Input
						input
						className='border bg-gray-100'
						title='Enter Delivery Type'
						id='delivery_type'
						value={delivery_type}
						readOnly={true}
					/>
					<Input
						input
						className='border'
						title='Shipping Cost'
						placeholder='Enter current rate'
						type='number'
						id='amount'
						value={calcDetails?.amount || ""}
						setItem={handleInputChange}
					/>
					<Btn
						loadingState={isLoading}
						className='bg-sec2 text-white my-5 w-full'
						text='Update Delivery Type'
					/>
				</form>
			</DefaultModal>
		</>
	);
};

export default Types;
