import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import axios from 'axios';

import { baseUrl } from '../../../Utils/constants';

const HistoryTab = ({ cardId }) => {
  const { token } = useSelector((state) => state.auth);
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchTransactions = async () => {
    console.log('Attempting to fetch transactions with cardId:', cardId);
  
    if (!cardId) {
      console.warn('No cardId provided, skipping fetchTransactions.');
      setError('Card ID is not available');
      return;
    }
  
    try {
      setIsLoading(true);
      setError(null);
  
      const formattedStartDate = startDate ? new Date(startDate).toISOString().split('T')[0] : '';
      const formattedEndDate = endDate ? new Date(endDate).toISOString().split('T')[0] : '';
  
      const queryParams = new URLSearchParams({
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        page_size: pageSize,
        page: currentPage
      });
  
      const cleanBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
      const fullUrl = `${cleanBaseUrl}/card-transactions/${cardId}?${queryParams}`;
  
      console.log('Making request to:', fullUrl);
  
      const response = await axios.get(fullUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      // Updated data extraction based on the provided response structure
      const transactionsData = response.data?.data?.data?.message?.details?.transactions;
      const total = response.data?.data?.data?.message?.details?.total || 0;
      const currentPageFromResponse = response.data?.data?.data?.message?.details?.page || 1;
      const pageSizeFromResponse = response.data?.data?.data?.message?.details?.page_size || 10;
  
      if (Array.isArray(transactionsData)) {
        setTransactions(transactionsData);
        setTotalPages(Math.ceil(total / pageSizeFromResponse));
        setCurrentPage(currentPageFromResponse);
        setPageSize(pageSizeFromResponse);
      } else {
        console.warn('Unexpected response structure:', response.data);
        setTransactions([]);
        setError('Received invalid data format from server');
      }
  
    } catch (err) {
      console.error('Transaction API Error:', {
        message: err.message,
        response: err.response?.data,
        cardId: cardId
      });
  
      setError(err.response?.data?.message || 'Failed to fetch transactions');
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (cardId) {
      fetchTransactions();
    }
  }, []);

  const handleFilterChange = () => {
    setCurrentPage(1);
    fetchTransactions();
  };

  return (
    <div className="p-4 space-y-4">
      <div className="flex flex-wrap gap-4 mb-6">
        <div className="flex items-center gap-2">
          <label className="text-sm font-medium">Start Date:</label>
          <input
            type="date"
            className="border p-2 rounded"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              handleFilterChange();
            }}
          />
        </div>
        <div className="flex items-center gap-2">
          <label className="text-sm font-medium">End Date:</label>
          <input
            type="date"
            className="border p-2 rounded"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              handleFilterChange();
            }}
          />
        </div>
        <div className="flex items-center gap-2">
          <label className="text-sm font-medium">Items per page:</label>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              handleFilterChange();
            }}
            className="border p-2 rounded"
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
      </div>

      {error && (
        <div className="text-red-500 p-4 rounded bg-red-50 mb-4">
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="text-center py-8">Loading transactions...</div>
      ) : (
        <>
          <div className="overflow-x-auto rounded-lg border">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount (USD)
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Array.isArray(transactions) && transactions.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                      No transactions found
                    </td>
                  </tr>
                ) : (
                  Array.isArray(transactions) && transactions.map((transaction, index) => (
                    <tr key={transaction.trans_id || index}>
                      <td className="px-6 py-4 whitespace-nowrap">{transaction.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{transaction.amount}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{transaction.created_at}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{transaction.description}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{transaction.status}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between items-center mt-4">
            <div className="text-sm text-gray-700">
              Page {currentPage} of {totalPages}
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className="px-4 py-2 border rounded disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Previous
              </button>
              <button
                onClick={() => setCurrentPage(prev => prev + 1)}
                disabled={currentPage === totalPages}
                className="px-4 py-2 border rounded disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HistoryTab;