import React, { useState } from "react";

import { Link, useParams } from "react-router-dom";

import { useEffect } from "react";

import axios from "axios";

import { Spinner } from "react-activity";

import { baseUrl } from "../../Utils/constants";
import { sortLists } from "../../Utils/sort";
import Timestamp from "../../Utils/timeStamp";
import circle from "../../Assets/images/circle.png";
import circle2 from "../../Assets/images/circle2.png";
import circle3 from "../../Assets/images/circle3.png";
import forum from "../../Assets/images/forum.png";
import vector from "../../Assets/images/message.png";
import phone from "../../Assets/images/phone.png";
import warn from "../../Assets/images/warn.png";
import Input from "../../Components/Form/Input";

const Track = () => {
  const { trackID } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [trackDetails, setTrackDetails] = useState([]);
  const [shipmentType, setShipmentType] = useState(''); 
  const [error, setError] = useState(''); 

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const trackShipment = async () => {
    // Validate inputs before making the request
    if (!trackID || !shipmentType) {
      setError('Please select a shipment type');
      return;
    }

    setIsLoading(true);
    setError(''); 
    const url = `${baseUrl}track`;
    try {
      const res = await axios.post(url, { 
        tracking_number: trackID, 
        shipment_type: shipmentType 
      });
      
      
      // console.log('Tracking Response:', res.data);
      // console.log('Shipment Type:', shipmentType);

      // Handle different shipment types
      if (shipmentType === 'dhl') {
     
        if (res.data.shipments && res.data.shipments.length > 0) {
          const dhlShipment = res.data.shipments[0];
          const formattedDhlDetails = dhlShipment.events.map(event => ({
            trak_details: event.description,
            updated_at: `${event.date}T${event.time}`
          }));
          
          // console.log('Formatted DHL Details:', formattedDhlDetails);
          setTrackDetails(formattedDhlDetails);
        } else {
          setError('No tracking information found for DHL shipment');
          setTrackDetails([]);
        }
      } else {
        setTrackDetails(res.data.data);
      }
      
      setIsLoading(false);
    } catch (error) {
      // console.error('Tracking Error:', error);
      setError(error.response?.data?.message || 'An error occurred while tracking the shipment');
      setIsLoading(false);
    }
  };

  sortLists(trackDetails);

  return (
    <div>
      <div className='flex flex-col items-center justify-center bg-black py-16 w-full relative'>
        <h2 className='text-white text-2xl font-medium'>Track Shipment</h2>
        
        <div className='p-4 w-full max-w-3xl'>
          <Input
            readOnly={true}
            className='w-full cursor-not-allowed relative z-10 mb-4'
            value={trackID}
            input
          />
          
          {/* Shipment Type Dropdown */}
          <select 
            value={shipmentType}
            onChange={(e) => setShipmentType(e.target.value)}
            className='w-full p-2 border rounded mb-4'
          >
            <option value="">Select Shipment Type</option>
            <option value="dhl">DHL</option>
            <option value="import">Import</option>
            <option value="cargo">Cargo</option>
          </select>

          {/* Error Message */}
          {error && (
            <div className='text-red-500 mb-4 text-center'>
              {error}
            </div>
          )}

          {/* Track Button */}
          <button 
            onClick={trackShipment}
            className='w-full bg-green-500 text-white p-2 rounded hover:bg-green-600'
          >
            Track Shipment
          </button>
        </div>

        <img
          src={circle}
          className='absolute bottom-0 left-0 w-16'
          alt='circle-1'
        />
        <img
          src={circle3}
          className='absolute top-0 right-0 w-10'
          alt='circle-1'
        />
        <img
          src={circle2}
          className='absolute bottom-5 right-[50%] w-8'
          alt='circle-1'
        />
      </div>
      {isLoading ? (
        <div className='h-[20vh] flex items-center justify-center'>
          <Spinner />
        </div>
      ) : !trackDetails || trackDetails.length === 0 ? (
        <div className='flex flex-col items-center justify-center py-16 p-5'>
          <img src={warn} className='w-40' alt='' />
          <h2 className='font-medium text-lg my-4'>No Shipment Found</h2>
          <p className='text-base'>
            There is no shipment with the tracking number found
          </p>
        </div>
      ) : (
        <div className='max-w-2xl mx-auto p-4 shadow-md my-10'>
          {trackDetails?.map(({ trak_details, updated_at }, ind) => {
            return (
              <div key={ind} className='my-4'>
                <Timestamp time={updated_at} />
                <h2 className='text-green-600 font-medium'>{trak_details}</h2>
              </div>
            );
          })}
        </div>
      )}

      <Link to='/' className='text-center block underline italic'>
        Track another shipment
      </Link>

      <div className='flex flex-col items-center justify-center bg-black my-4 py-12 p-5 max-w-3xl mx-auto'>
        <h2 className='font-medium text-xl my-4 text-white'>Need help?</h2>
        <div className='grid grid-cols-3 gap-2 text-white text-sm'>
          <div className='flex flex-col items-center justify-center'>
            <img className='w-7 my-3' src={forum} alt='message' />
            <h2>Chat with us</h2>
          </div>
          <div className='flex flex-col items-center justify-center'>
            <img className='w-7 my-3' src={phone} alt='message' />
            <h2>Call us</h2>
          </div>
          <div className='flex flex-col items-center justify-center'>
            <img className='w-7 my-3' src={vector} alt='message' />
            <h2>Email us</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Track;