export const referrals = [
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   count: "3",
  //   status: "Active",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   count: "3",
  //   status: "Inactive",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   count: "3",
  //   status: "Active",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   count: "3",
  //   status: "Inactive",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   count: "3",
  //   status: "Active",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   count: "3",
  //   status: "Inactive",
  // },
];
export const createdCodes = [
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   duration: "30/09/2020 - O2/01/2023",
  //   amount: "$200",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   duration: "30/09/2020 - O2/01/2023",
  //   amount: "$200",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   duration: "30/09/2020 - O2/01/2023",
  //   amount: "$200",
  // },
  // {
  //   name: "Abiodun Adeola",
  //   email: "Morenkecy@gmail.com",
  //   tel: "08034352422",
  //   referralCode: "34256",
  //   duration: "30/09/2020 - O2/01/2023",
  //   amount: "$200",
  // },
];
