import axios from "axios";

import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import Btn from "../../../Button/btn";
import Input from "../../../Form/Input";
import DefaultModal from "../../../Modals/defaultModal";
import SuccessModal from "../../../Modals/success";
import { baseUrl, config } from "../../../../Utils/constants";

const CreditWallet = ({ setIsTopupModalVisible, isTopupModalVisible }) => {
  const { token } = useSelector((state) => state.auth);
  const [isTopupCompleted, setIsTopupCompleted] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTopupCompleted(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [isTopupCompleted]);

  const makePayment = async () => {
    if (amount <= 0) {
      toast.error("Please enter a valid amount");
      return;
    }

    setIsLoading(true);
    const url = `${baseUrl}topup`;

    // Generate callback URL using environment variable
    const callbackUrl = `${process.env.REACT_APP_BASE_URL}/paymentresponse`;

    try {
      const response = await axios.post(
        url,
        {
          amount,
          callback_url: callbackUrl,
        },
        config(token),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response, "response");

      // Extract payment link from response
      const payLink = response.data?.url;
      console.log(payLink, "payLink");
      if (response) {
        window.location.href = payLink;
      }

      setIsTopupModalVisible(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred during topup";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DefaultModal
        visibilityState={isTopupModalVisible}
        closeModal={() => setIsTopupModalVisible(false)}
      >
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-center">Top Up Wallet</h2>
          {/* <Input
            input
            title="Amount"
            type="number"
            className={"border-2 font-medium w-full"}
            value={amount}
            setItem={(e) => setAmount(e.target.value)}
            placeholder="Enter amount to top up"
          /> */}

          <Input
            input
            title="Amount"
            type="number"
            className={"border-2 font-medium w-full"}
            value={amount}
            setItem={(e) => {
              const value = e.target.value;
              // Remove any + or - signs before processing
              const cleanedValue = value.replace(/[+-]/g, "");
              if (cleanedValue === "" || Number(cleanedValue) > 0) {
                setAmount(cleanedValue);
              }
            }}
            placeholder="Enter amount to top up"
            min="1"
            onKeyDown={(e) => {
              if (e.key === "+" || e.key === "-") {
                e.preventDefault();
              }
            }}
          />

          <Btn
            text={"Top Up"}
            className="bg-pry text-black w-full py-3 mt-4"
            onClick={makePayment}
            loadingState={isLoading}
            disabled={amount <= 0}
          />
        </div>
      </DefaultModal>

      <SuccessModal visibilityState={isTopupCompleted}>
        <h2 className="text-lg font-medium my-2">Top Up Successful</h2>
        <p className="text-sm">You have successfully topped up your wallet</p>
      </SuccessModal>
    </>
  );
};

export default CreditWallet;
