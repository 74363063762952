import React, { useState, useEffect } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import { sortList } from "../../../Utils/sort";
import Export from "../../../Utils/Admin/export";
import Filters from "../../../Utils/Admin/filters";
import Search from "../../../Utils/Admin/search";

const STATUS_OPTIONS = {
  successful: { label: 'Successful', class: 'bg-green-100 text-green-800' },
  pending: { label: 'Pending', class: 'bg-blue-100 text-blue-800' },
  processed: { label: 'Processed', class: 'bg-yellow-100 text-yellow-800' },
  cancelled: { label: 'Cancelled', class: 'bg-red-100 text-red-800' }
};

const Shipments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [allShipments, setAllShipments] = useState([]);
  const [filteredShipments, setFilteredShipments] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  
  const { adminToken } = useSelector((state) => state.admin);

  const columns = [
    { header: "Name", accessorKey: "user.fullname" },
    { header: "Email", accessorKey: "user.email" },
    { header: "Phone Number", accessorKey: "user.phone_number" },
    { header: "Delivery Address", accessorKey: "address" },
    { header: "Ship From", accessorKey: "country" },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ cell }) => {
        const rawDate = cell.getValue();
        const formattedDate = new Date(rawDate).toLocaleDateString("en-GB");
        return <span>{formattedDate}</span>;
      },
    },
    {
      header: "Status",
      accessorKey: "shipment_status",
      cell: ({ cell }) => {
        const status = cell.getValue();
        const statusConfig = STATUS_OPTIONS[status] || STATUS_OPTIONS.cancelled;
        
        return (
          <span className={`${statusConfig.class} p-3 rounded-md font-medium text-center`}>
            {status}
          </span>
        );
      }
    }
  ];

  const getShipments = async () => {
    setIsLoading(true);
    setError(null);

    const url = `${baseUrl}admin/get-shipments?shipment_type=import&status=all`;

    try {
      const { data } = await axios.get(url, config(adminToken));
      const sortedData = sortList([...data.data]);
      setAllShipments(sortedData);
      setFilteredShipments(sortedData);
    } catch (error) {
      console.error('Error fetching shipments:', error);
      setError(error.response?.data?.message || 'Failed to fetch shipments');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getShipments();
  }, []);

  // Apply filters and search
  useEffect(() => {
    let result = [...allShipments];

    // Apply status filter
    if (statusFilter) {
      result = result.filter(item => item.shipment_status === statusFilter);
    }

    // Apply search query
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      result = result.filter(item => 
        item.user?.fullname?.toLowerCase().includes(query) ||
        item.user?.email?.toLowerCase().includes(query) ||
        item.user?.phone_number?.toLowerCase().includes(query) ||
        item.address?.toLowerCase().includes(query) ||
        item.country?.toLowerCase().includes(query)
      );
    }

    setFilteredShipments(result);
  }, [statusFilter, searchQuery, allShipments]);

  const handleStatusFilter = (status) => {
    setStatusFilter(status);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleExport = () => {
    // Helper function to safely access nested properties
    const getNestedValue = (obj, path) => {
      const keys = path.split('.');
      return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : ''), obj);
    };

    const headers = columns.map(col => col.header).join(',');
    const rows = filteredShipments.map(row =>
      columns
        .map(col => `"${getNestedValue(row, col.accessorKey) || ''}"`)
        .join(',')
    );
    
    const csvContent = [headers, ...rows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `shipments_${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="h-[20vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[20vh] flex items-center justify-center text-red-600">
        {error}
      </div>
    );
  }

  if (allShipments.length < 1) {
    return (
      <div className="h-[20vh] flex items-center justify-center">
        <h2 className="font-medium text-lg">No shipment at the moment</h2>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between gap-4 items-center bg-white p-4 rounded-md">
        <h2 className="font-bold">
          All Shipments
          <span className="text-gray-500 font-normal ml-2">
            ({filteredShipments.length})
          </span>
        </h2>

        <div className="flex items-center gap-4">
          <div className="hidden xl:block">
            <input
              type="text"
              placeholder="Search shipments..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="hidden md:block">
            <select
              value={statusFilter}
              onChange={(e) => handleStatusFilter(e.target.value)}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">All Statuses</option>
              {Object.entries(STATUS_OPTIONS).map(([value, { label }]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={handleExport}
            className="bg-[#055429] text-white px-4 py-2 rounded hover:bg-[#044321] transition-colors"
          >
            Export CSV
          </button>
        </div>
      </div>

      <TanTable 
        columnData={columns} 
        data={filteredShipments}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Shipments;