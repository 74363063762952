import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast } from "react-toastify";

import { baseUrl, config } from "../../../Utils/constants";

 import AdminUpsZone from "../AdminDashboard/AdminUpsZone";
import AdminUpsDomestic from "../AdminDashboard/AdminUpsDomestic"
import AdminUpsWorld from "../AdminDashboard/AdminUpsWorld"
import AdminUpsNgn from "../AdminDashboard/AdminUpsNgn"

const PricingTab = () => {
	const [activeTab, setActiveTab] = useState("zone");

	const [isLoading, setIsLoading] = useState(false);
	const { token } = useSelector((state) => state.auth);
	const [upsZones, setUpsZones] = useState([]);
	const [prices, setPrices] = useState([]);
	const [zones, setZones] = useState([]);
	const [ngnPrices, setNgnPrices] = useState([]);

	// const getZones = async () => {
	// 	const url = `${baseUrl}get-ups-zones`;
	// 	setIsLoading(true);
	// 	try {
	// 	  const { data } = await axios.get(url, config(token));
	// 	  console.log("Zones API response:", data);
	// 	  setUpsZones(data?.data?.data || []); // Fallback to an empty array
	// 	} catch (error) {
	// 	  toast.error(error?.response?.data?.message || "Failed to fetch zones");
	// 	} finally {
	// 	  setIsLoading(false);
	// 	}
	//   };
	  

	// const getPrices = async () => {
	// 	const url = `${baseUrl}get-ups-international-prices`;
	// 	setIsLoading(true);
	// 	try {
	// 		const { data } = await axios.get(url, config(token));
	// 		setPrices(data?.data?.data);
	// 	} catch (error) {
	// 		toast.error(error?.response?.data?.message);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	// const getNgnZones = async () => {
	// 	const url = `${baseUrl}get-ups-domestic-zones`;
	// 	setIsLoading(true);
	// 	try {
	// 		const { data } = await axios.get(url, config(token));
	// 		setZones(data?.data?.data);
	// 	} catch (error) {
	// 		toast.error(error?.response?.data?.message);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	// const getNgnPrices = async () => {
	// 	const url = `${baseUrl}get-ups-domestic-prices`;
	// 	setIsLoading(true);
	// 	try {
	// 		const { data } = await axios.get(url, config(token));

	// 		setNgnPrices(data?.data?.data);
	// 	} catch (error) {
	// 		toast.error(error?.response?.data?.message);
	// 	} finally {
	// 		setIsLoading(false);
	// 	}
	// };

	useEffect(() => {
		// getZones();
		// getPrices();
		// getNgnZones();
		// getNgnPrices();
	}, []);

	

	const renderContent = () => {
		switch (activeTab) {
		  case "zone":
			return <AdminUpsZone  upsZones={upsZones} isLoading={isLoading} />
		  case "world":
			return <AdminUpsWorld isLoading={isLoading} prices={prices} />
		  case "domestic":
			return  <AdminUpsDomestic isLoading={isLoading} zones={zones} />
		  case "ngnzone":
			return <AdminUpsNgn isLoading={isLoading} prices={ngnPrices} />
		  default:
			return <div>Invalid tab selected</div>;
		}
	  };
	  

	return (
		<div className='p-4'>
			<p className='text-center font-bold pb-4 text-lg md:text-base sm:text-sm'>
				UPS Express Shipping Price
			</p>

			<div className='flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2'>
				<button
					className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
						activeTab === "zone"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/4"
							: ""
					}`}
					onClick={() => setActiveTab("zone")}
				>
					World
				</button>
				<button
					className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
						activeTab === "world"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/4"
							: ""
					}`}
					onClick={() => setActiveTab("world")}
				>
					Ngn World
				</button>
				<button
					className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
						activeTab === "domestic"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/4"
							: ""
					}`}
					onClick={() => setActiveTab("domestic")}
				>
					Ngn Zone
				</button>
				<button
					className={`py-2 px-4 text-sm md:text-sm sm:text-sm ${
						activeTab === "ngnzone"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/4"
							: ""
					}`}
					onClick={() => setActiveTab("ngnzone")}
				>
					Ngn Domestic
				</button>
			</div>
			<div className='p-4'>{renderContent()}</div>
		</div>
	);
};

export default PricingTab;
