import { useEffect } from 'react';

const Tawk = () => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/67d42debefed9c19107723af/1imad6omj';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Append script to the document head
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs only once

  return null; // No need to render anything
};

export default Tawk;