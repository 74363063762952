import React from 'react'
import FulfilmentTab from '../../Components/Fulfilment/fulfilmentTabs'

const FulfilmentPage = () => {
  return (
    <div>
      <FulfilmentTab/>
    </div>
  )
}

export default FulfilmentPage
