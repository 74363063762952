import React, { useState } from "react";

import * as XLSX from 'xlsx';

import TanTable from "../../../Utils/TanTable";

const UpsDomestic = () => {
  const [uploadedZones, setUploadedZones] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { 
      header: "S/N", 
      accessorKey: "sn", 
      cell: ({ row }) => row.index + 1 
    },
    { 
      header: "Zones", 
      accessorKey: "zone" 
    },
    { 
      header: "States", 
      accessorKey: "state" 
    },
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setIsLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const workbook = XLSX.read(event.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);

          // Transform data to match expected format
          const formattedData = data.map(row => ({
            zone: row.Zone || row['Zones'],
            state: row.State || row['States']
          }));

          setUploadedZones(formattedData);
        } catch (error) {
          console.error("Error parsing Excel file:", error);
          alert("Failed to upload file. Please check the file format.");
        } finally {
          setIsLoading(false);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <div className='w-full'>
      <div className='mb-4'>
        <label 
          htmlFor="excel-upload" 
          className='block w-full p-4 border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-100'
        >
         Upload Excel File For Ng Zone
          <input 
            type="file" 
            id="excel-upload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className='hidden'
          />
        </label>
      </div>

      <h1 className='text-center mb-4'>
        {uploadedZones.length > 0 
          ? "Uploaded Zones and States" 
          : "Below are the states belonging to Different Zones"}
      </h1>

      <TanTable 
        columnData={columns} 
        data={uploadedZones} 
        loadingState={isLoading} 
      />
    </div>
  );
};

export default UpsDomestic;