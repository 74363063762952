import React from 'react'
import SentOutForm from '../../Components/Fulfilment/SentOutForm'

const SentGoodsFormPage = () => {
  return (
    <div>
      <SentOutForm/>
    </div>
  )
}

export default SentGoodsFormPage
