import PaymentComponent from "./PaymentComponent";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../../Utils/constants";
import axios from "axios";
import { useSelector } from "react-redux";
import { Spinner } from "react-activity";

const Payment = ({ pickupPrice, paymentMethod, setPaymentMethod }) => {
  const [walletDetails, setWalletDetails] = useState();
  const [loading, setLoading] = useState(true);

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const getWallet = async () => {
      setLoading(true);
      const url = `${baseUrl}wallet`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const res = await axios.get(url, config);
        const data = res.data.data;
        setWalletDetails(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getWallet();
  }, [pickupPrice, token]); // Added 'token' to the dependency array

  if (loading) {
    return (
      <div className="flex flex-row h-full w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 w-full justify-center items-center">
      <h3 className="text-[#282828] text-center font-[500] text-[24px]">
        Choose a Payment Option
      </h3>
      <PaymentComponent
        amount={pickupPrice}
        balance={walletDetails.wallet_balance}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
      />
    </div>
  );
};

export default Payment;