import React, { useState, useEffect } from "react";

import { CiFilter } from "react-icons/ci";

import axios from "axios";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";

const KnowYourCus = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);

  const { adminToken } = useSelector((state) => state.admin);

  const columns = [
    { 
      header: "Full Name", 
      accessorKey: "user.firstname",
      cell: ({ cell }) => {
        const firstName = cell.getValue();
        const lastName = cell.row.original.user.lastname;
        return `${firstName} ${lastName}`;
      }
    },
    { 
      header: "Email", 
      accessorKey: "user.email" 
    },
    { 
      header: "Phone Number", 
      accessorKey: "user.phone_number" 
    },
    { 
      header: "Address", 
      accessorKey: "user.address",
      cell: ({ cell }) => cell.getValue() || "N/A"
    },
    {
      header: "Status",
      accessorKey: "user.verified",
      cell: ({ cell }) => {
        const status = cell.getValue() === "1" ? "Verified" : "Not Verified";
        const color = status === "Verified" ? "#055429" : "#E31352";
        return <span style={{ color }}>{status}</span>;
      },
    },
  ];

  // Fetch KYC data from the API
  useEffect(() => {
    const fetchKycData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}admin/kyc`, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        setData(response.data.data); // Update to use response.data.data
        setFilteredData(response.data.data); // Initialize filtered data
      } catch (error) {
        console.error("Error fetching KYC data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchKycData();
  }, [adminToken]);

  // Function to handle filter change and update filtered data
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "") {
      setFilteredData(data);
    } else {
      const newFilteredData = data.filter(
        (item) =>
          (item.user.verified === "1" && selectedFilter === "Verified") ||
          (item.user.verified !== "1" && selectedFilter === "Not Verified") ||
          item.user.firstname.toLowerCase().includes(selectedFilter.toLowerCase()) ||
          item.user.email.toLowerCase().includes(selectedFilter.toLowerCase()) ||
          item.user.phone_number.includes(selectedFilter)
      );
      setFilteredData(newFilteredData);
    }
  };

  // Function to export data to CSV
  const exportToCSV = (exportData) => {
    const csvData = exportData.map((row) => {
      const fullName = `${row.user.firstname} ${row.user.lastname}`;
      const status = row.user.verified === "1" ? "Verified" : "Not Verified";
      return [
        fullName, 
        row.user.email, 
        row.user.phone_number, 
        row.user.address || "N/A", 
        status
      ].map(value => `"${value}"`).join(",");
    });
    
    const csvContent = [
      "Name,Email,Phone Number,Address,Status", 
      ...csvData
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "kyc_data.csv");
    link.click();
  };

  const handleExportClick = () => {
    exportToCSV(filteredData);
  };

  return (
    <div>
      <div className="flex justify-between items-center p-2">
        <p className="text-center font-bold p-2 text-lg md:text-base sm:text-sm">
          KYC ({data.length})
        </p>

        <div className="flex items-center pb-4">
          <CiFilter className="mr-1" />
          <select
            value={filter}
            onChange={handleFilterChange}
            className="border p-2 rounded"
          >
            <option value="">Filter</option>
            <option value="Verified">Verified</option>
            <option value="Not Verified">Not Verified</option>
          </select>
        </div>

        <button
          onClick={handleExportClick}
          className="bg-[#055429] text-white p-2 rounded"
        >
          Export
        </button>
      </div>

      {/* Spinner or Table */}
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-green-500 border-solid"></div>
        </div>
      ) : (
        <TanTable columnData={columns} data={filteredData} />
      )}
    </div>
  );
};

export default KnowYourCus;