import { useEffect } from "react";

import { Spinner } from "react-activity";

import { convertCurrencyToNaira } from "../../../Utils/currency";

import useWallet from './useWallet'; 
const PaymentComponent = ({ paymentMethod, setPaymentMethod }) => {
    const { formattedBalance, loading } = useWallet();

    const options = [
        {
            title: "Pay From Wallet",
            desc: `Current balance: ${formattedBalance}` // Using formattedBalance from hook
        },
        {
            title: "Pay With Paystack",
            desc: "Pay with card, transfer or USSD via Paystack"
        },
    ];
    
    return (
        <div className="max-w-[500px] w-full flex flex-col">
            <div className="p-1 flex flex-col gap-4 rounded-2xl w-full">
                {loading ? (
                    <div className="flex justify-center">
                        <Spinner />
                    </div>
                ) : (
                    options.map((option, index) => (
                        <div
                            key={index}
                            className="flex flex-row items-center justify-between p-4 rounded-[10px] bg-[#E4E4E480] border-[#2828284D] border-[1px] cursor-pointer"
                            onClick={() => {
                                setPaymentMethod(index);
                            }}
                        >
                            <div className="flex flex-col gap-2.5">
                                <h3 className="text-[#282828] text-[24px] font-[500]">
                                    {option.title} 
                                </h3>
                                <p className="text-[#28282899]">
                                    {option.desc}
                                </p>
                            </div>
                            <div className="flex flex-col items-center min-w-[100px] w-[130px] gap-1">
                                <div className="border-4 border-[#008E56] h-[24px] w-[24px] rounded-full flex justify-center items-center">
                                    {index === paymentMethod && (
                                        <div className="h-[12px] w-[12px] rounded-full bg-[#008E56]" />
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default PaymentComponent;