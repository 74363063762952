import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";

import { CiLogout } from "react-icons/ci";

import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import { baseUrl, config } from "../../../Utils/constants";
import { menus } from "../../../Utils/dashboardMenu";
import { setLocation } from "../../../Redux/features/dashboardSlice";

const Sidebar = ({ isSidebarOpen, closeSidebar }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token } = useSelector((state) => state.auth);

	const logout = () => {};

	const [referralCount, setReferralCount] = useState(0);
	const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

	const toggleSubmenu = (index) => {
		setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
	};

	const getTotalReferrals = async () => {
		try {
			const url = `${baseUrl}total-referral`;
			const res = await axios.get(url, config(token));
			setReferralCount(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getTotalReferrals();
	}, []);

	return (
		<div
			className={`fixed top-0 ${
				isSidebarOpen ? "translate-x-0" : "-translate-x-full"
			} md:translate-x-0 transition w-[80%] md:w-[30%] lg:w-[20%] bg-sec text-white h-screen p-8 pt-28 z-20`}
		>
			<div className='h-full overflowHide'>
				{menus?.map(({ menu, url, img, submenus, dropdownIcon }, i) => {
					return (
						<div key={i}>
							<button
								className='flex items-center gap-4 my-8 cursor-pointer'
								onClick={() => {
									if (!submenus) {
										dispatch(setLocation(menu));
										closeSidebar();
										navigate(url);
									} else {
										toggleSubmenu(i);
									}
								}}
								// disabled={i === 2}
							>
								<img className='w-5' src={img} alt={menu} />
								<h2 className={`text-sm font-medium text-left`}>{menu}</h2>
								{dropdownIcon && (
									<button onClick={() => toggleSubmenu(i)}>
										{dropdownIcon}
									</button>
								)}

								{menu === "Dollar Card" && (
									<h2>
										
									</h2>
								)}
								{menu === "Referrals" && (
									<h2 className='text-xs bg-red-600 text-red-50 rounded-full w-5 h-5 flex items-center justify-center -translate-y-2 -translate-x-3'>
										{referralCount}
									</h2>
								)}
							</button>
							{/* SUBMENU BEGINS */}
							{openSubmenuIndex === i &&
								submenus?.map((submenu, j) => {
									return (
										<button
											className='flex items-center gap-4 my-8 cursor-pointer ml-8'
											key={j}
											onClick={() => {
												dispatch(setLocation(submenu.submenu));
												closeSidebar();
												navigate(submenu.url);
											}}
										>
											<img
												className='w-5'
												src={submenu.img}
												alt={submenu.submenu}
											/>
											<h2 className={`text-sm font-medium`}>
												{submenu.submenu}
											</h2>
										</button>
									);
								})}
							{/* SUBMENU ENDS */}
						</div>
					);
				})}
				<Link
					to='/'
					className='flex items-center gap-4 mt-20 font-medium'
					onClick={logout}
				>
					<i className='text-xl'>
						<CiLogout />
					</i>
					<h2>Logout</h2>
				</Link>
			</div>
		</div>
	);
};

export default Sidebar;
