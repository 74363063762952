import React, { useState, useEffect } from "react";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import { Spinner } from "react-activity";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";

const DeliveryCalculatorCargo = () => {
  const { adminToken } = useSelector((state) => state.admin);
  const [data, setData] = useState([]);
  const [deliveryLocation, setDeliveryLocation] = useState("");
  const [seaAmount, setSeaAmount] = useState("");
  const [airAmount, setAirAmount] = useState("");
  const [frozenAirAmount, setFrozenAirAmount] = useState("");
  const [frozenSeaAmount, setFrozenSeaAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingCargo, setEditingCargo] = useState(null);

  // Table columns
  const columnsData = [
    {
      header: "Delivery Location",
      accessorKey: "Delivery_location",
    },
    {
      header: "Air Amount",
      accessorKey: "cost",
    },
    {
      header: "Sea Amount",
      accessorKey: "sea_amount",
    },
    {
      header: "Air Frozen Amount",
      accessorKey: "frozen_air_amount",
    },
    {
      header: "Sea Frozen Amount",
      accessorKey: "frozen_sea_amount",
    },
    {
      header: "Action",
      accessorKey: "id",
      cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <AiOutlineEdit
            size={20}
            className="text-blue-500 cursor-pointer"
            onClick={() => handleEdit(row.original)}
          />
          <AiOutlineDelete
            size={20}
            className="text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.original.id)}
          />
        </div>
      ),
    },
  ];

  // Fetch cargo prices on component mount
  useEffect(() => {
    fetchCargoPrices();
  }, []);

  // Fetch cargo prices from the API
  const fetchCargoPrices = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${baseUrl}admin/cargo-prices`, {
        headers: {
          Authorization: `Bearer ${adminToken}`,
        },
      });
      const nestedData = response.data.data[0];
      const mappedData = nestedData.map((item) => ({
        id: item.id,
        Delivery_location: item.country,
        cost: item.air,
        sea_amount: item.sea,
        frozen_air_amount: item.air_frozen,
        frozen_sea_amount: item.sea_frozen,
      }));
      setData(mappedData);
    } catch (error) {
      toast.error("Failed to fetch cargo prices");
    } finally {
      setIsLoading(false);
    }
  };

  // Handle edit action
  const handleEdit = (cargo) => {
    setEditingCargo(cargo);
    setDeliveryLocation(cargo.Delivery_location);
    setAirAmount(cargo.cost); 
    setSeaAmount(cargo.sea_amount); 
    setFrozenAirAmount(cargo.frozen_air_amount); 
    setFrozenSeaAmount(cargo.frozen_sea_amount); 
  };

  // Handle delete action
  const handleDelete = async (cargoId) => {
    if (window.confirm("Are you sure you want to delete this cargo price?")) {
      setIsLoading(true);
      try {
        const response = await axios.delete(
          `${baseUrl}admin/cargo-prices/${cargoId}`,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
        toast.success(response.data.message);
        fetchCargoPrices(); // Refresh the table
      } catch (error) {
        toast.error(error.response?.data?.message || "Failed to delete cargo price");
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Handle form submission (create or update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      country: deliveryLocation,
      air: airAmount,
      sea: seaAmount,
      air_frozen: frozenAirAmount,
      sea_frozen: frozenSeaAmount,
    };

    try {
      let response;
      if (editingCargo) {
        // Update existing cargo price
        response = await axios.put(
          `${baseUrl}admin/cargo-prices/${editingCargo.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${adminToken}`,
            },
          }
        );
      } else {
        // Create new cargo price
        response = await axios.post(`${baseUrl}admin/cargo-prices`, payload, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
      }

      toast.success(response.data.message);
      fetchCargoPrices(); // Refresh the table
      resetForm(); // Clear the form
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  // Reset form fields
  const resetForm = () => {
    setEditingCargo(null);
    setDeliveryLocation("");
    setAirAmount("");
    setSeaAmount("");
    setFrozenAirAmount("");
    setFrozenSeaAmount("");
  };

  return (
    <div className="p-4">
      <ToastContainer autoClose={3000} />

      {/* Form */}
      <form
        onSubmit={handleSubmit}
        className="bg-gray-100 shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md"
      >
        <div className="mb-4">
          <label htmlFor="deliveryLocation" className="block text-gray-700 mb-2">
            Delivery Location
          </label>
          <input
            type="text"
            id="deliveryLocation"
            value={deliveryLocation}
            onChange={(e) => setDeliveryLocation(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter delivery location"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="airAmount" className="block text-gray-700 mb-2">
            Air Amount
          </label>
          <input
            type="text"
            id="airAmount"
            value={airAmount}
            onChange={(e) => setAirAmount(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter air amount"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="seaAmount" className="block text-gray-700 mb-2">
            Sea Amount
          </label>
          <input
            type="text"
            id="seaAmount"
            value={seaAmount}
            onChange={(e) => setSeaAmount(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter sea amount"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="frozenAirAmount" className="block text-gray-700 mb-2">
            Air Frozen Amount
          </label>
          <input
            type="text"
            id="frozenAirAmount"
            value={frozenAirAmount}
            onChange={(e) => setFrozenAirAmount(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter air frozen amount"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="frozenSeaAmount" className="block text-gray-700 mb-2">
            Sea Frozen Amount
          </label>
          <input
            type="text"
            id="frozenSeaAmount"
            value={frozenSeaAmount}
            onChange={(e) => setFrozenSeaAmount(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Enter sea frozen amount"
            required
          />
        </div>

        <div className="flex items-center justify-between">
          <button
            type="submit"
            className="bg-sec2 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : editingCargo ? "Update" : "Upload"}
          </button>
          {editingCargo && (
            <button
              type="button"
              onClick={resetForm}
              className="bg-gray-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      {/* Table */}
      <div className="py-3">
        <h2 className="font-medium text-lg mb-4 text-center">
          Cargo Delivery Location and Amount
        </h2>
        {isLoading ? <Spinner /> : <TanTable columnData={columnsData} data={data} />}
      </div>
    </div>
  );
};

export default DeliveryCalculatorCargo;