import axios from "axios";

import React, { useEffect, useState, useRef } from "react";

import { useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import { Spinner } from "react-activity";

import { toast, ToastContainer } from "react-toastify";

import Btn from "../../Button/btn";
import DefaultModal from "../../Modals/defaultModal";
import { baseUrl, config } from "../../../Utils/constants";
const ConfirmPayment = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const hasVerifiedPayment = useRef(false);
  
  const verifyPayment = async () => {
    setIsLoading(true);
    const trans = query.get("trxref");
    const reference = query.get("reference")
    console.log(trans, "trans")
    if (trans) {
      const url = `${baseUrl}verifypayment/${trans}`;
      try {
        const res = await axios.post(url, {reference : reference}, config(token));
        toast.success(res?.data.message);
        setIsLoading(false);
        setTimeout(() => {
          navigate("/dashboard/wallet");
        }, 1500);
      } catch (error) {
        console.error("Error during payment verification:", error);
        toast.error("Payment verification failed.");
        setIsLoading(false);
      }
    } else {
      toast.error("Invalid transaction reference.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasVerifiedPayment.current) {
      hasVerifiedPayment.current = true;
      verifyPayment(); 
    }
  }, []);

  return (
    <div className='flex min-h-screen justify-center items-center flex-col gap-2'>
      <Spinner size={30} />
      <h1 className="text-[#282828] text-center font-[500] text-[24px]">
          Verifying Payment...
      </h1>

      {/* <Spinner size={30} /> */}
    </div>
  );
};

export default ConfirmPayment;
