import "./App.css";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import { persistor } from "./Redux/store";
import { PersistGate } from "redux-persist/integration/react";
import AppRoutes from "./Routes/Routes";
import "react-activity/dist/library.css";
import "animate.css/animate.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Tawk from "./Pages/LandingPage/Tawk";

function App() {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<div className='mx-auto max-w-[2000px] relative'>
					<AppRoutes />
					<ToastContainer />
				</div>
				{/* <Tawk/> */}
			</PersistGate>
		</Provider>
	);
}

export default App;
