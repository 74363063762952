import React from 'react'
import Kyc from '../Components/Dashboard/kyc/Kyc'

const KycPage = () => {
  return (
    <div>
  <Kyc/>
    </div>
  )
}

export default KycPage
