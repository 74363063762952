import React from 'react'
import FundCard from "../../Components/Dashboard/VirtualCard/FundCardModal"

const FundCardPage = () => {
  return (
    <div>
      <FundCard/>
    </div>
  )
}

export default FundCardPage
