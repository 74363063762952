import React from "react";
import { useNavigate } from "react-router-dom";
import Btn from "../../Button/btn";
import OTPInput from "../../../Utils/OTP";

const CardDetailsPin = ({
  confirmPinDetails,
  setConfirmPinDetails,
  isLoading,
}) => {
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate("/dashboard/card-details");
  };
  return (
    <div>
      <div className="text-center">
        <h2 className="font-medium text-xl sm:text-2xl md:text-3xl mb-2 sm:mb-3">
          Enter your Pin
        </h2>
        <p className="text-sm sm:text-base mb-4 sm:mb-6">
          Please remember to keep your pin safe, It is used to keep your account secure.
        </p>
      </div>
      <form
        className="w-full"
        onSubmit={handleSubmit}
        // onSubmit={(e) => {
        //   e.preventDefault();
        //    createCardPin();
        // }}
      >
        <div className="mb-4 sm:mb-6">
          <OTPInput OTP={confirmPinDetails} setOTP={setConfirmPinDetails} />
        </div>
        <Btn
          loadingState={isLoading}
          text={"Continue"}
          className="bg-green-600 my-6 justify-center text-white flex items-center mx-auto"
        />
      </form>
    </div>
  );
};

export default CardDetailsPin;
