import React from "react"
import { shipmentMethodOptions } from "../../../../Constants"

const ShipmentMethodInput = (
    {
        shipmentMethod,
        setShipmentMethod,
        setPickupPrice,
        pickupArea,
        setPickupArea,
        pickupDate,
        setPickupDate,
        areaOptions,
    }
) => {

    const today = new Date().toISOString().split('T')[0];


    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col gap-6 w-full">
                {shipmentMethodOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`
                            flex flex-col gap-3 p-4 rounded-[10px] cursor-pointer w-full
                            ${shipmentMethod === option.value ? "bg-[#008E56]" : "border-[#2828284d] bg-[#e4e4e480]"}
                        `}
                        onClick={() => {
                            if(option.value === "drop_off") {
                                setPickupPrice("0")
                            }
                            setShipmentMethod(option.value)
                        }}
                    >
                        <h2
                            className={`text-[18px]
                                ${shipmentMethod === option.value ? "text-[#fff]" : "text-[#282828]"}
                            `}
                        >
                            {option.title}
                        </h2>
                        <p
                            className={`${shipmentMethod === option.value ? "text-[#eee]" : "text-[#28282899]"}`}
                        >
                            {option.description}
                        </p>
                    </div>
                ))}
            </div>
            {
                shipmentMethod === "pickup" &&
                (
                    <div className="grid gap-4 grid-cols-2 sm:grid-cols-1">
                        <div className="flex flex-col gap-1.5">
                            <label
                                htmlFor="pickupDate"
                                className="text-[#282828]"
                            >
                                Pickup Date
                            </label>
                            <input
                                type="date"
                                id="pickupDate"
                                className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] p-3"
                                value={pickupDate}
                                onChange={(e) => setPickupDate(e.target.value)}
                                min={today}
                                onKeyDown={(e) => e.preventDefault()}
                            />
                        </div>
                        <div className="flex flex-col gap-1.5">
                            <label
                                htmlFor="pickupDate"
                                className="text-[#282828]"
                            >
                                Pickup Area
                            </label>
                            <select
                                id="pickupArea"
                                className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] p-3"
                                value={pickupArea}
                                onChange={(e) => {
                                    setPickupArea(e.target.value)
                                    // setPickupPrice(areaOptions.find(area => area.id === e.target.value).price)
                                }
                            }>
                                <option value="">Select Pickup area</option>
                                {areaOptions.map((option) => (
                                    <option
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.location}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default ShipmentMethodInput