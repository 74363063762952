import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { FiMoreHorizontal } from "react-icons/fi";

import { useSelector } from "react-redux";

import axios from "axios";

import { Spinner } from "react-activity";
import "react-activity/dist/Spinner.css";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";

const Fulfilment = () => {
  const { adminToken } = useSelector((state) => state.admin);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFulfillmentData = async () => {
      try {
        const response = await axios.get(`${baseUrl}admin/fulfilment`, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
        // Map the data to a format compatible with the table
        const formattedData = response.data.map((item) => ({
          name: `${item.user.firstname} ${item.user.lastname}`,
          email: item.user.email,
          reggoodsreq: item.fulfilment,
          sentoutgoodsreq: item.sendOutGood,
          user_id: item.user_id,
        }));
        setTableData(formattedData);
      } catch (error) {
        console.error("Error fetching fulfillment data:", error);
      } finally {
        setIsLoading(false); // Stop loading spinner once data is fetched
      }
    };

    fetchFulfillmentData();
  }, [adminToken]);

  const handleIconClick = (row) => {
    navigate(`/secretroute/admin/fulfillmentdetails/${row.original.user_id}`);
  };


  const columns = [
    { header: "Name", accessorKey: "name" },
    { header: "Email", accessorKey: "email" },
    { header: "Reg Goods Req", accessorKey: "reggoodsreq" },
    { header: "Sentout Goods Req", accessorKey: "sentoutgoodsreq" },
    {
      header: "Action",
      accessorKey: "action",
      cell: ({ row }) => (
        <FiMoreHorizontal className="cursor-pointer" 
        onClick={() => handleIconClick(row)} />
      ),
    },
  ];

  return (
    <div className="p-2">
      <p className="text-center font-bold p-2 text-lg md:text-base sm:text-sm">
        User ({tableData.length})
      </p>
      {isLoading ? (
        <div className="flex justify-center items-center h-48">
          <Spinner size={32} />
        </div>
      ) : (
        <TanTable columnData={columns} data={tableData} />
      )}
    </div>
  );
};

export default Fulfilment;
