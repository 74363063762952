import React, { useState, useEffect } from "react";

import { FaMapMarkerAlt, FaEdit } from "react-icons/fa";

import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { IoMdArrowBack } from "react-icons/io";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { baseUrl, config } from "../../../Utils/constants";

import EditOrderModal from "./EditOrderModal";

const ExpressOrderDetails = () => {
  const [name, setName] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { adminToken } = useSelector((state) => state.admin);

  // Edit modal states
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedOrder, setEditedOrder] = useState({});

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const url = `${baseUrl}admin/single-shipment/${id}?shipment_type=express`;
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(url, config(adminToken));
        setOrder(data.data);
        // Initialize editedOrder with current order details
        setEditedOrder(data.data);
      } catch (error) {
        setError(error.response?.data?.message || "Failed to fetch order details");
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id, adminToken]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleTrackingSubmit = async (e) => {
    e.preventDefault();

    if (!name.trim()) {
      toast.error("Please enter a valid tracking number.");
      return;
    }

    const url = `${baseUrl}admin/update-express-tracking/${id}`;
    const payload = { tracking_number: name };

    try {
      const { data } = await axios.post(url, payload, config(adminToken));
      toast.success(data.message || "Tracking number updated successfully!");
      setOrder((prev) => ({ ...prev, tracking_number: name }));
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update tracking number.";
      toast.error(errorMessage);
    }
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  const handleEditSubmit = async (editedOrderData) => {
    const url = `${baseUrl}admin/update-express-shipments/${id}`;
    const payload = {
      receiver_name: editedOrderData.receiver_name,
      receiver_email: editedOrderData.receiver_email,
      receiver_phone: editedOrderData.receiver_phone,
      receiver_address_1: editedOrderData.receiver_address_1,
      receiver_address_2: editedOrderData.receiver_address_2,
      receiver_address_3: editedOrderData.receiver_address_3,
      receiver_landmark: editedOrderData.receiver_landmark,
      receiver_area_address: editedOrderData.receiver_area_address,
      receiver_country: editedOrderData.receiver_country,
      receiver_state: editedOrderData.receiver_state,
      receiver_city: editedOrderData.receiver_city,
      receiver_postal_address: editedOrderData.receiver_postal_address,
      item_category: editedOrderData.item_category,
      item_value: editedOrderData.item_value,
      item_description: editedOrderData.item_description,
      usd_value: editedOrderData.usd_value,
      quantity: editedOrderData.quantity,
      weight: editedOrderData.weight,
      length: editedOrderData.length,
      height: editedOrderData.height,
      width: editedOrderData.width,
      total_amount_to_pay: editedOrderData.amount

    };

    try {
      const { data } = await axios.post(url, payload, config(adminToken));
      toast.success(data.message || "Shipment details updated successfully!");
      setOrder(editedOrderData);
      setIsEditModalOpen(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update shipment details.";
      toast.error(errorMessage);
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="p-6 bg-gray-50">
      <ToastContainer />
      <button
        onClick={handleGoBack}
        className="mt-4 text-black hover:text-blue-800 flex items-center justify-center gap-2"
      >
        <IoMdArrowBack />
        Back
      </button>
      <h2 className="text-2xl font-semibold mb-4">Shipment Details</h2>

      <div className="border border-gray-300 rounded-lg p-6 bg-white shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 m-5">
          {/* Sender Details */}
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <FaMapMarkerAlt className="text-gray-600" />
              <h3 className="text-lg font-medium">Sender Details</h3>
            </div>
            <h1 className="text-gray-700">Address</h1>
            <p className="text-gray-700">Line 1: {order.sender_address_1}</p>
            <p className="text-gray-700">Line 2: {order.sender_address_2}</p>
            <p className="text-gray-700">Line 3: {order.sender_address_3}</p>
            <p className="text-gray-700">Postal Code: {order.sender_postal_address}</p>
          </div>

          {/* Receiver Details */}
          <div>
            <div className="flex items-center space-x-2 mb-2 justify-between">
              <div className="flex items-center space-x-2">
                <FaMapMarkerAlt className="text-gray-600" />
                <h3 className="text-lg font-medium">Receiver's Details</h3>
              </div>
              <button
                onClick={handleEditModalOpen}
                className="text-blue-600 hover:text-blue-800"
              >
                <FaEdit />
              </button>
            </div>
            <p className="text-gray-700">Name: {order.receiver_name}</p>
            <p className="text-gray-700">Email: {order.receiver_email}</p>
            <p className="text-gray-700">Address Line 1: {order.receiver_address_1}</p>
            <p className="text-gray-700">City: {order.receiver_city}</p>
            <p className="text-gray-700">State: {order.receiver_state}</p>
            <p className="text-gray-700">Country: {order.receiver_country}</p>
            <p className="text-gray-700">Postal Code: {order.receiver_postal_address}</p>
            <p className="text-gray-700">Phone: {order.receiver_phone}</p>
          </div>
        </div>

        {/* Item Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 m-5">
          <div>
            <h3 className="text-lg font-medium mb-2">Item Description</h3>
            <p className="text-gray-700">
              <span className="">Item Value:</span> {order.item_value}
            </p>
            <p className="text-gray-700">
              <span className="">Category:</span> {order.item_category}
            </p>
            <p className="text-gray-700">
              <span className="">Description:</span> {order.item_description}
            </p>
            <p className="text-gray-700">
              <span className="">Quantity:</span> {order.quantity}
            </p>
            <p className="text-gray-700">
              <span className="">Weight (KG):</span> {order.weight}
            </p>
            <p className="text-gray-700">
              <span className="">Length (CM):</span> {order.length}
            </p>
            <p className="text-gray-700">
              <span className="">Width (CM):</span> {order.width}
            </p>
            <p className="text-gray-700">
              <span className="">Height (CM):</span> {order.height}
            </p>
          </div>

          {/* Shipping Details */}
          <div>
            <div>
              <h3 className="text-lg  mb-2">Shipping Option and Insurance</h3>
            </div>
            <p className="text-gray-700">
              <span className="">Shipment Type:</span> {order.shipment_type}
            </p>
            <p className="text-gray-700">
              <span className="">Insurance:</span> {order.insurance}
            </p>
            <p className="text-gray-700">
              <span className="">Shipping Fee:</span> ₦{order.shipping_fee}
            </p>
            <p className="text-gray-700">
              <span className="">VAT:</span> {order.vat}
            </p>
            <p className="text-gray-700">
              <span className="">Payment Due in total:</span> ₦{order.amount}
            </p>
            <p className="text-gray-700">
              <span className="">Pickup Date:</span> {order.pickup_date || "N/A"}
            </p>
            <p className="text-gray-700">
              <span className="">Pickup Cost:</span> ₦{order.pickup_cost}
            </p>
            <p className="text-gray-700">
              <span className="">Pickup Area:</span> {order.delivery_area}
            </p>
          </div>
        </div>

        {/* Tracking Section */}
        <div className="mt-6 p-4 border-t">
          <h3 className="text-lg font-medium mb-2">Tracking Information</h3>
          <p className="text-gray-700 mb-4">
            <span className="font-semibold">Tracking Number:</span> {order.tracking_number || "Not available"}
          </p>

          <form onSubmit={handleTrackingSubmit} className="flex items-center space-x-2">
            <div className="flex-grow">
              <label htmlFor="trackingNumber" className="block text-sm text-gray-600 mb-1">
                Enter Tracking Number
              </label>
              <input
                type="text"
                id="trackingNumber"
                name="trackingNumber"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="border rounded px-3 py-2 w-full"
                placeholder="Enter new tracking number"
              />
            </div>
            <button
              type="submit"
              className="mt-6 text-white bg-blue-600 hover:bg-blue-700 rounded px-4 py-2"
            >
              Update Tracking
            </button>
          </form>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <EditOrderModal
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
          orderData={editedOrder}
          onSubmit={handleEditSubmit}
        />
      )}
    </div>
  );
};

export default ExpressOrderDetails;