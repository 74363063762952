import React, { useEffect, useState } from "react";
import req from "../../../../Assets/images/req.png";
import block from "../../../../Assets/images/block.png";
import unblock from "../../../../Assets/images/unblock.png";
import card from "../../../../Assets/images/card3.png";

import Card from "./card";
import DefaultModal from "../../../Modals/defaultModal";
import CancelModal from "../../../Modals/cancel";
import ErrorModal from "../../../Modals/errorModal";
import Input from "../../../Form/Input";
import Btn from "../../../Button/btn";
import SuccessModal from "../../../Modals/success";
import { toast, ToastContainer } from "react-toastify";
import OTPInput from "../../../../Utils/OTP";
import { useNavigate } from "react-router-dom";
import OtpCustomInput from "../../../../Utils/OtpCustomInput";
import CardScreen from "../CardScreen";

const DollarCard = ({
  dollarCardDetails,
  getDollarCard,
  isCardShown,
  isPageLoading,
}) => {
  const [activeIndex, setActiveIndex] = useState("");
  const [isHistoryModalOpen, setIsHistoryModal] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isUnblockModalOpen, setIsUnblockModalOpen] = useState(false);
  const [isHistorySuccessModalOpen, setIsHistorySuccessModalOpen] =
    useState(false);
  const [isBlockSuccessModalOpen, setIsBlockSuccessModalOpen] = useState(false);

  const lists = [
    // { title: "Request Transaction History", img: req },
    // { title: "Block Card", img: block },
    // { title: "Unblock Card", img: unblock },
    // { title: "Fund Dollar Card", img: card },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    if (activeIndex === 0) {
      setIsBlockModalOpen(true);
    } else if (activeIndex === 1) {
      setIsUnblockModalOpen(true);
    } else if (activeIndex === 2) {
      navigate("/dashboard/wallet");
    } else return;
  }, [activeIndex]);

  const handleHistorySubmit = (e) => {
    e.preventDefault();
    setIsHistoryModal(false);
    toast.success("Transaction history sent to your mail successfully");
  };

  const [OTP, setOTP] = useState(["", "", "", ""]);
  return (
    <>
      {isCardShown ? (
        <div>
          <ToastContainer autoClose={3000} />
          {/* <h2 className='text-xl font-medium'>Virtual Dollar Card</h2>
          <p className='text-sm my-3'>
            All transaction are secured and encrypted
          </p>
          <Card dollarCardDetails={dollarCardDetails} />
          <div className='my-10'>
            <h2 className='text-green-700 font-medium my-4'>
              Total Credit: ${dollarCardDetails?.dollar_wallet?.wallet_credit}
            </h2>
            <h2 className='text-red-700 font-medium my-4'>
              Total Debit: ${dollarCardDetails?.dollar_wallet?.wallet_debit}
            </h2>
          </div> */}
          <CardScreen dollarCardDetails={dollarCardDetails} />
          <div>
            {lists.map(({ title, img }, index) => {
              return (
                <div
                  key={index}
                  className='flex items-center gap-3 my-3 cursor-pointer hover:scale-95 hover:text-sec font-medium transition'
                  onClick={() => {
                    setActiveIndex(index);
                    // modalHandler();
                  }}
                >
                  <div className='bg-green-200 rounded-full p-3'>
                    <img className='w-5' src={img} alt={title} />
                  </div>
                  <h2 className='text-base select-none'>{title}</h2>
                </div>
              );
            })}
          </div>
          <DefaultModal
            visibilityState={isHistoryModalOpen}
            closeModal={() => setIsHistoryModal(false)}
          >
            <h2 className='text-sec'>Request transaction History</h2>
            <form action='' onSubmit={handleHistorySubmit}>
              <Input
                input
                title='Enter Email Address'
                required={true}
                type='email'
                id='email'
                className='border-2 my-3'
              />
              <Btn text='Continue' className='w-full bg-pry' />
            </form>
          </DefaultModal>
          <CancelModal
            closeModal={() => setIsBlockModalOpen(false)}
            visibilityState={isBlockModalOpen}
            modalText='Block Card'
            modalAction={() => {
              toast.success("Card blocked successfully.");
              setIsBlockModalOpen(false);
            }}
          />
          <ErrorModal
            visibilityState={isUnblockModalOpen}
            closeModal={() => setIsUnblockModalOpen(false)}
          >
            <h2 className='my-2'>
              To unblock your card kindly reach out to our customers service via
              email info@eshopafrica.co
            </h2>
          </ErrorModal>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center flex-col">
          {/* <ToastContainer autoClose={3000} /> */}
          <h2 className='font-medium text-2xl'>
            Enter your unique 4-digit pin!
          </h2>
          <form
            className='flex flex-col justify-center items-center'
            onSubmit={(e) => {
              e.preventDefault();
              getDollarCard(OTP.join(""));
            }}
          >
            <div>
              <OtpCustomInput otp={OTP} setOtp={setOTP} />
            </div>
            <Btn
              text={"Proceed"}
              className='bg-pry my-6'
              loadingState={isPageLoading}
            />
          </form>
        </div>
      )}
    </>
  );
};

export default DollarCard;
