import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import Input from './Input';

const ReceiverDetails = ({
    receiverName,
    setReceiverName,
    receiverEmail,
    setReceiverEmail,
    receiverPhone,
    setReceiverPhone,
    receiverAddressLine1,
    setReceiverAddressLine1,
    receiverAddressLine2,
    setReceiverAddressLine2,
    receiverAddressLine3,
    setReceiverAddressLine3,
    receiverCountry,
    setReceiverCountry,
    receiverState,
    setReceiverState,
    receiverCity,
    setReceiverCity,
    receiverPostalAddress,
    setReceiverPostalAddress,
    handleChangeStep,
}) => {
    const [searchParams] = useSearchParams();
    const shippingService = searchParams.get('service');

    const [countryid, setCountryid] = useState(0);
    const [countriesList, setCountriesList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);

    useEffect(() => {
        GetCountries().then((result) => {
            setCountriesList(result);
        });
        window.scrollTo(0, 0);
    }, []);

    const renderCountryInput = () => {
        if (shippingService === 'ups') {
            return (
                <Input 
                    label="Country"
                    type="text"
                    value={receiverCountry}
                    onChange={(e) => setReceiverCountry(e.target.value)}
                    placeholder="Enter country name in full"
                />
            );
        } else {
            return (
                <div className="flex flex-col gap-1.5">
                    <label htmlFor="country" className="text-[#282828]">
                        Country
                    </label>
                    <select
                        id="country"
                        defaultValue={""}
                        className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] p-3"
                        onChange={(e) => {
                            const country = countriesList[e.target.value];
                            setCountryid(country.id);
                            setReceiverCountry(country.iso2);
                            GetState(country.id).then((result) => {
                                setStateList(result);
                            });
                        }}
                    >
                        <option value="" disabled={true}>Select Country</option>
                        {countriesList.map((option, index) => (
                            <option key={index} value={index}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
            );
        }
    };

    return (
        <div className='flex flex-col gap-6 w-full'>
            <h3 className='text-[#282828] text-center font-[500] text-[24px]'>
                Receiver Details
            </h3>
            <div className='w-full'>
                <div className='w-full grid grid-cols-1 sm:grid-cols-2 gap-5'>
                    <Input
                        label="Full Name"
                        type="text"
                        value={receiverName}
                        onChange={(e) => setReceiverName(e.target.value)}
                        placeholder="John Doe"
                    />
                    <Input
                        label="Email"
                        type="email"
                        value={receiverEmail}
                        onChange={(e) => setReceiverEmail(e.target.value)}
                        placeholder="deborah@eshopafrica.co"
                    />
                    <Input
                        label="Phone"
                        type="tel"
                        value={receiverPhone}
                        onChange={(e) => setReceiverPhone(e.target.value)}
                        placeholder="+15133000396"
                    />
                    <Input
                        label="Address Line 1"
                        type="text"
                        value={receiverAddressLine1}
                        onChange={(e) => setReceiverAddressLine1(e.target.value)}
                        maxLength={45}
                    />
                    <Input
                        label="Address 2 (45 char)"
                        type="text"
                        value={receiverAddressLine2}
                        onChange={(e) => setReceiverAddressLine2(e.target.value)}
                        maxLength={45}
                    />
                    <Input
                        label="Address 3 (45 char)"
                        type="text"
                        value={receiverAddressLine3}
                        onChange={(e) => setReceiverAddressLine3(e.target.value)}
                        maxLength={45}
                    />
                    {renderCountryInput()}
                    <div className="flex flex-col gap-1.5">
                        <label htmlFor="state" className="text-[#282828]" placeholder='Minnesota'>
                            State
                        </label>
                        <input
                            id="state"
                            value={receiverState}
                            list="states"
                            className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] p-3"
                            onChange={(e) => {
                                setReceiverState(e.target.value);
                                const state = stateList.find(state => state.name === e.target.value);
                                console.log(state, "This is the chosen state edited");
                                if (state) {
                                    GetCity(countryid, state.id).then((result) => {
                                        setCityList(result);
                                    });
                                } else {
                                    setCityList([]);
                                }
                            }}
                        />
                        <datalist id="states">
                            {stateList.length < 1 ? 
                                <option disabled={true} value="">Select Country First</option> : 
                                <option value="" disabled={true}>Select State</option>
                            }
                            {stateList.map((option, index) => (
                                <option key={index} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </datalist>
                    </div>
                    <div className="flex flex-col gap-1.5">
                        <label htmlFor="city" className="text-[#282828]" placeholder='Fridley'>
                            City
                        </label>
                        <input
                            id="city"
                            value={receiverCity}
                            list="cities"
                            className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] p-3"
                            onChange={(e) => {
                                setReceiverCity(e.target.value);
                                const city = cityList.find(city => city.name === e.target.value);
                                console.log(city, "This is the chosen city edited");
                            }}
                        />
                        <datalist id="cities">
                            {cityList.length < 1 ? 
                                <option disabled={true} value="">Select State First</option> : 
                                <option value="" disabled={true}>Select City</option>
                            }
                            {cityList.map((option, index) => (
                                <option key={index} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </datalist>
                    </div>
                    <Input
                        label="Postal Address"
                        type="text"
                        value={receiverPostalAddress}
                        onChange={(e) => setReceiverPostalAddress(e.target.value)}
                        placeholder="Enter postal address"
                    />
                </div>
            </div>
        </div>
    );
};

export default ReceiverDetails;