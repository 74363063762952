import React from "react";
import ellipse2 from "../../Assets/images/ellipse2.png";
import bullet from "../../Assets/images/bullet.png";
import Calculator from "../../Pages/Dashboard/Calculator";
import CalculatorExport from "../../Pages/Dashboard/CalculatorExport";
const PricingHero = () => {
  return (
    <div className="bg-green-100 relative">
      <section className="max-width1">
        <div className="md:w-1/2 py-12 relative z-10">
          <h2 className="font-medium text-xl">Pricing</h2>
          <h2 className="font-bold my-3 text-3xl">
            Use our delivery calculator to get your shipment price.
          </h2>
          <p className="text-base">
            No hiding charges, what you see is what you will pay. we are happy
            to serve you at eshopAfrica.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-10">
          <CalculatorExport />
          <Calculator />
        </div>
      </section>
      <img
        src={ellipse2}
        className="absolute top-0 left-0 w-8 md:w-16"
        alt="ellipse"
      />
    </div>
  );
};

export default PricingHero;
