import React from "react";
import Btn from "../Button/btn";
import { useNavigate } from "react-router-dom";

const Experience = () => {
  const navigateTo = useNavigate();
  return (
    <div className='max-width1 my-10'>
      <h2 className='font-bold text-2xl max-w-lg'>
        Experience timely and, affordable shipping today!
      </h2>
      <Btn
        className='bg-pry font-medium my-6'
        text='Create Account'
        onClick={() => navigateTo("/signup")}
      />
    </div>
  );
};

export default Experience;
