import React from "react";

import { Link, useNavigate } from "react-router-dom";

import { AiOutlineSearch } from "react-icons/ai";

import { useState } from "react";

import { AnimationOnScroll } from "react-animation-on-scroll";

import Btn from "../Button/btn";
import hero from "../../Assets/images/hero.png";
const Hero = () => {
  const [trackingID, setTrackingID] = useState(null);
  const navigate = useNavigate();

  const track = (e) => {
    e.preventDefault();
    navigate(`track/${trackingID}`);
  };

  return (
    <section className='bg-grad'>
      <div className='max-width1'>
        <div className='grid grid-cols-1 lg:grid-cols-2 text-white items-center gap-4 py-10'>
          <div>
            <h5 className='text-[1rem] md:text-[2rem] font-medium leading-[2rem] md:leading-[3rem]'>
            Shop from your favorite stores in the US, UK, and China, and get your orders delivered to Nigeria or worldwide. 
            import and export your large heavy shipments to your preferred location from ₦500 per kg.
            </h5>
            <p className='text-lg md:text-xl my-3 font-medium'>
              Save up to 95% on international shipping and tax.
            </p>
            <div className='flex items-center gap-4'>
              <AnimationOnScroll
                animateIn='animate__pulse animate__infinite'
                initiallyVisible={true}
              >
                <Btn
                  text='Sign In'
                  className='bg-pry text-black'
                  onClick={() => navigate("/login")}
                />
              </AnimationOnScroll>
              <Link to='/how-to-ship' className='underline text-sm'>
                How to Ship
              </Link>
            </div>

            <div>
              <form action='' className='flex gap-4 my-10' onSubmit={track}>
                <div className='flex items-center gap-2 text-gray-800 w-full relative'>
                  <i className='text-xl left-4 absolute'>
                    <AiOutlineSearch />
                  </i>
                  <input
                    type='text'
                    required
                    className='bg-white p-4 w-full block px-8 pl-12 rounded-md'
                    placeholder='Enter Tracking ID'
                    onChange={(e) => setTrackingID(e.target.value)}
                  />
                </div>
                <Btn text='Track' className='bg-pry text-black' />
              </form>
            </div>
          </div>
          <div className='lg:p-12'>
            <img src={hero} className='w-full' alt='hero' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
