import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import DefaultModal from "../../Modals/defaultModal";
import Btn from "../../Button/btn";
import Input from "../../Form/Input";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { Spinner } from "react-activity";
import { ToastContainer, toast } from "react-toastify";

const Location = ({ item, getLocations }) => {
	const { delivery_location, Air: air, Sea: sea, id } = item || {};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [calcDetails, setCalcDetails] = useState({});
	const { adminToken } = useSelector((state) => state.admin);
	const [isLoading, setIsLoading] = useState(false);

	const handleInputChange = (e) => {
		setCalcDetails({ ...calcDetails, [e.target.name]: e.target.value });
	};

	const editType = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const url = `${baseUrl}admin/update-delivery-location/${id}`;
		try {
			const res = await axios.post(
				url,
				{ ...calcDetails, delivery_location },
				config(adminToken)
			);
			setIsModalOpen(false);
			setIsLoading(false);
			toast.success(res.data.message);
			getLocations();
		} catch (error) {
			setIsLoading(false);
			toast.error(
				`${error?.response?.request?.status} - ${error?.response?.data?.message}`
			);
		}
	};

	const deleteType = async () => {
		const url = `${baseUrl}admin/delete-delivery-location/${id}`;
		setIsLoading(true);
		try {
			const res = await axios.delete(url, config(adminToken));
			setIsLoading(false);
			getLocations();
			toast.success(res.data.message);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			toast.error(
				`${error?.response?.request?.status} - ${error?.response?.data?.message}`
			);
		}
	};

	// useEffect(() => {
	// 	setCalcDetails({ ...calcDetails, air_price: air, sea_price: sea });
	// }, []);

	useEffect(() => {
        setCalcDetails((prevCalcDetails) => ({
            ...prevCalcDetails,
            air_price: air,
            sea_price: sea,
        }));
    }, [air, sea]);

	return (
		<>
			<ToastContainer autoClose={3000} />
			<div className='grid gap-2 grid-cols-4 text-xs items-center font-medium'>
				<h2>{delivery_location}</h2>
				<h2>{air}</h2>
				<h2>{sea}</h2>
				{isLoading ? (
					<Spinner />
				) : (
					<div className='flex gap-4'>
						<AiOutlineDelete className='text-red-600' onClick={deleteType} />
						<AiOutlineEdit
							onClick={() => setIsModalOpen(true)}
							className='text-blue-600'
						/>
					</div>
				)}
			</div>
			<hr className='h-[1px] bg-gray-50 my-4' />

			<DefaultModal
				closeModal={() => setIsModalOpen(false)}
				visibilityState={isModalOpen}
			>
				<form action='' onSubmit={editType} className='flex flex-col  max-w-xl'>
					<Input
						input
						className='border bg-gray-100'
						title='Enter Delivery Type'
						id='delivery_type'
						value={delivery_location}
						readOnly={true}
					/>
					<Input
						input
						className='border'
						title='Shipping Cost'
						placeholder='Enter current rate'
						type='number'
						id='air_price'
						value={calcDetails?.air_price || ""}
						setItem={handleInputChange}
					/>
					<Input
						input
						className='border'
						title='Shipping Cost'
						placeholder='Enter current rate'
						type='number'
						id='sea_price'
						value={calcDetails?.sea_price || ""}
						setItem={handleInputChange}
					/>
					<Btn
						loadingState={isLoading}
						className='bg-sec2 text-white my-5 w-full'
						text='Update'
					/>
				</form>
			</DefaultModal>
		</>
	);
};

export default Location;
