const BookADelivery = (
        {
            choseToShip,
            setChoseToShip
        }
    ) => {
    return (
        <div className="flex flex-col">
            <div
                className={`flex flex-col gap-3 p-4 rounded-[10px] cursor-pointer
                    ${choseToShip ? "bg-[#008E56]" : "border-[#2828284d] bg-[#e4e4e480]"}
                `}
                onClick={() => setChoseToShip(prev => !prev)}
            >
                <h2
                    className={`text-[18px]
                        ${choseToShip ? "text-[#fff]" : "text-[#282828]"}
                    `}
                >
                    Book a Delivery
                </h2>
                <p
                    className={`${choseToShip ? "text-[#eee]" : "text-[#28282899]"}`}
                >
                    Send out parcel, locally or internationally
                </p>
            </div>
        </div>
    )
}

export default BookADelivery