import { useEffect, useState } from "react";
import Button from "./Button";

const AdditionalNoteModal = ({ setModalShowing, additionalNote, setAdditionalNote }) => {
    const [currentInputValue, setCurrentInputValue] = useState("");

    useEffect(() => {
        setCurrentInputValue(additionalNote);
    }, [additionalNote]);

    return (
        <div className="fixed h-screen w-full left-0 top-0 bg-[#33333354] backdrop-blur-sm flex justify-center items-center z-[50] p-5" onClick={() => setModalShowing(false)}>
            <div className="bg-white rounded-md p-5 flex flex-col justify-center items-center gap-4 w-full max-w-[550px]" onClick={(e) => e.stopPropagation()}>
                <h3 className="text-[#282828] text-[20px] font-semibold">
                    Additional Notes
                </h3>
                <p className="text-[#282828] font-[500]">
                    Give us additional Note about the shipment
                </p>
                <textarea
                    value={currentInputValue}
                    onChange={(e) => setCurrentInputValue(e.target.value)}
                    placeholder="Additional Note"
                    className="bg-transparent w-full rounded-[10px] border-[1px] border-[#ddd] px-3 py-2.5 mt-3"
                    rows="5"
                />
                <div className="w-full flex flex-row items-center justify-end gap-4 mt-3">
                    <Button
                        styles="bg-[#fff] border-[1px] border-[#499170]"
                        content="Cancel"
                        onClick={() => setModalShowing(false)}
                    />
                    <Button
                        styles="bg-[#008E56] text-white"
                        content="Save"
                        onClick={() => {
                            setAdditionalNote(currentInputValue);
                            setModalShowing(false);
                        }}
                        disabled={additionalNote === currentInputValue ? true : false}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdditionalNoteModal;