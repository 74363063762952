import { configureStore } from "@reduxjs/toolkit";

import { combineReducers } from "redux";

import { persistReducer, persistStore } from "redux-persist";

import thunk from "redux-thunk";

import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

import adminSlice from "./features/adminSlice";
import authReducer from "./features/authSlice";
import dashboardReducer from "./features/dashboardSlice";
import shipmentReducer from "./features/shipmentSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  dashboard: dashboardReducer,
  admin: adminSlice,
  shipment: shipmentReducer,
  
});

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
