import React from "react";
import del from "../../Assets/images/del2.png";
import bag from "../../Assets/images/bag2.png";
import location from "../../Assets/images/location.png";

const Ship = () => {
  const howToShip = [
    {
      img: location,
      title: "Import and export with ease",
      details:
        "For imports, get a valid US, UK, or China address when you ship with eShopAfrica. You can also send packages from Nigeria to over 200 countries worldwide with express or cargo shipping.",
    },
    {
      img: bag,
      title: "Access to monitor & track your shipments",
      details:
        "Track your shipment effortlessly from the warehouse to your doorstep, whether it’s an import or export. Plus, our customer service team is always available to assist with any inquiries..",
    },
    {
      img: del,
      title: "Doorstep delivery anywhere in the world ",
      details:
        "With eShopAfrica, your shipment is delivered doorstep, no matter where you are in the world.",
    },
  ];
  return (
    <div className='max-width1 my-10'>
      <h2 className='text-center p-3 font-bold text-2xl'>
        How to ship with eShopAfrica
      </h2>
      <div className='my-6 grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-5'>
        {howToShip.map(({ img, title, details }, ind) => {
          return (
            <div
              className='p-4 rounded-md shadow-sm flex flex-col items-center text-center'
              key={ind}
            >
              <div className='bg-sec w-12 h-12 rounded-full flex items-center justify-center'>
                <img src={img} alt={title} />
              </div>
              <h2 className='font-bold my-4'>{title}</h2>
              <p>{details}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Ship;
