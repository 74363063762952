import React from 'react'
import RegisterGoodsForm from "../../Components/Fulfilment/RegisterGoodsForm"

const RegisterGoodsFormPage = () => {
  return (
    <div>
      <RegisterGoodsForm/>
    </div>
  )
}

export default RegisterGoodsFormPage
