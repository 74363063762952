import React, { useState } from "react";

import ActiveCardUser from "./ActiveCardUser";
import Freeze from "./FreezeCard";
import TerminatedCard from "./TerminatedCard"


const VirtualDollarCard = () => {
  const [activeTab, setActiveTab] = useState("active");
  const renderContent = () => {
    switch (activeTab) {
      case "active":
        return <ActiveCardUser />;
      case "freeze":
        return <Freeze />;
        case "terminatedcard":
        return <TerminatedCard />;
      default:
        return <ActiveCardUser />;
    }
  };
  return (
    <div className="mx-auto p-4">
      <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "active"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("active")}
        >
          All Card User
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "freeze"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("freeze")}
        >
          Freeze
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "terminatedcard"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
              : ""
          }`}
          onClick={() => setActiveTab("terminatedcard")}
        >
          Terminated Card
        </button>
      </div>
      <div className="">{renderContent()}</div>
    </div>
  );
};

export default VirtualDollarCard;
