import React, { useState } from 'react';
import { FiCopy } from 'react-icons/fi'; 
import { useNavigate } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";

const CashDeposit = () => {
  const navigate = useNavigate();
  const [accountNumber] = useState('6532189076');
  const [bankName] = useState('9PSB');
  const [accountName] = useState('Adewale Adeniyi');

  const copyToClipboard = () => {
    navigator.clipboard.writeText(accountNumber);
    alert('Account number copied to clipboard');
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Cash Deposit Details',
          text: `Bank: ${bankName}\nAccount Number: ${accountNumber}\nAccount Name: ${accountName}`,
        });
        alert('Details shared successfully');
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <div className="w-full p-4">
       <IoArrowBackOutline onClick={goBackHandler} />
      <div className="text-center mb-8">
        <h1 className="text-2xl font-semibold">Cash Deposit</h1>
        <p className="mt-2 text-gray-600">
          Please ensure that you only send money to the account number provided below
        </p>
      </div>
      <div className="w-full mb-6">
        <label className="block text-sm font-medium text-gray-700">Bank</label>
        <div className="mt-1">
          <input
            type="text"
            value={bankName}
            readOnly
            className="block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="w-full mb-6 relative">
        <label className="block text-sm font-medium text-gray-700">Account Number</label>
        <div className="mt-1 flex">
          <input
            type="text"
            value={accountNumber}
            readOnly
            className="block w-full p-2 border border-gray-300 rounded-md"
          />
          <button
            onClick={copyToClipboard}
            className="ml-2 p-2 border border-gray-300 rounded-md text-gray-500 hover:text-gray-700"
          >
            <FiCopy size={20} />
          </button>
        </div>
      </div>
      <div className="w-full mb-6">
        <label className="block text-sm font-medium text-gray-700">Account Name</label>
        <div className="mt-1">
          <input
            type="text"
            value={accountName}
            readOnly
            className="block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
      </div>
      <div className="w-full flex justify-center">
        <button  className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24" onClick={handleShare}>
          Share
        </button>
      </div>
    </div>
  );
};

export default CashDeposit;

