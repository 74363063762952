import React from 'react'
import NgnWithdraw from '../../Components/Dashboard/Wallet/Withdraw/NgnWithdraw'

const NgnWithdrawPage = () => {
  return (
    <div>
      <NgnWithdraw/>
    </div>
  )
}

export default NgnWithdrawPage
