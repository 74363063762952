import React from "react";
import PartnerHero from "../../Components/Partner/hero";
import { useEffect } from "react";
import Ship from "../../Components/Partner/ship";
import PartnerWithUs from "../../Components/Partner/partner";
import Testimonial from "../../Components/Home/testimonial";
import Sponsors from "../../Components/Partner/sponsors";
// import Rate from "../../Components/Home/rate";
import Experience from "../../Components/Partner/experience";

const Partner = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <PartnerHero />
      <Ship />
      <PartnerWithUs />
      <Testimonial />
      <Sponsors />
      {/* <Rate /> */}
      <Experience />
    </div>
  );
};

export default Partner;
