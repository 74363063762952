import  { useState } from 'react';
import VirtualCard from "../../../Assets/images/VirtualCard.png"; 
import Btn from "../../Button/btn";
import CardType from "./CardType"

const GenerateCard = () => {
  const [currentSlide, setCurrentSlide] = useState('generate');

  const handleGetStarted = () => {
    setCurrentSlide('pin');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-scree bg-whit p-4">
      {currentSlide === 'generate' ? (
        <>
          <div className="relative mb-8">
            <img
              src={VirtualCard}
              alt="Visa Card"
              className="w-80 h-auto relative z-10"
            />
            {/* <img
              src={VirtualCard}
              alt="Mastercard"
              className="w-80 h-auto absolute top-5 left-5 opacity-50"
            /> */}
          </div>
          <div className="text-center mb-8">
            <h2 className="text-lg font-bold">USD cards now Available</h2>
            <p className="text-sm text-gray-600 mt-2">
              Make payments much easier by using eshop cards.
              <br />
              Enjoy seamless payments and more.
            </p>
          </div>
          <Btn
            text={"Get Started"}
            className={"bg-[#008E56] text-white px-6 py-3 rounded-lg md:px-240 lg:px-24"}
            onClick={handleGetStarted}
          />
        </>
      ) : (
        <CardType />
      )}
    </div>
  );
};

export default GenerateCard;














// Do not delete for mow
// import React from "react";
// import Btn from "../../Button/btn";
// import Input from "../../Form/Input";
// const Generate = ({ handleInputChange, isLoading, verifyBVN }) => {
//   return (
//     <div>
//       <h2 className='font-medium text-2xl'>Generate Virtual Dollar Card</h2>
//       <p className='text-base my-2'>
//         Kindly fill out the informations correctly.
//       </p>
//       <form onSubmit={verifyBVN}>
//         <Input
//           title='BVN'
//           id='bvn'
//           type='number'
//           input
//           required={true}
//           className='border-2'
//           minLength={11}
//           setItem={handleInputChange}
//         />
//         <Input
//           title='Date of Birth'
//           id='dob'
//           type='date'
//           input
//           required={true}
//           className='border-2'
//           setItem={handleInputChange}
//         />
//         <Btn loadingState={isLoading} text={"Generate"} className='bg-pry' />
//       </form>
//     </div>
//   );
// };

// export default Generate;
