import React, { useState } from "react";

import * as XLSX from 'xlsx';

import TanTable from "../../../Utils/TanTable";
import { FormatCurrency } from "../../../Utils/currency";

const NgnZone = () => {
  const [uploadedPrices, setUploadedPrices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
    { header: "KG", accessorKey: "kg" },
    {
      header: "Zone 1",
      accessorKey: "zone1",
      cell: ({ row }) => FormatCurrency(row.original.zone1),
    },
    {
      header: "Zone 2",
      accessorKey: "zone2",
      cell: ({ row }) => FormatCurrency(row.original.zone2),
    },
    {
      header: "Zone 3",
      accessorKey: "zone3",
      cell: ({ row }) => FormatCurrency(row.original.zone3),
    },
  ];

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setIsLoading(true);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const workbook = XLSX.read(event.target.result, { type: 'binary' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);

          // Transform data to match expected format
          const formattedData = data.map(row => ({
            kg: row.KG,
            zone1: row['Zone 1'],
            zone2: row['Zone 2'],
            zone3: row['Zone 3']
          }));

          setUploadedPrices(formattedData);
        } catch (error) {
          console.error("Error parsing Excel file:", error);
          alert("Failed to upload file. Please check the file format.");
        } finally {
          setIsLoading(false);
        }
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <div className='w-full'>
      <div className='mb-4'>
        <label 
          htmlFor="excel-upload" 
          className='block w-full p-4 border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-100'
        >
         Upload Excel File For Domestic Price
          <input 
            type="file" 
            id="excel-upload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className='hidden'
          />
        </label>
      </div>

      <h1 className='text-center mb-4'>
        {uploadedPrices.length > 0 
          ? "Uploaded Price List" 
          : "Below are the price list belonging to different states in Nigeria based on their zones"}
      </h1>

      <TanTable 
        columnData={columns} 
        data={uploadedPrices} 
        loadingState={isLoading} 
      />
    </div>
  );
};

export default NgnZone;