import React, { useState, useEffect } from "react";

import { Spinner } from "react-activity";

import axios from "axios";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import { sortList } from "../../../Utils/sort";

const Terminate = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { adminToken } = useSelector((state) => state.admin);
  const [searchQuery, setSearchQuery] = useState("");
  const [error, setError] = useState(null);
  const [allCard, setAllCard] = useState([]);

  const formatDate = (date) => {
    if (!date) return "";
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric"
    }).format(new Date(date));
  };

  const handleActionChange = (cardId, action) => {
    console.log(`Card ID: ${cardId}, Action: ${action}`);
    // Implement the action logic here, such as making an API call to update the card action.
  };

  const columns = [
    { header: "Name", accessorKey: "user.fullname" },
    { header: "Date", accessorKey: "user.created_at", formatter: formatDate },
    { header: "User Status", accessorKey: "user_status" },
    { header: "Balance", accessorKey: "dollar_card_details.balance" },
    {
      header: "Expiry Date",
      accessorKey: "dollar_card_details.expiry",
      formatter: formatDate
    },
    { header: "Status", accessorKey: "dollar_card_details.status" },
    { header: "Email", accessorKey: "user.email" },
    { header: "Card ID", accessorKey: "card_id" },
    // {
    //   header: "Action",
    //   accessorKey: "action",
    //   cell: ({ row }) => renderActionSelect(row.original.card_id)
    // }
  ];

  const getAllCard = async () => {
    setIsLoading(true);
    setError(null);

    const url = `${baseUrl}admin/get-dollar-cards?status=terminatedper_`;

    try {
      const { data } = await axios.get(url, config(adminToken));
      const enrichedData = data.data.map((card) => ({
        ...card,
        user: {
          ...card.user,
          fullname: `${card.user.firstname} ${card.user.lastname}`
        }
      }));
      const sortedData = sortList(enrichedData);
      setAllCard(sortedData);
      setFilteredData(sortedData);
    } catch (error) {
      console.error("Error fetching cards:", error);
      setError(error.response?.data?.message || "Failed to fetch cards");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllCard();
  }, []);

  const getNestedValue = (obj, path) => {
    return path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : ""),
        obj
      );
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query.trim()) {
      const lowerCaseQuery = query.toLowerCase();
      const filteredResults = allCard.filter((card) =>
        columns.some((col) =>
          getNestedValue(card, col.accessorKey)
            .toString()
            .toLowerCase()
            .includes(lowerCaseQuery)
        )
      );
      setFilteredData(filteredResults);
    } else {
      setFilteredData(allCard);
    }
  };

  const handleExport = () => {
    const headers = columns.map((col) => col.header).join(",");
    const rows = filteredData.map((row) =>
      columns
        .map((col) => `"${getNestedValue(row, col.accessorKey) || ""}"`)
        .join(",")
    );
    const csvContent = [headers, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `cards_${new Date().toISOString().split("T")[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const renderActionSelect = (cardId) => (
    <select
      onChange={(e) => handleActionChange(cardId, e.target.value)}
      defaultValue=""
      className="border px-2 py-1 rounded"
    >
      <option value="" disabled>
        Select Action
      </option>
      <option value="freeze">Freeze</option>
      <option value="unfreeze">Unfreeze</option>
      <option value="terminate">Terminate</option>
    </select>
  );

  const processedData = filteredData.map((card) => ({
    ...card,
    user: {
      ...card.user,
      created_at: formatDate(card.user.created_at)
    },
    dollar_card_details: {
      ...card.dollar_card_details,
      expiry: formatDate(card.dollar_card_details.expiry)
    }
  }));

  return (
    <div>
      <div className="flex justify-between gap-4 items-center bg-white p-4 rounded-md">
        <h2 className="font-bold">
          All freeze Cards
          <span className="text-gray-500 font-normal ml-2">
            ({filteredData.length})
          </span>
        </h2>

        <div className="flex items-center gap-4">
          <div className="hidden xl:block">
            <input
              type="text"
              placeholder="Search cards..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <button
            onClick={handleExport}
            className="bg-[#055429] text-white px-4 py-2 rounded hover:bg-[#044321] transition-colors"
          >
            Export CSV
          </button>
        </div>
      </div>

      {isLoading ? (
        <Spinner color="#055429" size={32} />
      ) : (
        <div className="pt-4">
        <TanTable columnData={columns} data={processedData} />
        </div>
      )}

      {error && <p className="text-red-500">Error: {error}</p>}
    </div>
  );
};

export default Terminate;
