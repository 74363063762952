import React, { useEffect } from "react";
import Help from "../../Components/Contact/help";
import Faq from "../../Components/Pricing/faq";
import PricingHero from "../../Components/Pricing/hero";
import PricingDetails from "../../Components/Pricing/pricingDetails";
// import ChinaPricing from "../../Components/Pricing/chinaPricing";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <PricingHero />
      {/* <ChinaPricing /> */}
      <PricingDetails />
      <Faq />
      <Help />
    </div>
  );
};

export default Pricing;
