import React from 'react'
import WithdrawUsdt from '../../Components/Dashboard/Wallet/Withdraw/withdrawUsdt'

const WithUsdtPage = () => {
  return (
    <div>
   <WithdrawUsdt/>
    </div>
  )
}

export default WithUsdtPage
