import React from "react";

import how from "../../Assets/images/how.png";
const How = () => {
  return (
    <div className="max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-10">
      <div>
        <h2 className="font-bold text-xl my-4">How to Ship</h2>
        <p className="text-sm">
          <strong> Step 1</strong>
          <br />
          Sign up with eShopAfrica Simply complete and submit the short online
          registration form. You can start sending or receiving, and taking
          advantage of frictionless dollar card creation right away, you can buy
          and ship from one country to another by registering with eShopAfrica.
          <br />
          <br />
          <strong>Step 2</strong>
          <br /> Ship goods from Nigeria to anywhere in the world and import
          from your favorite stores abroad with ease. Enjoy amazing deals from
          top online retailers like Amazon, Walmart, Nike, Apple, and more.
          eShopAfrica makes delivery to your country seamless, overcoming the
          challenges of purchasing from the US, China, Canada, or the UK and
          shipping internationally.
          <br />
          <br /> <strong>Step 3</strong>
          <br />
          <strong>IMPORT : </strong>
          After purchasing from your favorite international stores, log in to
          your eShopAfrica account to create an import and access a valid US,
          UK, or China address. Carefully fill out the required details to avoid
          errors and ensure we receive complete order information. At checkout,
          use any of the provided warehouse addresses as your shipping address
          and include your unique code in your name. Once your package arrives
          at our warehouse, we will receive and handle it on your behalf. You’ll
          be notified via your eShopAfrica account as soon as your order is
          received and processed. <br />
          <br />
          <strong>EXPORT : </strong>
          Ship from Nigeria to over 200 countries via Air or Sea with flexible
          options, including express and cargo shipping, tailored to fit your
          budget.
          <br />
          <br />
          <strong>Step 4</strong>
          <br />
          Once your shipment has been successfully created, please check your
          orders and payments to see if your shipment details are accurate. If
          the information is incorrect, you can cancel and recreate the
          shipment. You may be unable to cancel a shipment once it has been
          processed.
          <br /> You will also have access to all shipment information,
          including the tracking number, amount, and all other specifics about
          your shipment once it is processed. Please make payment as soon as the
          shipment is processed to prevent delays. <br />
          <br />
          <strong>Step 5</strong>
          <br />
          Track your imports and exports in real time until they arrive at your
          destination. Stay updated on your shipment status and manage
          everything seamlessly from your dashboard and the Orders & Payments
          tab.
        </p>
      </div>
      <div className="flex items-center justify-center my-10 md:my-0">
        <img className="md:w-2/3" src={how} alt="why" />
      </div>
    </div>
  );
};

export default How;
