
import VirtualCard from "../../../Assets/images/VirtualCard.png";
import Btn from "../../Button/btn";
import { useNavigate } from "react-router-dom";

const CreationProcess = () => {
    const navigate = useNavigate();
    const handleCard = () => {
        navigate('/dashboard/card');
      };
  return (
    <div className="flex flex-col items-center justify-center min-h-scree bg-whit p-4">
      <div className="relative mb-8">
        <img
          src={VirtualCard}
          alt="Visa Card"
          className="w-80 h-auto relative z-10"
        />
        {/* <img
          src={VirtualCard}
          alt="Mastercard"
          className="w-80 h-auto absolute top-5 left-5 opacity-50"
        /> */}
      </div>
      <div className="text-center mb-8">
        <h2 className="text-lg font-bold">Card Creation in Progress</h2>
        <p className="text-sm text-gray-600 mt-2">
          You will be notified in a minute once
          <br />
          your card is ready. Kindly check back.
        </p>
      </div>
      <Btn
        text={"OK"}
        className={
          "bg-[#008E56] text-white px-6 py-3 rounded-lg md:px-240 lg:px-24"
        }
         onClick={handleCard}
      />
    </div>
  );
};

export default CreationProcess;
