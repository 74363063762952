import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { IoMdArrowBack } from "react-icons/io";

import { baseUrl, config } from "../../../Utils/constants";

const ImportDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { adminToken } = useSelector((state) => state.admin);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const url = `${baseUrl}admin/single-shipment/${id}?shipment_type=import`;
      console.log("Fetching order details from URL:", url);
      setIsLoading(true);
      setError(null);

      try {
        const { data } = await axios.get(url, config(adminToken));
        console.log("Received data:", data);
        setOrder(data.data);
      } catch (error) {
        console.error("Error fetching order details:", error.response || error);
        setError(
          error.response?.data?.message || "Failed to fetch order details"
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (id) {
      fetchOrderDetails();
    }
  }, [id, adminToken]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-2xl mx-auto p-4 text-center">
        <div className="bg-red-50 text-red-800 p-4 rounded-lg">
          <h2 className="text-lg font-semibold mb-2">Error</h2>
          <p>{error}</p>
        </div>
        <button
          onClick={handleGoBack}
          className="mt-4 text-blue-600 hover:text-blue-800 flex items-center justify-center gap-2"
        >
          <IoMdArrowBack /> 
        </button>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="max-w-2xl mx-auto p-4 text-center">
        <div className="bg-yellow-50 text-yellow-800 p-4 rounded-lg">
          <h2 className="text-lg font-semibold">No Order Found</h2>
          <p>The requested shipment details could not be found.</p>
        </div>
        <button
          onClick={handleGoBack}
          className="mt-4 text-blue-600 hover:text-blue-800 flex items-center justify-center gap-2"
        >
          <IoMdArrowBack /> Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-4 font-sans">
      <header className="flex items-center mb-6">
        <button
          onClick={handleGoBack}
          className="mr-4 p-2 hover:bg-gray-100 rounded-full transition-colors"
          aria-label="Go back"
        >
          <IoMdArrowBack className="w-6 h-6" />
        </button>
        <h1 className="text-xl font-semibold">Shipment Details</h1>
      </header>

      <div className="lg:flex gap-6">
        <div className="flex-1">
          <div className="flex items-center mb-4">
            <span className="font-medium mr-2">Payment Status:</span>
            <span
              className={`px-2 py-1 rounded text-sm ${
                order?.payment_status === "paid"
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }`}
            >
              {order?.payment_status || "Not Available"}
            </span>
          </div>
          <InfoRow label="Delivery Address" value={order?.address} />
          <InfoRow label="Unique Code" value={order?.unique_code} />
          <InfoRow
            label="Date"
            value={new Date(order?.created_at).toLocaleDateString()}
          />
          <InfoRow label="Delivery Phone" value={order?.delivery_number} />
        </div>

        <div className="flex-1 mt-6 lg:mt-0">
          <div className="flex items-center mb-4">
            <span className="font-medium mr-2">Delivery Status:</span>
            <span
              className={`px-2 py-1 rounded text-sm ${
                order?.shipment_status === "processed"
                  ? "bg-yellow-100 text-yellow-800"
                  : "bg-blue-100 text-blue-800"
              }`}
            >
              {order?.shipment_status || "Not Available"}
            </span>
          </div>
          <InfoRow label="Email" value={order?.user.email} />
          <InfoRow label="Ship From" value={order?.country} />
          <InfoRow
            label="Quantity"
            value={order?.item_details?.[0]?.quantity}
          />
          {/* <InfoRow label="Category" value={order?.item_details?.category?.category_name} /> */}
          {order.item_details && order.item_details.length > 0 ? (
            order.item_details.map((item, index) => (
              <InfoRow
                key={index}
                label="Category"
                value={item.category?.category_name || "Not Available"}
              />
            ))
          ) : (
            <InfoRow label="Category" value="Not Available" />
          )}
          <InfoRow label="Details" value={order?.items} />
        </div>
      </div>
    </div>
  );
};

const InfoRow = ({ label, value }) => (
  <div className="mb-3">
    <span className="font-medium">{label}:</span> {value || "Not Available"}
  </div>
);

export default ImportDetails;
