import React, { useState, useEffect } from "react";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import axios from "axios";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import Btn from "../../../Components/Button/btn";
import Input from "../../../Components/Form/Input";

import EditModal from "./EditModal";

const Insurance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]); // State for table data
  const { adminToken } = useSelector((state) => state.admin);
  
  // Separate state for initial form
  const [initialFormData, setInitialFormData] = useState({
    insurance_name: "",
    insurance_coverage: "",
    cost: "",
  });

  // State for edit modal
  const [editData, setEditData] = useState({
    id: null,
    insurance_name: "",
    insurance_coverage: "",
    cost: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const columnsData = [
    {
      header: "Insurance Type",
      accessorKey: "insurance_name",
    },
    {
      header: "Insurance Amount",
      accessorKey: "cost",
    },
    {
      header: "Cover Amount",
      accessorKey: "insurance_coverage",
    },
    {
      header: "Action",
      accessorKey: "id",
      cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <AiOutlineEdit
            size={20}
            className="text-blue-500 cursor-pointer"
            onClick={() => handleEdit(row.original)}
          />
          <AiOutlineDelete
            size={20}
            className="text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.original.id)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (rowData) => {
    setEditData({
      id: rowData.id,
      insurance_name: rowData.insurance_name,
      insurance_coverage: rowData.insurance_coverage,
      cost: rowData.cost,
    });
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      const { data: response } = await axios.delete(
        `${baseUrl}admin/insurance/${id}`,
        config(adminToken)
      );
      toast.success(response?.message || "Insurance deleted successfully");
      setData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (error) {
      toast.error("Error deleting insurance entry");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createInsurancePrice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      insurance_name: initialFormData.insurance_name,
      insurance_coverage: initialFormData.insurance_coverage,
      cost: initialFormData.cost,
    };

    try {
      const { data: response } = await axios.post(
        `${baseUrl}admin/insurance`,
        payload,
        config(adminToken)
      );
      toast.success(response?.message);
      setData((prevData) => [
        { id: response?.data?.id, ...payload },
        ...prevData,
      ]);
      // Reset initial form data after submission
      setInitialFormData({
        insurance_name: "",
        insurance_coverage: "",
        cost: "",
      });
    } catch (error) {
      toast.error("Error creating insurance price");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInsurancePrices = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${baseUrl}admin/insurance`,
        config(adminToken)
      );
      setData(data?.data || []);
    } catch (error) {
      toast.error("Failed to fetch insurance prices");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInsurancePrices();
  }, []);

  const handleUpdateInsurance = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data } = await axios.put(
        `${baseUrl}admin/insurance/${editData.id}`,
        {
          insurance_name: editData.insurance_name,
          insurance_coverage: editData.insurance_coverage,
          cost: editData.cost,
        },
        config(adminToken)
      );
      toast.success(data?.message);
      setData((prevData) =>
        prevData.map((item) =>
          item.id === editData.id ? { ...editData } : item
        )
      );
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Error updating insurance entry");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-4">
      <ToastContainer autoClose={3000} />
      <h2 className="font-medium text-lg mb-4">Insurance</h2>

      <form onSubmit={createInsurancePrice} className="bg-gray-100 rounded-md p-4">
        <div className="mb-4">
          <label htmlFor="insuranceName" className="block mb-2 text-sm font-medium">
            Insurance Type
          </label>
          <Input
            input
            id="insuranceName"
            className="border p-2 w-full"
            value={initialFormData.insurance_name}
            setItem={(e) =>
              setInitialFormData({ ...initialFormData, insurance_name: e.target.value })
            }
            placeholder="Basic Premium Extended"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="cost" className="block mb-2 text-sm font-medium">
            Insurance Amount
          </label>
          <Input
            input
            id="cost"
            type="number"
            className="border p-2 w-full"
            value={initialFormData.cost}
            setItem={(e) =>
              setInitialFormData({ ...initialFormData, cost: e.target.value })
            }
            placeholder="Enter Insurance Amount"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="insuranceCoverage" className="block mb-2 text-sm font-medium">
            Insurance Cover
          </label>
          <Input
            input
            id="insuranceCoverage"
            className="border p-2 w-full"
            value={initialFormData.insurance_coverage}
            setItem={(e) =>
              setInitialFormData({ ...initialFormData, insurance_coverage: e.target.value })
            }
            placeholder="Enter Insurance Coverage"
          />
        </div>

        <Btn
          type="submit"
          loadingState={isLoading}
          className="bg-sec2 text-white w-full"
          text="Submit"
        />
      </form>

      <div className="py-3">
        <h2 className="font-medium text-lg mb-4 text-center">Insurance Table</h2>
        <TanTable columnData={columnsData} data={data} />
      </div>

      <EditModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Edit Insurance"
      >
        <form onSubmit={handleUpdateInsurance}>
          <div className="mb-4">
            <label htmlFor="editInsuranceName" className="block mb-2 text-sm font-medium">
              Insurance Type
            </label>
            <Input
              input
              id="editInsuranceName"
              className="border p-2 w-full"
              value={editData.insurance_name}
              setItem={(e) =>
                setEditData({ ...editData, insurance_name: e.target.value })
              }
              placeholder="Basic Premium Extended"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="editCost" className="block mb-2 text-sm font-medium">
              Insurance Amount
            </label>
            <Input
              input
              id="editCost"
              type="number"
              className="border p-2 w-full"
              value={editData.cost}
              setItem={(e) =>
                setEditData({ ...editData, cost: e.target.value })
              }
              placeholder="Enter Insurance Amount"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="editInsuranceCoverage"
              className="block mb-2 text-sm font-medium"
            >
              Insurance Cover
            </label>
            <Input
              input
              id="editInsuranceCoverage"
              className="border p-2 w-full"
              value={editData.insurance_coverage}
              setItem={(e) =>
                setEditData({ ...editData, insurance_coverage: e.target.value })
              }
              placeholder="Enter Insurance Coverage"
            />
          </div>

          <Btn
            loadingState={isLoading}
            text="Update Insurance"
            className="bg-sec2 text-white w-full"
          />
        </form>
      </EditModal>
    </div>
  );
};

export default Insurance;