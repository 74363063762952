import React from "react";
import logo from "../../Assets/images/logo.png";
import Input from "../../Components/Form/Input";
import { Link, useNavigate } from "react-router-dom";
import Btn from "../../Components/Button/btn";
import { baseUrl, config } from "../../Utils/constants";
import axios from "axios";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
	setAdminProfile,
	setAdminToken,
} from "../../Redux/features/adminSlice";
import { useDispatch } from "react-redux";

const AdminLogin = () => {
	const navigateTo = useNavigate();
	const [isLoading, setisLoading] = useState(false);
	const [loginData, setLoginData] = useState({});
	const dispatch = useDispatch();

	const login = async () => {
		const url = `${baseUrl}admin/login`;
		setisLoading(true);
		try {
			const { data } = await axios.post(url, loginData);

			setisLoading(false);
			dispatch(setAdminToken(data.token));
			dispatch(setAdminProfile(data.data));
			navigateTo("/secretroute/admin");
		} catch (error) {
			setisLoading(false);
			toast.error(error?.response?.data?.message);
		}
	};

	const handleInputs = (e) => {
		setLoginData({ ...loginData, [e.target.name]: e.target.value });
	};

	return (
		<div className='min-h-screen justify-center items-center flex flex-col bg-pry2 p-3'>
			<ToastContainer autoClose={3000} />
			<div className='bg-white justify-center items-center flex flex-col p-8 max-w-sm w-full shadow-md rounded-md'>
				<img src={logo} className='w-20' alt='' />
				<h2 className='font-medium text-xl'>Login to your Account</h2>
				<form
					action=''
					className='w-full my-5'
					onSubmit={(e) => {
						e.preventDefault();
						login();
					}}
				>
					<Input
						id='email'
						title='Email Address'
						className='border'
						input
						type='email'
						setItem={handleInputs}
					/>
					<Input
						id='password'
						title='Password'
						className='border'
						input
						type='password'
						setItem={handleInputs}
					/>
					<Link
						to=''
						className='text-sec2 font-medium text-xs italic block text-right'
					>
						Forgot Password
					</Link>
					<Btn
						className='bg-sec2 text-white w-full mt-5'
						text='Login'
						loadingState={isLoading}
					/>
				</form>
			</div>
		</div>
	);
};

export default AdminLogin;
