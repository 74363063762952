import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import TanTable from "../../../Utils/TanTable";
import { baseUrl } from "../../../Utils/constants";

const Cargo = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchShipments = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}cargo-shipments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.data.success) {
        // Access the shipments array directly from the nested data structure
        setShipments(response.data.data.data);
      } else {
        setError('Failed to fetch shipments data');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch shipments');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShipments();
  }, [token]);

  const columns = [
    {
      header: "S/N",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Sender's Name",
      accessorKey: "sender_name",
    },
    {
      header: "Sender's Email",
      accessorKey: "sender_email",
    },
    {
      header: "Tracking Number",
      accessorKey: "tracking_number",
      cell: ({ row }) => row.original.tracking_number || "Not Assigned",
    },
    {
      header: "Sender's Phone",
      accessorKey: "sender_phone",
    },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ cell }) => {
        const rawDate = cell.getValue();
        if (!rawDate) return "N/A";
        return new Date(rawDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      },
    },
    {
      header: "Cargo Type",
      accessorKey: "cargo_type",
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: ({ row }) => {
        const status = row.original.status?.toLowerCase() || 'N/A';
        return status;
      },
    },
    {
      header: "Payment Status",
      accessorKey: "payment_status",
      cell: ({ row }) => {
        const paymentStatus = row.original.payment_status;
        return paymentStatus ? paymentStatus.replace('_', ' ') : 'N/A';
      },
    },
    {
      header: "Price",
      accessorKey: "price",
      cell: ({ row }) => {
        const price = row.original.price;
        return price ? `₦${Number(price).toLocaleString()}` : 'N/A';
      },
    },
    {
      header: "Action",
      cell: ({ row }) => (
        <button
          onClick={() => navigate(`/dashboard/singlecargo/${row.original.id}`)}
          className="text-green-600 italic underline select-none cursor-pointer"
          type="button"
        >
          Payment & More
        </button>
      ),
    },
  ];

  return (
    <div className="">
      <div className="space-y-4 mb-8">
        <h2 className="font-medium text-2xl text-center">Shipment Information</h2>
        <p className="text-base text-center">
          Kindly note that once shipment has been processed it can't be cancelled.
        </p>
        <h3 className="text-base font-medium text-center">Recent Orders</h3>
      </div>

      {error && (
        <div className="bg-red-50 text-red-800 p-4 rounded-md mb-4">
          {error}
        </div>
      )}

      {isLoading ? (
        <div className="text-center py-4">
          <Spinner className="mx-auto mb-2" />
        </div>
      ) : (
        <TanTable 
          data={shipments} 
          columnData={columns} 
          loading={isLoading}
        />
      )}
    </div>
  );
};

export default Cargo;