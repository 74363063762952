import React from "react";
import { BiExport } from "react-icons/bi";
import { mkConfig, generateCsv, download } from "export-to-csv";

const Export = ({ data, filename, title, headers }) => {
  // Include the headers as an array of strings
  const options = mkConfig({
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title,
    useTextFile: false,
    useBom: true,
    filename,
    headers, // Use the headers array here
  });

  const handleExport = () => {
    const csv = generateCsv(options)(data); // Generate CSV data
    download(options)(csv); // Trigger download
  };

  return (
    <button
      className='flex items-center gap-2 bg-[#055429] text-white rounded-md p-3 px-6 border border-white shadow-md hover:bg-green-600 transition hover:scale-95 text-sm'
      onClick={handleExport} // Trigger CSV generation and download
    >
      <BiExport className='text-xl' />
      Export
    </button>
  );
};

export default Export;