import React, { useState, useEffect } from "react";

import { FaMapMarkerAlt, FaEdit } from "react-icons/fa";

import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { IoMdArrowBack } from "react-icons/io";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { baseUrl, config, imageUrl } from "../../../Utils/constants";
import amazon from "../../../Assets/images/amazon.png";

import CargoEditModal from "./CargoEditModal";

const CargoOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { adminToken } = useSelector((state) => state.admin);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedOrder, setEditedOrder] = useState({});

  // Fetch single shipment data
  const fetchOrderDetails = async () => {
    const url = `${baseUrl}admin/single-shipment/${id}?shipment_type=cargo`;
    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.get(url, config(adminToken));
      if (response.data) {
        setOrder(response.data?.data);
        setEditedOrder(response.data?.data);
      } else {
        throw new Error("No data in response");
      }
    } catch (error) {
      setError(
        error.response?.data?.message ||
          error.message ||
          "Failed to fetch order details"
      );
      console.error("Fetch error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchOrderDetails();
    }
  }, [id, adminToken]);

  const handleEditSubmit = async (editedOrderData) => {
    try {
      const url = `${baseUrl}admin/update-cargo-shipments/${id}`;
      const payload = {
        receiver_name: editedOrderData.receiver_name,
        receiver_email: editedOrderData.receiver_email,
        receiver_phone: editedOrderData.receiver_phone,
        receiver_address: editedOrderData.receiver_address,
        receiver_country: editedOrderData.receiver_country,
        receiver_state: editedOrderData.receiver_state,
        receiver_city: editedOrderData.receiver_city,
        receiver_zipcode: editedOrderData.receiver_zipcode,
        category: editedOrderData.category,
        item_value: editedOrderData.item_value,
        item_description: editedOrderData.item_description,
        weight: editedOrderData.weight,
        price: editedOrderData.price,
        insurance_id: editedOrderData.insurance_id,
        cargo_type: editedOrderData.cargo_type,
        shipment_method: editedOrderData.shipment_method,
        sender_name: editedOrderData.sender_name,
        sender_phone: editedOrderData.sender_phone,
        sender_address: editedOrderData.sender_address,
        sender_country: editedOrderData.sender_country,
        sender_state: editedOrderData.sender_state,
        sender_city: editedOrderData.sender_city,
      };

      const { data } = await axios.post(url, payload, config(adminToken));
      toast.success(data.message || "Shipment details updated successfully!");
      setOrder(editedOrderData);
      setIsEditModalOpen(false);
      fetchOrderDetails(); // Refresh the data after successful update
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to update shipment details.";
      toast.error(errorMessage);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-lg">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 bg-gray-50">
        <button
          onClick={handleGoBack}
          className="mt-4 text-black hover:text-blue-800 flex items-center justify-center gap-2"
        >
          <IoMdArrowBack />
          Back
        </button>
        <div className="text-red-500 text-center mt-4">Error: {error}</div>
      </div>
    );
  }

  if (!order) return null;
  
  const shipmentImage = order.shipment_image
    ? `${imageUrl}${order.shipment_image}`
    : amazon;
  console.log("Shipment Image URL:", shipmentImage);

  return (
    <div className="p-6 bg-gray-50">
      <ToastContainer />
      <button
        onClick={handleGoBack}
        className="mt-4 text-black hover:text-blue-800 flex items-center justify-center gap-2"
      >
        <IoMdArrowBack />
        Back
      </button>
      <h2 className="text-2xl font-semibold mb-4">Shipment Details</h2>

      <div className="border border-gray-300 rounded-lg p-6 bg-white shadow-md">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 m-5">
          {/* Sender Details */}
          <div>
            <div className="flex items-center space-x-2 mb-2">
              <FaMapMarkerAlt className="text-gray-600" />
              <h3 className="text-lg font-medium">Sender Details</h3>
            </div>
            <h1 className="text-gray-700">Address</h1>
            <p className="text-gray-700">{order.sender_address}</p>
            <p className="text-gray-700">Name: {order.sender_name}</p>
            <p className="text-gray-700">Email: {order.sender_email}</p>
            <p className="text-gray-700">Phone: {order.sender_phone}</p>
            <p className="text-gray-700">Country: {order.sender_country}</p>
            <p className="text-gray-700">State: {order.sender_state}</p>
            <p className="text-gray-700">City: {order.sender_city}</p>
            <p className="text-gray-700">Postal Code: {order.sender_zipcode}</p>
          </div>

          {/* Receiver Details */}
          <div>
            <div className="flex items-center space-x-2 mb-2 justify-between">
              <div className="flex items-center space-x-2">
                <FaMapMarkerAlt className="text-gray-600" />
                <h3 className="text-lg font-medium">Receiver's Details</h3>
              </div>
              <button
                onClick={handleEditModalOpen}
                className="text-blue-600 hover:text-blue-800"
              >
                <FaEdit />
              </button>
            </div>
            <p className="text-gray-700">Name: {order.receiver_name}</p>
            <p className="text-gray-700">Email: {order.receiver_email}</p>
            <p className="text-gray-700">Address: {order.receiver_address}</p>
            <p className="text-gray-700">City: {order.receiver_city}</p>
            <p className="text-gray-700">State: {order.receiver_state}</p>
            <p className="text-gray-700">Country: {order.receiver_country}</p>
            <p className="text-gray-700">
              Postal Code: {order.receiver_zipcode}
            </p>
            <p className="text-gray-700">Phone: {order.receiver_phone}</p>
          </div>
        </div>

        {/* Item Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 m-5">
          <div>
            <h3 className="text-lg font-medium mb-2">Item Description</h3>
            <p className="text-gray-700">
              <span className="">Item Value:</span> {order.item_value}
            </p>
            <p className="text-gray-700">
              <span className="">Category:</span> {order.category}
            </p>
            <p className="text-gray-700">
              <span className="">Description:</span> {order.item_description}
            </p>
            <p className="text-gray-700">
              <span className="">Weight (KG):</span> {order.weight}
            </p>

            <p className="text-gray-700">
              <span className="">Tracking ID: </span>
              {order.tracking_number || "Not Assigned"}
            </p>

            <p>Shipment image</p>
            {order.shipment_image && (
              <a
                href={order.shipment_image}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={order.shipment_image}
                  alt="Shipment"
                  className="h-[5rem] w-auto object-cover rounded cursor-pointer"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = amazon;
                  }}
                />
              </a>
            )}
          </div>

          {/* Shipping Details */}
          <div>
            <div>
              <h3 className="text-lg mb-2">Shipping Option and Insurance</h3>
            </div>
            <p className="text-gray-700">
              <span className="">Shipment Type:</span> {order.shipment_type}
            </p>
            <p className="text-gray-700">
              <span className="">Insurance:</span> ₦{order.insurance}
            </p>
            <p className="text-gray-700">
              <span className="">Price:</span> ₦{order.price}
            </p>
            <p className="text-gray-700">
              <span className="">Payment Status:</span> {order.payment_status}
            </p>
            <p className="text-gray-700">
              <span className="">Shipment Status:</span> {order.status}
            </p>
            <p className="text-gray-700">
              <span className="">Pickup Date:</span>{" "}
              {order.pickup_date || "N/A"}
            </p>
            <p className="text-gray-700">
              <span className="">Pickup Area:</span> {order.pickup_area}
            </p>
            <p className="text-gray-700">
              <span className="">Additional Note:</span> {order.additional_note}
            </p>
            {order.tracking_number && (
              <p className="text-gray-700">
                <span className="">Tracking Number:</span>{" "}
                {order.tracking_number}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Edit Modal */}
      {isEditModalOpen && (
        <CargoEditModal
          isOpen={isEditModalOpen}
          onClose={handleEditModalClose}
          orderData={editedOrder}
          onSubmit={handleEditSubmit}
        />
      )}
    </div>
  );
};

export default CargoOrderDetails;
