
import React from "react";
import { useEffect, useState } from "react";

import axios from "axios";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";

import { baseUrl } from "../../Utils/constants";
import OrderBase from "../../Components/Dashboard/OrderInfo/base";
import ShipmentDetails from "../../Components/Dashboard/OrderInfo/shipmentDetails";

const OrderInfo = () => {
	const [activeSlide, setActiveSlide] = useState(0);
	const { token } = useSelector((state) => state.auth);
	const [allOrders, setAllOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [singleOrder, setSingleOrder] = useState({});
	const [cancelLoader, setCancelLoader] = useState(false);

	// shipping related data
	const [allShipping, setAllShipping] = useState([])

	const [walletDetails, setWalletDetails] = useState()

	const config = {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	};

	const getWallet = async () => {
		setIsLoading(true);
		const url = `${baseUrl}wallet`;
		try {
			const res = await axios.get(url, config);
			const data = res.data.data;
			//console.log(data, "This is the wallet details")
			setWalletDetails(data)
			setIsLoading(false);
		} catch (error) {
			//console.log(error);
			setIsLoading(false);
		}
	};

	const getAllOrders = async () => {
		setIsLoading(true);
		const url = `${baseUrl}get-orders`;
		const url2 = `${baseUrl}express-shipments`
		try {
			const { data } = await axios.get(url, config);
			setAllOrders(data.data);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			//console.log(error);
		}
	};

	const handleFetchExpressData = async ()  => {
		setIsLoading(true)
		const response = await axios.get(`${baseUrl}express-shipments`, {
		  headers: {
			Authorization: `Bearer ${token}`
		  }
		})
		//console.log(response.data.data.data.data, "This is the response from fetching the express shipments")
		const originalData = response.data.data.data.data.map((data) => {
		  return {
			id: data?.id,
			sender: data?.sender_name,
			receiver: data?.receiver_name,
			trackingId: data?.tracking_number || "no tracking no.",
			amount: data?.total_amount_to_pay,
			pickup: `${data?.sender_state} ${data?.sender_country}`,
			delivery: `${data?.receiver_state} ${data?.receiver_country}`,
			estimatedDelivery: data?.shipment_type.toLowerCase() === "dhl" ? "3-5 Working Days after Payment" : "7-10 Working Days after Payment",
			shipmentType: data.shipment_type,
			deliveryStatus: data?.shipment_status ||  "pending",
			paymentStatus: data?.payment_status.toLowerCase() === "paid" ? "Paid" : "Pay Now",
		  }
		})
		//console.log(originalData, "This is the data to be used by the component")
		setAllShipping(originalData)
		setIsLoading(false)
	  }

	useEffect(() => {
		getWallet()
		getAllOrders();
		handleFetchExpressData()
	}, [activeSlide]);

	const getSingleOrder = async (id) => {
		setActiveSlide(1);
		setIsLoading(true);
		const url = `${baseUrl}get-single-order/${id}`;
		try {
			const { data } = await axios.get(url, config);
			setSingleOrder(data.data);
			setIsLoading(false);
		} catch (error) {
			//console.log(error);
			setIsLoading(false);
		}
	};
	const cancelShipment = async (id) => {
		setCancelLoader(true);
		const url = `${baseUrl}cancel-shipment/${id}`;
		try {
			const res = await axios.post(url, {}, config);
			setCancelLoader(false);
			toast.success(res.data.message);
			setTimeout(() => {
				setActiveSlide(0);
			}, 2000);
		} catch (error) {
			setCancelLoader(false);
			toast.error(error?.response?.data?.message);
		}
	};

	return (
		<div>
			{activeSlide === 0 ? (
				<OrderBase
					allOrders={allOrders}
					allShipping={allShipping}
					isLoading={isLoading}
					getSingleOrder={getSingleOrder}
					walletDetails={walletDetails}
				/>
			) : (
				<ShipmentDetails
					isLoading={isLoading}
					setActiveSlide={setActiveSlide}
					singleOrder={singleOrder}
					cancelShipment={cancelShipment}
					cancelLoader={cancelLoader}
				/>
			)}
		</div>
	);
};

export default OrderInfo;

// Add view button on express shipments
// Add draft too to the express shipment card
