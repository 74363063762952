import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Btn from "../../Button/btn";
import Input from "../../Form/Input";
import DefaultModal from "../../Modals/defaultModal";
import { baseUrl, config } from "../../../Utils/constants";

const Order = ({ item, getOrders, isModalOpen, setIsModalOpen }) => {
    const {
        address,
        amount,
        country,
        dimension,
        id,
        shipment_status,
        tracking_number,
        user,
    } = item || {};
    const { fullname } = user || {};
    const { adminToken } = useSelector((state) => state.admin);

    const [isLoading, setIsLoading] = useState(false);
    const [orderData, setOrderData] = useState({});

    const handleInputChange = (e) => {
        setOrderData({ ...orderData, [e.target.name]: e.target.value });
    };

    const updateOrder = async () => {
        setIsLoading(true);
        const url = `${baseUrl}admin/update-shipments/${id}`;
        try {
            const res = await axios.post(url, orderData, config(adminToken));
            getOrders();
            setIsLoading(false);
            setIsModalOpen(false);
            toast.success(res.data.message);
        } catch (error) {
            setIsLoading(false);
            toast.error(
                `${error?.response?.request?.status} - ${error?.response?.data?.message}`
            );
        }
    };

    useEffect(() => {
        setOrderData({
            dimension,
            delivery_price: amount,
            delivery_status: shipment_status,
            address,
            country,
            tracking_number,
        });
    }, [dimension, amount, shipment_status, address, country, tracking_number]); // Add all dependencies

    return (
        <>
            <ToastContainer autoClose={3000} />
            <DefaultModal
                closeModal={() => setIsModalOpen(false)}
                visibilityState={isModalOpen}
            >
                <div className="bg-white p-4 max-h-[80vh] overflow-y-auto">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="font-bold text-lg">Edit Order</h2>
                    </div>
                    <div className="grid grid-cols-2 gap-2 mb-4 text-sm">
                        <div>
                            <span className="font-medium">Name:</span> {fullname}
                        </div>
                        <div>
                            <span className="font-medium">Tracking ID:</span> {tracking_number}
                        </div>
                        <div>
                            <span className="font-medium">Delivery Address:</span> {address}
                        </div>
                        <div>
                            <span className="font-medium">Country:</span> {country}
                        </div>
                    </div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            updateOrder();
                        }}
                        className="flex flex-col gap-3"
                    >
                        <Input
                            input
                            className="border text-sm"
                            title="Dimension"
                            value={orderData?.dimension || ""}
                            id="dimension"
                            setItem={handleInputChange}
                        />
                        <Input
                            input
                            className="border text-sm"
                            title="Amount"
                            value={orderData?.delivery_price || ""}
                            id="delivery_price"
                            setItem={handleInputChange}
                        />
                        <Input
                            dropdown
                            className="border capitalize text-sm"
                            title="Delivery Status"
                            id="delivery_status"
                            data={["processed", "successful", "pending", "cancelled"]}
                            value={orderData?.delivery_status || ""}
                            setItem={handleInputChange}
                        />
                        <div className="flex justify-end gap-2 mt-2">
                            <Btn
                                type="button"
                                className="bg-gray-200 text-gray-800"
                                text="Cancel"
                                onClick={() => setIsModalOpen(false)}
                            />
                            <Btn
                                loadingState={isLoading}
                                className="bg-sec2 text-white"
                                text="Save Changes"
                            />
                        </div>
                    </form>
                </div>
            </DefaultModal>
        </>
    );
};

export default Order;