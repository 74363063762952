import { useEffect, useState } from "react";
import ChooseToShip from "./ChooseToShip";
import ChooseShipmentMethod from "./ChooseShipmentMethod";
import SenderDetails from "./SenderDetails";
import ReceiverDetails from "./ReceiverDetails";
import ItemDescription from "./ItemDescription";
import DeliveryOption from "./DeliveryOption";
import OrderSummary from "./OrderSummary";
import { toast } from "react-toastify";
import { checkStepTransitionValidity } from "../../../../Utils/checkStepTransionValidity";
import Button from "./Button";
import { baseUrl } from "../../../../Utils/constants";
import axios from "axios";
import { Spinner } from "react-activity";
import { useSelector } from "react-redux";
import Payment from "./Payment";
import StepsHeader from "./StepsHeader";
import { convertCurrencyToNaira } from "../../../../Utils/currency";
import { useNavigate } from "react-router-dom";

const BookShipmentStep = ({ service }) => {
  const navigate = useNavigate();

  const [shipmentId, setShipmentId] = useState();

  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [validSteps, setValidSteps] = useState([6]);
  const [userSavedAddresses, setUserSavedAdresses] = useState([]);
  const [saveAddress, setSaveAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState();
  const [walletDetails, setWalletDetails] = useState(null);
  // Choose To Ship
  const [choseToShip, setChoseToShip] = useState(false);

  useEffect(() => {
    const checkStepZeroValidity = () => {
      if (choseToShip && !validSteps.includes(0)) {
        setValidSteps((prev) => [...prev, 0]);
      } else if (!choseToShip && validSteps.includes(0)) {
        setValidSteps((prev) => prev.filter((step) => step !== 0));
      }
    };
    checkStepZeroValidity();
  }, [choseToShip]);

  // Shipment Method
  const [shipmentMethod, setShipmentMethod] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [pickupArea, setPickupArea] = useState("");
  const [pickupPrice, setPickupPrice] = useState("0");
  const [areaOptions, setAreaOptions] = useState([]);

  useEffect(() => {
    if (pickupArea && pickupArea !== "") {
      setPickupPrice(
        areaOptions.find((option) => option.id == pickupArea)?.price
      );
    } else {
      setPickupPrice("0");
    }
  }, [pickupArea]);

  // Order Summary
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const handleFetchStaticOptions = async () => {
      setLoading(false);
      const response = await axios.get(`${baseUrl}delivery-prices`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAreaOptions(response.data.data.data);
      setLoading(false);
    };
    handleFetchStaticOptions();
  }, []);

  useEffect(() => {
    const checkStepOneValidity = () => {
      if (shipmentMethod === "drop_off" && !validSteps.includes(1)) {
        setValidSteps((prev) => [...prev, 1]);
        setPickupPrice("0");
      } else if (
        shipmentMethod === "pickup" &&
        pickupDate.length === 10 &&
        pickupArea !== "" &&
        pickupDate >= new Date().toISOString().split("T")[0] &&
        !validSteps.includes(1)
      ) {
        setValidSteps((prev) => [...prev, 1]);
        // setPickupPrice(areaOptions.find(option => option.id == pickupArea)?.price)
      } else if (
        shipmentMethod === "pickup" &&
        (pickupDate.length < 10 ||
          pickupArea === "" ||
          pickupDate < new Date().toISOString().split("T")[0]) &&
        validSteps.includes(1)
      ) {
        setValidSteps((prev) => prev.filter((step) => step !== 1));
      }
    };
    checkStepOneValidity();
  }, [shipmentMethod, pickupDate, pickupArea]);

  // Sender Details
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [postalAddress, setPostalAddress] = useState("");

  useEffect(() => {
    const checkStepTwoValidity = () => {
      const fields = [
        name,
        email,
        phone,
        addressLine1,
        country,
        state,
        city,
        postalAddress,
      ];

      // Check if all fields are non-empty strings
      const allFieldsFilled = fields.every((field) => field.trim() !== "");

      if (allFieldsFilled && !validSteps.includes(2)) {
        setValidSteps((prev) => [...prev, 2]);
      } else if (!allFieldsFilled && validSteps.includes(2)) {
        setValidSteps((prev) => prev.filter((step) => step !== 2));
      }
    };

    checkStepTwoValidity();
  }, [
    name,
    email,
    phone,
    addressLine1,
    country,
    state,
    city,
    postalAddress,
    validSteps,
  ]);

  const handleFetchAddress = async () => {
    console.log("Fetch address called");
    const allAddresses = await axios.get(`${baseUrl}get-user-address`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(allAddresses.data.data, "These are the addresses");
    setUserSavedAdresses(allAddresses.data.data || []);
  };

  useEffect(() => {
    handleFetchAddress();
  }, []);

  const handleSaveAddress = () => {};

  useEffect(() => {
    const handleSelectedAddressChange = () => {
      if (selectedAddress === null) {
        console.log(`No selected address yet`);
      }
      console.log("This is the new selected address", selectedAddress);
      const address = userSavedAddresses.find(
        (address) => address.id === selectedAddress
      );
      if (!address) return;
      setName(address?.full_name);
      setAddressLine1(address?.address);
      setAddressLine2(address?.address);
      setAddressLine3(address?.address);
      setCountry(address?.country);
      setState(address?.state);
      setCity(address?.city);
      setPostalAddress(address?.postal_code);
      setPhone(address?.phone_number);
      setEmail(address?.email);
    };
    handleSelectedAddressChange();
  }, [selectedAddress]);

  // Receiver Details
  const [receiverName, setReceiverName] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [receiverPhone, setReceiverPhone] = useState("");
  const [receiverAddressLine1, setReceiverAddressLine1] = useState("");
  const [receiverAddressLine2, setReceiverAddressLine2] = useState("");
  const [receiverAddressLine3, setReceiverAddressLine3] = useState("");
  const [receiverCountry, setReceiverCountry] = useState("");
  const [receiverState, setReceiverState] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverPostalAddress, setReceiverPostalAddress] = useState("");

  useEffect(() => {
    const checkStepThreeValidity = () => {
      const fields = [
        receiverName,
        receiverEmail,
        receiverPhone,
        receiverAddressLine1,
        receiverCountry,
        receiverState,
        receiverCity,
        receiverPostalAddress,
      ];

      // Check if all fields are non-empty strings
      const allFieldsFilled = fields.every((field) => field.trim() !== "");

      if (allFieldsFilled && !validSteps.includes(3)) {
        setValidSteps((prev) => [...prev, 3]);
      } else if (!allFieldsFilled && validSteps.includes(3)) {
        setValidSteps((prev) => prev.filter((step) => step !== 3));
      }
    };

    checkStepThreeValidity();
  }, [
    receiverName,
    receiverEmail,
    receiverPhone,
    receiverAddressLine1,
    receiverCountry,
    receiverState,
    receiverCity,
    receiverPostalAddress,
    validSteps,
  ]);

  // Item Description
  const [items, setItems] = useState([
    {
      itemCategory: "",
      itemValue: "",
      itemDescription: "",
      useVolumetricWeight: true,
      quantity: "",
      weight: "",
      length: "",
      height: "",
      width: "",
    },
  ]);

  useEffect(() => {
    const checkStepFourValidity = () => {
      const allItemsValid = items.every((item) => {
        const {
          itemCategory,
          itemValue,
          itemDescription,
          useVolumetricWeight,
          quantity,
          weight,
          length,
          height,
          width,
        } = item;

        // Check if the common fields are filled
        const commonFieldsFilled =
          itemCategory.trim() !== "" &&
          itemValue.trim() !== "" &&
          itemDescription.trim() !== "";

        // Check fields for both normal weight and volumetric weight
        const weightFieldsFilled =
          quantity.trim() !== "" && weight.trim() !== "";
        const volumetricFieldsFilled = useVolumetricWeight
          ? length.trim() !== "" && height.trim() !== "" && width.trim() !== ""
          : true;

        return (
          commonFieldsFilled && weightFieldsFilled && volumetricFieldsFilled
        );
      });

      if (allItemsValid && !validSteps.includes(4)) {
        setValidSteps((prev) => [...prev, 4]);
      } else if (!allItemsValid && validSteps.includes(4)) {
        setValidSteps((prev) => prev.filter((step) => step !== 4));
      }
    };

    checkStepFourValidity();
  }, [items, validSteps]);

  // Delivery options
  const [insurance, setInsurance] = useState(null);
  const [price, setPrice] = useState("");

  useEffect(() => {
    const checkStepFiveValidity = () => {
      if (insurance !== null && !validSteps.includes(5)) {
        setValidSteps((prev) => [...prev, 5]);
      } else if (insurance === null && validSteps.includes(5)) {
        setValidSteps((prev) => prev.filter((step) => step !== 5));
      }
    };
    checkStepFiveValidity();
  }, [insurance]);

  const handleChangeStep = (step) => {
    if (currentStep === step || step < 0) {
      return;
    } else if (step < currentStep) {
      setCurrentStep(step);
    } else if (step > currentStep) {
      if (checkStepTransitionValidity(validSteps, step)) {
        setCurrentStep(step);
      } else {
        toast.info("Complete this step first");
      }
    }
  };

  const handleSubmit = async () => {
    if (submitLoading) return;
    setSubmitLoading(true);
    const dataToBackend = {
      shipment_type: service,
      shipment_method: shipmentMethod,
      pickup_date: shipmentMethod === "drop_off" ? "" : pickupDate,
      pickup_area_id: pickupArea,
      sender_name: name,
      sender_email: email,
      sender_phone: phone,
      sender_address_1: addressLine1,
      sender_address_2: addressLine2,
      sender_address_3: addressLine3,
      sender_landmark: null,
      sender_area_address: null,
      sender_country: country,
      sender_state: state,
      sender_city: city,
      sender_postal_address: postalAddress,
      receiver_name: receiverName,
      receiver_email: receiverEmail,
      receiver_phone: receiverPhone,
      receiver_address_1: receiverAddressLine1,
      receiver_address_2: receiverAddressLine2,
      receiver_address_3: receiverAddressLine3,
      receiver_landmark: null,
      receiver_area_address: null,
      receiver_country: receiverCountry,
      receiver_state: receiverState,
      receiver_city: receiverCity,
      receiver_postal_address: receiverPostalAddress,
      item_category: items[0].itemCategory,
      item_value: Number(items[0].itemValue),
      item_description: items[0].itemDescription,
      quantity: Number(items[0].quantity),
      weight: Number(items[0].weight),
      length: Number(items[0].length),
      height: Number(items[0].height),
      width: Number(items[0].width),
      insurance_id: insurance.id,
      price: price,
      usd_value: 700,
      coupon_code: "",
      unit_of_measurement: "PCS",
      vat: 0.075,
    };
    console.log(dataToBackend, "This is the data going to the");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(
        `${baseUrl}express-shipments`,
        dataToBackend,
        config
      );

      console.log(response.data.data, "This is the response");
      toast.success(response.data.message);
      setShipmentId(response.data.data.data.id);
      setSubmitLoading(false);
      return true;
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setSubmitLoading(false);
      return false;
    }
  };

  // Summary related Data
  const [additionalNote, setAdditionalNote] = useState("");

  // Payment related data
  const [paymentMethod, setPaymentMethod] = useState();

  useEffect(() => {
    const checkStepSevenValidity = () => {
      if (paymentMethod !== null && !validSteps.includes(7)) {
        setValidSteps((prev) => [...prev, 7]);
      } else if (paymentMethod === null && validSteps.includes(7)) {
        setValidSteps((prev) => prev.filter((step) => step !== 7));
      }
    };
    checkStepSevenValidity();
  }, [paymentMethod]);

  const steps = [
    <ChooseToShip
      service={service}
      choseToShip={choseToShip}
      setChoseToShip={setChoseToShip}
    />,
    <ChooseShipmentMethod
      shipmentMethod={shipmentMethod}
      setShipmentMethod={setShipmentMethod}
      setPickupPrice={setPickupPrice}
      pickupArea={pickupArea}
      setPickupArea={setPickupArea}
      pickupDate={pickupDate}
      setPickupDate={setPickupDate}
      areaOptions={areaOptions}
    />,
    <SenderDetails
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      phone={phone}
      setPhone={setPhone}
      addressLine1={addressLine1}
      setAddressLine1={setAddressLine1}
      addressLine2={addressLine2}
      setAddressLine2={setAddressLine2}
      addressLine3={addressLine3}
      setAddressLine3={setAddressLine3}
      country={country}
      setCountry={setCountry}
      state={state}
      setState={setState}
      city={city}
      setCity={setCity}
      postalAddress={postalAddress}
      setPostalAddress={setPostalAddress}
      userSavedAddresses={userSavedAddresses}
      setSelectedAddress={setSelectedAddress}
      selectedAddress={selectedAddress}
    />,
    <ReceiverDetails
      receiverName={receiverName}
      setReceiverName={setReceiverName}
      receiverEmail={receiverEmail}
      setReceiverEmail={setReceiverEmail}
      receiverPhone={receiverPhone}
      setReceiverPhone={setReceiverPhone}
      receiverAddressLine1={receiverAddressLine1}
      setReceiverAddressLine1={setReceiverAddressLine1}
      receiverAddressLine2={receiverAddressLine2}
      setReceiverAddressLine2={setReceiverAddressLine2}
      receiverAddressLine3={receiverAddressLine3}
      setReceiverAddressLine3={setReceiverAddressLine3}
      receiverCountry={receiverCountry}
      setReceiverCountry={setReceiverCountry}
      receiverState={receiverState}
      setReceiverState={setReceiverState}
      receiverCity={receiverCity}
      setReceiverCity={setReceiverCity}
      receiverPostalAddress={receiverPostalAddress}
      setReceiverPostalAddress={setReceiverPostalAddress}
      handleChangeStep={handleChangeStep}
    />,
    <ItemDescription items={items} setItems={setItems} />,
    <DeliveryOption
      insurance={insurance}
      setInsurance={setInsurance}
      price={price}
      setPrice={setPrice}
      service={service}
      items={items}
      receiverAddressLine1={receiverAddressLine1}
      receiverAddressLine2={receiverAddressLine2}
      receiverAddressLine3={receiverAddressLine3}
      receiverCity={receiverCity}
      receiverState={receiverState}
      receiverCountry={receiverCountry}
      receiverPostalAddress={receiverPostalAddress}
      handleChangeStep={handleChangeStep}
    />,
    <OrderSummary
      handleChangeStep={handleChangeStep}
      name={name}
      addressLine1={addressLine1}
      city={city}
      state={state}
      country={country}
      email={email}
      phone={phone}
      receiverName={receiverName}
      receiverAddressLine1={receiverAddressLine1}
      receiverAddressLine2={receiverAddressLine2}
      receiverAddressLine3={receiverAddressLine3}
      receiverCity={receiverCity}
      receiverState={receiverState}
      receiverCountry={receiverCountry}
      receiverEmail={receiverEmail}
      receiverPhone={receiverPhone}
      price={price}
      setPrice={setPrice}
      pickupArea={pickupArea}
      areaOptions={areaOptions}
      pickupPrice={pickupPrice}
      receiverPostalAddress={receiverPostalAddress}
      insurance={insurance}
      shipmentMethod={shipmentMethod}
      items={items}
      service={service}
      additionalNote={additionalNote}
      setAdditionalNote={setAdditionalNote}
      submitLoading={submitLoading}
      setSubmitLoading={setSubmitLoading}
    />,
    <Payment
      pickupPrice={pickupPrice}
      insurance={insurance}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      balance={walletDetails?.wallet_balance}
    />,
  ];

  if (loading) {
    return (
      <div className="flex flex-row h-full w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <section
      className={`w-full flex flex-col justify-center items-center gap-8`}
    >
      {currentStep > 1 && (
        <StepsHeader
          currentStep={currentStep}
          handleChangeStep={handleChangeStep}
        />
      )}
      {steps[currentStep]}
      <div className="flex w-full flex-row items-center justify-center">
        <div className="w-full grid grid-cols-2 gap-10 max-w-[600px] mt-4">
          <Button
            styles="col-span-1 bg-[#fff] border-[1px] border-[#499170]"
            content="Go Back"
            onClick={() => handleChangeStep(currentStep - 1)}
          />
          <Button
            styles="col-span-1 bg-[#008E56] text-white"
            content={
              currentStep === 7
                ? `Pay ${convertCurrencyToNaira(
                    Number(price) * 0.075 +
                      Number(price) +
                      Number(insurance.cost) +
                      Number(pickupPrice)
                  )}`
                : "Continue"
            }
            isLoading={
              currentStep === 6 || currentStep === 7 ? submitLoading : false
            }
            onClick={async () => {
              if (currentStep < 6) {
                handleChangeStep(currentStep + 1);
              } else if (currentStep === 6) {
                const result = await handleSubmit();
                if (result) {
                  handleChangeStep(currentStep + 1);
                } else {
                  return;
                }
              } else if (currentStep === 7) {
                if (submitLoading) return;
                // Check if the wallet balance is sufficient when paying from wallet
                if (
                  paymentMethod === 0 &&
                  walletDetails?.wallet_balance <
                    Number(price) * 0.075 +
                      Number(price) +
                      Number(insurance.cost) +
                      Number(pickupPrice)
                ) {
                  toast.error(
                    "Insufficient wallet balance. Please choose another payment method."
                  );
                  return; // Stop the function execution
                }
                setSubmitLoading(true);
                try {
                  if (paymentMethod === 0) {
                    const response = await axios.post(
                      `${baseUrl}express-shipment-wallet-payment`,
                      { shipment_id: shipmentId },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    );
                    toast.success(
                      "You have successfully paid from your wallet."
                    );
                    setSubmitLoading(false);
                    navigate("/dashboard");
                  } else if (paymentMethod === 1) {
                    const callbackUrl = `${process.env.REACT_APP_BASE_URL}/confirm-shipment-payment?shipmentId=${shipmentId}`;
                    const response = await axios.post(
                      `${baseUrl}express-shipment-with-card`,
                      { shipment_id: shipmentId, callback_url: callbackUrl },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    );
                    const payLink =
                      response.data.data.data.data.authorization_url;
                    window.location.replace(payLink);
                    setSubmitLoading(false);
                  }
                } catch (error) {
                  toast.error(error.response.data.message);
                  setSubmitLoading(false);
                }
              }
            }}
            disabled={!validSteps.includes(currentStep)}
          />
        </div>
      </div>
    </section>
  );
};

export default BookShipmentStep;