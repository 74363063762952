import React, { useState } from "react";
import OrdersAndPayment from './OrdersAndPayment'
import ExpressOrderAndPayment from './ExpressOrderAndPayment'
import CargoOrderAndPayment from './CargoOrderAndPayment'

const ManageOrders = () => {
    const [activeTab, setActiveTab] = useState("ordersandpayment");
    const renderContent = () => {
      switch (activeTab) {
        case "ordersandpayment":
          return <OrdersAndPayment />;
        case "expressorderandpayment":
          return <ExpressOrderAndPayment />;
          case "cargoorderandpayment":  
          return <CargoOrderAndPayment />;
        default:
          return <OrdersAndPayment />;
      }
    };
    return (
        <div className="mx-auto p-4">
          <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2">
            <button
              className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
                activeTab === "ordersandpayment"
                  ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
                  : ""
              }`}
              onClick={() => setActiveTab("ordersandpayment")}
            >
             Import
            </button>
            <button
              className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
                activeTab === "expressorderandpayment"
                  ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
                  : ""
              }`}
              onClick={() => setActiveTab("expressorderandpayment")}
            >
             Express
            </button>
            <button
              className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
                activeTab === "cargoorderandpayment"
                  ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full w-1/2"
                  : ""
              }`}
              onClick={() => setActiveTab("cargoorderandpayment")}
            >
            Cargo
            </button>
          </div>
          <div className="">{renderContent()}</div>
        </div>
      );
}

export default ManageOrders
