import React from "react";
import { BiSearch } from "react-icons/bi";

const SearchComp = ({ searchTerm, setSearchTerm, setPageIndex }) => {
	return (
		<div className='lg:flex max-w-xl mx-auto justify-between my-3 relative'>
			<BiSearch size={20} className='absolute bottom-3 left-5' />
			<input
				type='text'
				placeholder='Search...'
				value={searchTerm}
				onChange={(e) => {
					setSearchTerm(e.target.value);
					setPageIndex(1);
				}}
				className='border rounded-full p-3 w-full pl-12 bg-gray-50'
			/>
		</div>
	);
};

export default SearchComp;
