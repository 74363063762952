import React, { useState } from 'react';
import TanTable from "../../../Utils/TanTable";
import { CiFilter } from "react-icons/ci";

const UsdtWallet = () => {
  const data = [
    { name:"Adewale Oretuga", amount: "$200", date: "2024-08-01", type: "Deposit", status: "Successful" },
    { name:"Adewale Oretuga", amount: "$150", date: "2024-08-02", type: "Withdrawal", status: "Pending" },
    { name:"Adewale Oretuga", amount: "$300", date: "2024-08-03", type: "Deposit", status: "Successful" },
    { name:"Adewale Oretuga", amount: "$100", date: "2024-08-04", type: "Withdrawal", status: "Failed" },
    { name:"Adewale Oretuga", amount: "$250", date: "2024-08-05", type: "Deposit", status: "Successful" },
  ];

  const [filter, setFilter] = useState(""); 
  const [filteredData, setFilteredData] = useState(data); 
  const columns = [
    { header: "Name", accessorKey: "name" },
    { header: "Amount", accessorKey: "amount" },
    { header: "Date", accessorKey: "date" },
    { header: "Type", accessorKey: "type" },
    {
      header: "Status",
      accessorKey: "status",
      // Custom cell rendering to change color based on status
      cell: ({ cell }) => {
        const status = cell.getValue();
        let color;

        switch (status) {
          case "Successful":
            color = "#22c55e";
            break;
          case "Pending":
            color = "#7B61FF";
            break;
          case "Failed":
            color = "#E31352";
            break;
          default:
            color = "black";
        }

        return <span style={{ color }}>{status}</span>;
      },
    },
  ];

  // Function to handle filter change and update filtered data
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);

    if (selectedFilter === "") {
      setFilteredData(data);
    } else {
      const newFilteredData = data.filter((item) =>
        item.status === selectedFilter ||
        item.amount === selectedFilter ||
        item.type.toLowerCase() === selectedFilter.toLowerCase()
      );
      setFilteredData(newFilteredData);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-center font-bold p-2 text-lg md:text-base sm:text-sm">
          Transactions (10000)
        </p>
        
        {/* Filter Dropdown with Icon */}
        <div className="flex items-center pb-4">
          <CiFilter className="mr-1" /> 
          <select
            value={filter}
            onChange={handleFilterChange}
            className="border p-2 rounded"
          >
            <option value="">Filter</option>
            <option value="Successful">Successful</option>
            <option value="Pending">Pending</option>
            <option value="Failed">Failed</option>
            <option value="deposit">Deposit</option>
            <option value="withdrawal">Withdrawal</option>
          </select>
        </div>
      </div>

      <TanTable columnData={columns} data={filteredData} />
    </div>
  );
}

export default UsdtWallet;
