import React, { useState } from "react";

import {
	FacebookShareButton,
	LinkedinShareButton,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	FacebookIcon,
	LinkedinIcon,
	TelegramIcon,
	TwitterIcon,
	WhatsappIcon,
} from "react-share";

import { MdContentCopy, MdSend } from "react-icons/md";

import axios from "axios";

import { useEffect } from "react";

import { useSelector } from "react-redux";

import { toast, ToastContainer } from "react-toastify";

import { baseUrl, config, copyText } from "../../Utils/constants";
import { benefit } from "../../Utils/referral";
import warn from "../../Assets/images/warn.png";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";
import DefaultModal from "../../Components/Modals/defaultModal";
import Table from "../../Components/Admin/Layout/Table";

const Referral = () => {
	const [isReferral, setIsReferral] = useState(false);
	const [shareURL, setShareURL] = useState("");
	const { token } = useSelector((state) => state.auth);
	const [isLoading, setIsLoading] = useState(false);
	const [isTopupModalVisible, setIsTopupModalVisible] = useState(false);
	const [amount, setAmount] = useState("");
	const [recentTrans, setRecentTrans] = useState({});
	const [paymentLoading, setPaymentLoading] = useState(false);

	const getReferralLink = async () => {
		const url = `${baseUrl}get-referral-link`;
		try {
			const res = await axios.get(url, config(token));
			setShareURL(res.data.data);
		} catch (error) {
			console.log(error);
		}
	};
	const getReferralTransactions = async () => {
		const url = `${baseUrl}referral-transactions`;
		try {
			const res = await axios.get(url, config(token));
			setRecentTrans(res.data);
		} catch (error) {
			console.log(error);
		}
	};

	const header = ["S/N", "Date", "Amount", "Tracking ID", "Category", "Status"];

	useEffect(() => {
		getReferralLink();
		getReferralTransactions();
	}, []);

	const makePayment = async () => {
		setPaymentLoading(true);
		const url = `${baseUrl}make-transfer`;
		try {
			const res = await axios.post(url, { amount }, config(token));
			console.log(res);
			setIsTopupModalVisible(false);
			setPaymentLoading(false);
			toast.success(res.data.message || "Transfer syccesfully completed");
		} catch (error) {
			setPaymentLoading(false);
			toast.error(error?.response?.data?.message || "error occured");
			console.log(error);
			setIsTopupModalVisible(false);
		}
	};

	return (
		<div className=''>
			<ToastContainer autoClose={3000} />
			<h1 className='text-lg font-medium'>Referrals</h1>
			<div className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
				<div>
					<div className='bg-sec text-white p-4 rounded-md mb-4 mt-2'>
						<h2>Total Payment</h2>
						<h2 className='text-xl font-bold'>
							₦ {recentTrans?.referral_balance}
						</h2>
						<button
							onClick={() => setIsTopupModalVisible(true)}
							className='flex items-center gap-2 mt-4'
						>
							Transfer <MdSend />
						</button>
					</div>
					<p className='text-xs'>
						Share your link with your friends and family to earn 5 points.
					</p>
					<h2 className='text-sm my-3'>Your referral link</h2>
					<div className='bg-gray-100 p-3 flex items-center gap-4 w-fit border-2 px-6 my-4 rounded-md shadow-xl'>
						<h2 className='text-xs'>{shareURL}</h2>
						<i
							className='text-green-400 text-xl bg-green-100'
							onClick={() => copyText(shareURL)}
						>
							<MdContentCopy />
						</i>
					</div>
					<div className='flex gap-3 items-center'>
						<WhatsappShareButton url={shareURL}>
							<WhatsappIcon round size={30} />
						</WhatsappShareButton>
						<TwitterShareButton url={shareURL}>
							<TwitterIcon round size={30} />
						</TwitterShareButton>
						<FacebookShareButton url={shareURL}>
							<FacebookIcon round size={30} />
						</FacebookShareButton>
						<LinkedinShareButton url={shareURL}>
							<LinkedinIcon round size={30} />
						</LinkedinShareButton>
						<TelegramShareButton url={shareURL}>
							<TelegramIcon round size={30} />
						</TelegramShareButton>
					</div>
				</div>
				<div className='rounded-md border shadow-md p-6'>
					<h2 className='text-center text-lg font-medium'>
						How to benefit from referrals
					</h2>
					{benefit.map(({ img, title, text }, i) => {
						return (
							<div className='flex gap-3 my-4' key={i}>
								<img className='h-fit w-6' src={img} alt={title} />
								<div>
									<h2 className='text-sm font-medium'>{title}</h2>
									<p className='text-sm my-2'>{text}</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className='my-10'>
				{!isReferral ? (
					<div>
						{/* <h2 className='mb-2'>Recent Transactions</h2> */}
						{/* <Table
							cols='6'
							minSize='1000px'
							headerContent={header}
							loadingState={isLoading}
							data={recentTrans?.data}
						>
							{recentTrans?.data?.map((item, index) => (
								<div key={item} className='grid grid-cols-6 text-xs'>
									<h2>01</h2>
									<h2>02/02/2020</h2>
									<h2>₦10,000</h2>
									<h2>132343FS</h2>
									<h2>Payment</h2>
									<h2>Recieved</h2>
								</div>
							))}
						</Table> */}
					</div>
				) : (
					<div className='flex flex-col items-center justify-center my-4'>
						<img className='w-28' src={warn} alt='warning' />
						<p className='text-lg font-bold'>
							You do not have any referral yet
						</p>
						<p className='text-sm'>Check back later</p>
					</div>
				)}
			</div>
			<DefaultModal
				visibilityState={isTopupModalVisible}
				closeModal={() => setIsTopupModalVisible(false)}
			>
				<Input
					input
					title='Amount'
					type='number'
					className={"border-2 font-medium"}
					value={amount}
					setItem={(e) => setAmount(e.target.value)}
				/>
				<Btn
					text={"Make Payment"}
					className='bg-pry w-full my-4 font-medium'
					onClick={makePayment}
					loadingState={paymentLoading}
				/>
			</DefaultModal>
		</div>
	);
};

export default Referral;
