import React from 'react'
import UsdtWithdraw from '../../Components/Dashboard/Wallet/Withdraw/UsdtWithdraw'

const UsdtWithdrawPage = () => {
  return (
    <div>
      <UsdtWithdraw/>
    </div>
  )
}

export default UsdtWithdrawPage
