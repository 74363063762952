import React from "react";
// import cardLogo from "../../../../Assets/images/visa2.png";

const Card = ({ dollarCardDetails }) => {
  // const {
  //   card_firstname,
  //   card_lastname,
  //   card_number,
  //   card_type,
  //   cvv,
  //   valid_thru,
  //   dollar_wallet,
  // } = dollarCardDetails || {};

  return (
    <div className='bg-black text-white p-6 rounded-xl my-2 w-full max-w-[350px]'>
      <h2 className='text-xs'>Available Funds</h2>
      <h2 className='my-1 font-bold text-base'>
        ${dollarCardDetails?.dollar_card_details?.balance || "0.0"}
      </h2>
      {/* <h2 className='text-sm'>Available to spend</h2> */}
      <p className='text-sm my-2 font-medium'>
        {dollarCardDetails?.dollar_card_details?.masked || "xxxx xxxx xxxx xxxx"}
      </p>
      <div className='grid grid-cols-2 text-xs'>
        <div className='flex items-center gap-2 font-light'>
          <h2>
            Valid <br /> thru
          </h2>
          <h2>{dollarCardDetails?.dollar_card_details?.expiry || "xx/xx"}</h2>
        </div>
        <div>
          <h2>CVV</h2>
          <h2 className='font-light'>{dollarCardDetails?.dollar_card_details?.ccv || ""}</h2>
        </div>
      </div>
      <div className='flex justify-between gap-4 items-center my-3'>
        <h2 className='text-sm font-medium'>
          {dollarCardDetails?.user?.firstname} {dollarCardDetails?.user?.lastname}
        </h2>
        <h2>{dollarCardDetails?.dollar_card_details?.brand}</h2>
      </div>
    </div>
  );
};

export default Card;
