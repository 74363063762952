import { useState, useEffect } from "react";
// import plus from "../images/plus.png";
// import minus from "../images/minus.png";

const SingleFaq = ({ question, index }) => {
  const [ans, setAns] = useState(false);
  const [base, setBase] = useState(0);

  return (
    <div className='my-2 p-2 bg-gray-50 shadow-md'>
      <div
        className='flex items-center justify-between cursor-pointer'
        onClick={() => {
          setBase(index);
          setAns(!ans);
        }}
      >
        <h4 className='my-2 font-medium'>{question?.q}?</h4>
        <h2 src='' className='w-10 p-3' alt=''>
          +
        </h2>
      </div>
      {ans && <p className='text-sm sm:text-base my-2'>{question?.a}</p>}
    </div>
  );
};

export default SingleFaq;
