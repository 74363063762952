import React, { useState, useEffect } from 'react';

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import { useSelector } from "react-redux";

import TanTable from "../../../Utils/TanTable";
import { baseUrl } from "../../../Utils/constants";

const UpsZone = () => {
  const [uploadedData, setUploadedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);
  
  const columns = [
    {
      header: "Serial Number",
      accessorKey: "sn",
      cell: ({ row }) => row.index + 1,
    },
    { header: "Zone Numbers", accessorKey: "zone" },
    { header: "Countries", accessorKey: "country_name" },
  ];

// Delete pricing
const handleDeletePricing = async () => {
  setIsLoading(true);
  try {
    const response = await axios.delete(`${baseUrl}admin/delete-ups-international-zone`, {
      headers: {
        Authorization: `Bearer ${adminToken}`,
      },
    });
    setUploadedData([]);
    const successMessage = response.data.message || "Pricing deleted successfully!";
    toast.success(successMessage);
  } catch (error) {
    // console.error("Error deleting pricing:", error);
    const errorMessage = error.response?.data?.message || "Failed to delete pricing. Please try again.";
    toast.error(errorMessage);
  } finally {
    setIsLoading(false);
  }
};

  
  // Fetch initial data when component mounts
  useEffect(() => {
    fetchDhlZoneData();
  }, []);

  // Function to fetch DHL zone data
  const fetchDhlZoneData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${baseUrl}admin/get-ups-international-zone`, {
        headers: { Authorization: `Bearer ${adminToken}` }
      });
      
      // Check if the response has a data property and is an array
      if (response.data.status && Array.isArray(response.data.data)) {
        setUploadedData(response.data.data);
      } else {
        setUploadedData([]);
        toast.warning("No data available");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to fetch DHL zone data";
      toast.error(errorMessage);
      // console.error("Error fetching DHL zone data:", error);
      setUploadedData([]);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle file upload
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    
    if (file) {
      try {
        // Create FormData with the specific payload structure
        const formData = new FormData();
        formData.append('ups_international_zone', file, file.name);

        // Set loading state
        setIsLoading(true);

        // Upload the file
        const uploadResponse = await axios.post(
          `${baseUrl}admin/upload-ups-international-zone`, 
          formData, 
          {
            headers: { 
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${adminToken}` 
            }
          }
        );

        // Use backend message for success
        if (uploadResponse.data.status) {
          const successMessage = uploadResponse.data.message || "File uploaded successfully";
          toast.success(successMessage);
          
          // Fetch the latest data after upload
          fetchDhlZoneData();
        }
      } catch (error) {
        // Use backend error message
        const errorMessage = error.response?.data?.message || "Failed to upload file";
        const specificErrors = error.response?.data?.errors 
          ? Object.values(error.response.data.errors).flat().join(', ')
          : '';
        
        toast.error(`${errorMessage} ${specificErrors ? `: ${specificErrors}` : ''}`);
        // console.error("File upload error:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <div className='w-full'>
      <ToastContainer position="top-right" />
      <div className='mb-4 flex justify-between items-center'> 
        <label 
          htmlFor="excel-upload" 
          className='block w-full p-4 border-2 border-dashed border-gray-300 text-center cursor-pointer hover:bg-gray-100 mr-4'
        >
          Upload Excel File For World Zone
          <input 
            type="file" 
            id="excel-upload"
            accept=".xlsx, .xls"
            onChange={handleFileUpload}
            className='hidden'
          />
        </label>
        {uploadedData.length > 0 && (
          <button
            onClick={handleDeletePricing}
            disabled={isLoading}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:opacity-50"
          >
            {isLoading ? "Deleting..." : "Delete Pricing"}
          </button>
        )}
      </div>

      <h1 className='text-center mb-4'>
        {uploadedData.length > 0 
          ? "Uploaded Countries and Zones" 
          : "Below are the countries belonging to different Zones"}
      </h1>

      <TanTable 
        columnData={columns} 
        data={uploadedData} 
        loadingState={isLoading} 
      />
    </div>
  );
};

export default UpsZone;