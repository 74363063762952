import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { ToastContainer, toast } from "react-toastify";

import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import Btn from "../../../Components/Button/btn";
import Input from "../../../Components/Form/Input";

import EditModal from "./EditModal";

const DollarRate = () => {
  const columnsData = [
    {
      header: "Pickup Location",
      accessorKey: "location",
    },
    {
      header: "Price",
      accessorKey: "price",
    },
    {
      header: "Action",
      accessorKey: "id",
      cell: ({ row }) => (
        <div className="flex items-center space-x-2">
          <AiOutlineEdit
            size={20}
            className="text-blue-500 cursor-pointer"
            onClick={() => handleEdit(row.original)}
          />
          <AiOutlineDelete
            size={20}
            className="text-red-500 cursor-pointer"
            onClick={() => handleDelete(row.original.id)}
          />
        </div>
      ),
    },
  ];
  const { dollarRate, adminToken } = useSelector((state) => state.admin);
  const [rate, setRate] = useState("");
  const [amount, setAmount] = useState("");
  const [cost, setCost] = useState("");
  const [dhlCost, setDhlCost] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [pickupData, setPickupData] = useState([]);
  const [editData, setEditData] = useState({
    id: null,
    location: "",
    price: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const createDollarRate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}admin/create-dollar-rate`;
    try {
      const { data } = await axios.post(url, { rate }, config(adminToken));
      setIsLoading(false);
      toast.success(data?.message);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  // create dhl rate
  const createDhlRate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url = `${baseUrl}admin/dhl-rate`;

    try {
      const { data } = await axios.post(url, { amount }, config(adminToken));
      setIsLoading(false);
      toast.success(data?.message);

      // Update the DHL cost dynamically without reloading
      setDhlCost(amount);

     
      setAmount("");
    } catch (error) {
      console.error("Error creating DHL rate:", error);
      toast.error(
        error?.response?.data?.message || "Failed to create DHL rate"
      );
      setIsLoading(false);
    }
  };

  const createShippingRate = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let url;

    if (shippingCost !== 0) {
      url = `${baseUrl}admin/edit-shipping-cost/${shippingCost?.id}`;
    } else {
      console.log("false");
      url = `${baseUrl}admin/add-shipping-cost`;
    }

    try {
      const { data } = await axios.post(
        url,
        { amount: cost },
        config(adminToken)
      );
      setIsLoading(false);
      toast.success(data?.message);
      getShippingRate();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getShippingRate = async () => {
    setIsLoading(true);
    const url = `${baseUrl}get-shipping-cost`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setCost(data.data.shipping_cost);
      setShippingCost(data.data);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // GET dhl
  const getDhlRate = async () => {
    setIsLoading(true);
    const url = `${baseUrl}admin/dhl-rate`;
    try {
      const { data } = await axios.get(url, config(adminToken));
      setIsLoading(false);
      setDhlCost(data.data.amount); // Update DHL cost state
      setAmount(data.data.amount); // Update the input field state with fetched amount
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching DHL rate:", error);
    }
  };

  useEffect(() => {
    getShippingRate();
    setRate(dollarRate);
    fetchDeliveryPrices();
    getDhlRate();
  }, []);

  const handleEdit = (rowData) => {
    setEditData({
      id: rowData.id,
      location: rowData.location,
      price: rowData.price,
    });
    setIsModalOpen(true);
  };

  const handleDelete = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await axios.delete(
        `${baseUrl}admin/delete-delivery-price/${id}`,
        config(adminToken)
      );
      toast.success(data?.message);

      setPickupData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error deleting delivery price"
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createDeliveryPrice = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = { location, price };
    try {
      const { data } = await axios.post(
        `${baseUrl}admin/create-delivery-price`,
        payload,
        config(adminToken)
      );
      toast.success(data?.message);

      // Update the table data dynamically
      setPickupData((prevData) => [...prevData, { location, price }]);

      // Reset input fields
      setLocation("");
      setPrice("");
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error creating delivery price"
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDeliveryPrices = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${baseUrl}admin/get-delivery-prices`,
        config(adminToken)
      );
      setPickupData(data?.data || []);
    } catch (error) {
      toast.error("Failed to fetch delivery prices");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdatePickup = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data } = await axios.post(
        `${baseUrl}admin/update-delivery-price/${editData.id}`,
        { location: editData.location, price: editData.price },
        config(adminToken)
      );
      toast.success(data?.message);

      // Update table data dynamically
      setPickupData((prevData) =>
        prevData.map((item) =>
          item.id === editData.id
            ? { ...item, location: editData.location, price: editData.price }
            : item
        )
      );

      setIsModalOpen(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message || "Error updating delivery price"
      );
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex gap-3">
        <div className="bg-white w-full p-5 rounded-md my-5 max-w-xl">
          <ToastContainer autoClose={3000} />

          <h2 className="font-medium text-lg">Current Dollar Rate</h2>
          <p>1$ = ₦{dollarRate}</p>
          <form action="" onSubmit={createDollarRate}>
            <Input
              input
              className="border"
              id="rate"
              setItem={(e) => setRate(e.target.value)}
              placeholder="Enter current rate"
              value={rate || ""}
            />

            <Btn
              loadingState={isLoading}
              className="bg-sec2 text-white my-5"
              text="Update"
            />
          </form>
        </div>
        <div className="bg-white w-full p-5 rounded-md my-5 max-w-xl">
          <ToastContainer autoClose={3000} />

          <h2 className="font-medium text-lg">Current Shipping Rate</h2>
          <p>Cost = ${shippingCost?.shipping_cost || 0}</p>
          <form action="" onSubmit={createShippingRate}>
            <Input
              input
              className="border"
              id="rate"
              setItem={(e) => setCost(e.target.value)}
              placeholder="Enter current rate"
              value={cost || ""}
            />

            <Btn
              loadingState={isLoading}
              className="bg-sec2 text-white my-5"
              text="Update"
            />
          </form>
        </div>
        {/* dhl rate */}
        <div className="bg-white w-full p-5 rounded-md my-5 max-w-xl">
          <ToastContainer autoClose={3000} />

          <h2 className="font-medium text-lg">Dhl Added Rate</h2>
          <p>amount = ₦{dhlCost || "0"}</p>
          <form action="" onSubmit={createDhlRate}>
            <Input
              input
              className="border"
              id="dhlRate"
              setItem={(e) => setAmount(e.target.value)}
              placeholder="Enter current dhl rate"
              value={amount  || ""} 
            />

            <Btn
              loadingState={isLoading}
              className="bg-sec2 text-white my-5"
              text="Update"
            />
          </form>
        </div>
      </div>
      {/* second div */}
      <div className="flex flex-row md:flex-row gap-3">
        {/* Left Section */}

        {/* pickup location  */}
        <div className="flex-1 bg-gray-100 rounded-md p-3">
          <div className="bg-white w-full p-5 rounded-md my-5 max-w-xl">
            <h2 className="font-medium text-lg">Create Delivery Price</h2>
            <form onSubmit={createDeliveryPrice}>
              <div className="mb-3">
                <label htmlFor="location" className="block font-medium mb-2">
                  Location
                </label>
                <Input
                  input
                  id="location"
                  placeholder="Enter location (e.g., Island)"
                  value={location}
                  setItem={(e) => setLocation(e.target.value)}
                  className="border w-full"
                />
              </div>

              <div className="mb-3">
                <label htmlFor="price" className="block font-medium mb-2">
                  Price
                </label>
                <Input
                  input
                  id="price"
                  placeholder="Enter price (e.g., 1000)"
                  value={price}
                  setItem={(e) => setPrice(e.target.value)}
                  className="border w-full"
                />
              </div>

              <Btn
                loadingState={isLoading}
                text="Create Delivery Price"
                className="bg-sec2 text-white w-full"
              />
            </form>
          </div>
        </div>
        {/* Right Section */}
        <div className="flex-1 bg-white rounded-md p-3">
          <h2 className="font-medium text-lg mb-4">UPS Added Rate</h2>
          {/* <p>Cost = ${shippingCost?.shipping_cost || 0}</p> */}
          <p>Cost = 5000</p>
          <form onSubmit={createShippingRate}>
            <Input
              input
              className="border w-full my-4"
              id="rate"
              setItem={(e) => setCost(e.target.value)}
              placeholder="Enter current rate"
              value={cost || ""}
            />

            <Btn
              loadingState={isLoading}
              className="bg-sec2 text-white w-full"
              text="Update"
            />
          </form>
        </div>
      </div>

      <div className="py-3">
        <h2 className="font-medium text-lg mb-4 text-center">Pickup table </h2>
        <TanTable columnData={columnsData} data={pickupData} />
      </div>
      {/* edit */}
      <EditModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Edit Pickup"
      >
        <form onSubmit={handleUpdatePickup}>
          <div className="mb-3">
            <label htmlFor="location" className="block font-medium mb-2">
              Location
            </label>
            <Input
              input
              id="location"
              placeholder="Enter location"
              value={editData.location}
              setItem={(e) =>
                setEditData({ ...editData, location: e.target.value })
              }
              className="border w-full"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="price" className="block font-medium mb-2">
              Price
            </label>
            <Input
              input
              id="price"
              placeholder="Enter price"
              value={editData.price}
              setItem={(e) =>
                setEditData({ ...editData, price: e.target.value })
              }
              className="border w-full"
            />
          </div>
          <Btn
            loadingState={isLoading}
            text="Update Pickup"
            className="bg-sec2 text-white w-full"
          />
        </form>
      </EditModal>
    </div>
  );
};

export default DollarRate;
