import React, { useState } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast } from "react-toastify";

import { Spinner } from "react-activity";

import Btn from "../../Button/btn";
import { baseUrl, config } from "../../../Utils/constants";

import Card from "./DollarCard/card";

const FreezeCardTab = ({ otp, setDollarCardDetails, dollarCardDetails }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    //const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [loading, setLoading] = useState(false)
    console.log(dollarCardDetails, "This is the dollar card details received in the freeze card component")

    const { token } = useSelector((state) => state.auth)

  const handleFreezeClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getDollarCard = async (pin) => {
		const url = `${baseUrl}dollar-card-details`;
		console.log("dollar card details called")
		try {
			const { data } = await axios.post(url, { pin }, config(token));
			console.log(data.data.data)
			setDollarCardDetails(data.data.data);
		} catch (error) {
      toast.error("Please reload to see the update you made to your card.")
		}
	};


  const handleConfirmFreeze = async () => {
    if (loading) return;
    setLoading(true);
    console.log("Card frozen");
  
    try {
      // Ensure the card_id is included in the request
      const cardId = dollarCardDetails?.dollar_card_details?.card_id;
      if (!cardId) {
        throw new Error("Card ID is missing.");
      }
  
      const status =
        dollarCardDetails.dollar_card_details?.status === "active"
          ? "freeze"
          : "unfreeze";
  
      // Pass both status and card_id in the query
      const response = await axios.patch(
        `${baseUrl}update-dollar-card?status=${status}&card_id=${cardId}`,
        {},
        config(token)
      );
  
      console.log(response.data?.data?.data, "This is the freeze card response");
      toast.success(response.data?.message || "Operation successful");
      // Refresh the card details
      await getDollarCard(otp.join(""));
    } catch (error) {
      console.log(error?.response?.data);
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setIsModalOpen(false);
      setLoading(false);
    }
  };
  

  
  return (
    <div className="freeze-card-tab text-center">
      <div className="mb-4 w-full flex justify-center items-center">
        <Card dollarCardDetails={dollarCardDetails} />
      </div>
      <p className={`mb-4 ${dollarCardDetails?.dollar_card_details?.status === "active" ? "text-red-600" : "text-[#008E56]"} `}>
        {dollarCardDetails.dollar_card_details?.status === "active" ? "Warning: Once you freeze your card, it cannot be used for transactions until you unfreeze it." : "Unfreeze your card so you can use it for transactions."}
        
      </p>
      <div className="flex items-center justify-center">
        <Btn
          text={dollarCardDetails.dollar_card_details?.status === "active" ? "Freeze Card" : "Unfreeze Card"}
          className={
            "bg-[#008E56] text-white px-6 py-3 rounded-lg md:px-240 lg:px-24"
          }
          onClick={handleFreezeClick}
        />
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[999]" onClick={() => setIsModalOpen((prev) => !prev)}>
          <div className="bg-white p-6 rounded-lg shadow-lg text-center" onClick={(e) => e.stopPropagation()}>
            <div className="w-full flex flex-row items-start pb-2 border-b-[1px] border-solid border-[#ccc]">
              <h1 className="font-bold">Confirm Action</h1>
            </div>
            <h2 className="mb-4 mt-5">
              Are you sure you want to perform this action?
            </h2>
            <div className="flex justify-end gap-4 items-center mt-4">
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-md"
                onClick={handleCloseModal}
              >
                Cancel
              </button>
              <button
                className="bg-[#008E56] text-white px-4 py-2 rounded-md"
                onClick={handleConfirmFreeze}
              >
                {loading ? (
                  <Spinner size={10} />
                ) : (
                  <span>Yes</span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FreezeCardTab;
