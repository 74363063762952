import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FedexWorld from "./FedexWorld";
import FedexNgnZone from "./FedexNgnZone";
import FedexZone from "./FedexZone";
import FedexDomestic from "./FedexDomestic";
import { useSelector } from "react-redux";
import { baseUrl, config } from "../../../Utils/constants";
import axios from "axios";
import { toast } from "react-toastify";

const UpsPricingTab = () => {
	const [activeTab, setActiveTab] = useState("zone");

	const [isLoading, setIsLoading] = useState(false);
	const { token } = useSelector((state) => state.auth);
	const [dhlZones, setDhlZones] = useState([]);
	const [prices, setPrices] = useState([]);
	const [zones, setZones] = useState([]);
	const [domPrices, setDomPrices] = useState([]);

	const getNgnZones = async () => {
		const url = `${baseUrl}get-fedex-domestic-zones`;
		setIsLoading(true);
		try {
			const { data } = await axios.get(url, config(token));
			console.log(data);
			setZones(data?.data?.data);
		} catch (error) {
			toast.error(error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getZones = async () => {
		const url = `${baseUrl}get-fedex-zones`;
		setIsLoading(true);
		try {
			const { data } = await axios.get(url, config(token));

			setDhlZones(data?.data?.data);
		} catch (error) {
			toast.error(error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getPrices = async () => {
		const url = `${baseUrl}get-fedex-international-prices`;
		setIsLoading(true);
		try {
			const { data } = await axios.get(url, config(token));
			console.log(data);
			setPrices(data?.data?.data);
		} catch (error) {
			toast.error(error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getDomPrices = async () => {
		const url = `${baseUrl}get-fedex-domestic-prices`;
		setIsLoading(true);
		try {
			const { data } = await axios.get(url, config(token));
			console.log(data);
			setDomPrices(data?.data?.data);
		} catch (error) {
			toast.error(error?.response?.data?.message);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getZones();
		getPrices();
		getNgnZones();
		getDomPrices();
	}, []);

	const renderContent = () => {
		switch (activeTab) {
			case "zone":
				return <FedexZone dhlZones={dhlZones} isLoading={isLoading} />;
			case "world":
				return <FedexWorld isLoading={isLoading} prices={prices} />;
			case "domestic":
				return <FedexDomestic isLoading={isLoading} zones={zones} />;
			case "ngnzone":
				return <FedexNgnZone isLoading={isLoading} prices={domPrices} />;
			default:
				return <div>Invalid tab selected</div>;
		}
	};

	return (
		<div className='p-4'>
			<p className='text-center font-bold pb-4 text-lg md:text-base sm:text-sm'>
				FedEx Express Shipping Price
			</p>

			<div className='flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 shadow-lg p-2'>
				<button
					className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
						activeTab === "zone"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
							: ""
					}`}
					onClick={() => setActiveTab("zone")}
				>
					World
				</button>
				<button
					className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
						activeTab === "world"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
							: ""
					}`}
					onClick={() => setActiveTab("world")}
				>
					Ngn World
				</button>
				<button
					className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
						activeTab === "domestic"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
							: ""
					}`}
					onClick={() => setActiveTab("domestic")}
				>
					Ngn Zone
				</button>
				<button
					className={`py-2 px-4 text-sm md:text-sm sm:text-sm ${
						activeTab === "ngnzone"
							? "font-bold border-b-2 bg-[#008E56] text-white rounded-full"
							: ""
					}`}
					onClick={() => setActiveTab("ngnzone")}
				>
					Ngn Domestic
				</button>
			</div>
			<div className='p-4 border rounded-lg shadow-lg'>{renderContent()}</div>
		</div>
	);
};

export default UpsPricingTab;
