import React, { useEffect, useState } from "react";
// import TanTable from "../../../Components/Utils/TanTable";
import TanTable from "../../../Utils/TanTable";
import axios from "axios";
import { baseUrl, config } from "../../../Utils/constants";
import { useSelector } from "react-redux";
import { FormatCurrency } from "../../../Utils/currency";
import { toast } from "react-toastify";

const Fedexworld = ({ prices, isLoading }) => {
	const columns = [
		{ header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
		{ header: "KG", accessorKey: "kg" },
		{
			header: "Zone 1",
			accessorKey: "zone_1",
			cell: ({ row }) => FormatCurrency(row.original.zone_1),
		},
		{
			header: "Zone 2",
			accessorKey: "zone_2",
			cell: ({ row }) => FormatCurrency(row.original.zone_2),
		},
		{
			header: "Zone 3",
			accessorKey: "zone_3",
			cell: ({ row }) => FormatCurrency(row.original.zone_3),
		},
		{
			header: "Zone 4",
			accessorKey: "zone_4",
			cell: ({ row }) => FormatCurrency(row.original.zone_4),
		},
		{
			header: "Zone 5",
			accessorKey: "zone_5",
			cell: ({ row }) => FormatCurrency(row.original.zone_5),
		},
		{
			header: "Zone 6",
			accessorKey: "zone_6",
			cell: ({ row }) => FormatCurrency(row.original.zone_6),
		},
		{
			header: "Zone 7",
			accessorKey: "zone_7",
			cell: ({ row }) => FormatCurrency(row.original.zone_7),
		},
		{
			header: "Zone 8",
			accessorKey: "zone_8",
			cell: ({ row }) => FormatCurrency(row.original.zone_8),
		},
	];
	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the price list belonging to different countries based on their
				zones
			</h1>
			<TanTable columnData={columns} data={prices} loadingState={isLoading} />
		</div>
	);
};

export default Fedexworld;
