import React from "react";
import { useEffect } from "react";
import AboutHero from "../../Components/About/hero";
import Mission from "../../Components/About/mission";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <AboutHero />
      <Mission />
    </div>
  );
};

export default About;
