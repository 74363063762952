import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast } from "react-toastify";

import AdminDhlDomestic from "../AdminDashboard/AdminDhlDomestic";
import AdminDhlWorld from "../AdminDashboard/AdminDhlWorld";
import AdminNgnDomestic from "../AdminDashboard/AdminNgnDomestic";
import { baseUrl, config } from "../../../Utils/constants";

import AdminDhlZone from "../AdminDashboard/AdminDhlZone"
// import Ngworld from "./Dhlworld";



const PricingTab = () => {
  const [activeTab, setActiveTab] = useState("zone");

  const [isLoading, setIsLoading] = useState(false);
  const { adminToken } = useSelector((state) => state.admin);
  const [dhlZones, setDhlZones] = useState([]);
  const [prices, setPrices] = useState([]);
  const [zones, setZones] = useState([]);
  const [ngnPrice, setNgnPrice] = useState([]);

  // const getZones = async () => {
  //   const url = `${baseUrl}get-dhl-zones`;
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios.get(url, config(adminToken));
  //     setDhlZones(data?.data?.data);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const getPrices = async () => {
  //   const url = `${baseUrl}admin/get-dhl-pricing`;
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios.get(url, config(adminToken));
  //     console.log(data);
  //     setPrices(data?.data?.data);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const getNgnZones = async () => {
  //   const url = `${baseUrl}get-dhl-domestic-zones`;
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios.get(url, config(adminToken));
  //     console.log(data);
  //     setZones(data?.data?.data);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  // const getNgnPrices = async () => {
  //   const url = `${baseUrl}get-dhl-domestic-prices`;
  //   setIsLoading(true);
  //   try {
  //     const { data } = await axios.get(url, config(adminToken));
  //     console.log(data);
  //     setNgnPrice(data?.data?.data);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    //getZones();
    // getPrices();
    //getNgnZones();
    // getNgnPrices();
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "zone":
        return <AdminDhlZone dhlZones={dhlZones} isLoading={isLoading} />;
      case "world":
        return <AdminDhlWorld prices={prices} isLoading={isLoading} />;
      case "domestic":
        return <AdminDhlDomestic zones={zones} isLoading={isLoading} />;
      case "ngnzone":
        return <AdminNgnDomestic isLoading={isLoading} prices={ngnPrice} />;
      default:
        return <div>Invalid tab selected</div>;
    }
  };

  return (
    <div className="">
      <p className="text-center font-bold pb-4 text-lg md:text-base sm:text-sm">
        Dhl Express Shipping Price
      </p>
      {/* <p className="text-center font-bold pb-4 text-lg md:text-base sm:text-sm">
        Upload price in Excel format
      </p> */}
      <div className="flex flex-wrap justify-around bg-[#E5E5E5] rounded-full mb-4 p-2">
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "zone"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full "
              : ""
          }`}
          onClick={() => setActiveTab("zone")}
        >
          World
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "world"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full "
              : ""
          }`}
          onClick={() => setActiveTab("world")}
        >
          Ngn World
        </button>
        <button
          className={`py-2 px-4 mb-2 md:mb-0 text-sm md:text-sm sm:text-sm ${
            activeTab === "domestic"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full "
              : ""
          }`}
          onClick={() => setActiveTab("domestic")}
        >
          Ngn Zone
        </button>
        <button
          className={`py-2 px-4 text-sm md:text-sm sm:text-sm ${
            activeTab === "ngnzone"
              ? "font-bold border-b-2 bg-[#008E56] text-white rounded-full "
              : ""
          }`}
          onClick={() => setActiveTab("ngnzone")}
        >
          Ngn Domestic
        </button>
      </div>
      <div className="">{renderContent()}</div>
    </div>
  );
};

export default PricingTab;
