import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

// import { toast } from "react-toastify";

import { Spinner } from "react-activity";

import Btn from "../../Components/Button/btn";
import SuccessModal from "../../Components/Modals/success";

const Modal = ({ formData, onClose, onRegister, isLoading }) => {
  const [isFulfillmentCenterOpen, setIsFulfillmentCenterOpen] = useState(false);
  const [isSubscriptionFeeOpen, setIsSubscriptionFeeOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();

  const toggleFulfillmentCenter = () => {
    setIsFulfillmentCenterOpen(!isFulfillmentCenterOpen);
  };

  const toggleSubscriptionFee = () => {
    setIsSubscriptionFeeOpen(!isSubscriptionFeeOpen);
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate("/dashboard/fulfilment");
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4 overflow-y-auto">
      <div className="bg-white rounded-lg shadow-md w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl mx-auto p-4 sm:p-6 lg:p-8 space-y-4">
        <h2 className="text-lg sm:text-xl md:text-2xl font-bold text-center mb-4">
          Important Tips for using our Fulfillment Center
        </h2>

        <div className="space-y-4">
          <div className="border rounded-lg">
            <div
              className="flex justify-between items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={toggleFulfillmentCenter}
            >
              <h3 className="font-semibold text-sm sm:text-base">What is a Fulfillment Center</h3>
              <span className="text-sm">{isFulfillmentCenterOpen ? "▾" : "▸"}</span>
            </div>
            {isFulfillmentCenterOpen && (
              <div className="p-3 max-h-40 overflow-y-auto">
                <p className="text-xs sm:text-sm text-gray-600 bg-gray-100 p-2 rounded">
                  A Fulfillment Center is your business's secret weapon for
                  lightning-fast order processing and delivery. It's a high-tech
                  warehouse where your products are stored, picked, packed, and
                  shipped with laser precision.
                </p>
              </div>
            )}
          </div>

          <div className="border rounded-lg">
            <div
              className="flex justify-between items-center p-3 cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={toggleSubscriptionFee}
            >
              <h3 className="font-semibold text-sm sm:text-base">
                Fulfillment Center Subscription Fee
              </h3>
              <span className="text-sm">{isSubscriptionFeeOpen ? "▾" : "▸"}</span>
            </div>
            {isSubscriptionFeeOpen && (
              <div className="p-3 max-h-40 overflow-y-auto">
                <p className="text-xs sm:text-sm text-gray-600 bg-gray-100 p-2 rounded">
                  Please note that a fee of 3000 naira will apply monthly for
                  utilizing our fulfillment center, with an additional charge of
                  200 naira for packaging and sorting items per dispatch. Should
                  we manage the delivery of your goods, an extra fee will be
                  incurred. All charges will be paid into the company's account.
                  Failure to abide with the terms and condition will lead to deactivation of your account.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="text-[#008E56] text-xs sm:text-sm text-center mb-4 font-bold px-2">
          By clicking on "Register," you hereby agree to our rules and
          regulations governing the fulfillment center service.
        </div>

        <div className="flex flex-col sm:flex-row gap-4">
          <Btn
            onClick={onClose}
            className="font-medium border-2 text-gray-900 w-full text-sm sm:text-base"
            text="Cancel"
          />

          <Btn
            text={isLoading ? <Spinner /> : "Register"}
            onClick={onRegister}
            disabled={isLoading}
            className="bg-sec w-full text-white font-medium text-sm sm:text-base"
          />
        </div>
      </div>
      {showSuccessModal && (
        <SuccessModal
          visibilityState={showSuccessModal}
          setShowSuccessModal={handleModalClose}
        >
          <h2 className="text-center text-base sm:text-lg font-semibold">Success!</h2>
          <p className="text-center text-xs sm:text-sm mt-2">
            Fulfillment Center Registration Successful
          </p>
        </SuccessModal>
      )}
    </div>
  );
};

export default Modal;