import React, { useState, useEffect, useRef } from "react";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { FaFileUpload } from "react-icons/fa";

import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

import { useDispatch } from "react-redux";

import ReCAPTCHA from "react-google-recaptcha";

import { baseUrl } from "../../Utils/constants";
import google from "../../Assets/images/google.png";
import Sidebar from "../../Components/Auth/sidebar";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";
import { setToken, setUserProfile } from "../../Redux/features/authSlice";

const Signup = () => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [file, setFile] = useState(null);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // If the field is `user_type`, set the state directly
    if (name === "user_type") {
      setUserType(value !== "Select User Type" ? value : "");
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log("Selected File:", selectedFile);
    console.log("Selected File Details:", {
      name: selectedFile.name,
      type: selectedFile.type,
      size: selectedFile.size,
    });
    if (
      selectedFile &&
      selectedFile.type === "application/pdf" &&
      selectedFile.size <= 5 * 1024 * 1024
    ) {
      setFile(selectedFile);
    } else {
      toast.error("Please upload a PDF file less than 5MB.");
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleFileUpload = () => {
    document.getElementById("fileUpload").click();
  };

  const handleSignup = async (e) => {
    if (formData.user_type === "Select User Type" || !formData.user_type) {
      toast.error("Please select a valid user type.");
      return;
    }
    e.preventDefault();
    setIsLoading(true);

    if (!recaptchaToken) {
      toast.error("Please complete the reCAPTCHA");
      setIsLoading(false);
      return;
    }

    const URL = `${baseUrl}register`;
    let msg;
    try {
      const formDataWithFile = new FormData();
      Object.keys(formData).forEach((key) =>
        formDataWithFile.append(key, formData[key])
      );
      // if (file) {
      // 	formDataWithFile.append("file", file);
      // }

      if (userType === "business" && file) {
        formDataWithFile.append("cac_document", file);
      }

      formDataWithFile.append("g-recaptcha-response", recaptchaToken);
      
       // Debugging: Log the FormData content
    // console.log("FormData Entries:");
    for (let pair of formDataWithFile.entries()) {
      console.log(pair[0], pair[1]);
    }

      const res = await axios.post(URL, formDataWithFile, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log(res);
      setIsLoading(false);

      msg = res.data.message;
      const user = res.data.data;
      const token = res.data.token;

      dispatch(setUserProfile(user));
      dispatch(setToken(token));

      toast.success(msg);
      setTimeout(() => {
        navigate("/verify-signup", { state: { from: "signup" } });
      }, 2000);
    } catch (error) {
      msg = error?.response?.data?.message;
      setIsLoading(false);
      toast.error(msg);
    }
  };

  useEffect(() => {
    setFormData({ user_type: "" });
  }, []);

  return (
    <div className="relative flex">
      <ToastContainer autoClose={3000} />
      <Sidebar />
      <div className="md:w-[65%] ml-auto justify-center my-5 p-8">
        <h2 className="font-bold text-2xl">Create an Account</h2>
        <p className="text-sm my-3">
          To get started fill in your information or sign up with google
        </p>
        <div className="max-w-lg">
          <button className="flex items-center justify-center p-4 gap-4 bg-gray-300 rounded-md hover:bg-gray-500 text-sm font-medium w-full">
            <img src={google} className="w-5" alt="google" />
            Sign up with Google
          </button>
          <div className="flex items-center justify-between gap-2 my-5 ">
            <div className="w-[45%] h-[1px] bg-gray-500" />
            <h2 className="font-medium text-sm">OR</h2>
            <div className="w-[45%] h-[1px] bg-gray-500" />
          </div>
          <h2 className="text-sm">
            Already have an account?{" "}
            <Link to="/login" className="text-green-600 font-bold">
              Sign in
            </Link>
          </h2>
        </div>
        <div className="flex items-center gap-2">
          <form action="" className="max-w-lg w-full" onSubmit={handleSignup}>
            <Input
              input
              id="firstname"
              className={"border-2"}
              title="First Name"
              type="text"
              required={true}
              setItem={handleInputChange}
            />
            <Input
              input
              id="lastname"
              className={"border-2"}
              title="Last Name"
              type="text"
              required={true}
              setItem={handleInputChange}
            />
            <Input
              input
              id="email"
              type="email"
              className={"border-2"}
              title="Email Address"
              required={true}
              setItem={handleInputChange}
            />
            <Input
              input
              id="phone_number"
              type="tel"
              className={"border-2"}
              title="Phone Number"
              required={true}
              setItem={handleInputChange}
            />
            <Input
              dropdown
              id="user_type"
              className={"border-2"}
              title="User Type"
              data={["Select User Type", "individual", "business"]}
              required={true}
              setItem={handleInputChange}
            />
            {userType === "business" && (
              <div>
                <div
                  className="border-dashed border-2 border-gray-300 p-4 my-4 text-center cursor-pointer"
                  onClick={handleFileUpload}
                >
                  <FaFileUpload className="mx-auto text-4xl text-gray-400" />
                  <p className="text-sm text-gray-600 mt-2">
                    {/* Upload CAC Document for Indentity (max 3MB) */}
                    {file
                      ? `Uploaded: ${file.name}`
                      : "Upload CAC Document for Identity (max 3MB)"}
                  </p>
                </div>
                <input
                  type="file"
                  id="fileUpload"
                  name="cac_document"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="application/pdf"
                />
              </div>
            )}
            <Input
              input
              id="address"
              type="text"
              className={"border-2"}
              title="Address"
              required={true}
              setItem={handleInputChange}
            />
            <div className="relative">
              <Input
                input
                id="password"
                type={`${isPasswordVisible ? "text" : "password"}`}
                className={"border-2"}
                title="Password"
                required={true}
                setItem={handleInputChange}
              />
              <i
                className="absolute bottom-4 cursor-pointer right-5 text-2xl text-gray-400"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <AiOutlineEyeInvisible />
                ) : (
                  <AiOutlineEye />
                )}
              </i>
            </div>
            <div className="relative">
              <Input
                input
                id="confirm_password"
                type={`${isPasswordVisible ? "text" : "password"}`}
                className={"border-2"}
                title="Confirm Password"
                required={true}
                setItem={handleInputChange}
              />
              <i
                className="absolute bottom-4 cursor-pointer right-5 text-2xl text-gray-400"
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
              >
                {isPasswordVisible ? (
                  <AiOutlineEyeInvisible />
                ) : (
                  <AiOutlineEye />
                )}
              </i>
            </div>
            <Input
              input
              id="referral_code"
              type="text"
              className={"border-2"}
              title="Referral Code (optional)"
              setItem={handleInputChange}
            />
            <div className="flex items-center gap-3">
              <Input check id="agree" setItem={handleInputChange} />
              <h2 className="text-sm">
                I agree to eshop Africa{" "}
                <Link className="text-blue-800" to="/terms">
                  Term and Privacy Policy
                </Link>
              </h2>
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={handleRecaptchaChange}
            />
            <Btn
              text={"Create Account"}
              className="bg-pry w-full my-4 font-bold"
              loadingState={isLoading}
            />
            <Link to="/" className="flex items-center text-sm my-5">
              <i>
                <IoIosArrowBack />
              </i>
              <h2>Back to Homepage</h2>
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;