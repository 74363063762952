import React from 'react'
import ConfirmTransaction from '../../Components/Dashboard/Wallet/Withdraw/ConTransaction'

const ConTransactionPage = () => {
  return (
    <div>
      <ConfirmTransaction/>
    </div>
  )
}

export default ConTransactionPage
