import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BtnGrid from "../../../Button/BtnGrid";
import moneybag from "../../../../Assets/images/moneybag.png";
import { Link } from 'react-router-dom';


const NgnWithdraw = () => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const navigate = useNavigate();

  const handleSelectMethod = (method) => {
    setSelectedMethod(method);
  };

  const handleContinue = () => {
    if (selectedMethod === "bank") {
      navigate("/dashboard/wallet-bank-withdraw");
    } else if (selectedMethod === "usdt") {
      navigate("/dashboard/with-usdt");
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-center">
        <h1 className="text-2xl font-semibold">Wallet</h1>
        <p className="mt-2 text-gray-600">
          All transactions are secured and encrypted
        </p>
      </div>
      <div className="mt-8 flex flex-col gap-4 items-center">
        <BtnGrid
          onClick={() => handleSelectMethod("bank")}
          className={`w-full p-4 mb-4 flex items-center justify-between rounded-lg ${
            selectedMethod === "bank"
              ? "bg-[#008E56] text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          <div className="flex item-center gap-2">
            <img src={moneybag} alt="bank" className="w-6 h-9" />
   
            <div>
                          <p className="flex item-center">Bank Account </p>
                          <p className="flex item-center">Send cash to local bank </p>
            </div>
          </div>
        </BtnGrid>
        <BtnGrid
          onClick={() => handleSelectMethod("usdt")}
          className={`w-full p-4 flex items-center justify-between rounded-lg ${
            selectedMethod === "usdt"
              ? "bg-[#008E56] text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          <div className="flex item-center gap-4">
            <img src={moneybag} alt="cash" className="w-6 h-9" />
            <div>
            <p className="flex item-center">USDT (Instant) </p>
            <p className="flex item-center">Enjoy low network fees </p>
            </div>
          </div>
        </BtnGrid>
      </div>
      <div className="flex items-center justify-center pt-8">
        <BtnGrid
          className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24"
            onClick={handleContinue}
        >
          Continue
        </BtnGrid>
      </div>
    </div>
  );
};

export default NgnWithdraw;