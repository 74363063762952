import { useState } from "react";

import { useNavigate } from "react-router-dom";

import Btn from "../../Button/btn";
import PaymentModal from "../ExpressShipping/NewImplementation/PaymentModal";
import { FormatCurrency } from "../../../Utils/currency";

const SingleExpress = ({ data, walletDetails }) => {
  const [delStatus, setDelStatus] = useState(data?.deliveryStatus);
  const [payStatus, setPayStatus] = useState(data?.paymentStatus);
  console.log(data, "this is the data from the orders and payment");
  const navigateTo = useNavigate();

  const [modalShowing, setModalShowing] = useState(false);

  return (
    <div
      className="border-2 rounded-md p-4 grid grid-cols-1 sm:grid-cols-3 gap-6 text-sm my-6 cursor-pointer"
      onClick={() => navigateTo(`/dashboard/order-info/${data?.id}`)}
    >
      {(!data?.paymentStatus ||
        data?.paymentStatus.toLowerCase() !== "paid") && (
        <div className="sm:col-span-3 col-span-1">
          <div className="flex flex-row items-center rounded-full px-5 py-2 bg-[#ddd] w-fit text-[14px] font-[400]">
            {data?.paymentStatus.toLowerCase() === "paid" ? "" : "Draft"}
          </div>
        </div>
      )}

      <div>
        <h2 className="text-gray-500 uppercase">Sender</h2>
        <h2 className="font-medium">{data?.sender}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Receiver</h2>
        <h2 className="font-medium">{data?.receiver}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Tracking Id</h2>
        <h2 className="font-medium">{data?.trackingId}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Amount</h2>
        <h2 className="font-medium">{FormatCurrency(data?.amount || 0)}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Pickup</h2>
        <h2 className="font-medium">{data?.pickup}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Deliver to</h2>
        <h2 className="font-medium">{data?.delivery}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Estimated delivery time</h2>
        <h2 className="font-medium">{data?.estimatedDelivery}</h2>
      </div>
      <div>
        <h2 className="text-gray-500 uppercase">Shipment type</h2>
        <h2 className="font-medium">{data?.shipmentType}</h2>
      </div>
      <div
        className="flex flex-row sm:flex-col xl:flex-row gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        {delStatus.toLowerCase() !== "cancelled" && (
          <Btn
            text={payStatus}
            className={`${
              payStatus.toLowerCase() === "delivered"
                ? "bg-sec text-white"
                : payStatus.toLowerCase() === "processed"
                ? "bg-green-500 text-white"
                : payStatus.toLowerCase() === "pending"
                ? "bg-pry text-white"
                : payStatus.toLowerCase() === "cancelled"
                ? "bg-[#f00] text-white"
                : payStatus.toLowerCase() === "paid"
                ? "bg-[#d0d]"
                : "bg-blue-600 text-white"
            }  w-full capitalize`}
            onClick={() => {
              if (payStatus.toLowerCase() === "pay now") {
                setModalShowing(true);
              } else {
                return;
              }
            }}
          />
        )}
        <Btn
          text={delStatus}
          onClick={() => navigateTo(`/dashboard/order-info/${3}`)}
          className={`${
            delStatus.toLowerCase() === "delivered"
              ? "bg-sec text-white"
              : delStatus.toLowerCase() === "processed"
              ? "bg-green-500 text-white"
              : delStatus.toLowerCase() === "pending"
              ? "bg-pry"
              : delStatus.toLowerCase() === "cancelled"
              ? "bg-[#f00] text-white"
              : "bg-blue-600 text-white"
          }  w-full capitalize`}
        />
      </div>
      {modalShowing && (
        <PaymentModal
          setPayStatus={setPayStatus}
          setModalShowing={setModalShowing}
          balance={walletDetails?.wallet_balance}
          amount={data?.amount}
          shipmentId={data?.id}
        />
      )}
    </div>
  );
};

export default SingleExpress;
