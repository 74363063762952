import React from "react";

import axios from "axios";

import { useState } from "react";

import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

import OTP from "../../Utils/OTP";
import { baseUrl, config } from "../../Utils/constants";
import Sidebar from "../../Components/Auth/sidebar";
import Btn from "../../Components/Button/btn";
import Input from "../../Components/Form/Input";

const ForgotPassword = () => {
	const [email, setEmail] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [activePage, setActivePage] = useState(0);
	const [otp, setOtp] = useState("");
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [passwords, setPasswords] = useState({});

	const { token } = useSelector((state) => state.auth);
	const navigateTo = useNavigate();

	const resetPassword = async () => {
		const url = `${baseUrl}forgot-password`;
		setIsLoading(true);
		try {
			const res = await axios.post(url, { email }, config(token));
			console.log(res);
			setIsLoading(false);
			setTimeout(() => {
				setActivePage(1);
			}, 2000);
			toast.success(res.data.message);
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message);
			setIsLoading(false);
		}
	};

	const verify = async (e) => {
		setActivePage(2);
	};

	const handlePasswordChange = (e) => {
		setPasswords({ ...passwords, [e.target.name]: e.target.value });
	};

	const changePassword = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		const url = `${baseUrl}reset-password`;

		let msg;
		try {
			const res = await axios.post(url, { code: otp, ...passwords });
			console.log(res);
			msg = res.data.message;
			toast.success(msg);
			setTimeout(() => {
				navigateTo("/login");
			}, 2000);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			msg = error?.response?.data?.message;
			toast.error(msg);
			setIsLoading(false);
		}
	};

	return (
		<div className='relative flex'>
			<Sidebar />
			<ToastContainer autoClose={3000} />

			<div className='w-full md:w-[65%] ml-auto h-[600px] justify-center my-5 p-8 overflow-y-scroll'>
				{activePage === 0 && (
					<div className='max-w-md'>
						<h2 className='font-bold text-2xl '>Forgot Password</h2>
						<p className='my-2 text-sm'>
							Enter your email to reset your password
						</p>
						<form
							action=''
							onSubmit={(e) => {
								e.preventDefault();
								resetPassword();
							}}
						>
							<Input
								text='email'
								className='bg-gray-100'
								title='E-mail'
								type='email'
								input
								setItem={(e) => setEmail(e.target.value)}
							/>
							<div className='flex items-center justify-between my-4'>
								<Btn
									loadingState={isLoading}
									text='Continue'
									className='bg-pry'
								/>
								<Link className='text-sm underline' to='/login'>
									Back to login
								</Link>
							</div>
						</form>
					</div>
				)}
				{activePage === 1 && (
					<form className='' onSubmit={verify}>
						<h2 className='font-bold text-2xl '>Verify OTP</h2>
						<h2 className='text-base my-3'>
							Please enter the OTP sent to your email address check inbox or spam
						</h2>
						<OTP length={5} otp={otp} setOtp={setOtp} />
						<Btn
							loadingState={isLoading}
							text={"Verify"}
							className='bg-pry my-6'
						/>
					</form>
				)}
				{activePage === 2 && (
					<form action='' onSubmit={changePassword}>
						<div className='relative'>
							<h2 className='font-bold text-2xl '>Set New Password</h2>
							<Input
								input
								id='password'
								type={`${isPasswordVisible ? "text" : "password"}`}
								className={"border-2"}
								title='New Password'
								required={true}
								setItem={handlePasswordChange}
							/>
							<i
								className='absolute bottom-4 cursor-pointer right-5 text-2xl text-gray-400'
								onClick={() => setIsPasswordVisible(!isPasswordVisible)}
							>
								{isPasswordVisible ? (
									<AiOutlineEyeInvisible />
								) : (
									<AiOutlineEye />
								)}
							</i>
						</div>
						<div className='relative'>
							<Input
								input
								id='confirm_password'
								type={`${isPasswordVisible ? "text" : "password"}`}
								className={"border-2"}
								title='Confirm Password'
								required={true}
								setItem={handlePasswordChange}
							/>
							<i
								className='absolute bottom-4 cursor-pointer right-5 text-2xl text-gray-400'
								onClick={() => setIsPasswordVisible(!isPasswordVisible)}
							>
								{isPasswordVisible ? (
									<AiOutlineEyeInvisible />
								) : (
									<AiOutlineEye />
								)}
							</i>
						</div>
						<Btn
							loadingState={isLoading}
							text={"Submit"}
							className='bg-pry my-6'
						/>
					</form>
				)}
			</div>
		</div>
	);
};

export default ForgotPassword;
