import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BtnGrid from "../../../Button/BtnGrid";
import OTP from "../../../../Utils/OTP";
import CustomModal from "./CustomModal";
import SuccessModal from "../../../../Components/Modals/success";
import Withdraw from "./WalletBankwithdraw";

const ConfirmTransaction = () => {
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleWithdraw = () => {
    setShowOtpModal(true);
    // Handle the withdrawal process here
    //console.log('Withdrawing...');
  };

  const handleOtpSubmit = () => {
    // Handle OTP submission
    setShowOtpModal(false);
    setShowSuccessModal(true);
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    // navigate("/dashboard/card");
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  return (
    <div className="flex items-center justify-center p-3 md:p-8 lg:p-10">
      <div className="w-full flex flex-col bg-whit p-6 rounded shadow-md">
        <button onClick={() => navigate(-1)} className="text-lg text-left mb-6">
          {" "}
          Back
        </button>
        <h1 className="text-lg font-semibold mb-6 text-center">
          Confirm Transaction
        </h1>
        <div className="flex flex-col gap-4">
          <div className="flex justify-between">
            <span>Send:</span>
            <span>{state.amount}</span>
          </div>
          <div className="flex justify-between">
            <span>Recipient:</span>
            <span>{state.walletAddress}</span>
          </div>
          <div className="flex justify-between">
            <span>Network:</span>
            <span>{state.network}</span>
          </div>
          <div className="flex justify-between">
            <span>Recipient Receives:</span>
            <span>{parseFloat(state.amount) - 2.5}</span>
          </div>
          <div className="flex justify-between">
            <span>Fees:</span>
            <span>2.5</span>
          </div>
          <div className="flex justify-center mt-4">
            <BtnGrid
              onClick={handleWithdraw}
              className="bg-[#008E56] text-white  px-10 py-3 rounded-lg md:px-240 lg:px-24"
            >
              Withdraw
            </BtnGrid>
          </div>
        </div>
      </div>

      {/* OTP Modal */}
      <CustomModal isOpen={showOtpModal} onClose={() => setShowOtpModal(false)}>
        <div className="p-3 max-w-sm mx-auto">
          <h2 className="text-center text-lg font-semibold mb-3">
            Enter Transaction Pin
          </h2>
          <div className="flex justify-center mb-5">
            <OTP length={4} onChange={handleOtpChange} value={otp} />
          </div>
          <div className="flex justify-around">
            <BtnGrid
              onClick={() => setShowOtpModal(false)}
              className="bg-white border border-gray-300 text-gray-700 px-5 py-2 rounded-md"
            >
              Cancel
            </BtnGrid>
            <BtnGrid
              onClick={handleOtpSubmit}
              className="bg-[#008E56] text-white px-5 py-2 rounded-md"
            >
              Submit
            </BtnGrid>
          </div>
        </div>
      </CustomModal>

      {/* Success Modal */}
      {showSuccessModal && (
        <SuccessModal
          visibilityState={showSuccessModal}
          setShowSuccessModal={handleModalClose}
        >
          <h2 className="text-center text-lg font-semibold">Success!</h2>
          <p className="text-center mt-2">
            Your USDT Withdrawal has been successful
          </p>
        </SuccessModal>
      )}
    </div>
  );
};

export default ConfirmTransaction;
