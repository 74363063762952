import React, { useState, useEffect } from "react";

import { CiFilter } from "react-icons/ci";

import axios from "axios";

import { useSelector } from "react-redux";

import { Spinner } from "react-activity";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";

const STATUS_OPTIONS = {
  successful: { label: 'Successful', class: 'bg-green-100 text-green-800' },
  pending: { label: 'Pending', class: 'bg-blue-100 text-blue-800' },
  processed: { label: 'Processed', class: 'bg-yellow-100 text-yellow-800' },
  cancelled: { label: 'Cancelled', class: 'bg-red-100 text-red-800' }
};

const ExpressShipment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allShipments, setAllShipments] = useState([]);
  const [filter, setFilter] = useState("");
  const { adminToken } = useSelector((state) => state.admin);

  const columns = [
    { header: "Name", accessorKey: "user.fullname" },
    { header: "Email", accessorKey: "user.email" },
    { header: "Phone Number", accessorKey: "user.phone_number" },
    { header: "Ship From", accessorKey: "sender_country" },
    { header: "Ship To", accessorKey: "receiver_country" },
    {
      header: "Date",
      accessorKey: "created_at",
      cell: ({ cell }) => {
        const rawDate = cell.getValue();
        const formattedDate = new Date(rawDate).toLocaleDateString("en-GB");
        return <span>{formattedDate}</span>;
      },
    },
    { header: "Type", accessorKey: "shipment_type" },
    { header: "Pick Date", accessorKey: "pickup_date" },
    {
      header: "Status",
      accessorKey: "shipment_status",
      cell: ({ cell }) => {
        const status = cell.getValue();
        const statusConfig = STATUS_OPTIONS[status] || STATUS_OPTIONS.cancelled;
        
        return (
          <span className={`${statusConfig.class} p-3 rounded-md font-medium text-center`}>
            {statusConfig.label}
          </span>
        );
      },
    },
  ];

  const getExpressShipments = async () => {
    setIsLoading(true);
    setError(null);

    const url = `${baseUrl}admin/get-shipments?shipment_type=express&status=all`;

    try {
      const { data } = await axios.get(url, config(adminToken));
      setAllShipments(data.data);
    } catch (error) {
      console.error('Error fetching shipments:', error);
      setError(error.response?.data?.message || 'Failed to fetch shipments');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getExpressShipments();
  }, []);

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
  };

  // Fixed filtering logic to use shipment_status instead of status
  const filteredData = filter
    ? allShipments.filter((item) => item.shipment_status === filter)
    : allShipments;

  const exportToCSV = () => {
    // Helper function to safely access nested properties
    const getNestedValue = (obj, path) => {
      const keys = path.split('.');
      return keys.reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : ''), obj);
    };

    const headers = columns.map(col => col.header).join(',');
    const rows = filteredData.map(row =>
      columns
        .map(col => `"${getNestedValue(row, col.accessorKey) || ''}"`)
        .join(',')
    );
    
    const csvContent = [headers, ...rows].join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `express_shipments_${new Date().toISOString().split('T')[0]}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  if (isLoading) {
    return (
      <div className="h-[20vh] flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[20vh] flex items-center justify-center text-red-600">
        {error}
      </div>
    );
  }

  if (allShipments.length < 1) {
    return (
      <div className="h-[20vh] flex items-center justify-center">
        <h2 className="font-medium text-lg">No shipment at the moment</h2>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <p className="text-center font-bold p-2 text-lg md:text-base sm:text-sm">
          All Express Shipments ({filteredData.length})
        </p>

        <div className="flex items-center gap-4">
          <div className="flex items-center">
            <CiFilter className="mr-1" />
            <select
              value={filter}
              onChange={handleFilterChange}
              className="border p-2 rounded"
            >
              <option value="">All Statuses</option>
              {Object.entries(STATUS_OPTIONS).map(([value, { label }]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={exportToCSV}
            className="bg-[#055429] text-white px-4 py-2 rounded hover:bg-[#044321] transition-colors"
          >
            Export
          </button>
        </div>
      </div>

      <TanTable 
        columnData={columns} 
        data={filteredData} 
      />
    </div>
  );
};

export default ExpressShipment;