
import React, { useState,  } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../../Utils/constants";
import DeliveryOptions from "./DeliveryOptions";
import ItemDescription from "./ItemDescription";
import OrderSummary from "./OrderSummary";
import Payments from "./Payments";
import SenderReceiverDetails from "./SenderReceiverDetails";

const MainForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [shipmentId, setShipmentId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [shippingRateError, setShippingRateError] = useState(false);

  // Define initial form state
  const initialFormState = {
    cargoType: "",
    shipmentMethod: "",
    pickupDate: "",
    pickupArea: "",
    senderName: "",
    senderEmail: "",
    senderAddress: "",
    senderPhone: "",
    senderCity: "",
    senderState: "",
    senderCountry: "",
    senderZipcode: "",
    receiverName: "",
    receiverEmail: "",
    receiverAddress: "",
    receiverPhone: "",
    receiverCity: "",
    receiverState: "",
    receiverCountry: "",
    receiverZipcode: "",
    itemValue: "",
    itemCategory: "",
    itemDescription: "",
    weight: "",
    insurance: "",
    additionalNote: "",
    shipment_image: null,
    basePrice: "",
    price: "",
  };

  // Initialize form data with initial state directly (no localStorage)
  const [formData, setFormData] = useState(initialFormState);

  const [formErrors, setFormErrors] = useState({});

  // Update form data using React state only (no localStorage)
  const handleChange = (e, additionalData = {}) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
      ...additionalData,
    };
    setFormData(updatedFormData);
  };

  const handleBatchChange = (updates) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...updates,
    }));
  };

  const handleFileChange = (e) => {
    const updatedFormData = {
      ...formData,
      shipment_image: e.target.files[0],
    };
    setFormData(updatedFormData);
  };

  // Validate required fields for the current step
  const validateFields = (fields) => {
    const errors = {};
    fields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
      }
    });
    return errors;
  };

  const validateCurrentStep = () => {
    let requiredFields = [];
    if (currentStep === 1) {
      requiredFields = [
        "senderName",
        "senderEmail",
        "senderPhone",
        "senderAddress",
        "senderCity",
        "senderState",
        "senderCountry",
        "senderZipcode",
        "receiverName",
        "receiverEmail",
        "receiverPhone",
        "receiverAddress",
        "receiverCity",
        "receiverState",
        "receiverCountry",
        "receiverZipcode",
        "cargoType",
        "shipmentMethod",
      ];
      if (formData.shipmentMethod === "pickup") {
        requiredFields.push("pickupDate", "pickupArea");
      }
    } else if (currentStep === 2) {
      requiredFields = [
        "itemCategory",
        "itemValue",
        "itemDescription",
        "weight",
        "shipment_image",
      ];
    } else if (currentStep === 3) {
      requiredFields = ["cargoType", "shipmentMethod"];
      if (formData.shipmentMethod === "pickup") {
        requiredFields.push("pickupDate", "pickupArea");
      }
    } else if (currentStep === 4) {
      requiredFields = ["price"];
    }

    const errors = validateFields(requiredFields);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const nextStep = () => {
    if (currentStep === 3 && shippingRateError) {
      toast.error("Please resolve the shipping rate calculation error before proceeding.");
      return;
    }

    if (validateCurrentStep()) {
      setIsSubmitting(false);
      setCurrentStep((prev) => prev + 1);
    } else {
      toast.error("Please fill in all required fields.");
    }
  };

  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting || currentStep !== 4) return;
    if (!validateCurrentStep()) return;

    setIsSubmitting(true);
    setLoading(true);
    setError(null);

    try {
      const formDataToSend = new FormData();

      if (!formData.price || isNaN(parseFloat(formData.price))) {
        throw new Error("Invalid total cost calculation");
      }

      const dataToSubmit = {
        cargo_type: formData.cargoType,
        shipment_method: formData.shipmentMethod,
        pickup_date: formData.pickupDate || null,
        pickup_area_id: formData.pickupArea?.id || null,
        sender_name: formData.senderName,
        sender_email: formData.senderEmail,
        sender_phone: formData.senderPhone,
        sender_address: formData.senderAddress,
        sender_country: formData.senderCountry,
        sender_state: formData.senderState,
        sender_city: formData.senderCity,
        sender_zipcode: formData.senderZipcode,
        receiver_name: formData.receiverName,
        receiver_email: formData.receiverEmail,
        receiver_phone: formData.receiverPhone,
        receiver_address: formData.receiverAddress,
        receiver_country: formData.receiverCountry,
        receiver_state: formData.receiverState,
        receiver_city: formData.receiverCity,
        receiver_zipcode: formData.receiverZipcode,
        category: formData.itemCategory,
        item_value: formData.itemValue,
        item_description: formData.itemDescription,
        additional_note: formData.additionalNote || "",
        weight: formData.weight,
        insurance_id: formData.insurance?.id || null,
        price: parseFloat(formData.price).toFixed(2),
      };

      Object.entries(dataToSubmit).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formDataToSend.append(key, value);
        }
      });

      if (formData.shipment_image) {
        formDataToSend.append("shipment_image", formData.shipment_image);
      }

      const response = await axios.post(
        `${baseUrl}cargo-shipments`,
        formDataToSend,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        const shipmentId = response.data.data?.data?.id;
        if (!shipmentId) {
          throw new Error("No shipment ID received from server");
        }

        setShipmentId(shipmentId);
        toast.success(response.data.message);
        setFormData(initialFormState); // Reset form data to initial state
        setCurrentStep(5); // Move to payment step after reset
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || err.message || "An error occurred";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setIsSubmitting(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <SenderReceiverDetails
            formData={formData}
            handleChange={handleChange}
            handleBatchChange={handleBatchChange}
            errors={formErrors}
          />
        );
      case 2:
        return (
          <ItemDescription
            formData={formData}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            errors={formErrors}
          />
        );
      case 3:
        return (
          <DeliveryOptions
            formData={formData}
            handleChange={handleChange}
            selectedCountry={formData.receiverCountry}
            onShippingRateError={(hasError) => setShippingRateError(hasError)}
          />
        );
      case 4:
        return (
          <OrderSummary
            formData={formData}
            handleChange={handleChange}
            currentStep={currentStep}
            handleSubmit={handleSubmit}
            loading={loading}
            prevStep={prevStep}
          />
        );
      case 5:
        return (
          <div>
            <Payments shipmentID={shipmentId} hideMainFormButtons={true} />
          </div>
        );
      default:
        return null;
    }
  };

  const steps = [
    { title: "Shipping Details", step: 1 },
    { title: "Item Details", step: 2 },
    { title: "Delivery Options", step: 3 },
    { title: "Order Summary", step: 4 },
    { title: "Payment", step: 5 },
  ];

  return (
    <div className="bg-transparent w-full rounded-[10px] px-3 py-2.5 outline-[#008E56] focus:outline-[#008E56]">
      <ToastContainer position="top-right" />
      <div className="border-b p-4">
        <div className="hidden md:flex md:gap-2">
          {steps.map((item) => (
            <div
              key={item.step}
              className={`text-sm ${
                currentStep === item.step ? "text-[#008E56]" : "text-gray-500"
              }`}
            >
              {item.title}
            </div>
          ))}
        </div>

        <div className="md:hidden">
          <div className="flex justify-between mb-2">
            {steps.map((item) => (
              <div
                key={item.step}
                className={`relative flex flex-col items-center ${
                  currentStep === item.step ? "text-[#008E56]" : "text-gray-500"
                }`}
              >
                <div
                  className={`w-6 h-6 rounded-full border-2 flex items-center justify-center 
                  ${
                    currentStep === item.step
                      ? "border-[#008E56] bg-[#008E56] text-white"
                      : currentStep > item.step
                      ? "border-[#008E56] bg-[#008E56] text-white"
                      : "border-gray-300 bg-white"
                  }`}
                >
                  {currentStep > item.step ? "✓" : item.step}
                </div>
                <div className="text-xs mt-1 text-center w-16 sm:w-20">
                  {item.title}
                </div>
              </div>
            ))}
            <div className="absolute top-5 left-0 right-0 h-0.5 bg-gray-200 -z-10">
              <div
                className="h-full bg-[#008E56] transition-all duration-300"
                style={{
                  width: `${((currentStep - 1) / (steps.length - 1)) * 100}%`,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        {renderStep()}

        {currentStep !== 5 && currentStep !== 4 && (
          <div className="p-6 bg-gray-50 flex justify-center gap-4 sm:gap-12">
            {currentStep > 1 && (
              <button
                type="button"
                onClick={prevStep}
                className="px-4 py-2 bg-gray-200 rounded flex-1 max-w-xs hover:bg-gray-300"
                disabled={loading}
              >
                Back
              </button>
            )}
           <button
              type="button"
              onClick={nextStep}
              className={`px-4 py-2 rounded flex-1 max-w-xs ${
                currentStep === 3 && shippingRateError
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-[#008E56] text-white hover:bg-[#006E44]"
              }`}
              disabled={loading || (currentStep === 3 && shippingRateError)}
            >
              Next
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default MainForm;