import React from "react";
import TanTable from "../../../Utils/TanTable";

const DhlDomestic = ({ isLoading, zones }) => {
	const columns = [
		{ header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
		{ header: "Zones", accessorKey: "zone" },
		{ header: "States", accessorKey: "state" },
	];

	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the states belonging to Different Zones
			</h1>
			<TanTable columnData={columns} data={zones} loadingState={isLoading} />
		</div>
	);
};

export default DhlDomestic;
