import React from "react";
import Table from "../Layout/Table";
import Record from "./records";

const ReferralRecords = ({ referralHeader, isLoading, records }) => {
  return (
    <Table
      cols='6'
      minSize='1100px'
      headerContent={referralHeader}
      loadingState={isLoading}
      data={records}
    >
      {records?.map((item, index) => (
        <Record item={item} key={index} index={index} isLoading={isLoading} />
      ))}
    </Table>
  );
};

export default ReferralRecords;
