import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import axios from "axios";

import { toast } from "react-toastify";

import TanTable from "../../../Utils/TanTable";
import { baseUrl, config } from "../../../Utils/constants";
import { FormatCurrency } from "../../../Utils/currency";

const NgnZone = ({ prices, isLoading }) => {
	const columns = [
		{ header: "S/N", accessorKey: "sn", cell: ({ row }) => row.index + 1 },
		{ header: "KG", accessorKey: "kg" },
		{
			header: "Zone 1 A (south west)",
			accessorKey: "zone 1 ",
			cell: ({ row }) => FormatCurrency(row.original.zone1),
		},
		{
			header: "Zone 2 B (East & south)",
			accessorKey: "zone2",
			cell: ({ row }) => FormatCurrency(row.original.zone2),
		},
		{
			header: "Zone 3 C (North)",
			accessorKey: "zone3",
			cell: ({ row }) => FormatCurrency(row.original.zone3),
		},
	];

	return (
		<div className='w-full'>
			<h1 className='text-center '>
				Below are the price list belonging to different states in Nigeria based
				on their zones
			</h1>
			<TanTable columnData={columns} data={prices} loadingState={isLoading} />
		</div>
	);
};

export default NgnZone;
