import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Spinner } from "react-activity";
import { toast } from "react-toastify";
import DoorstepIcon from "../../../Icons/DoorstepIcon";
import MarkIcon from "../../../Icons/MarkIcon";
import SignatureIcon from "../../../Icons/SignatureIcon";
import SwiftDeliveryIcon from "../../../Icons/SwiftDeliveryIcon";
import { baseUrl } from "../../../../Utils/constants";
import { convertCurrencyToNaira } from "../../../../Utils/currency";
import InfoBox from "./InfoBox";

const DeliveryOption = ({
  insurance,
  setInsurance,
  price,
  setPrice,
  service,
  receiverAddressLine1,
  receiverAddressLine2,
  receiverAddressLine3,
  receiverCity,
  receiverState,
  receiverCountry,
  country,
  receiverPostalAddress,
  items,
  handleChangeStep,
}) => {
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [shippingRate, setShippingRate] = useState(0);

  useEffect(() => {
    const handleFetchRateAndInsurance = async () => {
      try {
        // Fetch insurance options
        const insuranceResponse = await axios.get(`${baseUrl}get-insurance`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInsurancePlans(insuranceResponse.data.data.insurance);

        // Check the service type and fetch the rate accordingly
        if (service === "dhl") {
          const dhlRateResponse = await axios.post(
            `${baseUrl}dhl-rate`,
            {
              receiver_address_1: receiverAddressLine1,
              receiver_address_2: receiverAddressLine2,
              receiver_address_3: receiverAddressLine3,
              receiver_country_code: receiverCountry,
              receiver_state: receiverState,
              receiver_city: receiverCity,
              receiver_postal_address: receiverPostalAddress,
              weight: Number(items[0].weight),
              length: Number(items[0].length),
              height: Number(items[0].height),
              width: Number(items[0].width),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setPrice(dhlRateResponse.data.data.price);
          setShippingRate(dhlRateResponse.data.data.price);
        } else if (service === "ups") {
          const upsRateResponse = await axios.post(
            `${baseUrl}ups-rate`,
            {
              receiver_address_1: receiverAddressLine1,
              receiver_address_2: receiverAddressLine2,
              receiver_address_3: receiverAddressLine3,
              country: receiverCountry,
              receiver_state: receiverState,
              receiver_city: receiverCity,
              receiver_postal_address: receiverPostalAddress,
              weight: Number(items[0].weight),
              length: Number(items[0].length),
              height: Number(items[0].height),
              width: Number(items[0].width),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setPrice(upsRateResponse.data?.data.data);
          setShippingRate(upsRateResponse.data?.data.data);
        }
      } catch (error) {
        console.log("An error occurred");
        toast.error("Failed to get rate, crosscheck receiver's details.");
        handleChangeStep(3);
      } finally {
        setLoading(false);
      }
    };

    handleFetchRateAndInsurance();
  }, [
    service,
    receiverAddressLine1,
    receiverAddressLine2,
    receiverAddressLine3,
    receiverCity,
    receiverState,
    receiverCountry,  // Added 'receiverCountry'
    receiverPostalAddress,
    items,
    token,
    handleChangeStep,
    setPrice,  // Added 'setPrice'
  ]);

  if (loading) {
    return (
      <div className="flex flex-row h-full w-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-6">
      <div className="flex flex-col gap-6 w-full">
        <h3 className="text-[#282828] text-center font-[500] text-[24px]">
          Choose a shipping option
        </h3>
        <div className="flex flex-col gap-4 w-full">
          <p className="text-[#282828] text-center">
            All shipping rates include{" "}
            <span className="font-semibold">doorstep delivery</span> and{" "}
            <span className="font-semibold">receiver's signature</span> would be
            required.
          </p>
          <InfoBox
            content={
              "The customs clearance fee ranges from 30% to 50% of the declared item value. Make sure to accurately declare the value of the item you are shipping. If you declare a lower value, it is considered fraud and may result in extra charges."
            }
          />
        </div>
        <div className="p-1 flex flex-col gap-2 bg-[#E4E4E466] rounded-2xl w-full">
          <div className="flex flex-col gap-2 p-4 md:p-6">
            <div className="flex flex-row items-center justify-between">
              <h2 className="font-[500] text-[24px] text-[#282828]">
                {service === "dhl" ? "DHL" : "UPS"}
              </h2>
              <h2 className="font-[500] text-[24px] text-[#282828]">
                {convertCurrencyToNaira(Number(shippingRate || 0))}
              </h2>
            </div>
            <div className="flex flex-row items-center justify-between">
              <p className="text-[#282828] text-sm">
                Delivery in {service === "dhl" ? "2-4" : "7-10"} working days
              </p>
              <p className="text-[#282828] text-sm">
                Estimated custom clearance {convertCurrencyToNaira(0)}
              </p>
            </div>
          </div>
          <div className="rounded-full h-1 w-full bg-[#D9D9D9]" />
          <div className="flex flex-col gap-4 md:gap-6 p-4 md:p-6">
            <div className="flex flex-row items-center gap-2.5">
              <DoorstepIcon styles={"h-[24px] w-[24px]"} color={"#282828"} />
              <p className="text-[#282828]">Doorstep Delivery</p>
              <MarkIcon />
            </div>
            <div className="flex flex-row items-center gap-2.5">
              <SignatureIcon styles={"h-[24px] w-[24px]"} color={"#282828"} />
              <p className="text-[#282828]">Receiver's Signature</p>
              <MarkIcon />
            </div>
            <div className="flex flex-row items-center gap-2.5">
              <SwiftDeliveryIcon styles={"h-[24px] w-[24px]"} color={"#282828"} />
              <p className="text-[#282828]">Swift Delivery Price Included</p>
              <MarkIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6 w-full">
        <h3 className="text-[#282828] text-center font-[500] text-[24px]">
          Choose an insurance option
        </h3>
        <div className="w-full flex justify-center">
          <InfoBox
            content={
              "We recommend purchasing Premium/Extended insurance to protect your goods in transit."
            }
          />
        </div>
        <div className="p-1 flex flex-col gap-4 rounded-2xl w-full">
          {insurancePlans.map((insurancePlan, index) => (
            <div
              key={index}
              className="flex flex-row items-center justify-between p-4 rounded-[10px] bg-[#E4E4E480] border-[#2828284D] border-[1px] cursor-pointer"
              onClick={() => {
                setInsurance(insurancePlan);
              }}
            >
              <div className="flex flex-col gap-2.5">
                <h3 className="text-[#282828] text-[24px] font-[500]">
                  {insurancePlan.insurance_name}
                </h3>
                <p className="text-[#28282899]">
                  {insurancePlan.insurance_coverage}
                </p>
              </div>
              <div className="flex flex-col items-center min-w-[100px] w-[130px] gap-1">
                <div className="border-4 border-[#008E56] h-[24px] w-[24px] rounded-full flex justify-center items-center">
                  {insurance && insurance?.id === insurancePlan.id && (
                    <div className="h-[12px] w-[12px] rounded-full bg-[#008E56]"></div>
                  )}
                </div>
                <h3 className="text-[#008e56] font-semibold">
                  {convertCurrencyToNaira(Number(insurancePlan.cost))}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DeliveryOption;