import React from 'react';

const Express = () => {
  const nationwideItems = [
    'Cooked meals',
    'Alcohol and Non-alcoholic drinks',
    'Pets',
    'Fruits & Vegetables',
    'Ammunition',
    'Bank bills, notes, or currency',
    'Fireworks',
    'Hazardous Waste',
    'Perishables',
    'Radioactive materials',
    'Illegal Items, according to law enforcement',
  ];

  const internationalItems = [
    'Cooked meals',
    'Raw meat',
    'Snails',
    'Fruits & Vegetables',
    'Pets',
    'Alcohol',
    'Grains',
    'Pasta (Noodles)',
    'Perfumes',
    'Gold',
    'Minerals & Precious Stones',
    'Ammunition',
    'Bank bills, notes or currency',
    'Fireworks',
    'Hazardous Waste',
    'Perishables',
    'Radioactive materials',
    'Illegal items, according to law enforcement',
    'Herbs/ Herbal medicine - Can be shipped with a phytosanitary/Quarantine certificate',
    'Medication - Prohibited in Canada/US - Can be shipped with a signed and stamped doctor\'s prescription to other countries',
    'Dried Fish - Should not exceed 0.5kg per waybill',
    'Milk/ Dairy products',
    'Skincare to Spain - The receiver must have a sanitary import license for clearance',
    'Batteries (Prohibited in all countries)',
    'Power bank(Prohibited in all countries)',
    'Phones (Only 2 pcs of phones per waybill is allowed)',
    'Alcoholic Beverages',
    'Palm oil',
  ];

  const moreinternationalItems = [
    'All forms of fish',
    'Bees',
    'Bearer Bonds',
    'Cash, currency, negotiable instruments',
    'Cash on Delivery (COD) shipment',
    'Gambling devices',
    'Human Remains',
    'Live Fish',
    'Live Household Pets',
    'Live Monkeys',
    'Lottery tickets and materials',
    'Money (Paper/coins)',
    'Negotiable Instruments (stocks, bonds, cash letters, and other negotiable instruments equivalent to cash)',
    'Pornography and items of pornography',
    'Shipments prohibited by any law, statute, or regulation'
  ];

  return (
    <div className="px-4 py-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
        <div>
          <h2 className="text-xl font-bold mb-4">Express Nationwide</h2>
          <ul className="list-decimal list-inside text-left">
            {nationwideItems.map((item, index) => (
              <li key={index} className="mb-2">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4 ">Express International</h2>
          <ul className="list-decimal list-inside text-left">
            {internationalItems.map((item, index) => (
              <li key={index} className="mb-2">
                {item}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h2 className="text-xl font-bold mb-4 ">More Express International</h2>
          <ul className="list-decimal list-inside text-left">
            {moreinternationalItems.map((item, index) => (
              <li key={index} className="mb-2">
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Express;
