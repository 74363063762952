import React from 'react'
import CardScreen from "../../Components/Dashboard/VirtualCard/CardScreen"



const CardScreenPage = () => {
  return (
    <div>
      <CardScreen/>
    </div>
  )
}

export default CardScreenPage
