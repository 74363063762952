import React from 'react'
// import ProhibitedItems from '../../Components/Prohibited/Express'
import ManageProhibitedItems from '../../Components/Prohibited/ManageProhibitedItem'

const ProhibitedPage = () => {
  return (
    <div>
      
      <ManageProhibitedItems />

    </div>
  )
}

export default ProhibitedPage
