import { navItems } from "../../../../Constants";

const StepsHeader = (
    {
        currentStep,
        handleChangeStep
    }
) => {

    return (
        <div className="sm:block hidden">
            <div className="flex flex-row items-center w-full justify-between flex-wrap gap-4">
                {navItems.map((item, index) => (
                    <div
                        key={index}
                        className={`flex flex-col gap-1 cursor-pointer`}
                        onClick={() => handleChangeStep(index + 2)}
                    >
                        <h3 className={`${currentStep === index + 2 ? "text-[#008E56] font-[500]" : "text-[#28282899]"}`}>
                           {item} 
                        </h3>
                        
                        <div className={`h-[6px] ${currentStep === index + 2 ? "w-[100%] bg-[#008E56] rounded-t-xl" : "bg-transparent"}`}></div>
                    </div>
                ))}
            </div>
            <div className="xy:bg-[#D9D9D9] bg-transparent w-full h-[6px] rounded-t-xl -mt-[6px] block">

            </div>
        </div>
    )
}

export default StepsHeader;