import { useEffect, useState, useCallback } from "react";

import { toast } from "react-toastify";

import { useSelector } from "react-redux";

import axios from "axios";

import Btn from "../../Button/btn";
import { baseUrl, config } from "../../../Utils/constants";
import Virtualsm from "../../../Assets/images/Virtualsm2.png";
import unbeatable from "../../../Assets/images/unbeatable.png";
import virtualCardSmall from "../../../Assets/images/virtualCardSmall.png";

import CreationProcess from "./CreationProcess";
import ConfirmPin from "./confirmPin";
import GenerateCard from "./generate"; 

const CardType = () => {
  const [currentSlide, setCurrentSlide] = useState("CreatePin");
  // const [usdRate, setUsdRate] = useState(null);
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [usdRate, setUsdRate] = useState(() => {
    const storedRate = localStorage.getItem("usdRate");
    return storedRate ? JSON.parse(storedRate) : null;
  });

  // Function to handle card creation process
  const [formData, setFormData] = useState({
    dob: "",
    street: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
    identification_type: "",
    identification_number: "",
    photo: null,
    identity_type: "",
    identity_number: "",
    identity_country: "",
    identity_image: null,
    dollar_amount_to_topup: ""
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    for (const key in formData) {
      if (formData[key] === "" || formData[key] === null) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Function to handle back navigation
  const goBackHandler = () => {
    setCurrentSlide("GenerateCard");
  };

  const handleCardCreationProcess = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    if (loading) return;
    setLoading(true);

    if (!isFormValid) {
      toast.error("Please fill in all fields before submitting.");
      return;
    }

    try {
      const data = new FormData();

      Object.keys(formData).forEach((key) => {
        if (key === "photo" || key === "identity_image") {
          if (formData[key]) {
            data.append(key, formData[key]);
          }
        } else {
          data.append(key, formData[key]);
        }
      });

      const response = await axios.post(`${baseUrl}create-dollar-card`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        }
      });

      console.log("Response:", response.data.data);
      toast.success("Card created successfully!");
      setCurrentSlide("CreationProcessStep");
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        error.response?.data?.message ||
          "An error occurred while creating the card"
      );
    } finally {
      setLoading(false);
    }
  };

  const getDollarRate = useCallback(async () => {
    setIsLoading(true);
    const url = `${baseUrl}current-dollar-rate`;
  
    try {
      const res = await axios.get(url, config(token));
      const rate = res.data?.data;
  
      if (rate && !isNaN(rate)) {
        setUsdRate(parseFloat(rate).toFixed(2));
        toast.success("Dollar rate fetched successfully");
      } else {
        setUsdRate("N/A");
      }
    } catch (error) {
      const msg = error?.response?.data?.message || "Failed to fetch dollar rate";
      toast.error(msg);
      setUsdRate("N/A");
    } finally {
      setIsLoading(false);
    }
  }, [token]);
  
  useEffect(() => {
    getDollarRate();
  }, [getDollarRate]);
  

  return (
    <div className="w-full max-w-2x">
      {currentSlide === "CreatePin" ? (
        <ConfirmPin setCurrentSlide={setCurrentSlide} />
      ) : currentSlide === "CreationProcess" ? (
        <>
          <button onClick={goBackHandler} className="text-gray-500">
            &larr; Back
          </button>
          <h1 className="text-1xl font-bold mb-2 text-center">
            Create your card
          </h1>
          <h1 className="font-semibold mb- text-center">
            {isLoading ? "Loading..." : `Rate NGN: ${usdRate || "N/A"} = $1`}
          </h1>

          <h2 className="text-lg font-semibold m-6">
            Benefits of our Virtual Card
          </h2>
          <div className="space-y-8 mb-6">
            <div className="flex items-center">
              <img
                src={Virtualsm}
                alt="Free Virtual Card"
                className="w-10 h-10 mr-4 rounded-full"
              />
              <div>
                <h3 className="font-semibold">Free Virtual Card</h3>
                <p className="text-sm text-gray-600">
                  Get our free virtual card and make payments in USD and NGN
                  effortlessly.
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <img
                src={virtualCardSmall}
                alt="Manage Card Activities"
                className="w-10 h-10 mr-4"
              />
              <div>
                <h3 className="font-semibold">
                  Manage all Card Activities Effortlessly
                </h3>
                <p className="text-sm text-gray-600">
                  Freeze and unfreeze your card at any time.
                </p>
              </div>
            </div>

            <div className="flex items-center">
              <img
                src={unbeatable}
                alt="Unbeatable Exchange Rates"
                className="w-10 h-10 mr-4"
              />
              <div>
                <h3 className="font-semibold">Unbeatable Exchange Rates</h3>
                <p className="text-sm text-gray-600">
                  We provide the best exchange rates and seamless transactions
                  at all times.
                </p>
              </div>
            </div>
          </div>

         

          <form onSubmit={handleCardCreationProcess}>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Date of Birth
              </label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleInputChange}
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Street
              </label>
              <input
                type="text"
                name="street"
                value={formData.street}
                onChange={handleInputChange}
                placeholder="2 Mende Street Maryland"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            {/* Add similar blocks for other inputs */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                City
              </label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                placeholder="Maryland"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                State
              </label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Lagos"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Nigeria"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Postal Code
              </label>
              <input
                type="text"
                name="postal_code"
                value={formData.postal_code}
                onChange={handleInputChange}
                placeholder="100012"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Identification Type
              </label>
              <input
                type="text"
                name="identification_type"
                value={formData.identification_type}
                onChange={handleInputChange}
                placeholder="BVN"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Identification Number
              </label>
              <input
                type="text"
                name="identification_number"
                value={formData.identification_number}
                onChange={handleInputChange}
                placeholder="BVN Number"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Photo
              </label>
              <input
                type="file"
                name="photo"
                onChange={handleInputChange}
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Identity Type
              </label>
              <input
                type="text"
                name="identity_type"
                value={formData.identity_type}
                onChange={handleInputChange}
                placeholder="Voters Card OR NIN"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Identity Number
              </label>
              <input
                type="text"
                name="identity_number"
                value={formData.identity_number}
                onChange={handleInputChange}
                placeholder="Identity No / Card No"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Identity Country
              </label>
              <input
                type="text"
                name="identity_country"
                value={formData.identity_country}
                onChange={handleInputChange}
                placeholder="Nigeria"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Upload ID
              </label>
              <input
                type="file"
                name="identity_image"
                onChange={handleInputChange}
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Dollar Amount to Top Up
              </label>
              <input
                type="number"
                name="dollar_amount_to_topup"
                value={formData.dollar_amount_to_topup}
                onChange={handleInputChange}
                placeholder="50"
                className="block w-full py-3 px-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
            </div>

            {/* <div className="flex justify-center">
              <Btn
                text={"Create Card"}
                className={"bg-[#008AD8] text-white w-full py-3 rounded-lg"}
                onClick={handleCardCreationProcess}
              />
            </div> */}
          </form>

          <div className="flex justify-center">
            <Btn
              text={"Create Card"}
              className={
                "bg-[#008E56] text-white px-6 py-3 rounded-lg md:px-240 lg:px-24"
              }
              loadingState={loading}
              onClick={handleCardCreationProcess}
              disabled={!isFormValid}
            />
          </div>
        </>
      ) : currentSlide === "CreationProcessStep" ? (
        <CreationProcess />
      ) : (
        <GenerateCard />
      )}
    </div>
  );
};

export default CardType;
