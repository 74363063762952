import React from "react";

import how from "../../Assets/images/how.png";
const How = () => {
  return (
    <div className='max-width1 grid grid-cols-1 md:grid-cols-2 gap-4 my-16'>
      <div>
        <h2 className='font-bold text-xl my-4'>How eshopAfrica work</h2>
        <p className='text-sm'>
          <strong> Step 1</strong>
          <br />
          Register with eShopAfrica Simply fill out and submit our simple online
          registration form. With your eShopAfrica Account, you may start
          shipping and enjoying seamless payment right now. <br />
          <br />
          <strong>Step 2</strong>
          <br />Shop and ship from online stores in the US, UK, China, and other countries straight to your location.
           You can also send shipments from Nigeria to over 200 countries via express or cargo. 
           Discover top brands and amazing deals from retailers like Amazon, Walmart, and Adidas. 
           Create a shipment on eShopAfrica and have your goods delivered to your country seamlessly.
          <br />
          <br /> <strong>Step 3</strong>
          <br />
          Use your card or wallet to make a payment. Choose your preferred shipping method, and we’ll help you save money.
           For imports, you’ll receive an alert on your eShopAfrica dashboard once your shipment arrives at our locations
            in the US, UK, or China. 
          We also ship from Nigeria to over 200 countries worldwide. You can view and manage all your shipments anytime.
          <br />
          <br />
          For exportation documentation, our professionals will record product
          information and inspect each package to guarantee no items are
          damaged. In our warehouse, your products are kept safe. <br />
          <br />
          <strong>Step 4</strong>
          <br />
          Get your things quickly and without anxiety. Thanks to our long-term
          connections with our partners, you'll get speedy delivery at a
          discounted rate. Packages can be sent internationally in as little as
          2-14 days once they depart the eShopAfrica warehouse.
        </p>
      </div>
      <div className='flex items-center justify-center my-10 md:my-0'>
        <img className='md:w-2/3' src={how} alt='why' />
      </div>
    </div>
  );
};

export default How;
