import React, { useEffect } from "react";
import DollarCardHero from "../../Components/DollarCard/hero";
import Content from "../../Components/DollarCard/content";

const DollarCard = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <DollarCardHero />
      <Content />
    </div>
  );
};

export default DollarCard;
